import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { FormsModule } from '@angular/forms';
import { SvgIconModule } from '../shared/svg-icon/svg-icon.module';
import { ProgressBarModule } from '../shared/progress-bar/progress-bar.module';
import { ScoreColorModule } from '../shared/score-color/score-color.module';
import { NgbDropdownConfig, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CygovLoaderModule } from 'app/shared/cygov-loader/cygov-loader.module';
import { UsernameIconModule } from 'app/shared/username-icon/username-icon.module';
import { GeneratorModule } from 'app/generator/generator.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SvgIconModule,
    ProgressBarModule,
    ScoreColorModule,
    SvgIconModule,
    NgbDropdownModule,
    CygovLoaderModule,
    UsernameIconModule,
    GeneratorModule,
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  providers: [NgbDropdownConfig],
})
export class HeaderModule {}
