import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { UtilsService } from '../utils.service';

@Directive({
  selector: '[cygovScanPointsColor]',
})
export class ScanPointsColorDirective implements OnChanges {
  @Input() angle: number;

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    const angle: string = changes.angle.currentValue;
    if (UtilsService.isDefined(angle)) {
      this.addSectorClass(angle);
    }
  }

  private addSectorClass(angle) {
    let className = null;

    if (angle <= 90) {
      className = 'sector1';
    } else if (angle > 90 && angle <= 180) {
      className = 'sector2';
    } else if (angle > 180 && angle <= 270) {
      className = 'sector3';
    } else if (angle > 270 && angle <= 360) {
      className = 'sector4';
    }

    this.renderer.addClass(this.element.nativeElement, className);
  }
}
