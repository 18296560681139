import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneratorComponent } from './generator.component';
import { SelectModule } from 'app/shared/select/select.module';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToggleButtonModule } from 'app/shared/toggle-button/toggle-button.module';
import { CheckboxModule } from 'app/shared/checkbox/checkbox.module';
import { FormsModule } from '@angular/forms';
import { CygovLoaderModule } from 'app/shared/cygov-loader/cygov-loader.module';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { NgbCalendarModule } from 'app/shared/ngb-calendar/ngb-calendar.module';

// import { GeneratorRoutes } from './generator.routes';

@NgModule({
  declarations: [GeneratorComponent],
  imports: [
    CommonModule,
    // GeneratorRoutes,
    FormsModule,
    SelectModule,
    SvgIconModule,
    NgbModule,
    ToggleButtonModule,
    CheckboxModule,
    PipesModule,
    CygovLoaderModule,
    NgbCalendarModule,
  ],
  exports: [GeneratorComponent],
  entryComponents: [GeneratorComponent],
})
export class GeneratorModule {}
