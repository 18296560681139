import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  CreateEntityInput,
  GetEntityQuery,
  DefaultSettingInput,
  QualificationInput,
  EntityTypeEnum,
  QuestionnaireInput,
  FrequencyCheckEnum,
  AccessLevelEnum,
  VendorStatusEnum,
  CollectionStatusEnum,
  RoleEnum,
  CreateUserInput,
  APIService,
  GetUserQuery,
  CreateUserMutation,
  CreateEntityMutation,
  ImpactEnum,
  StandardType,
  CreateVendorsDetailInput,
  CreateIntelligenceInput,
} from '../../../API.service';
import { NgForm } from '@angular/forms';
import { CrbVendorConstant } from './crb-vendor.constant';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'app/shared/utils.service';
import { AuthService } from 'app/auth/auth.service';
import * as uuid from 'uuid';
import { EntityService, RequiredStandard } from 'app/shared/entity.service';
import { NGXLogger } from 'ngx-logger';
import { ImpactNumEnum } from 'app/shared/enums/impact.enum';

@Component({
  selector: 'cygov-add-crb-vendor-modal',
  templateUrl: './add-crb-vendor-modal.component.html',
  styleUrls: ['./add-crb-vendor-modal.component.scss'],
})
export class AddCrbVendorModalComponent implements OnInit {
  @Input() entity: GetEntityQuery;
  @Output() modalResult = new EventEmitter<boolean>();
  vendor: CreateEntityInput;
  vendorDetails: CreateVendorsDetailInput;
  mandatoryArtifacts: QualificationInput[];
  selectedSurvey: ImpactEnum;
  selectedDueDate;
  dueDateOptions: number[] = [30, 60, 90];
  accessLevels = Object.keys(AccessLevelEnum).map(key => AccessLevelEnum[key]);
  surveys = Object.keys(ImpactEnum).map(key => ImpactEnum[key]);
  standardOptions: QualificationInput[];
  impactOptions: QualificationInput[];
  standardList: RequiredStandard[] = EntityService.initStandardList();

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private api: APIService,
    public activeModal: NgbActiveModal,
    private logger: NGXLogger,
    private entityService: EntityService,
    private config: NgbDatepickerConfig
  ) {
    const current = new Date();
    config.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
    config.outsideDays = 'hidden';
  }

  ngOnInit(): void {
    this.vendor = this.initVendor(this.entity);
    this.vendorDetails = this.initVendorDetails();
    this.loadDefaultSetting(this.entity.defaultSetting);
  }

  initVendor(entity: GetEntityQuery): CreateEntityInput {
    const now = Date.now();
    const generatedId = uuid.v4();
    const accessGroups = [EntityService.getAdminGroup(), entity.id, generatedId];
    const participantGroup = [EntityService.getParticipantGroup()];
    const vendor: CreateEntityInput = {
      id: generatedId,
      parentId: entity.id,
      rootEntityId: entity.id,
      childEntityId: generatedId,
      name: '',
      entityType: EntityTypeEnum.VENDOR,
      scores: {
        collection: 0,
        remediation: 0,
        target: 0,
        total: 0,
      },
      createdAt: now,
      modifiedAt: now,
      domain: EntityService.getDomain(),
      accessGroups,
      participantGroup,
      activeAssessmentId: 'null',
    };
    vendor.accessGroups.concat(entity.accessGroups);
    return vendor;
  }

  initVendorDetails(): CreateVendorsDetailInput {
    return {
      id: this.vendor.id,
      externalScan: 0,
      financialRisk: 0,
      criticalGaps: 0,
      standardsQualify: [],
      impactsQualify: [],
      status: VendorStatusEnum.IN_PROCESS,
      probability: 100,
      collectionStatus: CollectionStatusEnum.HAS_NOT_BEGUN,
      primaryPoc: {
        name: '',
        email: '',
      },
      timeline: {
        collectionDate: null,
        nextReviewDate: null,
        initiationDate: null,
        frequency: null,
      },
      activeScan: true,
      domain: '',
      privacyData: false,
      // TODO: Vendor Schema Changes Integration
      // ,
      // intelligence: {
      //   breaches: [],
      //   externalThreats: [],
      // },
    };
  }

  loadDefaultSetting(defaultSetting: DefaultSettingInput): void {
    const dueDate = defaultSetting.dueDateInDays || this.dueDateOptions[0];
    this.selectedDueDate = dueDate ? this.dateAsNgbDateStruct(dueDate) : null;

    this.vendorDetails.timeline.frequency = FrequencyCheckEnum[defaultSetting.frequency];
    this.selectedSurvey = ImpactEnum[defaultSetting.surveyLevel];
    this.vendorDetails.accessLevel = AccessLevelEnum[defaultSetting.accessLevel];

    this.vendorDetails.privacyData = defaultSetting.privacyData || false;

    // default entity standards and artifacts - predetermined by MSSP
    this.standardOptions =
      defaultSetting.standards ||
      Object.values(CrbVendorConstant.compliances).map(standard => ({
        isQualify: false,
        name: standard,
      }));
    this.standardOptions = JSON.parse(
      JSON.stringify(this.standardOptions, (k, v) => (k === '__typename' ? undefined : v))
    );

    this.mandatoryArtifacts =
      defaultSetting.artifacts ||
      Object.values(CrbVendorConstant.artifacts).map(name => ({
        isQualify: true,
        name,
      }));

    this.mandatoryArtifacts = JSON.parse(
      JSON.stringify(this.mandatoryArtifacts, (k, v) => (k === '__typename' ? undefined : v))
    );
    this.impactOptions =
      defaultSetting.impactsQualify ||
      CrbVendorConstant.impacts.map(name => ({
        isQualify: true,
        name,
      }));

    this.impactOptions = JSON.parse(
      JSON.stringify(this.impactOptions, (k, v) => (k === '__typename' ? undefined : v))
    );
    this.calcCrbVendorImpact();
  }

  isValid(ngForm: NgForm): boolean {
    let valid = true;
    if (!ngForm.value.name) {
      this.toastr.error('Vendor name is required');
      valid = false;
    }
    if (!ngForm.value.primaryPocName) {
      this.toastr.error('Vendor POC name is required');
      valid = false;
    }
    if (!ngForm.value.primaryPocMail) {
      this.toastr.error('Vendor POC email is required');
      valid = false;
    } else if (ngForm.value.primaryPocMail.toLowerCase().indexOf('gmail') !== -1) {
      this.toastr.error('Company email only');
      valid = false;
    }

    return valid;
  }

  resetForm(): void {
    let newVendor: CreateEntityInput;
    this.vendor = newVendor;
    this.loadDefaultSetting(this.entity.defaultSetting);
  }

  async addVendor(): Promise<void> {
    const isUserEmailPresent = await this.authService.isUserPresent(
      this.vendorDetails.primaryPoc.email
    );
    this.setTimeline();
    if (isUserEmailPresent) {
      this.toastr.error('User with this email Already exists');
      return;
    }

    this.toastr.info(`Adding ${this.vendor.name}!`);
    try {
      this.activeModal.close();
      if (!this.vendorDetails.domain) {
        this.vendorDetails.domain = this.vendorDetails.primaryPoc.email.split('@').pop();
      }
      const createdEntity = await this.addVendorEntity();
      const vendorUser = await this.addVendorUser();
      await this.createNewIntelligence();
      const updateEntity = await this.entityService.assignProjectManager(
        createdEntity.id,
        vendorUser.id
      );
      await Promise.all(
        updateEntity.activeAssessment.standardFrameworkList.items.map(async framework => {
          await this.entityService.assignFramework(
            framework.key,
            framework.assessmentId,
            updateEntity.projectManager
          );
        })
      );
      // await this.entityService.assignAssessment(updateEntity.activeAssessmentId, vendorUser.id);
      this.toastr.success(`${this.vendor.name} added successfully!`);
      this.modalResult.emit(true);
    } catch (e) {
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  async createNewIntelligence(): Promise<void> {
    const newIntelligence: CreateIntelligenceInput = { id: this.vendor.id };
    await this.api.CreateIntelligence(newIntelligence);
  }

  async addVendorEntity(): Promise<CreateEntityMutation> {
    try {
      this.vendorDetails.impact = this.calcCrbVendorImpact();
      const requiredStandards: QualificationInput[] = this.standardOptions.filter(
        standard => standard.isQualify
      );
      this.vendorDetails.impactsQualify = this.impactOptions;

      const selectedStandards = this.standardList.filter(standard => {
        const selected = requiredStandards.filter(s => s.name.toUpperCase() === standard.name)[0];
        if (selected) {
          standard.checked = true;
          return true;
        }
      });

      selectedStandards.forEach(standard => {
        standard.name = standard.name.replace(/ /g, '_');
        standard.fileName = `CRB_${standard.name}`;
      });

      this.mandatoryArtifacts.forEach(artifact => {
        if (artifact.isQualify) {
          const standardName = artifact.name.toLowerCase().replace(/ /g, '_');
          selectedStandards.push({
            name: standardName,
            key: standardName,
            type: StandardType.ARTIFACT,
            checked: true,
            fileName: `CRB_ARTIFACT_${standardName}`,
          });
        }
      });
      this.vendorDetails.standardsQualify = requiredStandards.map(standard => {
        standard.isQualify = false;
        return standard;
      });

      selectedStandards.push({
        name: 'NIST_CSF',
        key: 'NIST_CSF',
        type: StandardType.RISK_FRAMEWORK,
        checked: true,
        fileName: 'VENDOR_RISK_CRB',
        filter: {
          impact: this.selectedSurvey,
        },
      });

      const createdEntity = await this.entityService.createEntity(this.vendor, selectedStandards);
      await this.api.CreateVendorsDetail(this.vendorDetails);
      return createdEntity;
    } catch (e) {
      this.logger.error('addVendor - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  async addVendorUser(): Promise<CreateUserMutation> {
    try {
      const newUser: CreateUserInput = {
        name: this.vendorDetails.primaryPoc.name,
        email: this.vendorDetails.primaryPoc.email,
        role: RoleEnum.VENDOR,
        entityId: this.vendor.id,
        isCognitoUser: false,
      };

      const inputUser = JSON.stringify(newUser);
      const domain = EntityService.getAdminGroup();
      const createdUser: any = JSON.parse(await this.api.AddCognitoUser(inputUser, domain));

      return createdUser;
    } catch (e) {
      this.logger.error('addVendorUser - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  calcCrbVendorImpact(): number {
    const pointForEachImport = 100 / this.impactOptions.length;
    const requiredImpacts: QualificationInput[] = this.impactOptions.filter(
      impact => impact.isQualify
    );
    const impactLevel = Math.round(pointForEachImport * requiredImpacts.length);
    switch (true) {
      case impactLevel <= ImpactNumEnum.LOW:
        this.selectedSurvey = ImpactEnum.LOW;
        break;
      case impactLevel > ImpactNumEnum.LOW && impactLevel <= ImpactNumEnum.MEDIUM:
        this.selectedSurvey = ImpactEnum.MEDIUM;
        break;
      case impactLevel > ImpactNumEnum.MEDIUM && impactLevel <= ImpactNumEnum.HIGH:
        this.selectedSurvey = ImpactEnum.HIGH;
        break;
      default:
        this.selectedSurvey = ImpactEnum.VERY_HIGH;
        break;
    }
    return impactLevel;
  }

  initQuestionnaires(surveyData): QuestionnaireInput[] {
    const questionnaires = [];
    const processAreaMap = {};
    surveyData.forEach(el => {
      const processArea = el.processArea.toLowerCase();
      if (!processAreaMap[processArea]) {
        processAreaMap[processArea] = true;
        let questionnaire: QuestionnaireInput;
        questionnaire.tag = processArea;
        questionnaires.push(questionnaire);
      }
    });

    return questionnaires;
  }

  setTimeline(): void {
    const date: Date = this.getDueDate();
    this.vendor.timeline = {
      collectionDate: this.getDueDate().getTime(),
      initiationDate: date.getTime(),
    };
    this.vendorDetails.timeline.collectionDate = this.getDueDate().getTime();
    this.vendorDetails.timeline.initiationDate = date.getTime();
    let nextReviewDate;
    switch (this.vendorDetails.timeline.frequency) {
      case FrequencyCheckEnum.ANNUAL:
        nextReviewDate = date.setMonth(date.getMonth() + 12);
        break;
      case FrequencyCheckEnum.SEMI_ANNUAL:
        nextReviewDate = date.setMonth(date.getMonth() + 6);
        break;
      case FrequencyCheckEnum.QUARTERLY:
        nextReviewDate = date.setMonth(date.getMonth() + 3);
    }

    this.vendorDetails.timeline.nextReviewDate = nextReviewDate;
  }

  getDueDate(): Date {
    return new Date(
      this.selectedDueDate.year,
      this.selectedDueDate.month - 1,
      this.selectedDueDate.day
    );
  }

  dateAsNgbDateStruct(days): any {
    days = Number(days);
    const date = new Date();
    date.setDate(date.getDate() + days);

    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  getImpactText(survey: string): string {
    return survey
      .toLowerCase()
      .split('_')
      .join('-');
  }
}
