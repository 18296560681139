import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ThirdPartyService } from 'app/third-party/third-party.service';
import { ToastrService } from 'ngx-toastr';
import { GetEntityQuery, VendorStatusEnum, EntityTypeEnum, ScoresInput } from 'app/API.service';
import { EntityService, GetEntityQueryExtended } from 'app/shared/entity.service';

@Component({
  selector: 'cygov-vendor-item',
  templateUrl: './vendor-item.component.html',
  styleUrls: ['./vendor-item.component.scss'],
})
export class VendorItemComponent implements OnInit, OnChanges {
  @Input() vendor: GetEntityQueryExtended;
  collectionHeight = 20;
  isUpdatingVendor = false;
  entityTypeEnum = EntityTypeEnum;

  constructor(private entityService: EntityService, private toastr: ToastrService) {}

  ngOnInit(): void {
    console.log('item-onInit', this.vendor);
  }

  ngOnChanges(): void {
    console.log('item-onChanges', this.vendor);
  }

  calculateScore(vendor: GetEntityQuery): ScoresInput {
    return vendor.scores;
  }

  getImpactText(impact: number): string {
    return ThirdPartyService.getImpactText(impact);
  }

  async updateVendor(): Promise<void> {
    this.isUpdatingVendor = true;
    this.toastr.info(`  Updating Vendor: ${this.vendor.name}`);
    try {
      const vendorToUpdate = JSON.parse(
        JSON.stringify(this.vendor, (k, v) =>
          k === '__typename' || k === 'logoUrl' || k === 'isSelected' || k === 'activeAssessment'
            ? undefined
            : v
        )
      );
      this.vendor = await this.entityService.updateEntity(vendorToUpdate);
      this.isUpdatingVendor = false;
      this.toastr.success(`${this.vendor.name} Updated successfully`);
    } catch (e) {
      this.isUpdatingVendor = false;
      this.toastr.error(`Failed to update ${this.vendor.name}`);
    }
  }

  async statusChanged(status: VendorStatusEnum): Promise<void> {
    this.vendor.vendorDetails.status = status;
    if (status === VendorStatusEnum.APPROVED) {
      this.vendor.vendorDetails.approvalDate = Date.now();
    } else {
      this.vendor.vendorDetails.approvalDate = null;
    }
    await this.updateVendor();
  }
}
