import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from './../../../shared/confirmation-modal/confirmation-modal.component';
import { APIService, VendorStatusEnum } from 'app/API.service';
import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EntityService, GetEntityQueryExtended } from '../../../shared/entity.service';

@Component({
  selector: 'cygov-vendor-detail',
  templateUrl: './vendor-detail.component.html',
  styleUrls: ['./vendor-detail.component.scss'],
})
export class VendorDetailComponent implements OnInit {
  @Input() vendor: GetEntityQueryExtended;
  @Output() closeVendorDetail = new EventEmitter<any>();
  @Output() removeVendor = new EventEmitter<string>();
  @Output() updateVendorEvent = new EventEmitter<any>();
  isLoading = false;
  vendorStatusEnum = VendorStatusEnum;

  constructor(
    private api: APIService,
    private entityService: EntityService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.isLoading = false;
  }

  closeDetail(): void {
    this.closeVendorDetail.emit(this.vendor);
  }

  async updateHandler(vendor: GetEntityQueryExtended, updateVendor = true): Promise<void> {
    try {
      this.vendor = vendor;
      this.toastr.info(`  Updating Vendor: ${this.vendor.name}`);
      if (updateVendor) {
        await this.updateVendor(vendor);
      }
      await this.updateVendorDetails(vendor);
      this.updateVendorEvent.emit(this.vendor);
      this.toastr.success(`${this.vendor.name} Updated successfully`);
    } catch (e) {
      console.log('Vendor Update Faliure: ', e);
      this.toastr.error(`Failed to update ${this.vendor.name}`);
    }
  }

  async updateVendor(vendor: GetEntityQueryExtended): Promise<void> {
    const vendorToUpdate = JSON.parse(
      JSON.stringify(vendor, (k, v) =>
        k === '__typename' ||
        k === 'logoUrl' ||
        k === 'isSelected' ||
        k === 'activeAssessment' ||
        k === 'vendorDetails'
          ? undefined
          : v
      )
    );
    await this.entityService.updateEntity(vendorToUpdate);
  }

  async updateVendorDetails(vendor: GetEntityQueryExtended): Promise<void> {
    const vendorDetailsToUpdate = JSON.parse(
      JSON.stringify(vendor.vendorDetails, (k, v) =>
        k === '__typename' || k === 'intelligence' ? undefined : v
      )
    );
    await this.api.UpdateVendorsDetail(vendorDetailsToUpdate);
  }

  async deleteVendor(): Promise<void> {
    try {
      this.removeVendor.emit(this.vendor.id);
      this.closeDetail();
      await this.entityService.deleteEntity(this.vendor.id);
      this.toastr.success(`${this.vendor.name} deleted successfully`);
    } catch (e) {
      this.toastr.error(`Failed to delete ${this.vendor.name}`);
    }
  }
  openConfirmationModal(): void {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
      windowClass: 'confirmation-modal',
    });
    modalRef.componentInstance.message = `Are you sure you want to approved ${this.vendor.name}?`;

    modalRef.componentInstance.modalResult.subscribe((event: boolean) => {
      if (event) {
        this.saveStatus(VendorStatusEnum.APPROVED);
      }
      modalRef.close();
    });
  }

  saveStatus(status: VendorStatusEnum): void {
    this.statusChanged(status);
    this.updateHandler(this.vendor, true);
  }

  statusChanged(status: VendorStatusEnum): void {
    this.vendor.vendorDetails.status = status;
    if (status === VendorStatusEnum.APPROVED) {
      this.vendor.vendorDetails.approvalDate = Date.now();
    } else {
      this.vendor.vendorDetails.approvalDate = null;
    }
  }
}
