// Sort by column for array of objects
// Sort array of object base on column and its specific word
// After specific word sort, it will automatically sort by alphabetical (for string)
export function ObjectsSort(list, column, word = ''): any[] {
  let result = 0;
  return list.sort((obj1, obj2) => {
    let object1Column = obj1[column];
    let object2Column = obj2[column];
    if (typeof object1Column === 'string') {
      object1Column = object1Column.toLowerCase();
    }
    if (typeof object2Column === 'string') {
      object2Column = object2Column.toLowerCase();
    }

    if (
      (object1Column === undefined && object2Column === undefined) ||
      object1Column === undefined
    ) {
      result = 0;
    } else if (
      // if second object is undefined move it to last
      object2Column === undefined ||
      // if word is present and matched with first object, first object comes first in array
      (word.length > 0 && word.toLowerCase() === object1Column)
    ) {
      result = -1;
    } else if (
      // if word is present and matched with second object, second object comes first in array
      (word.length > 0 && word.toLowerCase() === object2Column) ||
      // if first greater than second, first obj move behind second
      object1Column > object2Column
    ) {
      result = 1;
      // if first smaller than second, first obj comes forward in array
    } else if (object1Column < object2Column) {
      result = -1;
    }

    return result;
  });
}
