import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScanDetailsComponent } from './scan-details.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

@NgModule({
  declarations: [ScanDetailsComponent],
  imports: [CommonModule, SvgIconModule],
  exports: [ScanDetailsComponent],
  entryComponents: [ScanDetailsComponent],
})
export class ScanDetailsModule {}
