import { Component, OnInit, Input } from '@angular/core';
import { GetEntityQueryExtended } from '../entity.service';

class Clock {
  show: boolean;
  className: string;
  tooltip: string;
}

@Component({
  selector: 'cygov-third-party-progress-bar',
  templateUrl: './third-party-progress-bar.component.html',
  styleUrls: ['./third-party-progress-bar.component.scss'],
})
export class ThirdPartyProgressBarComponent implements OnInit {
  @Input() vendor: GetEntityQueryExtended;
  @Input() preFill: string;
  @Input() postFill: string[];
  @Input() height: number;

  showCrown = false;
  clock: Clock;

  constructor() {}

  ngOnInit() {
    this.showCrown = this.vendor.scores.collection > 90;
    this.clock = this.initClockDisplay();
  }

  initClockDisplay(): Clock {
    const clock: Clock = {
      show: false,
      className: '',
      tooltip: null,
    };

    const collectionDate =
      (this.vendor.vendorDetails.timeline && this.vendor.vendorDetails.timeline.collectionDate) ||
      null;
    if (!collectionDate) {
      return clock;
    }

    let delta: number;
    // red-clock condition
    if (Date.now() > collectionDate) {
      delta = Math.abs(Date.now() - collectionDate) / 1000;
      clock.show = true;
      clock.className = 'red-clock';
      clock.tooltip = `Due date has passed ${Math.floor(delta / 86400)} ago`;
    } else {
      // yellow-clock condition
      const initiationDate = this.vendor.vendorDetails.timeline.initiationDate;
      // get total seconds between the times
      delta = Math.abs(collectionDate - initiationDate) / 1000;
      // calculate whole days
      const totalDays = Math.floor(delta / 86400);
      const percentagePerDay = totalDays / 100;
      const collection = this.vendor.scores.collection;
      delta = Math.abs(Date.now() - initiationDate) / 1000;
      const daysUntilNow = Math.floor(delta / 86400);
      if (collection < percentagePerDay * daysUntilNow) {
        clock.show = true;
        clock.className = 'yellow-clock';
        clock.tooltip = `${totalDays - daysUntilNow} days left`;
      }
    }

    return clock;
  }
}
