import { SelectModule } from './../select/select.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddUserModalComponent } from './add-user-modal.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, SvgIconModule, FormsModule, SelectModule],
  declarations: [AddUserModalComponent],
  exports: [AddUserModalComponent],
  entryComponents: [AddUserModalComponent],
})
export class AddUserModalModule {}
