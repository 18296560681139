import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[cygovScoreColor]',
})
export class ScoreColorDirective implements OnChanges {
  @Input() score: number;
  @Input() isText: boolean;

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    const score: string = changes.score.currentValue;
    const isText: boolean = changes.isText ? changes.isText.currentValue : false;
    // isDefined
    if (!(typeof score === 'undefined' || score === null)) {
      this.addScoreClass(score, isText);
    }
  }

  private addScoreClass(score, isText) {
    let className = null;
    switch (true) {
      case score <= 3.33:
        className = 'score-low';
        break;
      case score < 6.66:
        className = 'score-medium';
        break;
      case score >= 6.66:
        className = 'score-high';
        break;
    }
    className += isText ? '-stroke' : '';
    this.renderer.addClass(this.element.nativeElement, className);
  }
}
