import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import {
  NgbDateStruct,
  NgbInputDatepicker,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from '../utils.service';

/**
### Official Bug in DatePicker
 *
 *  MinDate will not dynamically update when dynamic binding, you need to close and re-open the calendar
 *  by force in order to see new values.
 *  This component will not handle that, the component in which you will use this will open or close it
 *  to update using the calendar reference.
 *
#### Bug Issued Forum for DatePicker
 *
 *  https://github.com/valor-software/ngx-bootstrap/issues/5888
 *
 *
 * #### Calendar Mode
 *
 * - ToggleButton = True
 *
 *  This will show an input field and an icon to toggle the calendar
 *
 *
 * - ToggleButton = False
 *
 *  This will show the calendar (opened) but you cannot close it.
 */

@Component({
  selector: 'cygov-ngb-calendar',
  templateUrl: './ngb-calendar.component.html',
  styleUrls: ['./ngb-calendar.component.scss'],
})
export class NgbCalendarComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() selectedDate: NgbDateStruct | null = null; // The current selected Date
  @Input() isCalendarDisabled = false; // Option to disable the calendar
  @Input() dateToShow: NgbDateStruct | null = null; // To show date on top of calendar, only seen in non-toggle mode
  @Input() dateType: string; // An ID for the calendar
  @Input() displayName: string; // Name of calendar showing on top of calendar,only seen in non-toggle mode
  @Input() inputMaxDate: Date; // minimum selection date on calendar, not passing this value will not set the minDate
  @Input() inputMinDate: Date; // maximum selection date on calendar, not passing this value will not set the maxDate
  @Input() toggleButton = false; // Toggle the icon and mode.
  @Input() placeHolder = ''; // Placeholder to show on input field, only visible in Toggle Mode
  @Input() dontDisplayInputArea = false; // To display Input area of date picker

  // Trigger to return the new selected date if any
  @Output() selectedDateChange: EventEmitter<NgbDateStruct> = new EventEmitter<NgbDateStruct>();
  // Pass the calendar ref to parent - optional
  @Output() calendarRef: EventEmitter<NgbInputDatepicker> = new EventEmitter<NgbInputDatepicker>();
  @ViewChild('dateInput', { static: false }) dateCalendar: NgbInputDatepicker; // A reference to the calendar

  loaded = false;

  constructor(private parserFormatter: NgbDateParserFormatter) { }

  getDateinDateFormat(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  calendarNavigateTrigger(event, date: NgbDateStruct): void {
    // navigate to the next month
    if (!this.toggleButton) {
      date.month = event.next.month;
      date.year = event.next.year;
    }
  }

  ngAfterViewInit(): void {
    // emit the calendar ref access internal functions
    setTimeout(() => {
      this.calendarRef.emit(this.dateCalendar);
      this.loaded = true;
    }, 100);
  }

  ngOnInit(): void { }

  dateUpdated(date): void {
    // emit the new date selected
    this.selectedDateChange.emit(date);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.loaded) {
      // Manually changing the date to refresh the calendar
      const updatedDate = UtilsService.getShortDate(
        new Date(this.parserFormatter.format(this.selectedDate))
      );
      this.dateCalendar.manualDateChange(updatedDate, true);
    }
  }
}
