/* eslint-disable no-useless-escape */
import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { UtilsService } from '../utils.service';
import {
  GetUserQuery,
  RoleEnum,
  CreateUserInput,
  APIService,
  GetEntityQuery,
} from '../../API.service';
import { EntityService } from '../entity.service';
import { NGXLogger } from 'ngx-logger';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cygov-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.scss'],
})
export class AddUserModalComponent implements OnInit {
  @Input() fromVendor = false;
  @Output() modalResult = new EventEmitter<CreateUserInput>();
  entity: GetEntityQuery;
  subEntity: GetEntityQuery;
  subEntityList: GetEntityQuery[];
  currentUser: GetUserQuery;
  newUser: CreateUserInput;
  roleEnum = RoleEnum;
  roleOptions: string[] = [];
  selectedEntity: string;
  leaderTypes = ['SubEntityLeader'];
  leaderType;

  constructor(
    private authService: AuthService,
    private api: APIService,
    private entityService: EntityService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private logger: NGXLogger,
    public activeModal: NgbActiveModal
  ) {}

  async ngOnInit() {
    this.currentUser = await this.authService.getCurrentUser();
    this.newUser = this.initUser();
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.entity = entityId ? await this.api.GetEntity(entityId) : null;
    this.roleOptions = this.populateRoleOptions();
    try {
      if (this.entity) {
        if (this.entityService.isChildEntity(this.entity)) {
          if (await this.authService.hasPermission(RoleEnum.LEADER, this.entity.parentId)) {
            this.subEntityList = await this.entityService.listChildEntitysByParentId(
              this.getDisplayEntityId()
            );
            this.leaderTypes = [...this.leaderTypes, 'EntityLeader'];
          } else {
            this.subEntityList = [this.entity];
            this.newUser.role = RoleEnum.LEADER;
            this.selectedEntity = this.entity.id;
          }
        } else if (this.entityService.isRootEntity(this.entity)) {
          this.subEntityList = await this.entityService.listChildEntitysByParentId(
            this.getDisplayEntityId()
          );
          this.leaderTypes = [...this.leaderTypes, 'EntityLeader'];
        } else {
          this.toastr.error('Unauthorized');
        }
      }
    } catch (e) {
      this.logger.error('AddUserModalComponent - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  initUser(): CreateUserInput {
    return {
      name: '',
      email: '',
      role: this.getDefault(),
      isCognitoUser: false,
      questionMap: JSON.stringify({}),
    };
  }

  getDefault(): RoleEnum {
    switch (this.currentUser.role) {
      case RoleEnum.ADMIN:
        return RoleEnum.ADMIN;
        break;
      case RoleEnum.MSSP:
        return RoleEnum.MSSP;
        break;
      case RoleEnum.LEADER:
        return RoleEnum.LEADER;
        break;
    }
    return RoleEnum.LEADER;
  }

  getDisplayEntityId() {
    return this.entityService.isChildEntity(this.entity) ? this.entity.parentId : this.entity.id;
  }

  getPermissions() {
    switch (this.newUser.role) {
      case RoleEnum.LEADER:
        if (this.leaderType === this.leaderTypes[0]) {
          return [this.entity.id];
        } else {
          return [this.subEntity.id];
        }
      case RoleEnum.VENDOR:
        return [this.entity.id];
      case RoleEnum.PARTICIPANT:
        return [this.subEntity.id];
      default:
        return [];
    }
  }

  isValid(user: CreateUserInput) {
    let valid = true;
    // eslint-disable-next-line max-len
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(user.email).toLowerCase())) {
      this.toastr.error('Email is incorrect');
      valid = false;
    }
    if (!user.name) {
      this.toastr.error('User name is required');
      valid = false;
    }
    if (!user.role) {
      this.toastr.error('User role is required');
      valid = false;
    }
    if (user.role === RoleEnum.LEADER && !user.entityId) {
      this.toastr.error('Please select sub entity');
      valid = false;
    }

    return valid;
  }

  emitUser(newUser: CreateUserInput) {
    newUser.email = newUser.email.toLowerCase();
    if (this.leaderType === this.leaderTypes[0] && this.selectedEntity) {
      newUser.entityId = this.selectedEntity;
    } else if (this.leaderType === this.leaderTypes[1]) {
      newUser.entityId = this.entity.id;
    }
    if (this.isValid(newUser)) {
      this.modalResult.emit(newUser);
    }
  }

  showSubEntityList(newUserRole) {
    return (
      newUserRole === RoleEnum.LEADER &&
      this.subEntityList &&
      this.leaderType === this.leaderTypes[0]
    );
  }

  populateRoleOptions(): string[] {
    const options: string[] = [];

    switch (this.currentUser.role) {
      case RoleEnum.ADMIN:
        options.push(RoleEnum.ADMIN);
        options.push(RoleEnum.MSSP);
        options.push(RoleEnum.LEADER);
        break;
      case RoleEnum.MSSP:
        options.push(RoleEnum.MSSP);
        options.push(RoleEnum.LEADER);
        break;
      case RoleEnum.LEADER:
        options.push(RoleEnum.LEADER);
        break;
    }
    return options;
  }
}
