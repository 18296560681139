import { Component, Input, OnInit } from '@angular/core';
import { EntityStandardsService } from './entity-standards.service';
import { QualificationInput, EntityTypeEnum } from 'app/API.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'cygov-entity-standards',
  templateUrl: './entity-standards.component.html',
  styleUrls: ['./entity-standards.component.scss'],
})
export class EntityStandardsComponent implements OnInit {
  @Input() entityId: string;
  @Input() entityType: EntityTypeEnum;
  @Input() horizontalView = false;
  @Input() limit = 100;
  @Input() thirdParty = false;
  isVendor: boolean;
  standardsQualify: QualificationInput[] = [];

  constructor(private entityStandardsService: EntityStandardsService, private logger: NGXLogger) {}

  async ngOnInit(): Promise<void> {
    this.isVendor = this.entityType === EntityTypeEnum.VENDOR;
    try {
      const isRootEntity = this.entityType === EntityTypeEnum.ROOT_ENTITY;
      this.standardsQualify = (
        await this.entityStandardsService.buildEntityStandardsQualify(
          this.entityId,
          isRootEntity,
          this.thirdParty
        )
      ).splice(0, this.limit);
    } catch (e) {
      this.logger.error('EntityStandardsComponent - Error: ', e);
    }
  }
}
