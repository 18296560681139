import { IPageInfo } from 'ngx-virtual-scroller';
import { EventEmitter, Input, OnChanges, OnDestroy, AfterViewInit } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { TableHeader } from 'models/table-header.model';
import { ThirdPartyService } from 'app/third-party/third-party.service';
import { GetEntityQuery } from 'app/API.service';
import { EntityService, GetEntityQueryExtended } from 'app/shared/entity.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { UtilsService } from 'app/shared/utils.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cygov-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.scss'],
})
export class VendorListComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() vendorList: GetEntityQuery[];
  @Output() vendorSelected = new EventEmitter<GetEntityQueryExtended>();
  @Output() next = new EventEmitter();
  @Input() isLoading = false;
  @Input() updatable = true;

  allSelected = false;
  showVendorDetail = false;
  selectedVendor: string = null;
  sortData;
  subscription: Subscription = EntityService.onDataChanged.subscribe((dataChanged: boolean) => {
    console.log('subscription');
    if (dataChanged) {
      // TODO: Subscription causes lot of reloads. Need to fix this
      // this.ngOnInit();
    }
  });

  constructor(
    private entityService: EntityService,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    console.log('vendorList', this.vendorList);
    if (this.vendorList) {
      this.vendorList = this.vendorList.map(vendor => {
        return { ...vendor, isSelected: false };
      });
    }
  }
  ngAfterViewInit(): void {
    const vendorId = UtilsService.getRouteParam(this.route.root.snapshot, 'vendorId');
    if (vendorId) {
      const queryParamVendor = this.vendorList.find(obj => {
        return obj.id === vendorId;
      });
      if (queryParamVendor) {
        setTimeout(() => {
          this.toggleList(queryParamVendor);
        }, 0);
      }
    }
  }
  ngOnChanges(): void {
    console.log('vendorList-changes', this.vendorList);
    this.vendorList = this.vendorList.map(vendor => {
      return { ...vendor, isSelected: false };
    });
    if (this.sortData) {
      this.onSortChanged(this.sortData);
    }
  }

  toggleList(vendor: GetEntityQuery): void {
    this.vendorSelected.emit(vendor);
  }

  calculateScore(vendor: GetEntityQuery): void {
    // return new Scores({ collection: vendor.scores.collection });
  }

  onSortChanged(sortData: TableHeader): void {
    console.log('sortChanged');
    this.sortData = sortData;
    ThirdPartyService.sortArray(sortData, this.vendorList);
  }

  fetchMore(event: IPageInfo): void {
    if (this.updatable && !this.isLoading && event.endIndex === this.vendorList.length - 1) {
      // // Need to work with Pagination Task
      // this.next.emit();
    }
  }

  async deleteSelectedVendors(): Promise<void> {
    try {
      const vendorsToDelete = this.vendorList.filter((vendor: any) => vendor.isSelected);
      if (!vendorsToDelete.length) {
        this.toastr.info('No vendor Selected');
        return;
      }

      this.toastr.info('It can take a while...', 'Deleting Vendors');
      const promises = [];
      vendorsToDelete.forEach((vendor: any) => {
        promises.push(this.entityService.deleteEntity(vendor.id));
      });
      await Promise.all(promises);
      this.vendorList = this.vendorList.filter((vendor: any) => !vendor.isSelected);
      this.toastr.success('Selected vendors deleted successfully');
    } catch (e) {
      this.logger.error(e);
      this.toastr.error('Failed to delete vendors');
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
