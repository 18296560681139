import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThirdPartyComponent } from './third-party.component';
import { EntityLeaderRoleGuard } from 'app/shared/guards/entity-leader-role.guard';
import { RiskManagementHeatmapComponent } from './risk-management-heatmap/risk-management-heatmap.component';
import { AllVendorListComponent } from './all-vendor-list/all-vendor-list.component';
import { ThirdPartyOverviewComponent } from './third-party-overview/third-party-overview.component';
import { VendorLeaderRoleGuard } from 'app/shared/guards/vendor-leader-role.guard';

const routes: Routes = [
  {
    path: ':entityId',
    canActivate: [VendorLeaderRoleGuard],
    component: ThirdPartyComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'overview',
      },
      {
        path: 'overview',
        canActivate: [EntityLeaderRoleGuard],
        component: ThirdPartyOverviewComponent,
      },
      {
        path: 'heat-map',
        canActivate: [EntityLeaderRoleGuard],
        component: RiskManagementHeatmapComponent,
      },
      {
        path: 'vendors',
        canActivate: [EntityLeaderRoleGuard],
        component: AllVendorListComponent,
      },
      {
        path: 'gaps',
        loadChildren: () =>
          import('app/remediation/remediation.module').then(m => m.RemediationModule),
      },
      {
        path: 'questionnaire',
        loadChildren: () =>
          import('app/questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
      },
      {
        path: 'collection',
        loadChildren: () =>
          import('app/collection/collection.module').then(m => m.CollectionModule),
      },
    ],
  },
];

export const ThirdPartyRoutes: ModuleWithProviders = RouterModule.forChild(routes);
