import {
  GetEntityQuery,
  DefaultSettingInput,
  UpdateEntityInput,
  FrequencyCheckEnum,
  QualificationInput,
} from './../../API.service';
import { ImpactTextEnum, ImpactNumEnum } from '../../shared/enums/impact.enum';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StandardEnum } from 'app/shared/enums/standard.enum';
import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute } from '@angular/router';
import { ArtifactsEnum } from 'app/shared/enums/artifacts.enum';
import { ToastrService } from 'ngx-toastr';
import { EntityService } from 'app/shared/entity.service';
import { NGXLogger } from 'ngx-logger';
import { CrbVendorConstant } from '../add-vendor/add-crb-vendor-modal/crb-vendor.constant';
import { environment } from 'environments/environment';
@Component({
  selector: 'cygov-default-setting-modal',
  templateUrl: './default-setting-modal.component.html',
  styleUrls: ['./default-setting-modal.component.scss'],
})
export class DefaultSettingModalComponent implements OnInit {
  @Output() closeDefaultModal = new EventEmitter<boolean>();
  entity: GetEntityQuery;
  isLoading = true;

  dueDateOptions: number[] = [30, 60, 90, 120];
  frequencyOptions = Object.keys(FrequencyCheckEnum).map(key =>
    FrequencyCheckEnum[key] !== FrequencyCheckEnum.MANUAL ? FrequencyCheckEnum[key] : null
  );
  impactOptions = Object.keys(ImpactTextEnum).map(key => ImpactTextEnum[key]);
  standardList = EntityService.initStandardList();
  defaultDueDate: number;
  isCRBAdjustments = UtilsService.isCRB;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private entityService: EntityService,
    private logger: NGXLogger,
    public activeModal: NgbActiveModal
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.frequencyOptions = this.frequencyOptions.filter(el => el !== null);
      const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      this.entity = await this.entityService.getEntity(entityId);
      Object.assign(
        this.entity.defaultSetting,
        this.initDefaultSetting(this.entity.defaultSetting)
      );
      if (this.isCRBAdjustments) {
        this.calcCrbVendorImpact();
      }
    } catch (err) {
      this.logger.error('An error occurred while showing default settings : ', err);
    }
    this.isLoading = false;
  }

  initDefaultSetting(existingDefaults): DefaultSettingInput {
    const defaults: DefaultSettingInput = {};
    defaults.dueDateInDays =
      existingDefaults.dueDateInDays || this.dueDateOptions[this.dueDateOptions.length - 1];
    this.setDate(defaults.dueDateInDays);

    const artifactsWithOutCompliance = Object.keys(ArtifactsEnum).filter(
      key => !key.includes('COMPLIANCE')
    );

    if (this.isCRBAdjustments) {
      defaults.privacyData = existingDefaults.privacyData || false;

      defaults.standards =
        existingDefaults.standards ||
        Object.values(CrbVendorConstant.compliances).map(name => ({
          isQualify: true,
          name,
        }));

      defaults.impactsQualify =
        existingDefaults.impactsQualify ||
        CrbVendorConstant.impacts.map(name => ({
          isQualify: true,
          name,
        }));

      defaults.artifacts =
        existingDefaults.artifacts ||
        Object.values(CrbVendorConstant.artifacts).map(name => ({
          isQualify: true,
          name,
        }));
      defaults.surveyLevel = existingDefaults.surveyLevel || 'VERY_HIGH';
    } else {
      defaults.standards =
        existingDefaults.standards ||
        this.standardList.map(standard => {
          return {
            isQualify: false,
            name: standard.name,
          };
        });

      defaults.artifacts =
        existingDefaults.artifacts ||
        artifactsWithOutCompliance.map(key => {
          return {
            isQualify: false,
            name: ArtifactsEnum[key],
          };
        });
      defaults.surveyLevel = existingDefaults.surveyLevel || 'Very High';
      defaults.surveyLevel = defaults.surveyLevel.toLowerCase().replace('_', ' ');
    }

    defaults.frequency = existingDefaults.frequency || defaults.frequency;

    return defaults;
  }

  async saveDefaultSetting(entity: UpdateEntityInput): Promise<void> {
    entity.defaultSetting.surveyLevel = entity.defaultSetting.surveyLevel
      .toUpperCase()
      .replace(' ', '_');
    entity = JSON.parse(
      JSON.stringify(entity, (k, v) =>
        k === '__typename' ||
        k === 'activeAssessment' ||
        k === 'activeAssessmentId' ||
        k === 'vendorDetails'
          ? undefined
          : v
      )
    );
    this.closeDefaultModal.emit(true);
    try {
      await this.entityService.updateEntity(entity);
      this.toastr.success('Default setting saved successfully!');
    } catch (e) {
      this.logger.error('saveDefaultSetting - Error: ', e);
      this.toastr.error('Failed to save default setting');
    }
  }

  setDate(days: number): void {
    const date = new Date();
    this.defaultDueDate = date.setDate(date.getDate() + +days);
  }

  calcCrbVendorImpact(): void {
    const pointForEachImport = 100 / this.entity.defaultSetting.impactsQualify.length;
    const requiredImpacts: QualificationInput[] = this.entity.defaultSetting.impactsQualify.filter(
      opt => opt.isQualify
    );

    const impact = Math.round(pointForEachImport * requiredImpacts.length);

    switch (true) {
      case impact <= ImpactNumEnum.LOW:
        this.entity.defaultSetting.surveyLevel = ImpactTextEnum.LOW;
        break;
      case impact > ImpactNumEnum.LOW && impact <= ImpactNumEnum.MEDIUM:
        this.entity.defaultSetting.surveyLevel = ImpactTextEnum.MEDIUM;
        break;
      case impact > ImpactNumEnum.MEDIUM && impact <= ImpactNumEnum.HIGH:
        this.entity.defaultSetting.surveyLevel = ImpactTextEnum.HIGH;
        break;
      default:
        this.entity.defaultSetting.surveyLevel = ImpactTextEnum.VERY_HIGH;
        break;
    }
  }
  getImpactText(surveyLevel: string): string {
    return surveyLevel
      .toLowerCase()
      .split(' ')
      .join('-');
  }
}
