import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from 'app/shared/utils.service';

@Component({
  selector: 'cygov-summary-widget',
  templateUrl: './summary-widget.component.html',
  styleUrls: ['./summary-widget.component.scss'],
})
export class SummaryWidgetComponent implements OnInit {
  @Input() riskAverage = 5;
  @Input() target = 5;
  showTarget = true;
  constructor() {}

  ngOnInit() {}

  toggleTarget(event): void {
    UtilsService.stopBubbling(event);
    this.showTarget = !this.showTarget;
    console.log('this.showTarget: ', this.showTarget);
  }
}
