import { Pipe, PipeTransform } from '@angular/core';
/*
 * Capitalize the first letter of the string and replace dashes with sapce.
 * Takes a string as a value.
 * Usage:
 *  value | capAndRemoveDashes
 */
@Pipe({
  name: 'capAndRemoveDashes',
})
export class CapAndRemoveDashesPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    value = value.replace(/-/g, ' ');
    value = value.charAt(0).toUpperCase() + value.substring(1);
    return value;
  }
}
