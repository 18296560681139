import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { UtilsService } from '../utils.service';
import { ImpactTextEnum, ImpactNumEnum } from '../enums/impact.enum';
import { RoleEnum } from '../../API.service';
import { GetEntityQueryExtended, EntityService } from '../entity.service';
import { ToastrService } from 'ngx-toastr';
import { FileService } from '../file.service';
import { NGXLogger } from 'ngx-logger';
import { AddClientModalComponent } from './add-client-modal/add-client-modal.component';

@Component({
  selector: 'cygov-ui-tools',
  templateUrl: './ui-tools.component.html',
  styleUrls: ['./ui-tools.component.scss'],
})
export class UiToolsComponent implements OnInit, OnChanges {
  @Input() inputCollection: any;
  @Output() onViewChanged = new EventEmitter<boolean>();
  @Output() onSearchChanged = new EventEmitter<GetEntityQueryExtended>();
  @Output() onSortChanged = new EventEmitter<GetEntityQueryExtended>();
  @Output() onFilterChanged = new EventEmitter<GetEntityQueryExtended>();

  queryText: string;
  sortBy: string = null;
  prevSortBy: string = null;
  sortOptions: string[] = ['name', 'score', 'target'];
  filterBy: string = null;
  filterOptions: string[] = ['low', 'medium', 'high'];
  vendorListFilterOptions = [
    { id: 2, name: 'High', groupType: 'Impact' },
    { id: 3, name: 'Medium', groupType: 'Impact' },
    { id: 4, name: 'Low', groupType: 'Impact' },
    { id: 5, name: 'Approved', groupType: 'Status' },
    { id: 6, name: 'Pending', groupType: 'Status' },
    { id: 7, name: 'In_Process', groupType: 'Status' },
    { id: 8, name: 'Denied', groupType: 'Status' },
  ];

  selectedVendorListFilters: string[] = [];
  cardView = true;
  cachedCollection: any;
  displayCollection: any;
  showAddEntity = false;
  multiEntityRoute = false;
  showAddMPL = false;
  isCrbAdjustments = false;
  showLayoutBtns = true;
  isVendorsTable = false;
  isCollection = false;
  isAdmin = false;

  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private entityService: EntityService,
    private route: ActivatedRoute,
    private router: Router,
    private fileService: FileService,
    private toastr: ToastrService,
    private logger: NGXLogger
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputCollection.currentValue) {
      // update cachedCollection and change the inputCollection reference
      this.cachedCollection = this.displayCollection = changes.inputCollection.currentValue.map(
        item => item
      );
    }
  }

  async ngOnInit() {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.isAdmin = await this.authService.hasPermission(RoleEnum.LEADER, entityId);

    this.isCrbAdjustments = UtilsService.isCRB;
    this.showAddMPL =
      this.isCrbAdjustments &&
      this.router.url.includes('multi-entity') &&
      (await this.authService.hasPermission(RoleEnum.LEADER, entityId));

    this.showAddEntity =
      !this.isCrbAdjustments &&
      (this.router.url.includes('clients') || this.router.url.includes('multi-entity'));
    this.multiEntityRoute = this.router.url.includes('multi-entity');
    this.showLayoutBtns = !this.router.url.includes('third-party');
    this.isVendorsTable = this.router.url.includes('vendors');
    this.isCollection = this.router.url.includes('collection');

    if (this.inputCollection) {
      // change the inputCollection reference
      this.cachedCollection = this.displayCollection = this.inputCollection.map(item => item);
    }
  }

  sortChanged(sortBy) {
    if (sortBy !== this.prevSortBy) {
      this.prevSortBy = sortBy;
      switch (sortBy) {
        case 'name':
          this.displayCollection = this.displayCollection.sort((a, b) => {
            return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
          });
          break;
        case 'score':
          this.displayCollection = this.displayCollection.sort((a, b) => {
            return a.scores.total > b.scores.total ? 1 : b.scores.total > a.scores.total ? -1 : 0;
          });
          break;
        case 'target':
          this.displayCollection = this.displayCollection.sort((a, b) => {
            return a.scores.target > b.scores.target
              ? 1
              : b.scores.target > a.scores.target
              ? -1
              : 0;
          });
          break;
      }
    }

    this.onSortChanged.emit(this.displayCollection);
  }

  filterChanged(filterBy, type) {
    if (this.isVendorsTable) {
      this.vendorListFilterChanged();
    } else {
      this.displayCollection = this.cachedCollection.map(item => item);
      switch (filterBy) {
        case 'low':
          this.displayCollection = this.displayCollection.filter(item => item.scores.total <= 3.33);
          break;
        case 'medium':
          this.displayCollection = this.displayCollection.filter(
            item => item.scores.total > 3.33 && item.scores.total < 6.66
          );
          break;
        case 'high':
          this.displayCollection = this.displayCollection.filter(item => item.scores.total >= 6.66);
          break;
        default:
          if (type === 'search' && filterBy) {
            this.displayCollection = this.displayCollection.filter(item => {
              return item.name.toLowerCase().includes(this.queryText.toLowerCase());
            });
          }
      }

      if (this.sortBy) {
        this.sortChanged(this.sortBy);
      } else {
        this.onSearchChanged.emit(this.displayCollection);
      }
    }
  }

  vendorListFilterChanged() {
    this.displayCollection = this.cachedCollection.map(item => item);

    this.selectedVendorListFilters.forEach(element => {
      const selectedFilter = this.vendorListFilterOptions.filter(item => {
        return item.id === +element;
      })[0];

      switch (selectedFilter.groupType) {
        case 'Impact':
          this.filterCollectionOnImpact(selectedFilter);
          break;
        case 'Status':
          this.filterCollectionOnStatus(selectedFilter);
          break;
        default:
          break;
      }
    });

    if (this.queryText) {
      this.displayCollection = this.displayCollection.filter(item => {
        return item.name.toLowerCase().includes(this.queryText.toLowerCase());
      });
    }

    this.onFilterChanged.emit(this.displayCollection);
  }

  filterCollectionOnImpact(selectedFilter) {
    switch (selectedFilter.name.toLowerCase()) {
      case ImpactTextEnum.LOW:
        this.displayCollection = this.displayCollection.filter(
          item => item.vendorDetails.impact <= ImpactNumEnum.LOW
        );
        break;
      case ImpactTextEnum.MEDIUM:
        this.displayCollection = this.displayCollection.filter(
          item =>
            item.vendorDetails.impact <= ImpactNumEnum.MEDIUM &&
            item.vendorDetails.impact >= ImpactNumEnum.LOW
        );
        break;
      case ImpactTextEnum.HIGH:
        this.displayCollection = this.displayCollection.filter(
          item => item.vendorDetails.impact >= ImpactNumEnum.MEDIUM
        );
        break;
      default:
        break;
    }
  }

  filterCollectionOnStatus(selectedFilter) {
    this.displayCollection = this.displayCollection.filter(
      item => item.vendorDetails.status.toLowerCase() === selectedFilter.name.toLowerCase()
    );
  }

  viewChanged(isCard) {
    // emit only if there was a change
    if (isCard !== this.cardView) {
      this.cardView = isCard;
      this.onViewChanged.emit(isCard);
    }
  }

  addClientModal() {
    const modalRef = this.modalService.open(AddClientModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'add-client-modal',
    });
    modalRef.componentInstance.modalResult.subscribe((event: any) => {
      this.createEntity(event);
      modalRef.close();
    });
  }

  addSubEntityModal() {
    const modalRef = this.modalService.open(AddClientModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'add-client-modal',
    });
    modalRef.componentInstance.modalResult.subscribe((event: any) => {
      this.createEntity(event);
      modalRef.close();
    });
  }

  async createEntity(event) {
    if (!event) {
      return;
    }
    this.toastr.info('Creating Entity...');
    if (event.s3Input) {
      try {
        event.entity.logo = await this.fileService.uploadToS3(event.s3Input);
      } catch (e) {
        this.logger.error('uploadLogoToS3 - Error: ', e);
        this.toastr.error('Failed to upload Logo');
      }
    }
    try {
      await this.entityService.createEntity(event.entity, event.requiredStandard);
    } catch (e) {
      this.toastr.error(`Failed to create ${event.entity.name}`);
    }
  }
}
