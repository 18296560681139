/* eslint-disable @typescript-eslint/prefer-for-of */
import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface AnswerOption {
  name: string;
  value: number[];
  selected: boolean;
}
@Component({
  selector: 'cygov-generate-data',
  templateUrl: './generate-data.component.html',
  styleUrls: ['./generate-data.component.scss'],
})
export class GenerateDataComponent implements OnInit {
  @Input() message = 'Are you sure?';
  @Output() modalResult = new EventEmitter<{ flag: boolean; selectedAnswers: number[] }>();
  possibleAnswers: AnswerOption[] = [];
  @Input() loading = false;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.initialize();
  }
  initialize(): void {
    this.possibleAnswers = [
      {
        name: 'EMPTY',
        value: [-1],
        selected: true,
      },
      {
        name: 'YES',
        value: [10],
        selected: true,
      },
      {
        name: 'NO',
        value: [1],
        selected: true,
      },
      {
        name: 'NOT_APPLICABLE',
        value: [0],
        selected: true,
      },
      {
        name: '1-3',
        value: [1, 2, 3],
        selected: true,
      },
      {
        name: '4-6',
        value: [4, 5, 6],
        selected: true,
      },
      {
        name: '7-9',
        value: [7, 8, 9],
        selected: true,
      },
    ];
  }
  generateData(): void {
    let selectedAnswers = [];
    this.possibleAnswers.forEach(element => {
      if (element.selected) {
        selectedAnswers = [...new Set(selectedAnswers), ...new Set(element.value)];
      }
    });
    if (!selectedAnswers.length) {
      this.possibleAnswers.forEach(element => {
        selectedAnswers = [...new Set(selectedAnswers), ...new Set(element.value)];
      });
    }
    this.loading = true;
    this.modalResult.emit({ flag: true, selectedAnswers });
  }
}
