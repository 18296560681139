import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import FrameworkNode from 'models/framework-node.model';
import { ImpactTextEnum } from './enums/impact.enum';
import { AnswerEnum } from './enums/answer.enum';
import { SortDirectionEnum } from './enums/sort-direction.enum';
import { parseDomain, fromUrl } from 'parse-domain';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class UtilsService {
  static get isCRB(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    return subDomains.length ? subDomains.includes('crb') : false;
  }

  static getStringEnum(enumnum) {
    const fields = [];
    Object.keys(enumnum).forEach(key => {
      if (key !== key.toUpperCase()) {
        fields.push(key);
      }
    });
    return fields;
  }

  // help function to get an array instead of tree
  static getArrayFromNestedObject(object: any) {
    const arr = [];
    const traverseSurvey = sheet => {
      Object.keys(sheet).forEach(key => {
        if (sheet[key].answers) {
          arr.push(sheet[key]);
        } else {
          traverseSurvey(sheet[key]);
        }
      });
    };

    traverseSurvey(object);
    return arr;
  }

  static isHebrew(str): boolean {
    return str.match(/[\u0590-\u05FF]+/g);
  }

  static capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static isIdFake(id: string): boolean {
    return id.length === 37 ? true : false;
  }

  static isDefined(variable: any): boolean {
    return !(typeof variable === 'undefined' || variable === null);
  }

  static isEmpty(variable: string): boolean {
    return !this.isDefined(variable) || variable === '' || variable === 'null';
  }

  static averageOfProp<T>(arr: T[], prop: string): number {
    const average = arr.reduce((acc, curr) => acc + curr[prop], 0) / arr.length;
    return average;
  }

  /**
   * Returns a random number between min (inclusive) and max (exclusive)
   */
  static getRandomNumber(min: number, max: number, toFixed: number): number {
    let randomNum: any = Math.random() * (max - min) + min;
    if (!isNaN(toFixed)) {
      randomNum = randomNum.toFixed(toFixed);
    }
    return Number(randomNum);
  }

  static getRandomElement(arr: any[]) {
    const index = UtilsService.getRandomNumber(0, arr.length - 1, 0);
    return arr[index];
  }

  static getRandomBoolean() {
    return UtilsService.getRandomElement([true, false]);
  }

  /**
   * Sort array by property.
   * @param array
   * @param prop: string
   * @return sorted array
   */
  static sortByProp<T>(
    arr: T[],
    prop: string,
    direction: SortDirectionEnum = SortDirectionEnum.ASCENDING
  ): T[] {
    return arr.slice().sort((a, b) => {
      if (direction === SortDirectionEnum.ASCENDING) {
        return a[prop] < b[prop] ? -1 : 1;
      } else {
        return a[prop] < b[prop] ? 1 : -1;
      }
    });
  }

  /**
   * Group array to object by property.
   * @param array
   * @param prop: string
   * @return grouped object
   */
  static groupBy<T>(arr: T[], prop: string): Map<string, T[]> {
    const initialMap: Map<string, T[]> = new Map();
    return arr.reduce((memo, x) => {
      if (!memo[x[prop]]) {
        memo[x[prop]] = [];
      }
      memo[x[prop]].push(x);
      return memo;
    }, initialMap);
  }

  /**
   * Create a map of all items of array with an attribute as key
   *
   * @param arr array
   * @param prop attribute
   */
  static mapBy<T>(arr: T[], prop: string): Map<string, T> {
    const initialMap: Map<string, T> = new Map();
    return arr.reduce((map, curr) => {
      map[curr[prop]] = curr;
      return map;
    }, initialMap);
  }

  static copyArrayDeep<T>(arr: T[]): T[] {
    return JSON.parse(JSON.stringify(arr));
  }

  static getUniqueListBy<T>(arr: T[], key: string): T[] {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }

  /**
   * generate the object from entries
   * Temporary until Microsoft doesn't support ES2019 in TS
   * The source code: http://2ality.com/2019/01/object-from-entries.html
   * @param iterable list of pairs key-value
   */
  static fromEntries(iterable): { [propName: string]: string } {
    const result = {};
    for (const [key, value] of iterable) {
      let coercedKey;
      if (typeof key === 'string' || typeof key === 'symbol') {
        coercedKey = key;
      } else {
        coercedKey = String(key);
      }
      Object.defineProperty(result, coercedKey, {
        value,
        writable: true,
        enumerable: true,
        configurable: true,
      });
    }
    return result;
  }

  static stopBubbling(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  static getRouteParam(child: ActivatedRouteSnapshot, key: string) {
    if (child) {
      if (child.params[key]) {
        return child.params[key];
      } else if (child.queryParams[key]) {
        return child.queryParams[key];
      } else {
        return this.getRouteParam(child.firstChild, key);
      }
    } else {
      return null;
    }
  }

  static msgFromError(e): string {
    let message = 'Internal Server Error';
    if (typeof e === 'string') {
      message = e;
    } else if (e.errors) {
      message = `Errors: \n ${e.errors.map(error => error.message).join('\n')}`;
    } else if (e.status === 0) {
      message = 'Server down. Please connect support.';
    } else if (!e.message.includes('Internal Server Error')) {
      message = e.message;
    } else if (!e.error.includes('Internal Server Error')) {
      message = e.error;
    }

    return message;
  }

  static getShortDate(date: Date, isUTC = false) {
    const shortDate = isUTC
      ? date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate()
      : date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    return shortDate;
  }

  static convertDate(date: Date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return (
      (month < 10 ? '0' + month : month.toString()) +
      '/' +
      (day < 10 ? '0' + day : day.toString()) +
      '/' +
      (year < 10 ? '0' + year : year.toString())
    );
  }

  static sum(array, key): number {
    return array.reduce((a, b) => a + (b[key] || 0), 0);
  }

  static filterOutNullElements = node => node != null;

  static filterOutUndefinedElements = node => node !== undefined;

  static calculateNodesScoreSum = (prev, current) => {
    prev.name = current.name;
    prev.scores.total += current.scores.total ? current.scores.total : 0;
    prev.scores.target += current.scores.target ? current.scores.target : 0;
    return prev;
  };

  static buildFlatList = (prev, current) => {
    return prev.concat(...current);
  };

  static buildUniqueNamesList = (prev, current) => {
    return prev.indexOf(current.name) === -1 ? prev.concat(current.name) : prev;
  };

  static buildFrameworksList = async (frameworks: any[]): Promise<FrameworkNode[]> => {
    let frameworkList: FrameworkNode[];
    if (frameworks && frameworks.length > 0) {
      // Create list for all unique keys of sub nodes i.e identity, recover
      const frameworkNames = frameworks.reduce(UtilsService.buildUniqueNamesList, []);

      // From the flat array of sub nodes, calculate average for scores
      const promises = frameworkNames.map(calculateFrameworkAverageScore, {
        frameworkList: frameworks,
      });
      frameworkList = await Promise.all(promises);
    }
    return frameworkList;
  };

  static answerNumToEnum(value: number): AnswerEnum {
    if (!this.isDefined(value) || value === -1) {
      return null;
    }
    if (value === 0) {
      return AnswerEnum.NOT_APPLICABLE;
    } else if (value >= 5) {
      return AnswerEnum.YES;
    } else {
      return AnswerEnum.NO;
    }
  }

  static answerEnumToNum(value: AnswerEnum): number {
    switch (value) {
      case AnswerEnum.NOT_APPLICABLE:
        return 0;
      case AnswerEnum.YES:
        return 10;
      case AnswerEnum.NO:
        return 1;
    }
  }

  static assignObjectArrayProperty(prev, current, property): void {
    if (prev[property]) {
      prev[property].push(...current[property]);
      prev[property] = [...new Set(prev[property])];
    } else {
      prev[property] = current[property];
    }
  }

  static initImpact(question): string {
    return question.impact ? question.impact : ImpactTextEnum.LOW;
  }
  static getIndexFromArray(questions, id: string): number {
    return questions.findIndex(question => {
      return question.id === id;
    });
  }

  static checkNewEntity(newSubList, existingSubList): string {
    if (UtilsService.isDefined(newSubList)) {
      if (UtilsService.isDefined(existingSubList)) {
        if (existingSubList.length < newSubList.length) {
          const newlyCreatedEntity = newSubList.filter(
            ({ name: newSubName }) =>
              !existingSubList.some(({ name: existingSubName }) => existingSubName === newSubName)
          );

          return this.isDefined(newlyCreatedEntity) && newlyCreatedEntity.length !== 0
            ? newlyCreatedEntity[0].name
            : null;
        }
      }
    }
    return null;
  }

  static getDateInNgbDateStructFormat(date): NgbDateStruct {
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }
  static findIndexBasedOnKey(data, key, value): number {
    return data.findIndex(element => element[key] === value);
  }
}

export function calculateFrameworkAverageScore(subNodeName): FrameworkNode {
  const subNodes = this.frameworkList.filter(subNode => subNode.name === subNodeName);
  const singleNode: FrameworkNode = subNodes.reduce(UtilsService.calculateNodesScoreSum, {
    name: '',
    scores: { total: 0.0, target: 0.0 },
  });
  singleNode.scores.total = singleNode.scores.total / subNodes.length;
  singleNode.scores.target = singleNode.scores.target / subNodes.length;
  return singleNode;
}
