import {
  ListAssessmentsQuery,
  GetAssessmentQuery,
  ModelAssessmentFilterInput,
  StandardFilterInput,
  GetFrameworkScoresQuery,
  CreateAssignmentMutation,
  TimelineInput,
  CreateAssessmentStandardFrameworkInput,
  CreateAssessmentStandardFrameworkMutation,
  FileTypeEnum,
} from './../API.service';
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/unbound-method */
import { Injectable, OnDestroy } from '@angular/core';
import * as uuid from 'uuid';
import { FrameworkTreeService } from './framework-tree.service';
import { UtilsService } from 'app/shared/utils.service';
import {
  GetEntityQuery,
  EntityTypeEnum,
  FrequencyCheckEnum,
  AccessLevelEnum,
  APIService,
  CreateEntityMutation,
  AddGroupInput,
  CreateEntityInput,
  UpdateEntityMutation,
  UpdateEntityInput,
  ModelEntityFilterInput,
  ListEntitysQuery,
  ModelSortDirection,
  ModelStringKeyConditionInput,
  GetUserQuery,
  ListUsersQuery,
  EntityParentQuery,
  RoleEnum,
  CreateAssessmentMutation,
  CreateAssessmentInput,
  StandardFrameworkInput,
  StandardType,
  CreateFrameworkManagerInput,
} from 'app/API.service';
import { ImpactTextEnum } from './enums/impact.enum';
import { FileService } from './file.service';
import { StandardEnum } from './enums/standard.enum';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Auth } from 'aws-amplify';
import { IKeyValue } from 'models/ikey-value';
import { environment } from 'environments/environment';
import { Lambda } from 'aws-sdk';
import awsmobile from 'aws-exports';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { FrameworkRangeMap } from '../constants';
import { ObjectsSort } from './helpers/generic-helpers';

export interface GetEntityQueryExtended extends GetEntityQuery {
  showProjectManager?: boolean;
  logoUrl?: string;
}

export interface FrameworkScore extends StandardFrameworkInput {
  assessmentId?: string;
  key: string;
  rootId?: string;
  childId?: string;
  name: string;
  scores: {};
  assessmentTree?: any;
  __typename: 'StandardFramework';
}

export interface FrameworkScoreAndManagers {
  frameworks: FrameworkScore[];
  managers: string[];
}

export interface RequiredStandard {
  key: string;
  name: string;
  type: StandardType;
  checked: boolean;
  fileName?: string;
  filter?: StandardFilterInput;
}

export interface RequiredStandardIndustry extends RequiredStandard {
  industry: string;
}

@Injectable({
  providedIn: 'root',
})
export class EntityService implements OnDestroy {
  public static onDataChanged = new BehaviorSubject(false);
  public static onEntityAdded: Subject<any> = new Subject<any>();
  private static promCache: any = {};
  private static entityMap: IKeyValue<any> = {};
  user: GetUserQuery;
  authUser = null; // This is for cognito user
  private subscriptionList: Subscription[];
  updateEntityScoreSubscriber: Subject<any> = new Subject<any>();
  updateVendorSubscriber: Subject<any> = new Subject<any>();
  private activeSubEntityObservable = new Subject();
  public activeSubEntitySubscriber = this.activeSubEntityObservable.asObservable();

  constructor(
    private api: APIService,
    private logger: NGXLogger,
    private fileService: FileService,
    private frameworkTreeService: FrameworkTreeService
  ) {
    this.subscriptionList = [
      this.onCreateEntityListener(),
      this.onUpdateEntityListener(),
      this.onDeleteEntityListener(),
    ];
  }

  public static getDomain(): string {
    return window.location.origin;
  }

  public static getAdminGroup(): string {
    return window.location.origin;
  }

  public static getParticipantGroup(): string {
    return `${window.location.origin}-participants`;
  }

  public static initEntity(
    entityType: EntityTypeEnum,
    projectManager: string,
    parentId?: string
  ): CreateEntityInput {
    if (!parentId && entityType !== EntityTypeEnum.ROOT_ENTITY) {
      throw new Error('parentId is required!');
    }
    const now = Date.now();
    const generatedId = uuid();
    // 'admin' means admin access to the entity and the whole tree under
    const accessGroups = [this.getAdminGroup()];
    if (entityType === EntityTypeEnum.ROOT_ENTITY) {
      accessGroups.push(generatedId);
    }
    if (entityType === EntityTypeEnum.CHILD_ENTITY) {
      accessGroups.push(parentId);
      accessGroups.push(generatedId);
    }
    const participantGroup = [this.getParticipantGroup()];
    return {
      id: generatedId,
      name: '',
      entityType,
      scores: {
        target: 9.1,
        collection: 0,
        remediation: 0,
        notApplicable: false,
        total: 0,
        criticalGaps: 0,
        totalGaps: 0,
        totalQuestions: 0,
        completedQuestions: 0,
      },
      timeline: {
        initiationDate: now,
        collectionDate: null,
      },
      defaultSetting: {
        frequency: FrequencyCheckEnum.ANNUAL,
        surveyLevel: ImpactTextEnum.HIGH,
        accessLevel: AccessLevelEnum.PHYSICAL,
      },
      createdAt: now,
      modifiedAt: now,
      frameworkType: entityType,
      accessGroups,
      participantGroup,
      projectManager,
      activeAssessmentId: 'null',
      domain: this.getDomain(),
      parentId: entityType === EntityTypeEnum.CHILD_ENTITY ? parentId : undefined,
      rootEntityId: entityType === EntityTypeEnum.CHILD_ENTITY ? parentId : undefined,
      childEntityId: entityType === EntityTypeEnum.CHILD_ENTITY ? generatedId : undefined,
    };
  }

  // convert StandardEnum to RequiredStandard type
  public static initStandardList(): RequiredStandard[] {
    return [
      Object.entries(StandardEnum).map(([key, name]) => {
        return {
          key,
          name,
          type: StandardType.COMPLIANCE_FRAMEWORK,
          checked: false,
        };
      }),
      // Object.entries(RiskFrameworkEnum).map(([key, name]) => {
      //   return {
      //     key,
      //     name,
      //     type: EntityTypeEnum.RISK_FRAMEWORK,
      //     checked: false,
      //   };
      // }),
    ].flat();
  }

  // convert Add industry in standardList with help of csv file
  public async initStandardListWithIndustry(
    sortColumn = null
  ): Promise<RequiredStandardIndustry[]> {
    const standardList = EntityService.initStandardList();
    // Fetch standards and industries csv file from bucket
    const filePath = `${FileTypeEnum.INDUSTRY_FRAMEWORKS}/standards_n_industries.csv`;
    const options = { contentType: 'text/csv', level: 'public' };
    const url = await this.fileService.getFileUrlFromBucket(filePath, options);
    const response = await fetch(url);
    const standardsNIndustries = this.fileService.convertCSVDataToJSON(await response.text());

    let list: RequiredStandardIndustry[] = standardList.map(standard => {
      const foundedStandardObj = standardsNIndustries.find(sNi => {
        return (
          sNi.standards_name.replace(/[\s-]/g, '_') === standard.name &&
          (sNi.category || '').toLowerCase() === 'compliance' // check if category type should be compliance
        );
      });

      return {
        ...standard,
        industry:
          foundedStandardObj && foundedStandardObj.industry ? foundedStandardObj.industry : null,
      };
    });

    if (sortColumn) {
      // Sort by label which is provided in parameter
      list = ObjectsSort(list, sortColumn);
    }
    return list;
  }

  public async setLogoUrl(entity: GetEntityQuery): Promise<GetEntityQueryExtended> {
    let logoUrl = '';
    if (entity.logo) {
      logoUrl = (await this.fileService.downloadFromS3(entity.logo)) as string;
    }

    return {
      ...entity,
      logoUrl,
    };
  }

  public async createEntity(
    entity: CreateEntityInput,
    requiredStandard?: RequiredStandard[]
  ): Promise<CreateEntityMutation> {
    try {
      await this.createAndAddUserGroup(entity);
      if (
        entity.entityType === EntityTypeEnum.CHILD_ENTITY ||
        entity.entityType === EntityTypeEnum.VENDOR
      ) {
        const assessment: CreateAssessmentMutation = await this.createAssessment(
          entity.rootEntityId,
          entity.childEntityId,
          entity.timeline,
          entity.projectManager,
          requiredStandard
        );
        entity.activeAssessmentId = assessment.id;
      }
      console.log(entity);
      const createdEntity: CreateEntityMutation = await this.api.CreateEntity(entity);
      await this.afterCreateEntity(createdEntity);
      return createdEntity;
    } catch (e) {
      this.logger.error('createEntity - Error: ', e);
      return Promise.reject(e);
    }
  }

  public async createAssessment(
    rootId: string,
    childId: string,
    timeline: TimelineInput,
    projectManager: string,
    requiredStandard: RequiredStandard[]
  ): Promise<CreateAssessmentMutation> {
    const assessmentId = uuid.v4();
    const mapStandardToFramework = async (
      standard: RequiredStandard
    ): Promise<CreateAssessmentStandardFrameworkMutation> => {
      const framework: CreateAssessmentStandardFrameworkInput = {
        key: standard.key,
        type: standard.type,
        assessmentId,
        managerId: projectManager,
        timeline,
      };
      if (!!standard.fileName && standard.fileName !== null) {
        framework.fileName = standard.fileName;
      }
      if (!!standard.filter && standard.filter !== null) {
        framework.filter = standard.filter;
      }

      const createdFramework = await this.api.CreateAssessmentStandardFramework(framework);
      return createdFramework;
    };
    timeline.initiationDate = Date.now(); // Remove this later when we have dialog for assessment
    const assessmentStandardFrameworks = await Promise.all(
      requiredStandard.map(mapStandardToFramework)
    );

    const createAssessmentInput: CreateAssessmentInput = {
      id: assessmentId,
      rootId,
      childId,
      timeline,
      scores: {
        target: 9.1,
        collection: 0,
        remediation: 0,
        notApplicable: false,
        total: 0,
        criticalGaps: 0,
        totalGaps: 0,
        totalQuestions: 0,
        completedQuestions: 0,
      },
      managers: [projectManager],
    };
    const createdAssessment: CreateAssessmentMutation = await this.api.CreateAssessment(
      createAssessmentInput
    );
    const frameworksManagerList = assessmentStandardFrameworks.map(frameworks => {
      return {
        assessmentId: frameworks.assessmentId,
        standardFrameworkId: frameworks.id,
        managerId: projectManager,
      } as CreateFrameworkManagerInput;
    });

    const allFrameworkManagerList = await Promise.all(
      frameworksManagerList.map(item => this.api.CreateFrameworkManager(item))
    );

    console.log('createdAssessment', createdAssessment);
    return createdAssessment;
  }

  public async createAssessmentFramework(
    assessmentId: string,
    projectManagerId: string,
    requiredStandard: RequiredStandard,
    timeline: TimelineInput
  ): Promise<CreateAssessmentStandardFrameworkMutation> {
    const framework: CreateAssessmentStandardFrameworkInput = {
      key: requiredStandard.key,
      type: requiredStandard.type,
      assessmentId,
      managerId: projectManagerId,
      timeline,
    };
    if (UtilsService.isDefined(requiredStandard.fileName)) {
      framework.fileName = requiredStandard.fileName;
    }
    if (UtilsService.isDefined(requiredStandard.filter)) {
      framework.filter = requiredStandard.filter;
    }

    const createdFramework = await this.api.CreateAssessmentStandardFramework(framework);
    console.log('Created Assessment Framework: ', createdFramework);

    await this.assignFramework(requiredStandard.key, assessmentId, projectManagerId);
    return createdFramework;
  }

  public async getEntity(entityId: string, fromCache = true): Promise<GetEntityQuery> {
    if (!entityId) {
      this.logger.error('getEntity - Error: entityId is required');
      return Promise.reject('getEntity - Error: entityId is required');
    }
    if (UtilsService.isIdFake(entityId)) {
      entityId = entityId.substring(1);
    }

    // Data cache mechanism
    let entity: GetEntityQuery = null;
    if (fromCache && EntityService.entityMap[entityId]) {
      entity = EntityService.entityMap[entityId];
    }

    if (fromCache && entity) {
      return Promise.resolve(entity);
    }

    // Promises cache mechanism
    let queryProm: Promise<GetEntityQuery>;
    if (fromCache && EntityService.promCache && EntityService.promCache[entityId]) {
      this.logger.log('EntityService.getEntity() returned from cache');
      queryProm = EntityService.promCache[entityId];
    } else {
      queryProm = this.api.GetEntity(entityId);
      EntityService.promCache[entityId] = queryProm;
      this.logger.log('EntityService.getEntity() returned (not from cache)');
    }

    try {
      entity = await queryProm;

      // update cache map
      EntityService.entityMap[entity.id] = entity;

      return entity;
    } catch (e) {
      this.logger.error('getEntity - Error: ', e);
      return Promise.reject(e);
    }
  }

  public async updateEntity(entity: UpdateEntityInput): Promise<UpdateEntityMutation> {
    try {
      const updatedEntity: UpdateEntityMutation = await this.api.UpdateEntity(entity);
      return updatedEntity;
    } catch (e) {
      this.logger.error('updateEntity - Error: ', e);
      return Promise.reject(e);
    }
  }

  public async deleteEntity(entityId: string): Promise<void> {
    try {
      const deletedEntity = await this.api.DeleteEntity({ id: entityId });
      console.log('deleted entity', deletedEntity);
    } catch (e) {
      this.logger.error('deleteEntity - Error: ', e);
      return Promise.reject(e);
    }
  }

  async listEntitys(
    filter: ModelEntityFilterInput,
    fromCache: boolean = true
  ): Promise<GetEntityQuery[]> {
    if (!filter) {
      this.logger.error('listEntitys - Error: filter is required');
      return Promise.reject('listEntitys - Error: filter is required');
    }

    let listEntitys: GetEntityQuery[] = null;
    // if (fromCache && EntityService.entityMap[JSON.stringify(filter)]) {
    //   listEntitys = EntityService.entityMap[JSON.stringify(filter)];
    // }

    // if (fromCache && listEntitys && listEntitys.length) {
    //   return Promise.resolve(listEntitys);
    // }

    try {
      let result = await this.api.ListEntitys(filter, environment.queryListLimit);

      listEntitys = result.items as any;

      while (result.nextToken) {
        result = await this.api.ListEntitys(filter, environment.queryListLimit, result.nextToken);
        listEntitys = listEntitys.concat(result.items as any);
      }

      this.logger.log('EntityService.listEntitys() returned (not from cache)');

      // update cache map
      // EntityService.entityMap[JSON.stringify(filter)] = listEntitys;
      listEntitys.forEach(entity => (EntityService.entityMap[entity.id] = entity));

      const promises = [];
      if (filter.entityType && filter.entityType.eq === EntityTypeEnum.CHILD_ENTITY) {
        listEntitys.forEach(({ activeAssessment }) => {
          activeAssessment &&
            activeAssessment.standardFrameworkList.items.forEach(({ key }) => {
              promises.push(this.frameworkTreeService.getFrameworkTreeFromS3(key));
            });
        });
      }
      await Promise.all(promises);
      return listEntitys;
    } catch (e) {
      this.logger.error('listEntitys - Error: ', e);
      return Promise.reject(e);
    }
  }

  async listEntitysByEntityType(
    entityType?: EntityTypeEnum,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    fetchLogo: boolean = false
  ): Promise<GetEntityQueryExtended[]> {
    const domain = EntityService.getDomain();

    let listEntitys: GetEntityQuery[] = null;

    try {
      let result = await this.api.EntitiesByDomainAndEntityType(
        domain,
        { eq: entityType },
        sortDirection,
        filter,
        environment.queryListLimit
      );

      listEntitys = result.items as any;

      while (result.nextToken) {
        result = await this.api.EntitiesByDomainAndEntityType(
          domain,
          { eq: entityType },
          sortDirection,
          filter,
          environment.queryListLimit,
          result.nextToken
        );
        listEntitys = listEntitys.concat(result.items as any);
      }

      if (fetchLogo) {
        const promises = listEntitys.map(entity => this.setLogoUrl(entity));
        return Promise.all(promises);
      }

      this.logger.log('EntityService.listEntitys() returned (not from cache)');

      // update cache map
      // EntityService.entityMap[JSON.stringify(filter)] = listEntitys;
      listEntitys.forEach(entity => (EntityService.entityMap[entity.id] = entity));
      return listEntitys;
    } catch (e) {
      this.logger.error('listEntitys - Error: ', e);
      return Promise.reject(e);
    }
  }

  async listEntitysByEntityId(
    entityId: string,
    isRoot: boolean,
    filter?: ModelEntityFilterInput
  ): Promise<GetEntityQuery[]> {
    try {
      let entityList: GetEntityQuery[] = [];
      let nextToken = null;
      const apiQuery = isRoot ? this.api.EntityRootEntityByType : this.api.EntityChildEntityByType;
      do {
        const entityResponse: EntityParentQuery = await apiQuery(
          entityId,
          null,
          null,
          filter,
          environment.queryListLimit,
          nextToken
        );
        entityList = entityList.concat(entityResponse.items as any);
        nextToken = entityResponse.nextToken;
      } while (nextToken);
      return entityList;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async listChildEntitysByParentId(
    parentId: string,
    filter?: ModelEntityFilterInput
  ): Promise<GetEntityQuery[]> {
    try {
      let entityList: GetEntityQuery[] = [];
      let nextToken = null;
      const childFilter: ModelEntityFilterInput = {
        entityType: { eq: EntityTypeEnum.CHILD_ENTITY },
      };
      filter = { ...filter, ...childFilter };
      do {
        const entityResponse: EntityParentQuery = await this.api.EntityParent(
          parentId,
          null,
          filter,
          environment.queryListLimit,
          nextToken
        );
        entityList = entityList.concat(entityResponse.items as any);
        nextToken = entityResponse.nextToken;
      } while (nextToken);
      return entityList;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async listEntitysByParentId(
    parentId: string,
    filter?: ModelEntityFilterInput
  ): Promise<GetEntityQuery[]> {
    try {
      let entityList: GetEntityQuery[] = [];
      let nextToken = null;
      do {
        const entityResponse: EntityParentQuery = await this.api.EntityParent(
          parentId,
          null,
          filter,
          environment.queryListLimit,
          nextToken
        );
        entityList = entityList.concat(entityResponse.items as any);
        nextToken = entityResponse.nextToken;
      } while (nextToken);
      return entityList;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async listEntitysByFrameworkType(
    entityId?: string,
    frameworkType?: ModelStringKeyConditionInput,
    isRootEntity = true,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    fromCache = true
  ): Promise<GetEntityQuery[]> {
    if (!entityId) {
      this.logger.error('listEntitysByFrameworkType - Error: entityId is required');
      return Promise.reject('listEntitysByFrameworkType - Error: filter is required');
    }

    let listEntitys: GetEntityQuery[] = null;
    // if (fromCache && EntityService.entityMap[JSON.stringify(filter)]) {
    //   listEntitys = EntityService.entityMap[JSON.stringify(filter)];
    // }

    // if (fromCache && listEntitys && listEntitys.length) {
    //   return Promise.resolve(listEntitys);
    // }

    try {
      const fetchEntitiesFunction = isRootEntity
        ? this.api.EntityRootEntityByType
        : this.api.EntityChildEntityByType;
      let result = await fetchEntitiesFunction(
        entityId,
        frameworkType,
        sortDirection,
        filter,
        environment.queryListLimit
      );

      listEntitys = result.items as any;

      while (result.nextToken) {
        result = await fetchEntitiesFunction(
          entityId,
          frameworkType,
          (sortDirection = ModelSortDirection.ASC),
          filter,
          environment.queryListLimit,
          result.nextToken
        );
        listEntitys = listEntitys.concat(result.items as any);
      }

      this.logger.log('EntityService.listEntitys() returned (not from cache)');

      // update cache map
      // EntityService.entityMap[JSON.stringify(filter)] = listEntitys;
      listEntitys.forEach(entity => (EntityService.entityMap[entity.id] = entity));

      return listEntitys;
    } catch (e) {
      this.logger.error('listEntitys - Error: ', e);
      return Promise.reject(e);
    }
  }

  public async assignProjectManager(
    entityId: string,
    userId: string
  ): Promise<UpdateEntityMutation> {
    const updateEntityInput: UpdateEntityInput = {
      id: entityId,
      projectManager: userId,
    };
    const response = await this.api.UpdateEntity(updateEntityInput);
    return response;
  }

  private async afterCreateEntity(createdEntity: CreateEntityMutation): Promise<void> {
    console.log('afterCreateEntity called', createdEntity);
    if (
      createdEntity.entityType === EntityTypeEnum.CHILD_ENTITY ||
      createdEntity.entityType === EntityTypeEnum.VENDOR
    ) {
      try {
        if (!!createdEntity.activeAssessment && !!createdEntity.projectManager) {
          const response = await Promise.all(
            createdEntity.activeAssessment.standardFrameworkList.items.map(async framework => {
              await this.assignFramework(
                framework.fileName ? framework.fileName : framework.key,
                framework.assessmentId,
                createdEntity.projectManager
              );
            })
          );
          console.log('assigned assessment to project manager', response);
        }
      } catch (e) {
        console.log('failed to assign assessment to project manager', e);
      }
    }
  }

  async assignAssessment(assessmentId: string, userId: string): Promise<CreateAssignmentMutation> {
    const response = await this.api.CreateAssignment({
      id: uuid(),
      assessmentId,
      userId,
      targetId: assessmentId,
      left: 0,
      right: 1000000,
    });

    return response;
  }

  async assignFramework(
    frameworkKey: string,
    assessmentId: string,
    userId: string
  ): Promise<CreateAssignmentMutation> {
    const frameworkRange = this.getFrameworkRange(frameworkKey);
    if (frameworkRange) {
      const response = await this.api.CreateAssignment({
        id: uuid(),
        assessmentId,
        userId,
        targetId: assessmentId,
        left: frameworkRange.left,
        right: frameworkRange.right,
      });

      return response;
    }
    return {} as CreateAssignmentMutation;
  }

  getFrameworkRange(frameworkKey): any {
    return FrameworkRangeMap.find(mapItem => mapItem.name === frameworkKey);
  }

  async getCurrentUser(): Promise<GetUserQuery> {
    if (!this.authUser) {
      this.authUser = await Auth.currentAuthenticatedUser();
    }
    if (this.authUser && !this.user) {
      const uid = this.authUser.username;
      this.user = await this.api.GetUser(uid);
    }
    return this.user;
  }

  onCreateEntityListener(): any {
    return this.api.OnCreateEntityListener.subscribe({
      next: (res: any) => {
        const createdEntity: GetEntityQuery = res.value.data.onCreateEntity;
        // this.logger.log('onCreateEntityListener - createdEntity', createdEntity);
        if (createdEntity) {
          // update cache map
          EntityService.entityMap[createdEntity.id] = createdEntity;
        }
        // emit updated entity list
        EntityService.onEntityAdded.next(true);
      },
    });
  }

  onUpdateEntityListener(): any {
    return this.api.OnUpdateEntityScoreListener.subscribe({
      next: (res: any) => {
        const updatedEntity: GetEntityQuery = res.value.data.onUpdateEntity;
        // this.logger.log('onUpdateEntityListener - updatedEntity', updatedEntity);
        if (updatedEntity) {
          this.updateEntityScoreSubscriber.next(updatedEntity);
          this.updateVendorSubscriber.next(updatedEntity);
        }
        // emit updated entity list
        EntityService.onDataChanged.next(true);
      },
    });
  }

  onDeleteEntityListener(): any {
    return this.api.OnDeleteEntityListener.subscribe({
      next: (res: any) => {
        const deletedEntity: GetEntityQuery = res.value.data.onDeleteEntity;
        // this.logger.log('onDeleteEntityListener - deletedEntity', deletedEntity);
        if (deletedEntity && EntityService.entityMap[deletedEntity.id]) {
          // update cache map
          delete EntityService.entityMap[deletedEntity.id];
          // emit updated entity list
          EntityService.onDataChanged.next(true);
        }
      },
    });
  }

  async afterDeleteEntity(entityId): Promise<void> {
    await this.removeChildEntities(entityId);
  }

  async removeChildEntities(entityId): Promise<void> {
    this.logger.log('parentId = ', entityId);
    const childs = await this.getAllChilds(entityId);
    this.logger.log('child  =  ', childs);
  }

  async getAllChilds(entityId: string): Promise<GetEntityQueryExtended[]> {
    let result: ListEntitysQuery;
    let items: GetEntityQuery[];
    let nextToken;
    do {
      nextToken = result ? result.nextToken : null;
      result = await this.api.ListEntitys(null, environment.queryListLimit, nextToken);
      items = result.items as any;
    } while (result.nextToken);

    const promises = items.map(entity => this.setLogoUrl(entity));
    return Promise.all(promises);
  }

  async getExtendEntityList(
    filter: ModelEntityFilterInput,
    fetchLogo = true
  ): Promise<GetEntityQueryExtended[]> {
    let result = await this.api.ListEntitys(filter, environment.queryListLimit);

    let entityList: GetEntityQuery[] = result.items as any;

    while (result.nextToken) {
      result = await this.api.ListEntitys(filter, environment.queryListLimit, result.nextToken);
      entityList = entityList.concat(result.items as any);
    }

    if (fetchLogo) {
      const promises = entityList.map(entity => this.setLogoUrl(entity));
      return Promise.all(promises);
    }

    return entityList;
  }

  isRootEntity(entity: GetEntityQuery): boolean {
    return entity.entityType === EntityTypeEnum.ROOT_ENTITY;
  }

  isChildEntity(entity: GetEntityQuery): boolean {
    return entity.entityType === EntityTypeEnum.CHILD_ENTITY;
  }

  async getUsersByEntityId(entityId: string): Promise<GetUserQuery[]> {
    let result: ListUsersQuery;
    let items: GetUserQuery[] = [];
    let nextToken;
    do {
      nextToken = result ? result.nextToken : null;
      result = await this.api.UserByEntityId(
        entityId,
        null,
        null,
        environment.queryListLimit,
        nextToken
      );
      items = items.concat(result.items);
    } while (result.nextToken);

    return items;
  }

  async getUsersByRole(role: RoleEnum): Promise<GetUserQuery[]> {
    let result: ListUsersQuery;
    let items: GetUserQuery[] = [];
    let nextToken;
    do {
      nextToken = result ? result.nextToken : null;
      result = await this.api.UserRole(role, null, null, environment.queryListLimit, nextToken);
      items = items.concat(result.items);
    } while (result.nextToken);

    return items;
  }

  async getAllUsersForEntity(
    entity: GetEntityQuery,
    childEntityIds: [string]
  ): Promise<GetUserQuery[]> {
    let promises = [];
    promises.push(this.getUsersByEntityId(entity.id));

    if (this.isRootEntity(entity)) {
      promises = promises.concat(childEntityIds.map(this.getUsersByEntityId, this));
    }

    const usersArrays = await Promise.all(promises);
    let users = [];
    usersArrays.forEach(usersArray => {
      users = users.concat(usersArray);
    });

    return users;
  }

  async getAllUsersForEntityWithRole(
    rootEntityId: string,
    childEntityIds: [string],
    user: GetUserQuery
  ): Promise<GetUserQuery[]> {
    let promises = [];

    switch (user.role) {
      case RoleEnum.ADMIN:
        promises.push(this.getUsersByRole(RoleEnum.ADMIN));
        promises.push(this.getUsersByEntityId(rootEntityId));
        promises = promises.concat(childEntityIds.map(this.getUsersByEntityId, this));
        break;
      case RoleEnum.MSSP:
        promises.push(this.getUsersByEntityId(rootEntityId));
        promises = promises.concat(childEntityIds.map(this.getUsersByEntityId, this));
        break;
      case RoleEnum.LEADER:
        if (user.entityId === rootEntityId) {
          // Entity Leader
          promises.push(this.getUsersByEntityId(rootEntityId));
        }
        promises = promises.concat(childEntityIds.map(this.getUsersByEntityId, this));
        break;
      default:
        promises = promises.concat(childEntityIds.map(this.getUsersByEntityId, this));
        break;
    }

    const usersArrays = await Promise.all(promises);
    let users = [];
    usersArrays.forEach(usersArray => {
      users = users.concat(usersArray);
    });
    return users;
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach(listener => listener.unsubscribe());
  }

  private async createAndAddUserGroup(createdEntity: CreateEntityInput): Promise<void> {
    try {
      // Add Cognito Group after creating Entity
      const groupInput: AddGroupInput = { id: createdEntity.id, desc: createdEntity.name };
      let shouldAddUser = false;
      const currentUser = await this.getCurrentUser();

      if (currentUser.role === RoleEnum.MSSP) {
        shouldAddUser = true;
      }
      const addCognitoGroupRes = await this.api.AddCognitoGroup(groupInput, shouldAddUser);
      this.logger.log('AddCognitoGroup: ', addCognitoGroupRes);
    } catch (e) {
      this.logger.error('afterCreateEntity - AddCognitoGroup - Error: ', e);
    }

    try {
      const updateUserGroupsRes = await this.updateUserGroups();
      this.logger.log('updateUserGroups: ', updateUserGroupsRes);
    } catch (e) {
      this.logger.error('afterCreateEntity - updateUserGroups - Error: ', e);
    }
  }

  async listAssessmentByRootId(entityId): Promise<GetAssessmentQuery[]> {
    let result: ListAssessmentsQuery;
    let items: GetAssessmentQuery[];
    let nextToken;
    const filter: ModelAssessmentFilterInput = {
      rootId: { eq: entityId },
    };
    do {
      nextToken = result ? result.nextToken : null;
      result = await this.api.ListAssessments(filter, environment.queryListLimit, nextToken);
      items = result.items as any;
    } while (result.nextToken);

    return result.items as any;
  }

  public async getFrameworksAndManagersByEntityId(
    entityId: string,
    standardType: StandardType,
    isRootEntity: boolean,
    thirdParty = false
  ): Promise<FrameworkScoreAndManagers> {
    const subEntitiesList: GetEntityQuery[] = await this.getSubEntitiesList(
      entityId,
      isRootEntity,
      thirdParty
    );
    const managers = [];
    subEntitiesList.forEach(se => {
      if (se.activeAssessment && se.activeAssessment.managers) {
        se.activeAssessment.managers.forEach(manager => {
          managers.push(manager);
        });
      }
    });
    const frameworks = this.fetchEntitiesFrameworks(subEntitiesList, standardType);
    return {
      frameworks,
      managers,
    };
  }

  public async getFrameworksByEntityId(
    entityId: string,
    standardType: StandardType,
    isRootEntity: boolean,
    thirdParty = false
  ): Promise<FrameworkScore[]> {
    const subEntitiesList: GetEntityQuery[] = await this.getSubEntitiesList(
      entityId,
      isRootEntity,
      thirdParty
    );
    const frameworks = this.fetchEntitiesFrameworks(subEntitiesList, standardType);
    return frameworks;
  }

  public async getSubEntitiesList(
    entityId: string,
    isRoot: boolean,
    thirdParty = false
  ): Promise<GetEntityQuery[]> {
    let subEntitiesList: GetEntityQuery[];
    if (isRoot) {
      let filter: ModelEntityFilterInput;
      if (thirdParty) {
        filter = {
          entityType: { eq: EntityTypeEnum.VENDOR },
        };
      } else {
        filter = {
          entityType: { eq: EntityTypeEnum.CHILD_ENTITY },
        };
      }
      subEntitiesList = await this.listEntitysByParentId(entityId, filter);
    } else {
      const entity = await this.getEntity(entityId, false);
      if (entity) {
        subEntitiesList = [entity];
      }
    }
    return subEntitiesList;
  }

  public fetchEntitiesFrameworks(
    subEntitiesList: GetEntityQuery[],
    frameworkType?: StandardType
  ): FrameworkScore[] {
    let filter = (listItem): boolean => true;
    if (frameworkType) {
      filter = (listItem): boolean => listItem.type === frameworkType;
    }
    return subEntitiesList
      .map(subEntity => {
        return subEntity.activeAssessment.standardFrameworkList.items
          .filter(filter)
          .map(listItem => {
            return {
              ...{
                key: listItem.key,
                id: listItem.id,
                type: listItem.type,
                fileName: listItem.fileName,
                filter: listItem.filter,
              },
              assessmentId: subEntity.activeAssessmentId,
              name: listItem.key,
              scores: {},
              rootId: subEntity.parentId,
              childId: subEntity.id,
              __typename: 'StandardFramework',
            } as FrameworkScore;
          });
      })
      .flat();
  }

  public getFrameworkScores(
    assessmentId: string,
    frameworkId: string
  ): Promise<GetFrameworkScoresQuery> {
    return this.api.GetFrameworkScores(`${assessmentId}_${frameworkId}`);
  }

  private async updateUserGroups(): Promise<void> {
    const currentUser = await Auth.currentAuthenticatedUser();
    const userSession = currentUser.getSignInUserSession();
    const refreshToken = userSession.getRefreshToken();
    currentUser.refreshSession(refreshToken, (err, session) => {
      currentUser.setSignInUserSession(session);
    });
  }

  emitSubEntityName(subEntityName: string): void {
    this.activeSubEntityObservable.next(subEntityName);
  }
}
