import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../shared/utils.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  ModelEntityFilterInput,
  EntityTypeEnum,
  APIService,
  GetEntityQuery,
} from 'app/API.service';
import { EntityService } from 'app/shared/entity.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'cygov-third-party',
  templateUrl: './third-party.component.html',
  styleUrls: ['./third-party.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'third-party-class',
  },
})
export class ThirdPartyComponent implements OnInit {
  isLoading = true;
  expandHeatMap = false;
  vendors: GetEntityQuery[];
  sortBy: string;
  filterBy: string;
  searchText: string;

  constructor(
    private api: APIService,
    private router: Router,
    private route: ActivatedRoute,
    private entityService: EntityService,
    private logger: NGXLogger
  ) {}

  ngOnInit() {
    this.getVendorList();
  }

  async getVendorList() {
    try {
      const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      if (!this.router.url.includes('vendors')) {
        try {
          const filter: ModelEntityFilterInput = {
            entityType: { eq: EntityTypeEnum.VENDOR },
          };
          this.vendors = await this.entityService.listEntitysByParentId(entityId, filter);
        } catch (e) {
          this.logger.error('Vendor Overview Get - Error: ', e);
        }
      }
      this.isLoading = false;
      const url = this.router.url.slice(1, this.router.url.length);
      if (entityId && !url.includes('/')) {
        this.router.navigate([`third-party/${entityId}/overview`]);
      }
    } catch (e) {
      this.isLoading = false;
      this.logger.error('Error While calling Vendor Api', e);
    }
  }
  openExtendedHeatMap(value: boolean) {
    this.expandHeatMap = value;
  }
}
