/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import * as Observable from "zen-observable";

export enum StandardType {
  COMPLIANCE_FRAMEWORK = "COMPLIANCE_FRAMEWORK",
  RISK_FRAMEWORK = "RISK_FRAMEWORK",
  ARTIFACT = "ARTIFACT"
}

export type CreateFrameworksNodesInput = {
  frameworkList?: Array<Framework> | null;
  accessGroups: Array<string>;
  vendorRawData?: string | null;
};

export type Framework = {
  name: string;
  type?: EntityTypeEnum | null;
};

export enum EntityTypeEnum {
  ROOT_ENTITY = "ROOT_ENTITY",
  CHILD_ENTITY = "CHILD_ENTITY",
  VENDOR = "VENDOR",
  RISK_FRAMEWORK = "RISK_FRAMEWORK",
  COMPLIANCE_FRAMEWORK = "COMPLIANCE_FRAMEWORK",
  FRAMEWORK_NODE = "FRAMEWORK_NODE"
}

export type AddGroupInput = {
  id: string;
  desc?: string | null;
};

export type AssignUserInput = {
  userId: string;
  targetId: string;
  assessmentId: string;
  left: number;
  right: number;
};

export enum RoleEnum {
  ADMIN = "ADMIN",
  MSSP = "MSSP",
  LEADER = "LEADER",
  VENDOR = "VENDOR",
  PARTICIPANT = "PARTICIPANT"
}

export type IntegrationDetailsInput = {
  name: string;
  credentials: string;
  isEnable: boolean;
  isAuth: boolean;
  inheritFromParent: boolean;
};

export enum ImpactEnum {
  VERY_HIGH = "VERY_HIGH",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW"
}

export enum FrequencyCheckEnum {
  ANNUAL = "ANNUAL",
  SEMI_ANNUAL = "SEMI_ANNUAL",
  QUARTERLY = "QUARTERLY",
  MANUAL = "MANUAL"
}

export type GenerateEntityDataInput = {
  entityId: string;
  possibleAnswers: Array<number | null>;
};

export type CreateUserInput = {
  id?: string | null;
  name: string;
  email: string;
  role: RoleEnum;
  phone?: string | null;
  entityId?: string | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
};

export type ModelUserConditionInput = {
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelRoleEnumInput | null;
  phone?: ModelStringInput | null;
  entityId?: ModelIDInput | null;
  isCognitoUser?: ModelBooleanInput | null;
  questionMap?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  createdAt?: ModelIntInput | null;
  lastLogin?: ModelIntInput | null;
  invitationDate?: ModelIntInput | null;
  reminderDate?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  modifiedAt?: ModelIntInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelRoleEnumInput = {
  eq?: RoleEnum | null;
  ne?: RoleEnum | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateUserInput = {
  id: string;
  name?: string | null;
  email?: string | null;
  role?: RoleEnum | null;
  phone?: string | null;
  entityId?: string | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
};

export type DeleteUserInput = {
  id?: string | null;
};

export type CreateEntityInput = {
  id?: string | null;
  name: string;
  users?: Array<string | null> | null;
  completionStatus?: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId?: string | null;
  childEntityId?: string | null;
  frameworkId?: string | null;
  frameworkName?: string | null;
  frameworkType?: EntityTypeEnum | null;
  parentId?: string | null;
  timeline?: TimelineInput | null;
  scores?: ScoresInput | null;
  entityType: EntityTypeEnum;
  projectManager?: string | null;
  projectManagerName?: string | null;
  projectDeadline?: number | null;
  logo?: S3FileInput | null;
  integrations?: Array<IntegrationsInput | null> | null;
  externalFeed?: Array<IntegrationsInput | null> | null;
  defaultSetting?: DefaultSettingInput | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  industry?: IndustryEnum | null;
  domain: string;
  activeAssessmentId: string;
};

export enum CollectionStatusEnum {
  HAS_NOT_BEGUN = "HAS_NOT_BEGUN",
  IN_PROCESS = "IN_PROCESS",
  COMPLETED = "COMPLETED"
}

export type TimelineInput = {
  initiationDate?: number | null;
  collectionDate?: number | null;
  nextReviewDate?: number | null;
  frequency?: FrequencyCheckEnum | null;
};

export type ScoresInput = {
  total?: number | null;
  target?: number | null;
  collection?: number | null;
  remediation?: number | null;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  totalGaps?: number | null;
  criticalGaps?: number | null;
  notApplicable?: boolean | null;
  top20?: ScoresInput | null;
};

export type S3FileInput = {
  id?: string | null;
  name: string;
  names?: string | null;
  entityId: string;
  fileType: FileTypeEnum;
  contentType?: string | null;
  date?: number | null;
  body?: string | null;
  length?: number | null;
  key?: string | null;
};

export enum FileTypeEnum {
  LOGO = "LOGO",
  ARTIFACTS = "ARTIFACTS",
  REVISIONS = "REVISIONS",
  ALERTS = "ALERTS",
  MEDIA = "MEDIA",
  ASSESSMENTS = "ASSESSMENTS",
  FRAMEWORK_TREES = "FRAMEWORK_TREES",
  INDUSTRY_FRAMEWORKS = "INDUSTRY_FRAMEWORKS"
}

export type IntegrationsInput = {
  name: string;
  credentials?: string | null;
  isEnable?: boolean | null;
  isAuth?: boolean | null;
  inheritFromParent?: boolean | null;
};

export type DefaultSettingInput = {
  dueDateInDays?: number | null;
  frequency?: string | null;
  surveyLevel?: string | null;
  artifacts?: Array<QualificationInput | null> | null;
  standards?: Array<QualificationInput | null> | null;
  accessLevel?: string | null;
  privacyData?: boolean | null;
  impactsQualify?: Array<QualificationInput | null> | null;
};

export type QualificationInput = {
  name?: string | null;
  isQualify?: boolean | null;
};

export enum IndustryEnum {
  FINANCE = "FINANCE",
  HEALTHCARE = "HEALTHCARE",
  EDUCATION = "EDUCATION",
  GOVERNMENT = "GOVERNMENT",
  RETAIL = "RETAIL",
  ENERGY = "ENERGY"
}

export type ModelEntityConditionInput = {
  name?: ModelStringInput | null;
  users?: ModelIDInput | null;
  completionStatus?: ModelCollectionStatusEnumInput | null;
  rootEntityId?: ModelIDInput | null;
  childEntityId?: ModelIDInput | null;
  frameworkId?: ModelIDInput | null;
  frameworkName?: ModelStringInput | null;
  frameworkType?: ModelEntityTypeEnumInput | null;
  parentId?: ModelIDInput | null;
  entityType?: ModelEntityTypeEnumInput | null;
  projectManager?: ModelIDInput | null;
  projectManagerName?: ModelStringInput | null;
  projectDeadline?: ModelIntInput | null;
  createdBy?: ModelStringInput | null;
  createdAt?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  modifiedAt?: ModelIntInput | null;
  industry?: ModelIndustryEnumInput | null;
  domain?: ModelStringInput | null;
  activeAssessmentId?: ModelIDInput | null;
  and?: Array<ModelEntityConditionInput | null> | null;
  or?: Array<ModelEntityConditionInput | null> | null;
  not?: ModelEntityConditionInput | null;
};

export type ModelCollectionStatusEnumInput = {
  eq?: CollectionStatusEnum | null;
  ne?: CollectionStatusEnum | null;
};

export type ModelEntityTypeEnumInput = {
  eq?: EntityTypeEnum | null;
  ne?: EntityTypeEnum | null;
};

export type ModelIndustryEnumInput = {
  eq?: IndustryEnum | null;
  ne?: IndustryEnum | null;
};

export enum VendorStatusEnum {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  IN_PROCESS = "IN_PROCESS",
  DENIED = "DENIED",
  OPEN = "OPEN",
  CLOSE = "CLOSE",
  DELETE = "DELETE"
}

export enum AccessLevelEnum {
  PHYSICAL = "PHYSICAL",
  SOFTWARE_PROVIDER = "SOFTWARE_PROVIDER",
  INTERNAL_NETWORK = "INTERNAL_NETWORK"
}

export type UpdateEntityInput = {
  id: string;
  name?: string | null;
  users?: Array<string | null> | null;
  completionStatus?: CollectionStatusEnum | null;
  accessGroups?: Array<string> | null;
  participantGroup?: Array<string> | null;
  rootEntityId?: string | null;
  childEntityId?: string | null;
  frameworkId?: string | null;
  frameworkName?: string | null;
  frameworkType?: EntityTypeEnum | null;
  parentId?: string | null;
  timeline?: TimelineInput | null;
  scores?: ScoresInput | null;
  entityType?: EntityTypeEnum | null;
  projectManager?: string | null;
  projectManagerName?: string | null;
  projectDeadline?: number | null;
  logo?: S3FileInput | null;
  integrations?: Array<IntegrationsInput | null> | null;
  externalFeed?: Array<IntegrationsInput | null> | null;
  defaultSetting?: DefaultSettingInput | null;
  createdBy?: string | null;
  createdAt?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  industry?: IndustryEnum | null;
  domain?: string | null;
  activeAssessmentId?: string | null;
};

export type DeleteEntityInput = {
  id?: string | null;
};

export type CreateVendorsDetailInput = {
  id?: string | null;
  impact?: number | null;
  externalScan?: number | null;
  financialRisk?: number | null;
  criticalGaps?: number | null;
  standardsQualify?: Array<QualificationInput | null> | null;
  status?: VendorStatusEnum | null;
  probability?: number | null;
  website?: string | null;
  location?: string | null;
  size?: string | null;
  accessLevel?: AccessLevelEnum | null;
  collectionStatus?: CollectionStatusEnum | null;
  timeline?: TimelineInput | null;
  approvalDate?: number | null;
  questionnaires?: Array<QuestionnaireInput | null> | null;
  artifacts?: ArtifactsInput | null;
  primaryPoc?: ShortRefInput | null;
  stage?: VendorStatusEnum | null;
  activeScan?: boolean | null;
  domain?: string | null;
  privacyData?: boolean | null;
  impactsQualify?: Array<QualificationInput | null> | null;
};

export type QuestionnaireInput = {
  tag?: string | null;
  status?: QuestionnaireStatusInput | null;
  criticalGaps?: Array<string | null> | null;
  allGaps?: string | null;
};

export type QuestionnaireStatusInput = {
  totalQuestions?: number | null;
  completedQuestions?: number | null;
};

export type ArtifactsInput = {
  documents?: Array<S3FileInput | null> | null;
  scans?: Array<S3FileInput | null> | null;
  insurances?: Array<S3FileInput | null> | null;
  certifications?: Array<S3FileInput | null> | null;
};

export type ShortRefInput = {
  name?: string | null;
  id?: string | null;
  email?: string | null;
};

export type ModelVendorsDetailConditionInput = {
  impact?: ModelIntInput | null;
  externalScan?: ModelFloatInput | null;
  financialRisk?: ModelIntInput | null;
  criticalGaps?: ModelIntInput | null;
  status?: ModelVendorStatusEnumInput | null;
  probability?: ModelIntInput | null;
  website?: ModelStringInput | null;
  location?: ModelStringInput | null;
  size?: ModelStringInput | null;
  accessLevel?: ModelAccessLevelEnumInput | null;
  collectionStatus?: ModelCollectionStatusEnumInput | null;
  approvalDate?: ModelIntInput | null;
  stage?: ModelVendorStatusEnumInput | null;
  activeScan?: ModelBooleanInput | null;
  domain?: ModelStringInput | null;
  privacyData?: ModelBooleanInput | null;
  and?: Array<ModelVendorsDetailConditionInput | null> | null;
  or?: Array<ModelVendorsDetailConditionInput | null> | null;
  not?: ModelVendorsDetailConditionInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelVendorStatusEnumInput = {
  eq?: VendorStatusEnum | null;
  ne?: VendorStatusEnum | null;
};

export type ModelAccessLevelEnumInput = {
  eq?: AccessLevelEnum | null;
  ne?: AccessLevelEnum | null;
};

export type UpdateVendorsDetailInput = {
  id: string;
  impact?: number | null;
  externalScan?: number | null;
  financialRisk?: number | null;
  criticalGaps?: number | null;
  standardsQualify?: Array<QualificationInput | null> | null;
  status?: VendorStatusEnum | null;
  probability?: number | null;
  website?: string | null;
  location?: string | null;
  size?: string | null;
  accessLevel?: AccessLevelEnum | null;
  collectionStatus?: CollectionStatusEnum | null;
  timeline?: TimelineInput | null;
  approvalDate?: number | null;
  questionnaires?: Array<QuestionnaireInput | null> | null;
  artifacts?: ArtifactsInput | null;
  primaryPoc?: ShortRefInput | null;
  stage?: VendorStatusEnum | null;
  activeScan?: boolean | null;
  domain?: string | null;
  privacyData?: boolean | null;
  impactsQualify?: Array<QualificationInput | null> | null;
};

export type DeleteVendorsDetailInput = {
  id?: string | null;
};

export type CreateFrameworkScoresInput = {
  id?: string | null;
  assessmentId: string;
  type: StandardType;
  total: number;
  target: number;
  collection: number;
  remediation: number;
  totalQuestions: number;
  completedQuestions: number;
  totalGaps: number;
  criticalGaps: number;
  notApplicable?: boolean | null;
  assignmentCount?: number | null;
  riskStandardsCount?: number | null;
  complianceStandardsCount?: number | null;
  standardsCount?: number | null;
  top20?: ScoresInput | null;
};

export type ModelFrameworkScoresConditionInput = {
  assessmentId?: ModelIDInput | null;
  type?: ModelStandardTypeInput | null;
  total?: ModelFloatInput | null;
  target?: ModelFloatInput | null;
  collection?: ModelFloatInput | null;
  remediation?: ModelFloatInput | null;
  totalQuestions?: ModelIntInput | null;
  completedQuestions?: ModelIntInput | null;
  totalGaps?: ModelIntInput | null;
  criticalGaps?: ModelIntInput | null;
  notApplicable?: ModelBooleanInput | null;
  assignmentCount?: ModelIntInput | null;
  riskStandardsCount?: ModelIntInput | null;
  complianceStandardsCount?: ModelIntInput | null;
  standardsCount?: ModelIntInput | null;
  and?: Array<ModelFrameworkScoresConditionInput | null> | null;
  or?: Array<ModelFrameworkScoresConditionInput | null> | null;
  not?: ModelFrameworkScoresConditionInput | null;
};

export type ModelStandardTypeInput = {
  eq?: StandardType | null;
  ne?: StandardType | null;
};

export type UpdateFrameworkScoresInput = {
  id: string;
  assessmentId?: string | null;
  type?: StandardType | null;
  total?: number | null;
  target?: number | null;
  collection?: number | null;
  remediation?: number | null;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  totalGaps?: number | null;
  criticalGaps?: number | null;
  notApplicable?: boolean | null;
  assignmentCount?: number | null;
  riskStandardsCount?: number | null;
  complianceStandardsCount?: number | null;
  standardsCount?: number | null;
  top20?: ScoresInput | null;
};

export type DeleteFrameworkScoresInput = {
  id?: string | null;
};

export type CreateIntelligenceInput = {
  id?: string | null;
};

export type ModelIntelligenceConditionInput = {
  and?: Array<ModelIntelligenceConditionInput | null> | null;
  or?: Array<ModelIntelligenceConditionInput | null> | null;
  not?: ModelIntelligenceConditionInput | null;
};

export type UpdateIntelligenceInput = {
  id: string;
};

export type DeleteIntelligenceInput = {
  id?: string | null;
};

export type CreateBreachesInput = {
  id?: string | null;
  vendorId: string;
  date?: number | null;
  name?: string | null;
  description?: string | null;
  leaks?: number | null;
  data?: Array<string | null> | null;
  status?: string | null;
};

export type ModelBreachesConditionInput = {
  vendorId?: ModelIDInput | null;
  date?: ModelIntInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  leaks?: ModelIntInput | null;
  data?: ModelStringInput | null;
  status?: ModelStringInput | null;
  and?: Array<ModelBreachesConditionInput | null> | null;
  or?: Array<ModelBreachesConditionInput | null> | null;
  not?: ModelBreachesConditionInput | null;
};

export type UpdateBreachesInput = {
  id: string;
  vendorId?: string | null;
  date?: number | null;
  name?: string | null;
  description?: string | null;
  leaks?: number | null;
  data?: Array<string | null> | null;
  status?: string | null;
};

export type DeleteBreachesInput = {
  id?: string | null;
};

export type CreateExternalThreatsInput = {
  id?: string | null;
  vendorId: string;
  source?: string | null;
  findings?: Array<string | null> | null;
  riskScore?: string | null;
  details?: Array<ThreatDetailsInput | null> | null;
  status?: string | null;
};

export type ThreatDetailsInput = {
  ip?: string | null;
  port?: number | null;
  cve?: string | null;
  cvss?: number | null;
};

export type ModelExternalThreatsConditionInput = {
  vendorId?: ModelIDInput | null;
  source?: ModelStringInput | null;
  findings?: ModelStringInput | null;
  riskScore?: ModelStringInput | null;
  status?: ModelStringInput | null;
  and?: Array<ModelExternalThreatsConditionInput | null> | null;
  or?: Array<ModelExternalThreatsConditionInput | null> | null;
  not?: ModelExternalThreatsConditionInput | null;
};

export type UpdateExternalThreatsInput = {
  id: string;
  vendorId?: string | null;
  source?: string | null;
  findings?: Array<string | null> | null;
  riskScore?: string | null;
  details?: Array<ThreatDetailsInput | null> | null;
  status?: string | null;
};

export type DeleteExternalThreatsInput = {
  id?: string | null;
};

export type CreateOthersInput = {
  id?: string | null;
  vendorId: string;
  name?: string | null;
  label?: string | null;
  severity?: string | null;
  description?: string | null;
  status?: string | null;
};

export type ModelOthersConditionInput = {
  vendorId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  label?: ModelStringInput | null;
  severity?: ModelStringInput | null;
  description?: ModelStringInput | null;
  status?: ModelStringInput | null;
  and?: Array<ModelOthersConditionInput | null> | null;
  or?: Array<ModelOthersConditionInput | null> | null;
  not?: ModelOthersConditionInput | null;
};

export type UpdateOthersInput = {
  id: string;
  vendorId?: string | null;
  name?: string | null;
  label?: string | null;
  severity?: string | null;
  description?: string | null;
  status?: string | null;
};

export type DeleteOthersInput = {
  id?: string | null;
};

export type CreateQuestionnaireCompletionInput = {
  id?: string | null;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  userId: string;
  assessmentId: string;
  assignmentMap?: string | null;
  firstAnswer?: number | null;
  lastAnswer?: number | null;
};

export type ModelQuestionnaireCompletionConditionInput = {
  totalQuestions?: ModelIntInput | null;
  completedQuestions?: ModelIntInput | null;
  userId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  assignmentMap?: ModelStringInput | null;
  firstAnswer?: ModelIntInput | null;
  lastAnswer?: ModelIntInput | null;
  and?: Array<ModelQuestionnaireCompletionConditionInput | null> | null;
  or?: Array<ModelQuestionnaireCompletionConditionInput | null> | null;
  not?: ModelQuestionnaireCompletionConditionInput | null;
};

export type UpdateQuestionnaireCompletionInput = {
  id: string;
  totalQuestions?: number | null;
  completedQuestions?: number | null;
  userId?: string | null;
  assessmentId?: string | null;
  assignmentMap?: string | null;
  firstAnswer?: number | null;
  lastAnswer?: number | null;
};

export type DeleteQuestionnaireCompletionInput = {
  id?: string | null;
};

export type CreateAnswerInput = {
  id?: string | null;
  createdAt?: string | null;
  answer?: number | null;
  comment?: string | null;
  file?: Array<S3FileInput | null> | null;
  userId: string;
  riskAction?: RiskAction | null;
  frameworkName: string;
  questionId?: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken?: boolean | null;
};

export enum RiskAction {
  REMEDIATED = "REMEDIATED",
  ACCEPTED = "ACCEPTED",
  IGNORED = "IGNORED",
  MANAGED = "MANAGED"
}

export type ModelAnswerConditionInput = {
  createdAt?: ModelStringInput | null;
  answer?: ModelIntInput | null;
  comment?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  riskAction?: ModelRiskActionInput | null;
  frameworkName?: ModelStringInput | null;
  questionId?: ModelIDInput | null;
  date?: ModelIntInput | null;
  assessmentId?: ModelIDInput | null;
  assignmentID?: ModelIDInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  isActionTaken?: ModelBooleanInput | null;
  and?: Array<ModelAnswerConditionInput | null> | null;
  or?: Array<ModelAnswerConditionInput | null> | null;
  not?: ModelAnswerConditionInput | null;
};

export type ModelRiskActionInput = {
  eq?: RiskAction | null;
  ne?: RiskAction | null;
};

export type UpdateAnswerInput = {
  id: string;
  createdAt?: string | null;
  answer?: number | null;
  comment?: string | null;
  file?: Array<S3FileInput | null> | null;
  userId?: string | null;
  riskAction?: RiskAction | null;
  frameworkName?: string | null;
  questionId?: string | null;
  date?: number | null;
  assessmentId?: string | null;
  assignmentID?: string | null;
  left?: number | null;
  right?: number | null;
  isActionTaken?: boolean | null;
};

export type DeleteAnswerInput = {
  id?: string | null;
};

export type CreateAssignmentInput = {
  id?: string | null;
  userId: string;
  targetId: string;
  assessmentId: string;
  left: number;
  right: number;
};

export type ModelAssignmentConditionInput = {
  userId?: ModelIDInput | null;
  targetId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  and?: Array<ModelAssignmentConditionInput | null> | null;
  or?: Array<ModelAssignmentConditionInput | null> | null;
  not?: ModelAssignmentConditionInput | null;
};

export type UpdateAssignmentInput = {
  id: string;
  userId?: string | null;
  targetId?: string | null;
  assessmentId?: string | null;
  left?: number | null;
  right?: number | null;
};

export type DeleteAssignmentInput = {
  id?: string | null;
};

export type CreateAssessmentInput = {
  id?: string | null;
  standardList?: Array<StandardFrameworkInput | null> | null;
  timeline?: TimelineInput | null;
  rootId: string;
  childId: string;
  scores?: ScoresInput | null;
  left?: number | null;
  right?: number | null;
  managers?: Array<string | null> | null;
  exports?: ExportsInput | null;
};

export type StandardFrameworkInput = {
  id: string;
  type?: StandardType | null;
  fileName?: string | null;
  filter?: StandardFilterInput | null;
};

export type StandardFilterInput = {
  impact?: ImpactEnum | null;
};

export type ExportsInput = {
  jiraEntityIds?: Array<string | null> | null;
};

export type ModelAssessmentConditionInput = {
  rootId?: ModelIDInput | null;
  childId?: ModelIDInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  managers?: ModelStringInput | null;
  and?: Array<ModelAssessmentConditionInput | null> | null;
  or?: Array<ModelAssessmentConditionInput | null> | null;
  not?: ModelAssessmentConditionInput | null;
};

export type UpdateAssessmentInput = {
  id: string;
  standardList?: Array<StandardFrameworkInput | null> | null;
  timeline?: TimelineInput | null;
  rootId?: string | null;
  childId?: string | null;
  scores?: ScoresInput | null;
  left?: number | null;
  right?: number | null;
  managers?: Array<string | null> | null;
  exports?: ExportsInput | null;
};

export type DeleteAssessmentInput = {
  id?: string | null;
};

export type CreateIntegrationMappingsInput = {
  id?: string | null;
  name: string;
  projectKey?: string | null;
  fileName?: string | null;
};

export type ModelIntegrationMappingsConditionInput = {
  name?: ModelStringInput | null;
  projectKey?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  and?: Array<ModelIntegrationMappingsConditionInput | null> | null;
  or?: Array<ModelIntegrationMappingsConditionInput | null> | null;
  not?: ModelIntegrationMappingsConditionInput | null;
};

export type UpdateIntegrationMappingsInput = {
  id: string;
  name?: string | null;
  projectKey?: string | null;
  fileName?: string | null;
};

export type DeleteIntegrationMappingsInput = {
  id?: string | null;
};

export type CreateQuestionSettingsInput = {
  questionId: string;
  assessmentId: string;
  isCollaborative?: boolean | null;
  isArtifactsMandatory?: boolean | null;
  isApprovalRequired?: boolean | null;
};

export type ModelQuestionSettingsConditionInput = {
  questionId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  isCollaborative?: ModelBooleanInput | null;
  isArtifactsMandatory?: ModelBooleanInput | null;
  isApprovalRequired?: ModelBooleanInput | null;
  and?: Array<ModelQuestionSettingsConditionInput | null> | null;
  or?: Array<ModelQuestionSettingsConditionInput | null> | null;
  not?: ModelQuestionSettingsConditionInput | null;
};

export type UpdateQuestionSettingsInput = {
  questionId?: string | null;
  assessmentId?: string | null;
  isCollaborative?: boolean | null;
  isArtifactsMandatory?: boolean | null;
  isApprovalRequired?: boolean | null;
};

export type DeleteQuestionSettingsInput = {
  id?: string | null;
};

export type CreateCustomTaskInput = {
  id?: string | null;
  name?: string | null;
  label?: string | null;
  impact?: ImpactEnum | null;
  description?: string | null;
  standardsQualify?: Array<QualificationInput | null> | null;
  status?: TaskStatusEnum | null;
  assessmentId: string;
};

export enum TaskStatusEnum {
  OPEN = "OPEN",
  CLOSED = "CLOSED"
}

export type ModelCustomTaskConditionInput = {
  name?: ModelStringInput | null;
  label?: ModelStringInput | null;
  impact?: ModelImpactEnumInput | null;
  description?: ModelStringInput | null;
  status?: ModelTaskStatusEnumInput | null;
  assessmentId?: ModelIDInput | null;
  and?: Array<ModelCustomTaskConditionInput | null> | null;
  or?: Array<ModelCustomTaskConditionInput | null> | null;
  not?: ModelCustomTaskConditionInput | null;
};

export type ModelImpactEnumInput = {
  eq?: ImpactEnum | null;
  ne?: ImpactEnum | null;
};

export type ModelTaskStatusEnumInput = {
  eq?: TaskStatusEnum | null;
  ne?: TaskStatusEnum | null;
};

export type UpdateCustomTaskInput = {
  id: string;
  name?: string | null;
  label?: string | null;
  impact?: ImpactEnum | null;
  description?: string | null;
  standardsQualify?: Array<QualificationInput | null> | null;
  status?: TaskStatusEnum | null;
  assessmentId?: string | null;
};

export type DeleteCustomTaskInput = {
  id?: string | null;
};

export type CreateCustomTaskAssignmentInput = {
  id?: string | null;
  userId: string;
  targetId: string;
  assessmentId: string;
};

export type ModelCustomTaskAssignmentConditionInput = {
  userId?: ModelIDInput | null;
  targetId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  and?: Array<ModelCustomTaskAssignmentConditionInput | null> | null;
  or?: Array<ModelCustomTaskAssignmentConditionInput | null> | null;
  not?: ModelCustomTaskAssignmentConditionInput | null;
};

export type UpdateCustomTaskAssignmentInput = {
  id: string;
  userId?: string | null;
  targetId?: string | null;
  assessmentId?: string | null;
};

export type DeleteCustomTaskAssignmentInput = {
  id?: string | null;
};

export type CreateAssessmentStandardFrameworkInput = {
  id?: string | null;
  key: string;
  type?: StandardType | null;
  fileName?: string | null;
  filter?: StandardFilterInput | null;
  timeline?: TimelineInput | null;
  assessmentId: string;
  managerId?: string | null;
};

export type ModelAssessmentStandardFrameworkConditionInput = {
  key?: ModelStringInput | null;
  type?: ModelStandardTypeInput | null;
  fileName?: ModelStringInput | null;
  assessmentId?: ModelIDInput | null;
  managerId?: ModelIDInput | null;
  and?: Array<ModelAssessmentStandardFrameworkConditionInput | null> | null;
  or?: Array<ModelAssessmentStandardFrameworkConditionInput | null> | null;
  not?: ModelAssessmentStandardFrameworkConditionInput | null;
};

export type UpdateAssessmentStandardFrameworkInput = {
  id: string;
  key?: string | null;
  type?: StandardType | null;
  fileName?: string | null;
  filter?: StandardFilterInput | null;
  timeline?: TimelineInput | null;
  assessmentId?: string | null;
  managerId?: string | null;
};

export type DeleteAssessmentStandardFrameworkInput = {
  id?: string | null;
};

export type CreateFrameworkManagerInput = {
  id?: string | null;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
};

export type ModelFrameworkManagerConditionInput = {
  assessmentId?: ModelIDInput | null;
  standardFrameworkId?: ModelStringInput | null;
  managerId?: ModelStringInput | null;
  and?: Array<ModelFrameworkManagerConditionInput | null> | null;
  or?: Array<ModelFrameworkManagerConditionInput | null> | null;
  not?: ModelFrameworkManagerConditionInput | null;
};

export type UpdateFrameworkManagerInput = {
  id: string;
  assessmentId?: string | null;
  standardFrameworkId?: string | null;
  managerId?: string | null;
};

export type DeleteFrameworkManagerInput = {
  id?: string | null;
};

export type CreateCommentInput = {
  id?: string | null;
  latestSource: ExportSourceEnum;
  comment: string;
  date?: number | null;
  questionId?: string | null;
  assessmentId?: string | null;
  userId: string;
};

export enum ExportSourceEnum {
  CYGOV = "CYGOV",
  JIRA = "JIRA"
}

export type ModelCommentConditionInput = {
  latestSource?: ModelExportSourceEnumInput | null;
  comment?: ModelStringInput | null;
  date?: ModelIntInput | null;
  questionId?: ModelStringInput | null;
  assessmentId?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  and?: Array<ModelCommentConditionInput | null> | null;
  or?: Array<ModelCommentConditionInput | null> | null;
  not?: ModelCommentConditionInput | null;
};

export type ModelExportSourceEnumInput = {
  eq?: ExportSourceEnum | null;
  ne?: ExportSourceEnum | null;
};

export enum CategoryEnum {
  ANSWER = "ANSWER",
  COMMENT = "COMMENT",
  ENTITY = "ENTITY"
}

export type UpdateCommentInput = {
  id: string;
  latestSource?: ExportSourceEnum | null;
  comment?: string | null;
  date?: number | null;
  questionId?: string | null;
  assessmentId?: string | null;
  userId?: string | null;
};

export type DeleteCommentInput = {
  id?: string | null;
};

export type CreateExportInput = {
  id?: string | null;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category?: CategoryEnum | null;
  categoryId: string;
  date?: number | null;
};

export type ModelExportConditionInput = {
  destination?: ModelExportSourceEnumInput | null;
  destinationCategoryId?: ModelIDInput | null;
  category?: ModelCategoryEnumInput | null;
  categoryId?: ModelStringInput | null;
  date?: ModelIntInput | null;
  and?: Array<ModelExportConditionInput | null> | null;
  or?: Array<ModelExportConditionInput | null> | null;
  not?: ModelExportConditionInput | null;
};

export type ModelCategoryEnumInput = {
  eq?: CategoryEnum | null;
  ne?: CategoryEnum | null;
};

export type UpdateExportInput = {
  id: string;
  destination?: ExportSourceEnum | null;
  destinationCategoryId?: string | null;
  category?: CategoryEnum | null;
  categoryId?: string | null;
  date?: number | null;
};

export type DeleteExportInput = {
  id?: string | null;
};

export type JiraAuthInput = {
  host: string;
  email: string;
  apiToken: string;
  project: string;
};

export type Rapid7Input = {
  entityId: string;
  action: string;
  siteId?: string | null;
  page?: number | null;
  activePluginId?: string | null;
};

export type TenableDetailsInput = {
  query?: queryAnaylsis | null;
  sourceType?: string | null;
  type?: string | null;
  scanID?: string | null;
};

export type queryAnaylsis = {
  name?: string | null;
  type?: string | null;
  tool?: string | null;
  startOffset?: number | null;
  endOffset?: number | null;
  filters?: Array<FilterObject | null> | null;
  view?: string | null;
};

export type FilterObject = {
  filterName?: string | null;
  operator?: string | null;
  value?: string | null;
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelRoleEnumInput | null;
  phone?: ModelStringInput | null;
  entityId?: ModelIDInput | null;
  isCognitoUser?: ModelBooleanInput | null;
  questionMap?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  createdAt?: ModelIntInput | null;
  lastLogin?: ModelIntInput | null;
  invitationDate?: ModelIntInput | null;
  reminderDate?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  modifiedAt?: ModelIntInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelEntityFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  users?: ModelIDInput | null;
  completionStatus?: ModelCollectionStatusEnumInput | null;
  accessGroups?: ModelStringInput | null;
  participantGroup?: ModelStringInput | null;
  rootEntityId?: ModelIDInput | null;
  childEntityId?: ModelIDInput | null;
  frameworkId?: ModelIDInput | null;
  frameworkName?: ModelStringInput | null;
  frameworkType?: ModelEntityTypeEnumInput | null;
  parentId?: ModelIDInput | null;
  entityType?: ModelEntityTypeEnumInput | null;
  projectManager?: ModelIDInput | null;
  projectManagerName?: ModelStringInput | null;
  projectDeadline?: ModelIntInput | null;
  createdBy?: ModelStringInput | null;
  createdAt?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  modifiedAt?: ModelIntInput | null;
  industry?: ModelIndustryEnumInput | null;
  domain?: ModelStringInput | null;
  activeAssessmentId?: ModelIDInput | null;
  and?: Array<ModelEntityFilterInput | null> | null;
  or?: Array<ModelEntityFilterInput | null> | null;
  not?: ModelEntityFilterInput | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyConditionInput = {
  eq?: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyInput | null;
  le?: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyInput | null;
  lt?: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyInput | null;
  ge?: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyInput | null;
  gt?: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyInput | null;
  between?: Array<ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyInput | null> | null;
  beginsWith?: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyInput | null;
};

export type ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyInput = {
  frameworkType?: EntityTypeEnum | null;
  frameworkName?: string | null;
};

export type ModelVendorsDetailFilterInput = {
  id?: ModelIDInput | null;
  impact?: ModelIntInput | null;
  externalScan?: ModelFloatInput | null;
  financialRisk?: ModelIntInput | null;
  criticalGaps?: ModelIntInput | null;
  status?: ModelVendorStatusEnumInput | null;
  probability?: ModelIntInput | null;
  website?: ModelStringInput | null;
  location?: ModelStringInput | null;
  size?: ModelStringInput | null;
  accessLevel?: ModelAccessLevelEnumInput | null;
  collectionStatus?: ModelCollectionStatusEnumInput | null;
  approvalDate?: ModelIntInput | null;
  stage?: ModelVendorStatusEnumInput | null;
  activeScan?: ModelBooleanInput | null;
  domain?: ModelStringInput | null;
  privacyData?: ModelBooleanInput | null;
  and?: Array<ModelVendorsDetailFilterInput | null> | null;
  or?: Array<ModelVendorsDetailFilterInput | null> | null;
  not?: ModelVendorsDetailFilterInput | null;
};

export type ModelFrameworkScoresFilterInput = {
  id?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  type?: ModelStandardTypeInput | null;
  total?: ModelFloatInput | null;
  target?: ModelFloatInput | null;
  collection?: ModelFloatInput | null;
  remediation?: ModelFloatInput | null;
  totalQuestions?: ModelIntInput | null;
  completedQuestions?: ModelIntInput | null;
  totalGaps?: ModelIntInput | null;
  criticalGaps?: ModelIntInput | null;
  notApplicable?: ModelBooleanInput | null;
  assignmentCount?: ModelIntInput | null;
  riskStandardsCount?: ModelIntInput | null;
  complianceStandardsCount?: ModelIntInput | null;
  standardsCount?: ModelIntInput | null;
  and?: Array<ModelFrameworkScoresFilterInput | null> | null;
  or?: Array<ModelFrameworkScoresFilterInput | null> | null;
  not?: ModelFrameworkScoresFilterInput | null;
};

export type ModelIntelligenceFilterInput = {
  id?: ModelIDInput | null;
  and?: Array<ModelIntelligenceFilterInput | null> | null;
  or?: Array<ModelIntelligenceFilterInput | null> | null;
  not?: ModelIntelligenceFilterInput | null;
};

export type ModelBreachesFilterInput = {
  id?: ModelIDInput | null;
  vendorId?: ModelIDInput | null;
  date?: ModelIntInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  leaks?: ModelIntInput | null;
  data?: ModelStringInput | null;
  status?: ModelStringInput | null;
  and?: Array<ModelBreachesFilterInput | null> | null;
  or?: Array<ModelBreachesFilterInput | null> | null;
  not?: ModelBreachesFilterInput | null;
};

export type ModelExternalThreatsFilterInput = {
  id?: ModelIDInput | null;
  vendorId?: ModelIDInput | null;
  source?: ModelStringInput | null;
  findings?: ModelStringInput | null;
  riskScore?: ModelStringInput | null;
  status?: ModelStringInput | null;
  and?: Array<ModelExternalThreatsFilterInput | null> | null;
  or?: Array<ModelExternalThreatsFilterInput | null> | null;
  not?: ModelExternalThreatsFilterInput | null;
};

export type ModelOthersFilterInput = {
  id?: ModelIDInput | null;
  vendorId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  label?: ModelStringInput | null;
  severity?: ModelStringInput | null;
  description?: ModelStringInput | null;
  status?: ModelStringInput | null;
  and?: Array<ModelOthersFilterInput | null> | null;
  or?: Array<ModelOthersFilterInput | null> | null;
  not?: ModelOthersFilterInput | null;
};

export type ModelQuestionnaireCompletionFilterInput = {
  id?: ModelIDInput | null;
  totalQuestions?: ModelIntInput | null;
  completedQuestions?: ModelIntInput | null;
  userId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  assignmentMap?: ModelStringInput | null;
  firstAnswer?: ModelIntInput | null;
  lastAnswer?: ModelIntInput | null;
  and?: Array<ModelQuestionnaireCompletionFilterInput | null> | null;
  or?: Array<ModelQuestionnaireCompletionFilterInput | null> | null;
  not?: ModelQuestionnaireCompletionFilterInput | null;
};

export type ModelAnswerFilterInput = {
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  answer?: ModelIntInput | null;
  comment?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  riskAction?: ModelRiskActionInput | null;
  frameworkName?: ModelStringInput | null;
  questionId?: ModelIDInput | null;
  date?: ModelIntInput | null;
  assessmentId?: ModelIDInput | null;
  assignmentID?: ModelIDInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  isActionTaken?: ModelBooleanInput | null;
  and?: Array<ModelAnswerFilterInput | null> | null;
  or?: Array<ModelAnswerFilterInput | null> | null;
  not?: ModelAnswerFilterInput | null;
};

export type ModelIDKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyConditionInput = {
  eq?: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyInput | null;
  le?: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyInput | null;
  lt?: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyInput | null;
  ge?: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyInput | null;
  gt?: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyInput | null;
  between?: Array<ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyInput | null> | null;
  beginsWith?: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyInput | null;
};

export type ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyInput = {
  questionId?: string | null;
  userId?: string | null;
};

export type ModelAssignmentFilterInput = {
  id?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  targetId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  and?: Array<ModelAssignmentFilterInput | null> | null;
  or?: Array<ModelAssignmentFilterInput | null> | null;
  not?: ModelAssignmentFilterInput | null;
};

export type ModelAssessmentFilterInput = {
  id?: ModelIDInput | null;
  rootId?: ModelIDInput | null;
  childId?: ModelIDInput | null;
  left?: ModelIntInput | null;
  right?: ModelIntInput | null;
  managers?: ModelStringInput | null;
  and?: Array<ModelAssessmentFilterInput | null> | null;
  or?: Array<ModelAssessmentFilterInput | null> | null;
  not?: ModelAssessmentFilterInput | null;
};

export type ModelIntegrationMappingsFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  projectKey?: ModelStringInput | null;
  fileName?: ModelStringInput | null;
  and?: Array<ModelIntegrationMappingsFilterInput | null> | null;
  or?: Array<ModelIntegrationMappingsFilterInput | null> | null;
  not?: ModelIntegrationMappingsFilterInput | null;
};

export type ModelQuestionSettingsFilterInput = {
  questionId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  isCollaborative?: ModelBooleanInput | null;
  isArtifactsMandatory?: ModelBooleanInput | null;
  isApprovalRequired?: ModelBooleanInput | null;
  and?: Array<ModelQuestionSettingsFilterInput | null> | null;
  or?: Array<ModelQuestionSettingsFilterInput | null> | null;
  not?: ModelQuestionSettingsFilterInput | null;
};

export type ModelCustomTaskFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  label?: ModelStringInput | null;
  impact?: ModelImpactEnumInput | null;
  description?: ModelStringInput | null;
  status?: ModelTaskStatusEnumInput | null;
  assessmentId?: ModelIDInput | null;
  and?: Array<ModelCustomTaskFilterInput | null> | null;
  or?: Array<ModelCustomTaskFilterInput | null> | null;
  not?: ModelCustomTaskFilterInput | null;
};

export type ModelCustomTaskAssignmentFilterInput = {
  id?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  targetId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  and?: Array<ModelCustomTaskAssignmentFilterInput | null> | null;
  or?: Array<ModelCustomTaskAssignmentFilterInput | null> | null;
  not?: ModelCustomTaskAssignmentFilterInput | null;
};

export type ModelAssessmentStandardFrameworkFilterInput = {
  id?: ModelStringInput | null;
  key?: ModelStringInput | null;
  type?: ModelStandardTypeInput | null;
  fileName?: ModelStringInput | null;
  assessmentId?: ModelIDInput | null;
  managerId?: ModelIDInput | null;
  and?: Array<ModelAssessmentStandardFrameworkFilterInput | null> | null;
  or?: Array<ModelAssessmentStandardFrameworkFilterInput | null> | null;
  not?: ModelAssessmentStandardFrameworkFilterInput | null;
};

export type ModelFrameworkManagerFilterInput = {
  id?: ModelStringInput | null;
  assessmentId?: ModelIDInput | null;
  standardFrameworkId?: ModelStringInput | null;
  managerId?: ModelStringInput | null;
  and?: Array<ModelFrameworkManagerFilterInput | null> | null;
  or?: Array<ModelFrameworkManagerFilterInput | null> | null;
  not?: ModelFrameworkManagerFilterInput | null;
};

export type ModelCommentFilterInput = {
  id?: ModelStringInput | null;
  latestSource?: ModelExportSourceEnumInput | null;
  comment?: ModelStringInput | null;
  date?: ModelIntInput | null;
  questionId?: ModelStringInput | null;
  assessmentId?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  and?: Array<ModelCommentFilterInput | null> | null;
  or?: Array<ModelCommentFilterInput | null> | null;
  not?: ModelCommentFilterInput | null;
};

export type ModelExportFilterInput = {
  id?: ModelStringInput | null;
  destination?: ModelExportSourceEnumInput | null;
  destinationCategoryId?: ModelIDInput | null;
  category?: ModelCategoryEnumInput | null;
  categoryId?: ModelStringInput | null;
  date?: ModelIntInput | null;
  and?: Array<ModelExportFilterInput | null> | null;
  or?: Array<ModelExportFilterInput | null> | null;
  not?: ModelExportFilterInput | null;
};

export type OnUpdateEntityScoreSubscription = {
  __typename: "Entity";
  id: string;
};

export type onFrameworkScoresUpdatedSubscription = {
  __typename: "FrameworkScores";
  id: string;
  assessmentId: string;
  type: StandardType;
  total: number;
  target: number;
  collection: number;
  remediation: number;
};

export type CreateFrameworksNodesMutation = {
  __typename: "IDList";
  ids: Array<string | null> | null;
};

export type AssignUserMutation = {
  __typename: "AssignUser";
  createdAssignments: Array<{
    __typename: "Assignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    left: number;
    right: number;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
  } | null> | null;
  deletedAssignments: Array<{
    __typename: "Assignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    left: number;
    right: number;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
  } | null> | null;
};

export type IncrementQuestionnaireAnswerMutation = {
  __typename: "QuestionnaireCompletion";
  id: string | null;
  totalQuestions: number | null;
  completedQuestions: number | null;
  userId: string;
  assessmentId: string;
  assignmentMap: string | null;
  firstAnswer: number | null;
  lastAnswer: number | null;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
  assessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type RemoveUserAssignmentMutation = {
  __typename: "AssignUser";
  createdAssignments: Array<{
    __typename: "Assignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    left: number;
    right: number;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
  } | null> | null;
  deletedAssignments: Array<{
    __typename: "Assignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    left: number;
    right: number;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
  } | null> | null;
};

export type CreateUserMutation = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role: RoleEnum;
  phone: string | null;
  entityId: string | null;
  isCognitoUser: boolean | null;
  questionMap: string | null;
  createdBy: string | null;
  createdAt: number | null;
  lastLogin: number | null;
  invitationDate: number | null;
  reminderDate: number | null;
  modifiedBy: string | null;
  modifiedAt: number | null;
};

export type UpdateUserMutation = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role: RoleEnum;
  phone: string | null;
  entityId: string | null;
  isCognitoUser: boolean | null;
  questionMap: string | null;
  createdBy: string | null;
  createdAt: number | null;
  lastLogin: number | null;
  invitationDate: number | null;
  reminderDate: number | null;
  modifiedBy: string | null;
  modifiedAt: number | null;
};

export type DeleteUserMutation = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role: RoleEnum;
  phone: string | null;
  entityId: string | null;
  isCognitoUser: boolean | null;
  questionMap: string | null;
  createdBy: string | null;
  createdAt: number | null;
  lastLogin: number | null;
  invitationDate: number | null;
  reminderDate: number | null;
  modifiedBy: string | null;
  modifiedAt: number | null;
};

export type CreateEntityMutation = {
  __typename: "Entity";
  id: string;
  name: string;
  users: Array<string | null> | null;
  completionStatus: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId: string | null;
  childEntityId: string | null;
  frameworkId: string | null;
  frameworkName: string | null;
  frameworkType: EntityTypeEnum | null;
  parentId: string | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  scores: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  entityType: EntityTypeEnum;
  projectManager: string | null;
  projectManagerName: string | null;
  projectDeadline: number | null;
  logo: {
    __typename: "S3File";
    id: string | null;
    name: string;
    names: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date: number | null;
    contentType: string | null;
    length: number | null;
    key: string | null;
  } | null;
  integrations: Array<{
    __typename: "Integrations";
    name: string;
    credentials: string | null;
    isEnable: boolean | null;
    isAuth: boolean | null;
    inheritFromParent: boolean | null;
  } | null> | null;
  externalFeed: Array<{
    __typename: "Integrations";
    name: string;
    credentials: string | null;
    isEnable: boolean | null;
    isAuth: boolean | null;
    inheritFromParent: boolean | null;
  } | null> | null;
  defaultSetting: {
    __typename: "DefaultSetting";
    dueDateInDays: number | null;
    frequency: string | null;
    surveyLevel: string | null;
    artifacts: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    standards: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    accessLevel: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
  } | null;
  createdBy: string | null;
  createdAt: number | null;
  modifiedBy: string | null;
  modifiedAt: number | null;
  industry: IndustryEnum | null;
  domain: string;
  activeAssessmentId: string;
  vendorDetails: {
    __typename: "VendorsDetail";
    id: string;
    impact: number | null;
    externalScan: number | null;
    financialRisk: number | null;
    criticalGaps: number | null;
    standardsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    status: VendorStatusEnum | null;
    probability: number | null;
    website: string | null;
    location: string | null;
    size: string | null;
    accessLevel: AccessLevelEnum | null;
    collectionStatus: CollectionStatusEnum | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    approvalDate: number | null;
    questionnaires: Array<{
      __typename: "Questionnaire";
      tag: string | null;
      status: {
        __typename: "QuestionnaireStatus";
        totalQuestions: number | null;
        completedQuestions: number | null;
      } | null;
      criticalGaps: Array<string | null> | null;
      allGaps: string | null;
    } | null> | null;
    artifacts: {
      __typename: "Artifacts";
      documents: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      scans: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      insurances: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      certifications: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
    } | null;
    primaryPoc: {
      __typename: "ShortRef";
      name: string | null;
      id: string | null;
      email: string | null;
    } | null;
    stage: VendorStatusEnum | null;
    activeScan: boolean | null;
    domain: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    intelligence: {
      __typename: "Intelligence";
      id: string;
      breaches: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date: number | null;
          name: string | null;
          description: string | null;
          leaks: number | null;
          data: Array<string | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      externalThreats: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source: string | null;
          findings: Array<string | null> | null;
          riskScore: string | null;
          details: Array<{
            __typename: "ThreatDetails";
            ip: string | null;
            port: number | null;
            cve: string | null;
            cvss: number | null;
          } | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      others: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name: string | null;
          label: string | null;
          severity: string | null;
          description: string | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
  activeAssessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type UpdateEntityMutation = {
  __typename: "Entity";
  id: string;
  name: string;
  users: Array<string | null> | null;
  completionStatus: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId: string | null;
  childEntityId: string | null;
  frameworkId: string | null;
  frameworkName: string | null;
  frameworkType: EntityTypeEnum | null;
  parentId: string | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  scores: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  entityType: EntityTypeEnum;
  projectManager: string | null;
  projectManagerName: string | null;
  projectDeadline: number | null;
  logo: {
    __typename: "S3File";
    id: string | null;
    name: string;
    names: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date: number | null;
    contentType: string | null;
    length: number | null;
    key: string | null;
  } | null;
  integrations: Array<{
    __typename: "Integrations";
    name: string;
    credentials: string | null;
    isEnable: boolean | null;
    isAuth: boolean | null;
    inheritFromParent: boolean | null;
  } | null> | null;
  externalFeed: Array<{
    __typename: "Integrations";
    name: string;
    credentials: string | null;
    isEnable: boolean | null;
    isAuth: boolean | null;
    inheritFromParent: boolean | null;
  } | null> | null;
  defaultSetting: {
    __typename: "DefaultSetting";
    dueDateInDays: number | null;
    frequency: string | null;
    surveyLevel: string | null;
    artifacts: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    standards: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    accessLevel: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
  } | null;
  createdBy: string | null;
  createdAt: number | null;
  modifiedBy: string | null;
  modifiedAt: number | null;
  industry: IndustryEnum | null;
  domain: string;
  activeAssessmentId: string;
  vendorDetails: {
    __typename: "VendorsDetail";
    id: string;
    impact: number | null;
    externalScan: number | null;
    financialRisk: number | null;
    criticalGaps: number | null;
    standardsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    status: VendorStatusEnum | null;
    probability: number | null;
    website: string | null;
    location: string | null;
    size: string | null;
    accessLevel: AccessLevelEnum | null;
    collectionStatus: CollectionStatusEnum | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    approvalDate: number | null;
    questionnaires: Array<{
      __typename: "Questionnaire";
      tag: string | null;
      status: {
        __typename: "QuestionnaireStatus";
        totalQuestions: number | null;
        completedQuestions: number | null;
      } | null;
      criticalGaps: Array<string | null> | null;
      allGaps: string | null;
    } | null> | null;
    artifacts: {
      __typename: "Artifacts";
      documents: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      scans: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      insurances: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      certifications: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
    } | null;
    primaryPoc: {
      __typename: "ShortRef";
      name: string | null;
      id: string | null;
      email: string | null;
    } | null;
    stage: VendorStatusEnum | null;
    activeScan: boolean | null;
    domain: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    intelligence: {
      __typename: "Intelligence";
      id: string;
      breaches: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date: number | null;
          name: string | null;
          description: string | null;
          leaks: number | null;
          data: Array<string | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      externalThreats: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source: string | null;
          findings: Array<string | null> | null;
          riskScore: string | null;
          details: Array<{
            __typename: "ThreatDetails";
            ip: string | null;
            port: number | null;
            cve: string | null;
            cvss: number | null;
          } | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      others: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name: string | null;
          label: string | null;
          severity: string | null;
          description: string | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
  activeAssessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type DeleteEntityMutation = {
  __typename: "Entity";
  id: string;
  name: string;
  users: Array<string | null> | null;
  completionStatus: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId: string | null;
  childEntityId: string | null;
  frameworkId: string | null;
  frameworkName: string | null;
  frameworkType: EntityTypeEnum | null;
  parentId: string | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  scores: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  entityType: EntityTypeEnum;
  projectManager: string | null;
  projectManagerName: string | null;
  projectDeadline: number | null;
  logo: {
    __typename: "S3File";
    id: string | null;
    name: string;
    names: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date: number | null;
    contentType: string | null;
    length: number | null;
    key: string | null;
  } | null;
  integrations: Array<{
    __typename: "Integrations";
    name: string;
    credentials: string | null;
    isEnable: boolean | null;
    isAuth: boolean | null;
    inheritFromParent: boolean | null;
  } | null> | null;
  externalFeed: Array<{
    __typename: "Integrations";
    name: string;
    credentials: string | null;
    isEnable: boolean | null;
    isAuth: boolean | null;
    inheritFromParent: boolean | null;
  } | null> | null;
  defaultSetting: {
    __typename: "DefaultSetting";
    dueDateInDays: number | null;
    frequency: string | null;
    surveyLevel: string | null;
    artifacts: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    standards: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    accessLevel: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
  } | null;
  createdBy: string | null;
  createdAt: number | null;
  modifiedBy: string | null;
  modifiedAt: number | null;
  industry: IndustryEnum | null;
  domain: string;
  activeAssessmentId: string;
  vendorDetails: {
    __typename: "VendorsDetail";
    id: string;
    impact: number | null;
    externalScan: number | null;
    financialRisk: number | null;
    criticalGaps: number | null;
    standardsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    status: VendorStatusEnum | null;
    probability: number | null;
    website: string | null;
    location: string | null;
    size: string | null;
    accessLevel: AccessLevelEnum | null;
    collectionStatus: CollectionStatusEnum | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    approvalDate: number | null;
    questionnaires: Array<{
      __typename: "Questionnaire";
      tag: string | null;
      status: {
        __typename: "QuestionnaireStatus";
        totalQuestions: number | null;
        completedQuestions: number | null;
      } | null;
      criticalGaps: Array<string | null> | null;
      allGaps: string | null;
    } | null> | null;
    artifacts: {
      __typename: "Artifacts";
      documents: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      scans: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      insurances: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      certifications: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
    } | null;
    primaryPoc: {
      __typename: "ShortRef";
      name: string | null;
      id: string | null;
      email: string | null;
    } | null;
    stage: VendorStatusEnum | null;
    activeScan: boolean | null;
    domain: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    intelligence: {
      __typename: "Intelligence";
      id: string;
      breaches: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date: number | null;
          name: string | null;
          description: string | null;
          leaks: number | null;
          data: Array<string | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      externalThreats: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source: string | null;
          findings: Array<string | null> | null;
          riskScore: string | null;
          details: Array<{
            __typename: "ThreatDetails";
            ip: string | null;
            port: number | null;
            cve: string | null;
            cvss: number | null;
          } | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      others: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name: string | null;
          label: string | null;
          severity: string | null;
          description: string | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
  activeAssessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type CreateVendorsDetailMutation = {
  __typename: "VendorsDetail";
  id: string;
  impact: number | null;
  externalScan: number | null;
  financialRisk: number | null;
  criticalGaps: number | null;
  standardsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  status: VendorStatusEnum | null;
  probability: number | null;
  website: string | null;
  location: string | null;
  size: string | null;
  accessLevel: AccessLevelEnum | null;
  collectionStatus: CollectionStatusEnum | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  approvalDate: number | null;
  questionnaires: Array<{
    __typename: "Questionnaire";
    tag: string | null;
    status: {
      __typename: "QuestionnaireStatus";
      totalQuestions: number | null;
      completedQuestions: number | null;
    } | null;
    criticalGaps: Array<string | null> | null;
    allGaps: string | null;
  } | null> | null;
  artifacts: {
    __typename: "Artifacts";
    documents: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    scans: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    insurances: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    certifications: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
  } | null;
  primaryPoc: {
    __typename: "ShortRef";
    name: string | null;
    id: string | null;
    email: string | null;
  } | null;
  stage: VendorStatusEnum | null;
  activeScan: boolean | null;
  domain: string | null;
  privacyData: boolean | null;
  impactsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  intelligence: {
    __typename: "Intelligence";
    id: string;
    breaches: {
      __typename: "ModelBreachesConnection";
      items: Array<{
        __typename: "Breaches";
        id: string;
        vendorId: string;
        date: number | null;
        name: string | null;
        description: string | null;
        leaks: number | null;
        data: Array<string | null> | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    externalThreats: {
      __typename: "ModelExternalThreatsConnection";
      items: Array<{
        __typename: "ExternalThreats";
        id: string;
        vendorId: string;
        source: string | null;
        findings: Array<string | null> | null;
        riskScore: string | null;
        details: Array<{
          __typename: "ThreatDetails";
          ip: string | null;
          port: number | null;
          cve: string | null;
          cvss: number | null;
        } | null> | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    others: {
      __typename: "ModelOthersConnection";
      items: Array<{
        __typename: "Others";
        id: string;
        vendorId: string;
        name: string | null;
        label: string | null;
        severity: string | null;
        description: string | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type UpdateVendorsDetailMutation = {
  __typename: "VendorsDetail";
  id: string;
  impact: number | null;
  externalScan: number | null;
  financialRisk: number | null;
  criticalGaps: number | null;
  standardsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  status: VendorStatusEnum | null;
  probability: number | null;
  website: string | null;
  location: string | null;
  size: string | null;
  accessLevel: AccessLevelEnum | null;
  collectionStatus: CollectionStatusEnum | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  approvalDate: number | null;
  questionnaires: Array<{
    __typename: "Questionnaire";
    tag: string | null;
    status: {
      __typename: "QuestionnaireStatus";
      totalQuestions: number | null;
      completedQuestions: number | null;
    } | null;
    criticalGaps: Array<string | null> | null;
    allGaps: string | null;
  } | null> | null;
  artifacts: {
    __typename: "Artifacts";
    documents: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    scans: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    insurances: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    certifications: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
  } | null;
  primaryPoc: {
    __typename: "ShortRef";
    name: string | null;
    id: string | null;
    email: string | null;
  } | null;
  stage: VendorStatusEnum | null;
  activeScan: boolean | null;
  domain: string | null;
  privacyData: boolean | null;
  impactsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  intelligence: {
    __typename: "Intelligence";
    id: string;
    breaches: {
      __typename: "ModelBreachesConnection";
      items: Array<{
        __typename: "Breaches";
        id: string;
        vendorId: string;
        date: number | null;
        name: string | null;
        description: string | null;
        leaks: number | null;
        data: Array<string | null> | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    externalThreats: {
      __typename: "ModelExternalThreatsConnection";
      items: Array<{
        __typename: "ExternalThreats";
        id: string;
        vendorId: string;
        source: string | null;
        findings: Array<string | null> | null;
        riskScore: string | null;
        details: Array<{
          __typename: "ThreatDetails";
          ip: string | null;
          port: number | null;
          cve: string | null;
          cvss: number | null;
        } | null> | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    others: {
      __typename: "ModelOthersConnection";
      items: Array<{
        __typename: "Others";
        id: string;
        vendorId: string;
        name: string | null;
        label: string | null;
        severity: string | null;
        description: string | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type DeleteVendorsDetailMutation = {
  __typename: "VendorsDetail";
  id: string;
  impact: number | null;
  externalScan: number | null;
  financialRisk: number | null;
  criticalGaps: number | null;
  standardsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  status: VendorStatusEnum | null;
  probability: number | null;
  website: string | null;
  location: string | null;
  size: string | null;
  accessLevel: AccessLevelEnum | null;
  collectionStatus: CollectionStatusEnum | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  approvalDate: number | null;
  questionnaires: Array<{
    __typename: "Questionnaire";
    tag: string | null;
    status: {
      __typename: "QuestionnaireStatus";
      totalQuestions: number | null;
      completedQuestions: number | null;
    } | null;
    criticalGaps: Array<string | null> | null;
    allGaps: string | null;
  } | null> | null;
  artifacts: {
    __typename: "Artifacts";
    documents: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    scans: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    insurances: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    certifications: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
  } | null;
  primaryPoc: {
    __typename: "ShortRef";
    name: string | null;
    id: string | null;
    email: string | null;
  } | null;
  stage: VendorStatusEnum | null;
  activeScan: boolean | null;
  domain: string | null;
  privacyData: boolean | null;
  impactsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  intelligence: {
    __typename: "Intelligence";
    id: string;
    breaches: {
      __typename: "ModelBreachesConnection";
      items: Array<{
        __typename: "Breaches";
        id: string;
        vendorId: string;
        date: number | null;
        name: string | null;
        description: string | null;
        leaks: number | null;
        data: Array<string | null> | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    externalThreats: {
      __typename: "ModelExternalThreatsConnection";
      items: Array<{
        __typename: "ExternalThreats";
        id: string;
        vendorId: string;
        source: string | null;
        findings: Array<string | null> | null;
        riskScore: string | null;
        details: Array<{
          __typename: "ThreatDetails";
          ip: string | null;
          port: number | null;
          cve: string | null;
          cvss: number | null;
        } | null> | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    others: {
      __typename: "ModelOthersConnection";
      items: Array<{
        __typename: "Others";
        id: string;
        vendorId: string;
        name: string | null;
        label: string | null;
        severity: string | null;
        description: string | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type CreateFrameworkScoresMutation = {
  __typename: "FrameworkScores";
  id: string;
  assessmentId: string;
  type: StandardType;
  total: number;
  target: number;
  collection: number;
  remediation: number;
  totalQuestions: number;
  completedQuestions: number;
  totalGaps: number;
  criticalGaps: number;
  notApplicable: boolean | null;
  assignmentCount: number | null;
  riskStandardsCount: number | null;
  complianceStandardsCount: number | null;
  standardsCount: number | null;
  top20: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateFrameworkScoresMutation = {
  __typename: "FrameworkScores";
  id: string;
  assessmentId: string;
  type: StandardType;
  total: number;
  target: number;
  collection: number;
  remediation: number;
  totalQuestions: number;
  completedQuestions: number;
  totalGaps: number;
  criticalGaps: number;
  notApplicable: boolean | null;
  assignmentCount: number | null;
  riskStandardsCount: number | null;
  complianceStandardsCount: number | null;
  standardsCount: number | null;
  top20: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteFrameworkScoresMutation = {
  __typename: "FrameworkScores";
  id: string;
  assessmentId: string;
  type: StandardType;
  total: number;
  target: number;
  collection: number;
  remediation: number;
  totalQuestions: number;
  completedQuestions: number;
  totalGaps: number;
  criticalGaps: number;
  notApplicable: boolean | null;
  assignmentCount: number | null;
  riskStandardsCount: number | null;
  complianceStandardsCount: number | null;
  standardsCount: number | null;
  top20: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CreateIntelligenceMutation = {
  __typename: "Intelligence";
  id: string;
  breaches: {
    __typename: "ModelBreachesConnection";
    items: Array<{
      __typename: "Breaches";
      id: string;
      vendorId: string;
      date: number | null;
      name: string | null;
      description: string | null;
      leaks: number | null;
      data: Array<string | null> | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  externalThreats: {
    __typename: "ModelExternalThreatsConnection";
    items: Array<{
      __typename: "ExternalThreats";
      id: string;
      vendorId: string;
      source: string | null;
      findings: Array<string | null> | null;
      riskScore: string | null;
      details: Array<{
        __typename: "ThreatDetails";
        ip: string | null;
        port: number | null;
        cve: string | null;
        cvss: number | null;
      } | null> | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  others: {
    __typename: "ModelOthersConnection";
    items: Array<{
      __typename: "Others";
      id: string;
      vendorId: string;
      name: string | null;
      label: string | null;
      severity: string | null;
      description: string | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type UpdateIntelligenceMutation = {
  __typename: "Intelligence";
  id: string;
  breaches: {
    __typename: "ModelBreachesConnection";
    items: Array<{
      __typename: "Breaches";
      id: string;
      vendorId: string;
      date: number | null;
      name: string | null;
      description: string | null;
      leaks: number | null;
      data: Array<string | null> | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  externalThreats: {
    __typename: "ModelExternalThreatsConnection";
    items: Array<{
      __typename: "ExternalThreats";
      id: string;
      vendorId: string;
      source: string | null;
      findings: Array<string | null> | null;
      riskScore: string | null;
      details: Array<{
        __typename: "ThreatDetails";
        ip: string | null;
        port: number | null;
        cve: string | null;
        cvss: number | null;
      } | null> | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  others: {
    __typename: "ModelOthersConnection";
    items: Array<{
      __typename: "Others";
      id: string;
      vendorId: string;
      name: string | null;
      label: string | null;
      severity: string | null;
      description: string | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type DeleteIntelligenceMutation = {
  __typename: "Intelligence";
  id: string;
  breaches: {
    __typename: "ModelBreachesConnection";
    items: Array<{
      __typename: "Breaches";
      id: string;
      vendorId: string;
      date: number | null;
      name: string | null;
      description: string | null;
      leaks: number | null;
      data: Array<string | null> | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  externalThreats: {
    __typename: "ModelExternalThreatsConnection";
    items: Array<{
      __typename: "ExternalThreats";
      id: string;
      vendorId: string;
      source: string | null;
      findings: Array<string | null> | null;
      riskScore: string | null;
      details: Array<{
        __typename: "ThreatDetails";
        ip: string | null;
        port: number | null;
        cve: string | null;
        cvss: number | null;
      } | null> | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  others: {
    __typename: "ModelOthersConnection";
    items: Array<{
      __typename: "Others";
      id: string;
      vendorId: string;
      name: string | null;
      label: string | null;
      severity: string | null;
      description: string | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type CreateBreachesMutation = {
  __typename: "Breaches";
  id: string;
  vendorId: string;
  date: number | null;
  name: string | null;
  description: string | null;
  leaks: number | null;
  data: Array<string | null> | null;
  status: string | null;
};

export type UpdateBreachesMutation = {
  __typename: "Breaches";
  id: string;
  vendorId: string;
  date: number | null;
  name: string | null;
  description: string | null;
  leaks: number | null;
  data: Array<string | null> | null;
  status: string | null;
};

export type DeleteBreachesMutation = {
  __typename: "Breaches";
  id: string;
  vendorId: string;
  date: number | null;
  name: string | null;
  description: string | null;
  leaks: number | null;
  data: Array<string | null> | null;
  status: string | null;
};

export type CreateExternalThreatsMutation = {
  __typename: "ExternalThreats";
  id: string;
  vendorId: string;
  source: string | null;
  findings: Array<string | null> | null;
  riskScore: string | null;
  details: Array<{
    __typename: "ThreatDetails";
    ip: string | null;
    port: number | null;
    cve: string | null;
    cvss: number | null;
  } | null> | null;
  status: string | null;
};

export type UpdateExternalThreatsMutation = {
  __typename: "ExternalThreats";
  id: string;
  vendorId: string;
  source: string | null;
  findings: Array<string | null> | null;
  riskScore: string | null;
  details: Array<{
    __typename: "ThreatDetails";
    ip: string | null;
    port: number | null;
    cve: string | null;
    cvss: number | null;
  } | null> | null;
  status: string | null;
};

export type DeleteExternalThreatsMutation = {
  __typename: "ExternalThreats";
  id: string;
  vendorId: string;
  source: string | null;
  findings: Array<string | null> | null;
  riskScore: string | null;
  details: Array<{
    __typename: "ThreatDetails";
    ip: string | null;
    port: number | null;
    cve: string | null;
    cvss: number | null;
  } | null> | null;
  status: string | null;
};

export type CreateOthersMutation = {
  __typename: "Others";
  id: string;
  vendorId: string;
  name: string | null;
  label: string | null;
  severity: string | null;
  description: string | null;
  status: string | null;
};

export type UpdateOthersMutation = {
  __typename: "Others";
  id: string;
  vendorId: string;
  name: string | null;
  label: string | null;
  severity: string | null;
  description: string | null;
  status: string | null;
};

export type DeleteOthersMutation = {
  __typename: "Others";
  id: string;
  vendorId: string;
  name: string | null;
  label: string | null;
  severity: string | null;
  description: string | null;
  status: string | null;
};

export type CreateQuestionnaireCompletionMutation = {
  __typename: "QuestionnaireCompletion";
  id: string | null;
  totalQuestions: number | null;
  completedQuestions: number | null;
  userId: string;
  assessmentId: string;
  assignmentMap: string | null;
  firstAnswer: number | null;
  lastAnswer: number | null;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
  assessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type UpdateQuestionnaireCompletionMutation = {
  __typename: "QuestionnaireCompletion";
  id: string | null;
  totalQuestions: number | null;
  completedQuestions: number | null;
  userId: string;
  assessmentId: string;
  assignmentMap: string | null;
  firstAnswer: number | null;
  lastAnswer: number | null;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
  assessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type DeleteQuestionnaireCompletionMutation = {
  __typename: "QuestionnaireCompletion";
  id: string | null;
  totalQuestions: number | null;
  completedQuestions: number | null;
  userId: string;
  assessmentId: string;
  assignmentMap: string | null;
  firstAnswer: number | null;
  lastAnswer: number | null;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
  assessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type CreateAnswerMutation = {
  __typename: "Answer";
  id: string;
  createdAt: string | null;
  answer: number | null;
  comment: string | null;
  file: Array<{
    __typename: "S3File";
    id: string | null;
    name: string;
    names: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date: number | null;
    contentType: string | null;
    length: number | null;
    key: string | null;
  } | null> | null;
  userId: string;
  riskAction: RiskAction | null;
  frameworkName: string;
  questionId: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken: boolean | null;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type UpdateAnswerMutation = {
  __typename: "Answer";
  id: string;
  createdAt: string | null;
  answer: number | null;
  comment: string | null;
  file: Array<{
    __typename: "S3File";
    id: string | null;
    name: string;
    names: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date: number | null;
    contentType: string | null;
    length: number | null;
    key: string | null;
  } | null> | null;
  userId: string;
  riskAction: RiskAction | null;
  frameworkName: string;
  questionId: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken: boolean | null;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type DeleteAnswerMutation = {
  __typename: "Answer";
  id: string;
  createdAt: string | null;
  answer: number | null;
  comment: string | null;
  file: Array<{
    __typename: "S3File";
    id: string | null;
    name: string;
    names: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date: number | null;
    contentType: string | null;
    length: number | null;
    key: string | null;
  } | null> | null;
  userId: string;
  riskAction: RiskAction | null;
  frameworkName: string;
  questionId: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken: boolean | null;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type CreateAssignmentMutation = {
  __typename: "Assignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  left: number;
  right: number;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type UpdateAssignmentMutation = {
  __typename: "Assignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  left: number;
  right: number;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type DeleteAssignmentMutation = {
  __typename: "Assignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  left: number;
  right: number;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type CreateAssessmentMutation = {
  __typename: "Assessment";
  id: string;
  standardList: Array<{
    __typename: "StandardFramework";
    id: string;
    type: StandardType | null;
    fileName: string | null;
    filter: {
      __typename: "StandardFilter";
      impact: ImpactEnum | null;
    } | null;
  } | null> | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  rootId: string;
  childId: string;
  scores: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  left: number | null;
  right: number | null;
  managers: Array<string | null> | null;
  exports: {
    __typename: "Exports";
    jiraEntityIds: Array<string | null> | null;
  } | null;
  standardFrameworkList: {
    __typename: "ModelAssessmentStandardFrameworkConnection";
    items: Array<{
      __typename: "AssessmentStandardFramework";
      id: string;
      key: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      assessmentId: string;
      managerId: string | null;
      assessment: {
        __typename: "Assessment";
        id: string;
        standardList: Array<{
          __typename: "StandardFramework";
          id: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
        } | null> | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        rootId: string;
        childId: string;
        scores: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
        left: number | null;
        right: number | null;
        managers: Array<string | null> | null;
        exports: {
          __typename: "Exports";
          jiraEntityIds: Array<string | null> | null;
        } | null;
        standardFrameworkList: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type: StandardType | null;
            fileName: string | null;
            assessmentId: string;
            managerId: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null;
      managers: {
        __typename: "ModelFrameworkManagerConnection";
        items: Array<{
          __typename: "FrameworkManager";
          id: string;
          assessmentId: string;
          standardFrameworkId: string;
          managerId: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type UpdateAssessmentMutation = {
  __typename: "Assessment";
  id: string;
  standardList: Array<{
    __typename: "StandardFramework";
    id: string;
    type: StandardType | null;
    fileName: string | null;
    filter: {
      __typename: "StandardFilter";
      impact: ImpactEnum | null;
    } | null;
  } | null> | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  rootId: string;
  childId: string;
  scores: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  left: number | null;
  right: number | null;
  managers: Array<string | null> | null;
  exports: {
    __typename: "Exports";
    jiraEntityIds: Array<string | null> | null;
  } | null;
  standardFrameworkList: {
    __typename: "ModelAssessmentStandardFrameworkConnection";
    items: Array<{
      __typename: "AssessmentStandardFramework";
      id: string;
      key: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      assessmentId: string;
      managerId: string | null;
      assessment: {
        __typename: "Assessment";
        id: string;
        standardList: Array<{
          __typename: "StandardFramework";
          id: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
        } | null> | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        rootId: string;
        childId: string;
        scores: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
        left: number | null;
        right: number | null;
        managers: Array<string | null> | null;
        exports: {
          __typename: "Exports";
          jiraEntityIds: Array<string | null> | null;
        } | null;
        standardFrameworkList: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type: StandardType | null;
            fileName: string | null;
            assessmentId: string;
            managerId: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null;
      managers: {
        __typename: "ModelFrameworkManagerConnection";
        items: Array<{
          __typename: "FrameworkManager";
          id: string;
          assessmentId: string;
          standardFrameworkId: string;
          managerId: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type DeleteAssessmentMutation = {
  __typename: "Assessment";
  id: string;
  standardList: Array<{
    __typename: "StandardFramework";
    id: string;
    type: StandardType | null;
    fileName: string | null;
    filter: {
      __typename: "StandardFilter";
      impact: ImpactEnum | null;
    } | null;
  } | null> | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  rootId: string;
  childId: string;
  scores: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  left: number | null;
  right: number | null;
  managers: Array<string | null> | null;
  exports: {
    __typename: "Exports";
    jiraEntityIds: Array<string | null> | null;
  } | null;
  standardFrameworkList: {
    __typename: "ModelAssessmentStandardFrameworkConnection";
    items: Array<{
      __typename: "AssessmentStandardFramework";
      id: string;
      key: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      assessmentId: string;
      managerId: string | null;
      assessment: {
        __typename: "Assessment";
        id: string;
        standardList: Array<{
          __typename: "StandardFramework";
          id: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
        } | null> | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        rootId: string;
        childId: string;
        scores: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
        left: number | null;
        right: number | null;
        managers: Array<string | null> | null;
        exports: {
          __typename: "Exports";
          jiraEntityIds: Array<string | null> | null;
        } | null;
        standardFrameworkList: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type: StandardType | null;
            fileName: string | null;
            assessmentId: string;
            managerId: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null;
      managers: {
        __typename: "ModelFrameworkManagerConnection";
        items: Array<{
          __typename: "FrameworkManager";
          id: string;
          assessmentId: string;
          standardFrameworkId: string;
          managerId: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type CreateIntegrationMappingsMutation = {
  __typename: "IntegrationMappings";
  id: string;
  name: string;
  projectKey: string | null;
  fileName: string | null;
};

export type UpdateIntegrationMappingsMutation = {
  __typename: "IntegrationMappings";
  id: string;
  name: string;
  projectKey: string | null;
  fileName: string | null;
};

export type DeleteIntegrationMappingsMutation = {
  __typename: "IntegrationMappings";
  id: string;
  name: string;
  projectKey: string | null;
  fileName: string | null;
};

export type CreateQuestionSettingsMutation = {
  __typename: "QuestionSettings";
  questionId: string;
  assessmentId: string;
  isCollaborative: boolean | null;
  isArtifactsMandatory: boolean | null;
  isApprovalRequired: boolean | null;
};

export type UpdateQuestionSettingsMutation = {
  __typename: "QuestionSettings";
  questionId: string;
  assessmentId: string;
  isCollaborative: boolean | null;
  isArtifactsMandatory: boolean | null;
  isApprovalRequired: boolean | null;
};

export type DeleteQuestionSettingsMutation = {
  __typename: "QuestionSettings";
  questionId: string;
  assessmentId: string;
  isCollaborative: boolean | null;
  isArtifactsMandatory: boolean | null;
  isApprovalRequired: boolean | null;
};

export type CreateCustomTaskMutation = {
  __typename: "CustomTask";
  id: string;
  name: string | null;
  label: string | null;
  impact: ImpactEnum | null;
  description: string | null;
  standardsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  status: TaskStatusEnum | null;
  assessmentId: string;
};

export type UpdateCustomTaskMutation = {
  __typename: "CustomTask";
  id: string;
  name: string | null;
  label: string | null;
  impact: ImpactEnum | null;
  description: string | null;
  standardsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  status: TaskStatusEnum | null;
  assessmentId: string;
};

export type DeleteCustomTaskMutation = {
  __typename: "CustomTask";
  id: string;
  name: string | null;
  label: string | null;
  impact: ImpactEnum | null;
  description: string | null;
  standardsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  status: TaskStatusEnum | null;
  assessmentId: string;
};

export type CreateCustomTaskAssignmentMutation = {
  __typename: "CustomTaskAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type UpdateCustomTaskAssignmentMutation = {
  __typename: "CustomTaskAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type DeleteCustomTaskAssignmentMutation = {
  __typename: "CustomTaskAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type CreateAssessmentStandardFrameworkMutation = {
  __typename: "AssessmentStandardFramework";
  id: string;
  key: string;
  type: StandardType | null;
  fileName: string | null;
  filter: {
    __typename: "StandardFilter";
    impact: ImpactEnum | null;
  } | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  assessmentId: string;
  managerId: string | null;
  assessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
  managers: {
    __typename: "ModelFrameworkManagerConnection";
    items: Array<{
      __typename: "FrameworkManager";
      id: string;
      assessmentId: string;
      standardFrameworkId: string;
      managerId: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type UpdateAssessmentStandardFrameworkMutation = {
  __typename: "AssessmentStandardFramework";
  id: string;
  key: string;
  type: StandardType | null;
  fileName: string | null;
  filter: {
    __typename: "StandardFilter";
    impact: ImpactEnum | null;
  } | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  assessmentId: string;
  managerId: string | null;
  assessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
  managers: {
    __typename: "ModelFrameworkManagerConnection";
    items: Array<{
      __typename: "FrameworkManager";
      id: string;
      assessmentId: string;
      standardFrameworkId: string;
      managerId: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type DeleteAssessmentStandardFrameworkMutation = {
  __typename: "AssessmentStandardFramework";
  id: string;
  key: string;
  type: StandardType | null;
  fileName: string | null;
  filter: {
    __typename: "StandardFilter";
    impact: ImpactEnum | null;
  } | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  assessmentId: string;
  managerId: string | null;
  assessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
  managers: {
    __typename: "ModelFrameworkManagerConnection";
    items: Array<{
      __typename: "FrameworkManager";
      id: string;
      assessmentId: string;
      standardFrameworkId: string;
      managerId: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type CreateFrameworkManagerMutation = {
  __typename: "FrameworkManager";
  id: string;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
};

export type UpdateFrameworkManagerMutation = {
  __typename: "FrameworkManager";
  id: string;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
};

export type DeleteFrameworkManagerMutation = {
  __typename: "FrameworkManager";
  id: string;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
};

export type CreateCommentMutation = {
  __typename: "Comment";
  id: string;
  latestSource: ExportSourceEnum;
  comment: string;
  date: number | null;
  questionId: string | null;
  assessmentId: string | null;
  userId: string;
  user: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  exports: {
    __typename: "ModelExportConnection";
    items: Array<{
      __typename: "Export";
      id: string;
      destination: ExportSourceEnum;
      destinationCategoryId: string;
      category: CategoryEnum | null;
      categoryId: string;
      date: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type UpdateCommentMutation = {
  __typename: "Comment";
  id: string;
  latestSource: ExportSourceEnum;
  comment: string;
  date: number | null;
  questionId: string | null;
  assessmentId: string | null;
  userId: string;
  user: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  exports: {
    __typename: "ModelExportConnection";
    items: Array<{
      __typename: "Export";
      id: string;
      destination: ExportSourceEnum;
      destinationCategoryId: string;
      category: CategoryEnum | null;
      categoryId: string;
      date: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type DeleteCommentMutation = {
  __typename: "Comment";
  id: string;
  latestSource: ExportSourceEnum;
  comment: string;
  date: number | null;
  questionId: string | null;
  assessmentId: string | null;
  userId: string;
  user: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  exports: {
    __typename: "ModelExportConnection";
    items: Array<{
      __typename: "Export";
      id: string;
      destination: ExportSourceEnum;
      destinationCategoryId: string;
      category: CategoryEnum | null;
      categoryId: string;
      date: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type CreateExportMutation = {
  __typename: "Export";
  id: string;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category: CategoryEnum | null;
  categoryId: string;
  date: number | null;
};

export type UpdateExportMutation = {
  __typename: "Export";
  id: string;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category: CategoryEnum | null;
  categoryId: string;
  date: number | null;
};

export type DeleteExportMutation = {
  __typename: "Export";
  id: string;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category: CategoryEnum | null;
  categoryId: string;
  date: number | null;
};

export type GetRevisionQuery = {
  __typename: "Entity";
  id: string;
  name: string;
  users: Array<string | null> | null;
  completionStatus: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId: string | null;
  childEntityId: string | null;
  frameworkId: string | null;
  frameworkName: string | null;
  frameworkType: EntityTypeEnum | null;
  parentId: string | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  scores: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  entityType: EntityTypeEnum;
  projectManager: string | null;
  projectManagerName: string | null;
  projectDeadline: number | null;
  logo: {
    __typename: "S3File";
    id: string | null;
    name: string;
    names: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date: number | null;
    contentType: string | null;
    length: number | null;
    key: string | null;
  } | null;
  integrations: Array<{
    __typename: "Integrations";
    name: string;
    credentials: string | null;
    isEnable: boolean | null;
    isAuth: boolean | null;
    inheritFromParent: boolean | null;
  } | null> | null;
  externalFeed: Array<{
    __typename: "Integrations";
    name: string;
    credentials: string | null;
    isEnable: boolean | null;
    isAuth: boolean | null;
    inheritFromParent: boolean | null;
  } | null> | null;
  defaultSetting: {
    __typename: "DefaultSetting";
    dueDateInDays: number | null;
    frequency: string | null;
    surveyLevel: string | null;
    artifacts: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    standards: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    accessLevel: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
  } | null;
  createdBy: string | null;
  createdAt: number | null;
  modifiedBy: string | null;
  modifiedAt: number | null;
  industry: IndustryEnum | null;
  domain: string;
  activeAssessmentId: string;
  vendorDetails: {
    __typename: "VendorsDetail";
    id: string;
    impact: number | null;
    externalScan: number | null;
    financialRisk: number | null;
    criticalGaps: number | null;
    standardsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    status: VendorStatusEnum | null;
    probability: number | null;
    website: string | null;
    location: string | null;
    size: string | null;
    accessLevel: AccessLevelEnum | null;
    collectionStatus: CollectionStatusEnum | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    approvalDate: number | null;
    questionnaires: Array<{
      __typename: "Questionnaire";
      tag: string | null;
      status: {
        __typename: "QuestionnaireStatus";
        totalQuestions: number | null;
        completedQuestions: number | null;
      } | null;
      criticalGaps: Array<string | null> | null;
      allGaps: string | null;
    } | null> | null;
    artifacts: {
      __typename: "Artifacts";
      documents: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      scans: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      insurances: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      certifications: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
    } | null;
    primaryPoc: {
      __typename: "ShortRef";
      name: string | null;
      id: string | null;
      email: string | null;
    } | null;
    stage: VendorStatusEnum | null;
    activeScan: boolean | null;
    domain: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    intelligence: {
      __typename: "Intelligence";
      id: string;
      breaches: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date: number | null;
          name: string | null;
          description: string | null;
          leaks: number | null;
          data: Array<string | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      externalThreats: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source: string | null;
          findings: Array<string | null> | null;
          riskScore: string | null;
          details: Array<{
            __typename: "ThreatDetails";
            ip: string | null;
            port: number | null;
            cve: string | null;
            cvss: number | null;
          } | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      others: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name: string | null;
          label: string | null;
          severity: string | null;
          description: string | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
  activeAssessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type ListAlertsQuery = {
  __typename: "Alert";
  id: string | null;
  type: string | null;
  content: string | null;
  priority: number | null;
};

export type GetUserQuery = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role: RoleEnum;
  phone: string | null;
  entityId: string | null;
  isCognitoUser: boolean | null;
  questionMap: string | null;
  createdBy: string | null;
  createdAt: number | null;
  lastLogin: number | null;
  invitationDate: number | null;
  reminderDate: number | null;
  modifiedBy: string | null;
  modifiedAt: number | null;
};

export type ListUsersQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null> | null;
  nextToken: string | null;
};

export type UserRoleQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null> | null;
  nextToken: string | null;
};

export type UserByEntityIdQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetEntityQuery = {
  __typename: "Entity";
  id: string;
  name: string;
  users: Array<string | null> | null;
  completionStatus: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId: string | null;
  childEntityId: string | null;
  frameworkId: string | null;
  frameworkName: string | null;
  frameworkType: EntityTypeEnum | null;
  parentId: string | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  scores: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  entityType: EntityTypeEnum;
  projectManager: string | null;
  projectManagerName: string | null;
  projectDeadline: number | null;
  logo: {
    __typename: "S3File";
    id: string | null;
    name: string;
    names: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date: number | null;
    contentType: string | null;
    length: number | null;
    key: string | null;
  } | null;
  integrations: Array<{
    __typename: "Integrations";
    name: string;
    credentials: string | null;
    isEnable: boolean | null;
    isAuth: boolean | null;
    inheritFromParent: boolean | null;
  } | null> | null;
  externalFeed: Array<{
    __typename: "Integrations";
    name: string;
    credentials: string | null;
    isEnable: boolean | null;
    isAuth: boolean | null;
    inheritFromParent: boolean | null;
  } | null> | null;
  defaultSetting: {
    __typename: "DefaultSetting";
    dueDateInDays: number | null;
    frequency: string | null;
    surveyLevel: string | null;
    artifacts: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    standards: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    accessLevel: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
  } | null;
  createdBy: string | null;
  createdAt: number | null;
  modifiedBy: string | null;
  modifiedAt: number | null;
  industry: IndustryEnum | null;
  domain: string;
  activeAssessmentId: string;
  vendorDetails: {
    __typename: "VendorsDetail";
    id: string;
    impact: number | null;
    externalScan: number | null;
    financialRisk: number | null;
    criticalGaps: number | null;
    standardsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    status: VendorStatusEnum | null;
    probability: number | null;
    website: string | null;
    location: string | null;
    size: string | null;
    accessLevel: AccessLevelEnum | null;
    collectionStatus: CollectionStatusEnum | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    approvalDate: number | null;
    questionnaires: Array<{
      __typename: "Questionnaire";
      tag: string | null;
      status: {
        __typename: "QuestionnaireStatus";
        totalQuestions: number | null;
        completedQuestions: number | null;
      } | null;
      criticalGaps: Array<string | null> | null;
      allGaps: string | null;
    } | null> | null;
    artifacts: {
      __typename: "Artifacts";
      documents: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      scans: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      insurances: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      certifications: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
    } | null;
    primaryPoc: {
      __typename: "ShortRef";
      name: string | null;
      id: string | null;
      email: string | null;
    } | null;
    stage: VendorStatusEnum | null;
    activeScan: boolean | null;
    domain: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    intelligence: {
      __typename: "Intelligence";
      id: string;
      breaches: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date: number | null;
          name: string | null;
          description: string | null;
          leaks: number | null;
          data: Array<string | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      externalThreats: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source: string | null;
          findings: Array<string | null> | null;
          riskScore: string | null;
          details: Array<{
            __typename: "ThreatDetails";
            ip: string | null;
            port: number | null;
            cve: string | null;
            cvss: number | null;
          } | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      others: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name: string | null;
          label: string | null;
          severity: string | null;
          description: string | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
  activeAssessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type ListEntitysQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users: Array<string | null> | null;
    completionStatus: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId: string | null;
    childEntityId: string | null;
    frameworkId: string | null;
    frameworkName: string | null;
    frameworkType: EntityTypeEnum | null;
    parentId: string | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager: string | null;
    projectManagerName: string | null;
    projectDeadline: number | null;
    logo: {
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null;
    integrations: Array<{
      __typename: "Integrations";
      name: string;
      credentials: string | null;
      isEnable: boolean | null;
      isAuth: boolean | null;
      inheritFromParent: boolean | null;
    } | null> | null;
    externalFeed: Array<{
      __typename: "Integrations";
      name: string;
      credentials: string | null;
      isEnable: boolean | null;
      isAuth: boolean | null;
      inheritFromParent: boolean | null;
    } | null> | null;
    defaultSetting: {
      __typename: "DefaultSetting";
      dueDateInDays: number | null;
      frequency: string | null;
      surveyLevel: string | null;
      artifacts: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      standards: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      accessLevel: string | null;
      privacyData: boolean | null;
      impactsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
    } | null;
    createdBy: string | null;
    createdAt: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
    industry: IndustryEnum | null;
    domain: string;
    activeAssessmentId: string;
    vendorDetails: {
      __typename: "VendorsDetail";
      id: string;
      impact: number | null;
      externalScan: number | null;
      financialRisk: number | null;
      criticalGaps: number | null;
      standardsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      status: VendorStatusEnum | null;
      probability: number | null;
      website: string | null;
      location: string | null;
      size: string | null;
      accessLevel: AccessLevelEnum | null;
      collectionStatus: CollectionStatusEnum | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      approvalDate: number | null;
      questionnaires: Array<{
        __typename: "Questionnaire";
        tag: string | null;
        status: {
          __typename: "QuestionnaireStatus";
          totalQuestions: number | null;
          completedQuestions: number | null;
        } | null;
        criticalGaps: Array<string | null> | null;
        allGaps: string | null;
      } | null> | null;
      artifacts: {
        __typename: "Artifacts";
        documents: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        scans: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        insurances: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        certifications: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
      } | null;
      primaryPoc: {
        __typename: "ShortRef";
        name: string | null;
        id: string | null;
        email: string | null;
      } | null;
      stage: VendorStatusEnum | null;
      activeScan: boolean | null;
      domain: string | null;
      privacyData: boolean | null;
      impactsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      intelligence: {
        __typename: "Intelligence";
        id: string;
        breaches: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date: number | null;
            name: string | null;
            description: string | null;
            leaks: number | null;
            data: Array<string | null> | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
        externalThreats: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source: string | null;
            findings: Array<string | null> | null;
            riskScore: string | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
        others: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name: string | null;
            label: string | null;
            severity: string | null;
            description: string | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null;
    } | null;
    activeAssessment: {
      __typename: "Assessment";
      id: string;
      standardList: Array<{
        __typename: "StandardFramework";
        id: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
      } | null> | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      rootId: string;
      childId: string;
      scores: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
      left: number | null;
      right: number | null;
      managers: Array<string | null> | null;
      exports: {
        __typename: "Exports";
        jiraEntityIds: Array<string | null> | null;
      } | null;
      standardFrameworkList: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          assessmentId: string;
          managerId: string | null;
          assessment: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left: number | null;
            right: number | null;
            managers: Array<string | null> | null;
          } | null;
          managers: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type EntityParentQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users: Array<string | null> | null;
    completionStatus: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId: string | null;
    childEntityId: string | null;
    frameworkId: string | null;
    frameworkName: string | null;
    frameworkType: EntityTypeEnum | null;
    parentId: string | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager: string | null;
    projectManagerName: string | null;
    projectDeadline: number | null;
    logo: {
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null;
    integrations: Array<{
      __typename: "Integrations";
      name: string;
      credentials: string | null;
      isEnable: boolean | null;
      isAuth: boolean | null;
      inheritFromParent: boolean | null;
    } | null> | null;
    externalFeed: Array<{
      __typename: "Integrations";
      name: string;
      credentials: string | null;
      isEnable: boolean | null;
      isAuth: boolean | null;
      inheritFromParent: boolean | null;
    } | null> | null;
    defaultSetting: {
      __typename: "DefaultSetting";
      dueDateInDays: number | null;
      frequency: string | null;
      surveyLevel: string | null;
      artifacts: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      standards: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      accessLevel: string | null;
      privacyData: boolean | null;
      impactsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
    } | null;
    createdBy: string | null;
    createdAt: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
    industry: IndustryEnum | null;
    domain: string;
    activeAssessmentId: string;
    vendorDetails: {
      __typename: "VendorsDetail";
      id: string;
      impact: number | null;
      externalScan: number | null;
      financialRisk: number | null;
      criticalGaps: number | null;
      standardsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      status: VendorStatusEnum | null;
      probability: number | null;
      website: string | null;
      location: string | null;
      size: string | null;
      accessLevel: AccessLevelEnum | null;
      collectionStatus: CollectionStatusEnum | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      approvalDate: number | null;
      questionnaires: Array<{
        __typename: "Questionnaire";
        tag: string | null;
        status: {
          __typename: "QuestionnaireStatus";
          totalQuestions: number | null;
          completedQuestions: number | null;
        } | null;
        criticalGaps: Array<string | null> | null;
        allGaps: string | null;
      } | null> | null;
      artifacts: {
        __typename: "Artifacts";
        documents: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        scans: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        insurances: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        certifications: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
      } | null;
      primaryPoc: {
        __typename: "ShortRef";
        name: string | null;
        id: string | null;
        email: string | null;
      } | null;
      stage: VendorStatusEnum | null;
      activeScan: boolean | null;
      domain: string | null;
      privacyData: boolean | null;
      impactsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      intelligence: {
        __typename: "Intelligence";
        id: string;
        breaches: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date: number | null;
            name: string | null;
            description: string | null;
            leaks: number | null;
            data: Array<string | null> | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
        externalThreats: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source: string | null;
            findings: Array<string | null> | null;
            riskScore: string | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
        others: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name: string | null;
            label: string | null;
            severity: string | null;
            description: string | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null;
    } | null;
    activeAssessment: {
      __typename: "Assessment";
      id: string;
      standardList: Array<{
        __typename: "StandardFramework";
        id: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
      } | null> | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      rootId: string;
      childId: string;
      scores: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
      left: number | null;
      right: number | null;
      managers: Array<string | null> | null;
      exports: {
        __typename: "Exports";
        jiraEntityIds: Array<string | null> | null;
      } | null;
      standardFrameworkList: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          assessmentId: string;
          managerId: string | null;
          assessment: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left: number | null;
            right: number | null;
            managers: Array<string | null> | null;
          } | null;
          managers: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type EntityRootEntityByTypeQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users: Array<string | null> | null;
    completionStatus: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId: string | null;
    childEntityId: string | null;
    frameworkId: string | null;
    frameworkName: string | null;
    frameworkType: EntityTypeEnum | null;
    parentId: string | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager: string | null;
    projectManagerName: string | null;
    projectDeadline: number | null;
    logo: {
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null;
    integrations: Array<{
      __typename: "Integrations";
      name: string;
      credentials: string | null;
      isEnable: boolean | null;
      isAuth: boolean | null;
      inheritFromParent: boolean | null;
    } | null> | null;
    externalFeed: Array<{
      __typename: "Integrations";
      name: string;
      credentials: string | null;
      isEnable: boolean | null;
      isAuth: boolean | null;
      inheritFromParent: boolean | null;
    } | null> | null;
    defaultSetting: {
      __typename: "DefaultSetting";
      dueDateInDays: number | null;
      frequency: string | null;
      surveyLevel: string | null;
      artifacts: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      standards: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      accessLevel: string | null;
      privacyData: boolean | null;
      impactsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
    } | null;
    createdBy: string | null;
    createdAt: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
    industry: IndustryEnum | null;
    domain: string;
    activeAssessmentId: string;
    vendorDetails: {
      __typename: "VendorsDetail";
      id: string;
      impact: number | null;
      externalScan: number | null;
      financialRisk: number | null;
      criticalGaps: number | null;
      standardsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      status: VendorStatusEnum | null;
      probability: number | null;
      website: string | null;
      location: string | null;
      size: string | null;
      accessLevel: AccessLevelEnum | null;
      collectionStatus: CollectionStatusEnum | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      approvalDate: number | null;
      questionnaires: Array<{
        __typename: "Questionnaire";
        tag: string | null;
        status: {
          __typename: "QuestionnaireStatus";
          totalQuestions: number | null;
          completedQuestions: number | null;
        } | null;
        criticalGaps: Array<string | null> | null;
        allGaps: string | null;
      } | null> | null;
      artifacts: {
        __typename: "Artifacts";
        documents: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        scans: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        insurances: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        certifications: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
      } | null;
      primaryPoc: {
        __typename: "ShortRef";
        name: string | null;
        id: string | null;
        email: string | null;
      } | null;
      stage: VendorStatusEnum | null;
      activeScan: boolean | null;
      domain: string | null;
      privacyData: boolean | null;
      impactsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      intelligence: {
        __typename: "Intelligence";
        id: string;
        breaches: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date: number | null;
            name: string | null;
            description: string | null;
            leaks: number | null;
            data: Array<string | null> | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
        externalThreats: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source: string | null;
            findings: Array<string | null> | null;
            riskScore: string | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
        others: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name: string | null;
            label: string | null;
            severity: string | null;
            description: string | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null;
    } | null;
    activeAssessment: {
      __typename: "Assessment";
      id: string;
      standardList: Array<{
        __typename: "StandardFramework";
        id: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
      } | null> | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      rootId: string;
      childId: string;
      scores: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
      left: number | null;
      right: number | null;
      managers: Array<string | null> | null;
      exports: {
        __typename: "Exports";
        jiraEntityIds: Array<string | null> | null;
      } | null;
      standardFrameworkList: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          assessmentId: string;
          managerId: string | null;
          assessment: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left: number | null;
            right: number | null;
            managers: Array<string | null> | null;
          } | null;
          managers: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type EntityChildEntityByTypeQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users: Array<string | null> | null;
    completionStatus: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId: string | null;
    childEntityId: string | null;
    frameworkId: string | null;
    frameworkName: string | null;
    frameworkType: EntityTypeEnum | null;
    parentId: string | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager: string | null;
    projectManagerName: string | null;
    projectDeadline: number | null;
    logo: {
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null;
    integrations: Array<{
      __typename: "Integrations";
      name: string;
      credentials: string | null;
      isEnable: boolean | null;
      isAuth: boolean | null;
      inheritFromParent: boolean | null;
    } | null> | null;
    externalFeed: Array<{
      __typename: "Integrations";
      name: string;
      credentials: string | null;
      isEnable: boolean | null;
      isAuth: boolean | null;
      inheritFromParent: boolean | null;
    } | null> | null;
    defaultSetting: {
      __typename: "DefaultSetting";
      dueDateInDays: number | null;
      frequency: string | null;
      surveyLevel: string | null;
      artifacts: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      standards: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      accessLevel: string | null;
      privacyData: boolean | null;
      impactsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
    } | null;
    createdBy: string | null;
    createdAt: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
    industry: IndustryEnum | null;
    domain: string;
    activeAssessmentId: string;
    vendorDetails: {
      __typename: "VendorsDetail";
      id: string;
      impact: number | null;
      externalScan: number | null;
      financialRisk: number | null;
      criticalGaps: number | null;
      standardsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      status: VendorStatusEnum | null;
      probability: number | null;
      website: string | null;
      location: string | null;
      size: string | null;
      accessLevel: AccessLevelEnum | null;
      collectionStatus: CollectionStatusEnum | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      approvalDate: number | null;
      questionnaires: Array<{
        __typename: "Questionnaire";
        tag: string | null;
        status: {
          __typename: "QuestionnaireStatus";
          totalQuestions: number | null;
          completedQuestions: number | null;
        } | null;
        criticalGaps: Array<string | null> | null;
        allGaps: string | null;
      } | null> | null;
      artifacts: {
        __typename: "Artifacts";
        documents: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        scans: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        insurances: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        certifications: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
      } | null;
      primaryPoc: {
        __typename: "ShortRef";
        name: string | null;
        id: string | null;
        email: string | null;
      } | null;
      stage: VendorStatusEnum | null;
      activeScan: boolean | null;
      domain: string | null;
      privacyData: boolean | null;
      impactsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      intelligence: {
        __typename: "Intelligence";
        id: string;
        breaches: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date: number | null;
            name: string | null;
            description: string | null;
            leaks: number | null;
            data: Array<string | null> | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
        externalThreats: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source: string | null;
            findings: Array<string | null> | null;
            riskScore: string | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
        others: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name: string | null;
            label: string | null;
            severity: string | null;
            description: string | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null;
    } | null;
    activeAssessment: {
      __typename: "Assessment";
      id: string;
      standardList: Array<{
        __typename: "StandardFramework";
        id: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
      } | null> | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      rootId: string;
      childId: string;
      scores: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
      left: number | null;
      right: number | null;
      managers: Array<string | null> | null;
      exports: {
        __typename: "Exports";
        jiraEntityIds: Array<string | null> | null;
      } | null;
      standardFrameworkList: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          assessmentId: string;
          managerId: string | null;
          assessment: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left: number | null;
            right: number | null;
            managers: Array<string | null> | null;
          } | null;
          managers: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type EntityFrameworkQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users: Array<string | null> | null;
    completionStatus: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId: string | null;
    childEntityId: string | null;
    frameworkId: string | null;
    frameworkName: string | null;
    frameworkType: EntityTypeEnum | null;
    parentId: string | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager: string | null;
    projectManagerName: string | null;
    projectDeadline: number | null;
    logo: {
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null;
    integrations: Array<{
      __typename: "Integrations";
      name: string;
      credentials: string | null;
      isEnable: boolean | null;
      isAuth: boolean | null;
      inheritFromParent: boolean | null;
    } | null> | null;
    externalFeed: Array<{
      __typename: "Integrations";
      name: string;
      credentials: string | null;
      isEnable: boolean | null;
      isAuth: boolean | null;
      inheritFromParent: boolean | null;
    } | null> | null;
    defaultSetting: {
      __typename: "DefaultSetting";
      dueDateInDays: number | null;
      frequency: string | null;
      surveyLevel: string | null;
      artifacts: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      standards: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      accessLevel: string | null;
      privacyData: boolean | null;
      impactsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
    } | null;
    createdBy: string | null;
    createdAt: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
    industry: IndustryEnum | null;
    domain: string;
    activeAssessmentId: string;
    vendorDetails: {
      __typename: "VendorsDetail";
      id: string;
      impact: number | null;
      externalScan: number | null;
      financialRisk: number | null;
      criticalGaps: number | null;
      standardsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      status: VendorStatusEnum | null;
      probability: number | null;
      website: string | null;
      location: string | null;
      size: string | null;
      accessLevel: AccessLevelEnum | null;
      collectionStatus: CollectionStatusEnum | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      approvalDate: number | null;
      questionnaires: Array<{
        __typename: "Questionnaire";
        tag: string | null;
        status: {
          __typename: "QuestionnaireStatus";
          totalQuestions: number | null;
          completedQuestions: number | null;
        } | null;
        criticalGaps: Array<string | null> | null;
        allGaps: string | null;
      } | null> | null;
      artifacts: {
        __typename: "Artifacts";
        documents: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        scans: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        insurances: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        certifications: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
      } | null;
      primaryPoc: {
        __typename: "ShortRef";
        name: string | null;
        id: string | null;
        email: string | null;
      } | null;
      stage: VendorStatusEnum | null;
      activeScan: boolean | null;
      domain: string | null;
      privacyData: boolean | null;
      impactsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      intelligence: {
        __typename: "Intelligence";
        id: string;
        breaches: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date: number | null;
            name: string | null;
            description: string | null;
            leaks: number | null;
            data: Array<string | null> | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
        externalThreats: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source: string | null;
            findings: Array<string | null> | null;
            riskScore: string | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
        others: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name: string | null;
            label: string | null;
            severity: string | null;
            description: string | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null;
    } | null;
    activeAssessment: {
      __typename: "Assessment";
      id: string;
      standardList: Array<{
        __typename: "StandardFramework";
        id: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
      } | null> | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      rootId: string;
      childId: string;
      scores: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
      left: number | null;
      right: number | null;
      managers: Array<string | null> | null;
      exports: {
        __typename: "Exports";
        jiraEntityIds: Array<string | null> | null;
      } | null;
      standardFrameworkList: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          assessmentId: string;
          managerId: string | null;
          assessment: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left: number | null;
            right: number | null;
            managers: Array<string | null> | null;
          } | null;
          managers: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type EntitiesByEntityTypeQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users: Array<string | null> | null;
    completionStatus: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId: string | null;
    childEntityId: string | null;
    frameworkId: string | null;
    frameworkName: string | null;
    frameworkType: EntityTypeEnum | null;
    parentId: string | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager: string | null;
    projectManagerName: string | null;
    projectDeadline: number | null;
    logo: {
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null;
    integrations: Array<{
      __typename: "Integrations";
      name: string;
      credentials: string | null;
      isEnable: boolean | null;
      isAuth: boolean | null;
      inheritFromParent: boolean | null;
    } | null> | null;
    externalFeed: Array<{
      __typename: "Integrations";
      name: string;
      credentials: string | null;
      isEnable: boolean | null;
      isAuth: boolean | null;
      inheritFromParent: boolean | null;
    } | null> | null;
    defaultSetting: {
      __typename: "DefaultSetting";
      dueDateInDays: number | null;
      frequency: string | null;
      surveyLevel: string | null;
      artifacts: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      standards: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      accessLevel: string | null;
      privacyData: boolean | null;
      impactsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
    } | null;
    createdBy: string | null;
    createdAt: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
    industry: IndustryEnum | null;
    domain: string;
    activeAssessmentId: string;
    vendorDetails: {
      __typename: "VendorsDetail";
      id: string;
      impact: number | null;
      externalScan: number | null;
      financialRisk: number | null;
      criticalGaps: number | null;
      standardsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      status: VendorStatusEnum | null;
      probability: number | null;
      website: string | null;
      location: string | null;
      size: string | null;
      accessLevel: AccessLevelEnum | null;
      collectionStatus: CollectionStatusEnum | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      approvalDate: number | null;
      questionnaires: Array<{
        __typename: "Questionnaire";
        tag: string | null;
        status: {
          __typename: "QuestionnaireStatus";
          totalQuestions: number | null;
          completedQuestions: number | null;
        } | null;
        criticalGaps: Array<string | null> | null;
        allGaps: string | null;
      } | null> | null;
      artifacts: {
        __typename: "Artifacts";
        documents: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        scans: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        insurances: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        certifications: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
      } | null;
      primaryPoc: {
        __typename: "ShortRef";
        name: string | null;
        id: string | null;
        email: string | null;
      } | null;
      stage: VendorStatusEnum | null;
      activeScan: boolean | null;
      domain: string | null;
      privacyData: boolean | null;
      impactsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      intelligence: {
        __typename: "Intelligence";
        id: string;
        breaches: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date: number | null;
            name: string | null;
            description: string | null;
            leaks: number | null;
            data: Array<string | null> | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
        externalThreats: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source: string | null;
            findings: Array<string | null> | null;
            riskScore: string | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
        others: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name: string | null;
            label: string | null;
            severity: string | null;
            description: string | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null;
    } | null;
    activeAssessment: {
      __typename: "Assessment";
      id: string;
      standardList: Array<{
        __typename: "StandardFramework";
        id: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
      } | null> | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      rootId: string;
      childId: string;
      scores: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
      left: number | null;
      right: number | null;
      managers: Array<string | null> | null;
      exports: {
        __typename: "Exports";
        jiraEntityIds: Array<string | null> | null;
      } | null;
      standardFrameworkList: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          assessmentId: string;
          managerId: string | null;
          assessment: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left: number | null;
            right: number | null;
            managers: Array<string | null> | null;
          } | null;
          managers: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type EntitiesByDomainAndEntityTypeQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users: Array<string | null> | null;
    completionStatus: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId: string | null;
    childEntityId: string | null;
    frameworkId: string | null;
    frameworkName: string | null;
    frameworkType: EntityTypeEnum | null;
    parentId: string | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager: string | null;
    projectManagerName: string | null;
    projectDeadline: number | null;
    logo: {
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null;
    integrations: Array<{
      __typename: "Integrations";
      name: string;
      credentials: string | null;
      isEnable: boolean | null;
      isAuth: boolean | null;
      inheritFromParent: boolean | null;
    } | null> | null;
    externalFeed: Array<{
      __typename: "Integrations";
      name: string;
      credentials: string | null;
      isEnable: boolean | null;
      isAuth: boolean | null;
      inheritFromParent: boolean | null;
    } | null> | null;
    defaultSetting: {
      __typename: "DefaultSetting";
      dueDateInDays: number | null;
      frequency: string | null;
      surveyLevel: string | null;
      artifacts: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      standards: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      accessLevel: string | null;
      privacyData: boolean | null;
      impactsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
    } | null;
    createdBy: string | null;
    createdAt: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
    industry: IndustryEnum | null;
    domain: string;
    activeAssessmentId: string;
    vendorDetails: {
      __typename: "VendorsDetail";
      id: string;
      impact: number | null;
      externalScan: number | null;
      financialRisk: number | null;
      criticalGaps: number | null;
      standardsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      status: VendorStatusEnum | null;
      probability: number | null;
      website: string | null;
      location: string | null;
      size: string | null;
      accessLevel: AccessLevelEnum | null;
      collectionStatus: CollectionStatusEnum | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      approvalDate: number | null;
      questionnaires: Array<{
        __typename: "Questionnaire";
        tag: string | null;
        status: {
          __typename: "QuestionnaireStatus";
          totalQuestions: number | null;
          completedQuestions: number | null;
        } | null;
        criticalGaps: Array<string | null> | null;
        allGaps: string | null;
      } | null> | null;
      artifacts: {
        __typename: "Artifacts";
        documents: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        scans: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        insurances: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        certifications: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
      } | null;
      primaryPoc: {
        __typename: "ShortRef";
        name: string | null;
        id: string | null;
        email: string | null;
      } | null;
      stage: VendorStatusEnum | null;
      activeScan: boolean | null;
      domain: string | null;
      privacyData: boolean | null;
      impactsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      intelligence: {
        __typename: "Intelligence";
        id: string;
        breaches: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date: number | null;
            name: string | null;
            description: string | null;
            leaks: number | null;
            data: Array<string | null> | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
        externalThreats: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source: string | null;
            findings: Array<string | null> | null;
            riskScore: string | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
        others: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name: string | null;
            label: string | null;
            severity: string | null;
            description: string | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null;
    } | null;
    activeAssessment: {
      __typename: "Assessment";
      id: string;
      standardList: Array<{
        __typename: "StandardFramework";
        id: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
      } | null> | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      rootId: string;
      childId: string;
      scores: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
      left: number | null;
      right: number | null;
      managers: Array<string | null> | null;
      exports: {
        __typename: "Exports";
        jiraEntityIds: Array<string | null> | null;
      } | null;
      standardFrameworkList: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          assessmentId: string;
          managerId: string | null;
          assessment: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left: number | null;
            right: number | null;
            managers: Array<string | null> | null;
          } | null;
          managers: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type EntityRootEntityByTypeAndFrameworkNameQuery = {
  __typename: "ModelEntityConnection";
  items: Array<{
    __typename: "Entity";
    id: string;
    name: string;
    users: Array<string | null> | null;
    completionStatus: CollectionStatusEnum | null;
    accessGroups: Array<string>;
    participantGroup: Array<string>;
    rootEntityId: string | null;
    childEntityId: string | null;
    frameworkId: string | null;
    frameworkName: string | null;
    frameworkType: EntityTypeEnum | null;
    parentId: string | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    entityType: EntityTypeEnum;
    projectManager: string | null;
    projectManagerName: string | null;
    projectDeadline: number | null;
    logo: {
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null;
    integrations: Array<{
      __typename: "Integrations";
      name: string;
      credentials: string | null;
      isEnable: boolean | null;
      isAuth: boolean | null;
      inheritFromParent: boolean | null;
    } | null> | null;
    externalFeed: Array<{
      __typename: "Integrations";
      name: string;
      credentials: string | null;
      isEnable: boolean | null;
      isAuth: boolean | null;
      inheritFromParent: boolean | null;
    } | null> | null;
    defaultSetting: {
      __typename: "DefaultSetting";
      dueDateInDays: number | null;
      frequency: string | null;
      surveyLevel: string | null;
      artifacts: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      standards: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      accessLevel: string | null;
      privacyData: boolean | null;
      impactsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
    } | null;
    createdBy: string | null;
    createdAt: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
    industry: IndustryEnum | null;
    domain: string;
    activeAssessmentId: string;
    vendorDetails: {
      __typename: "VendorsDetail";
      id: string;
      impact: number | null;
      externalScan: number | null;
      financialRisk: number | null;
      criticalGaps: number | null;
      standardsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      status: VendorStatusEnum | null;
      probability: number | null;
      website: string | null;
      location: string | null;
      size: string | null;
      accessLevel: AccessLevelEnum | null;
      collectionStatus: CollectionStatusEnum | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      approvalDate: number | null;
      questionnaires: Array<{
        __typename: "Questionnaire";
        tag: string | null;
        status: {
          __typename: "QuestionnaireStatus";
          totalQuestions: number | null;
          completedQuestions: number | null;
        } | null;
        criticalGaps: Array<string | null> | null;
        allGaps: string | null;
      } | null> | null;
      artifacts: {
        __typename: "Artifacts";
        documents: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        scans: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        insurances: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
        certifications: Array<{
          __typename: "S3File";
          id: string | null;
          name: string;
          names: string | null;
          entityId: string;
          fileType: FileTypeEnum;
          date: number | null;
          contentType: string | null;
          length: number | null;
          key: string | null;
        } | null> | null;
      } | null;
      primaryPoc: {
        __typename: "ShortRef";
        name: string | null;
        id: string | null;
        email: string | null;
      } | null;
      stage: VendorStatusEnum | null;
      activeScan: boolean | null;
      domain: string | null;
      privacyData: boolean | null;
      impactsQualify: Array<{
        __typename: "Qualification";
        name: string | null;
        isQualify: boolean | null;
      } | null> | null;
      intelligence: {
        __typename: "Intelligence";
        id: string;
        breaches: {
          __typename: "ModelBreachesConnection";
          items: Array<{
            __typename: "Breaches";
            id: string;
            vendorId: string;
            date: number | null;
            name: string | null;
            description: string | null;
            leaks: number | null;
            data: Array<string | null> | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
        externalThreats: {
          __typename: "ModelExternalThreatsConnection";
          items: Array<{
            __typename: "ExternalThreats";
            id: string;
            vendorId: string;
            source: string | null;
            findings: Array<string | null> | null;
            riskScore: string | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
        others: {
          __typename: "ModelOthersConnection";
          items: Array<{
            __typename: "Others";
            id: string;
            vendorId: string;
            name: string | null;
            label: string | null;
            severity: string | null;
            description: string | null;
            status: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null;
    } | null;
    activeAssessment: {
      __typename: "Assessment";
      id: string;
      standardList: Array<{
        __typename: "StandardFramework";
        id: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
      } | null> | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      rootId: string;
      childId: string;
      scores: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
      left: number | null;
      right: number | null;
      managers: Array<string | null> | null;
      exports: {
        __typename: "Exports";
        jiraEntityIds: Array<string | null> | null;
      } | null;
      standardFrameworkList: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          assessmentId: string;
          managerId: string | null;
          assessment: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left: number | null;
            right: number | null;
            managers: Array<string | null> | null;
          } | null;
          managers: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListVendorsDetailsQuery = {
  __typename: "ModelVendorsDetailConnection";
  items: Array<{
    __typename: "VendorsDetail";
    id: string;
    impact: number | null;
    externalScan: number | null;
    financialRisk: number | null;
    criticalGaps: number | null;
    standardsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    status: VendorStatusEnum | null;
    probability: number | null;
    website: string | null;
    location: string | null;
    size: string | null;
    accessLevel: AccessLevelEnum | null;
    collectionStatus: CollectionStatusEnum | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    approvalDate: number | null;
    questionnaires: Array<{
      __typename: "Questionnaire";
      tag: string | null;
      status: {
        __typename: "QuestionnaireStatus";
        totalQuestions: number | null;
        completedQuestions: number | null;
      } | null;
      criticalGaps: Array<string | null> | null;
      allGaps: string | null;
    } | null> | null;
    artifacts: {
      __typename: "Artifacts";
      documents: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      scans: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      insurances: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      certifications: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
    } | null;
    primaryPoc: {
      __typename: "ShortRef";
      name: string | null;
      id: string | null;
      email: string | null;
    } | null;
    stage: VendorStatusEnum | null;
    activeScan: boolean | null;
    domain: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    intelligence: {
      __typename: "Intelligence";
      id: string;
      breaches: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date: number | null;
          name: string | null;
          description: string | null;
          leaks: number | null;
          data: Array<string | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      externalThreats: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source: string | null;
          findings: Array<string | null> | null;
          riskScore: string | null;
          details: Array<{
            __typename: "ThreatDetails";
            ip: string | null;
            port: number | null;
            cve: string | null;
            cvss: number | null;
          } | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      others: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name: string | null;
          label: string | null;
          severity: string | null;
          description: string | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetVendorsDetailQuery = {
  __typename: "VendorsDetail";
  id: string;
  impact: number | null;
  externalScan: number | null;
  financialRisk: number | null;
  criticalGaps: number | null;
  standardsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  status: VendorStatusEnum | null;
  probability: number | null;
  website: string | null;
  location: string | null;
  size: string | null;
  accessLevel: AccessLevelEnum | null;
  collectionStatus: CollectionStatusEnum | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  approvalDate: number | null;
  questionnaires: Array<{
    __typename: "Questionnaire";
    tag: string | null;
    status: {
      __typename: "QuestionnaireStatus";
      totalQuestions: number | null;
      completedQuestions: number | null;
    } | null;
    criticalGaps: Array<string | null> | null;
    allGaps: string | null;
  } | null> | null;
  artifacts: {
    __typename: "Artifacts";
    documents: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    scans: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    insurances: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    certifications: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
  } | null;
  primaryPoc: {
    __typename: "ShortRef";
    name: string | null;
    id: string | null;
    email: string | null;
  } | null;
  stage: VendorStatusEnum | null;
  activeScan: boolean | null;
  domain: string | null;
  privacyData: boolean | null;
  impactsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  intelligence: {
    __typename: "Intelligence";
    id: string;
    breaches: {
      __typename: "ModelBreachesConnection";
      items: Array<{
        __typename: "Breaches";
        id: string;
        vendorId: string;
        date: number | null;
        name: string | null;
        description: string | null;
        leaks: number | null;
        data: Array<string | null> | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    externalThreats: {
      __typename: "ModelExternalThreatsConnection";
      items: Array<{
        __typename: "ExternalThreats";
        id: string;
        vendorId: string;
        source: string | null;
        findings: Array<string | null> | null;
        riskScore: string | null;
        details: Array<{
          __typename: "ThreatDetails";
          ip: string | null;
          port: number | null;
          cve: string | null;
          cvss: number | null;
        } | null> | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    others: {
      __typename: "ModelOthersConnection";
      items: Array<{
        __typename: "Others";
        id: string;
        vendorId: string;
        name: string | null;
        label: string | null;
        severity: string | null;
        description: string | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type GetFrameworkScoresQuery = {
  __typename: "FrameworkScores";
  id: string;
  assessmentId: string;
  type: StandardType;
  total: number;
  target: number;
  collection: number;
  remediation: number;
  totalQuestions: number;
  completedQuestions: number;
  totalGaps: number;
  criticalGaps: number;
  notApplicable: boolean | null;
  assignmentCount: number | null;
  riskStandardsCount: number | null;
  complianceStandardsCount: number | null;
  standardsCount: number | null;
  top20: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ListFrameworkScoressQuery = {
  __typename: "ModelFrameworkScoresConnection";
  items: Array<{
    __typename: "FrameworkScores";
    id: string;
    assessmentId: string;
    type: StandardType;
    total: number;
    target: number;
    collection: number;
    remediation: number;
    totalQuestions: number;
    completedQuestions: number;
    totalGaps: number;
    criticalGaps: number;
    notApplicable: boolean | null;
    assignmentCount: number | null;
    riskStandardsCount: number | null;
    complianceStandardsCount: number | null;
    standardsCount: number | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type FrameworkScoresByAssessmentQuery = {
  __typename: "ModelFrameworkScoresConnection";
  items: Array<{
    __typename: "FrameworkScores";
    id: string;
    assessmentId: string;
    type: StandardType;
    total: number;
    target: number;
    collection: number;
    remediation: number;
    totalQuestions: number;
    completedQuestions: number;
    totalGaps: number;
    criticalGaps: number;
    notApplicable: boolean | null;
    assignmentCount: number | null;
    riskStandardsCount: number | null;
    complianceStandardsCount: number | null;
    standardsCount: number | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListIntelligencesQuery = {
  __typename: "ModelIntelligenceConnection";
  items: Array<{
    __typename: "Intelligence";
    id: string;
    breaches: {
      __typename: "ModelBreachesConnection";
      items: Array<{
        __typename: "Breaches";
        id: string;
        vendorId: string;
        date: number | null;
        name: string | null;
        description: string | null;
        leaks: number | null;
        data: Array<string | null> | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    externalThreats: {
      __typename: "ModelExternalThreatsConnection";
      items: Array<{
        __typename: "ExternalThreats";
        id: string;
        vendorId: string;
        source: string | null;
        findings: Array<string | null> | null;
        riskScore: string | null;
        details: Array<{
          __typename: "ThreatDetails";
          ip: string | null;
          port: number | null;
          cve: string | null;
          cvss: number | null;
        } | null> | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    others: {
      __typename: "ModelOthersConnection";
      items: Array<{
        __typename: "Others";
        id: string;
        vendorId: string;
        name: string | null;
        label: string | null;
        severity: string | null;
        description: string | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetIntelligenceQuery = {
  __typename: "Intelligence";
  id: string;
  breaches: {
    __typename: "ModelBreachesConnection";
    items: Array<{
      __typename: "Breaches";
      id: string;
      vendorId: string;
      date: number | null;
      name: string | null;
      description: string | null;
      leaks: number | null;
      data: Array<string | null> | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  externalThreats: {
    __typename: "ModelExternalThreatsConnection";
    items: Array<{
      __typename: "ExternalThreats";
      id: string;
      vendorId: string;
      source: string | null;
      findings: Array<string | null> | null;
      riskScore: string | null;
      details: Array<{
        __typename: "ThreatDetails";
        ip: string | null;
        port: number | null;
        cve: string | null;
        cvss: number | null;
      } | null> | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  others: {
    __typename: "ModelOthersConnection";
    items: Array<{
      __typename: "Others";
      id: string;
      vendorId: string;
      name: string | null;
      label: string | null;
      severity: string | null;
      description: string | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetBreachesQuery = {
  __typename: "Breaches";
  id: string;
  vendorId: string;
  date: number | null;
  name: string | null;
  description: string | null;
  leaks: number | null;
  data: Array<string | null> | null;
  status: string | null;
};

export type ListBreachessQuery = {
  __typename: "ModelBreachesConnection";
  items: Array<{
    __typename: "Breaches";
    id: string;
    vendorId: string;
    date: number | null;
    name: string | null;
    description: string | null;
    leaks: number | null;
    data: Array<string | null> | null;
    status: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type BreachesByVendorIdAndStatusQuery = {
  __typename: "ModelBreachesConnection";
  items: Array<{
    __typename: "Breaches";
    id: string;
    vendorId: string;
    date: number | null;
    name: string | null;
    description: string | null;
    leaks: number | null;
    data: Array<string | null> | null;
    status: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetExternalThreatsQuery = {
  __typename: "ExternalThreats";
  id: string;
  vendorId: string;
  source: string | null;
  findings: Array<string | null> | null;
  riskScore: string | null;
  details: Array<{
    __typename: "ThreatDetails";
    ip: string | null;
    port: number | null;
    cve: string | null;
    cvss: number | null;
  } | null> | null;
  status: string | null;
};

export type ListExternalThreatssQuery = {
  __typename: "ModelExternalThreatsConnection";
  items: Array<{
    __typename: "ExternalThreats";
    id: string;
    vendorId: string;
    source: string | null;
    findings: Array<string | null> | null;
    riskScore: string | null;
    details: Array<{
      __typename: "ThreatDetails";
      ip: string | null;
      port: number | null;
      cve: string | null;
      cvss: number | null;
    } | null> | null;
    status: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type ExternalThreatsByVendorIdAndStatusQuery = {
  __typename: "ModelExternalThreatsConnection";
  items: Array<{
    __typename: "ExternalThreats";
    id: string;
    vendorId: string;
    source: string | null;
    findings: Array<string | null> | null;
    riskScore: string | null;
    details: Array<{
      __typename: "ThreatDetails";
      ip: string | null;
      port: number | null;
      cve: string | null;
      cvss: number | null;
    } | null> | null;
    status: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetOthersQuery = {
  __typename: "Others";
  id: string;
  vendorId: string;
  name: string | null;
  label: string | null;
  severity: string | null;
  description: string | null;
  status: string | null;
};

export type ListOtherssQuery = {
  __typename: "ModelOthersConnection";
  items: Array<{
    __typename: "Others";
    id: string;
    vendorId: string;
    name: string | null;
    label: string | null;
    severity: string | null;
    description: string | null;
    status: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type OthersByVendorIdAndStatusQuery = {
  __typename: "ModelOthersConnection";
  items: Array<{
    __typename: "Others";
    id: string;
    vendorId: string;
    name: string | null;
    label: string | null;
    severity: string | null;
    description: string | null;
    status: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetQuestionnaireCompletionQuery = {
  __typename: "QuestionnaireCompletion";
  id: string | null;
  totalQuestions: number | null;
  completedQuestions: number | null;
  userId: string;
  assessmentId: string;
  assignmentMap: string | null;
  firstAnswer: number | null;
  lastAnswer: number | null;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
  assessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type ListQuestionnaireCompletionsQuery = {
  __typename: "ModelQuestionnaireCompletionConnection";
  items: Array<{
    __typename: "QuestionnaireCompletion";
    id: string | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    userId: string;
    assessmentId: string;
    assignmentMap: string | null;
    firstAnswer: number | null;
    lastAnswer: number | null;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
    assessment: {
      __typename: "Assessment";
      id: string;
      standardList: Array<{
        __typename: "StandardFramework";
        id: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
      } | null> | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      rootId: string;
      childId: string;
      scores: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
      left: number | null;
      right: number | null;
      managers: Array<string | null> | null;
      exports: {
        __typename: "Exports";
        jiraEntityIds: Array<string | null> | null;
      } | null;
      standardFrameworkList: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          assessmentId: string;
          managerId: string | null;
          assessment: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left: number | null;
            right: number | null;
            managers: Array<string | null> | null;
          } | null;
          managers: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type CompletionByAssessmentQuery = {
  __typename: "ModelQuestionnaireCompletionConnection";
  items: Array<{
    __typename: "QuestionnaireCompletion";
    id: string | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    userId: string;
    assessmentId: string;
    assignmentMap: string | null;
    firstAnswer: number | null;
    lastAnswer: number | null;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
    assessment: {
      __typename: "Assessment";
      id: string;
      standardList: Array<{
        __typename: "StandardFramework";
        id: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
      } | null> | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      rootId: string;
      childId: string;
      scores: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
      left: number | null;
      right: number | null;
      managers: Array<string | null> | null;
      exports: {
        __typename: "Exports";
        jiraEntityIds: Array<string | null> | null;
      } | null;
      standardFrameworkList: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          assessmentId: string;
          managerId: string | null;
          assessment: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left: number | null;
            right: number | null;
            managers: Array<string | null> | null;
          } | null;
          managers: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type CompletionByUserQuery = {
  __typename: "ModelQuestionnaireCompletionConnection";
  items: Array<{
    __typename: "QuestionnaireCompletion";
    id: string | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    userId: string;
    assessmentId: string;
    assignmentMap: string | null;
    firstAnswer: number | null;
    lastAnswer: number | null;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
    assessment: {
      __typename: "Assessment";
      id: string;
      standardList: Array<{
        __typename: "StandardFramework";
        id: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
      } | null> | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      rootId: string;
      childId: string;
      scores: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
      left: number | null;
      right: number | null;
      managers: Array<string | null> | null;
      exports: {
        __typename: "Exports";
        jiraEntityIds: Array<string | null> | null;
      } | null;
      standardFrameworkList: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          assessmentId: string;
          managerId: string | null;
          assessment: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left: number | null;
            right: number | null;
            managers: Array<string | null> | null;
          } | null;
          managers: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetAnswerQuery = {
  __typename: "Answer";
  id: string;
  createdAt: string | null;
  answer: number | null;
  comment: string | null;
  file: Array<{
    __typename: "S3File";
    id: string | null;
    name: string;
    names: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date: number | null;
    contentType: string | null;
    length: number | null;
    key: string | null;
  } | null> | null;
  userId: string;
  riskAction: RiskAction | null;
  frameworkName: string;
  questionId: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken: boolean | null;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type ListAnswersQuery = {
  __typename: "ModelAnswerConnection";
  items: Array<{
    __typename: "Answer";
    id: string;
    createdAt: string | null;
    answer: number | null;
    comment: string | null;
    file: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    userId: string;
    riskAction: RiskAction | null;
    frameworkName: string;
    questionId: string | null;
    date: number;
    assessmentId: string;
    assignmentID: string;
    left: number;
    right: number;
    isActionTaken: boolean | null;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type AnswersByQuestionIdAndUserQuery = {
  __typename: "ModelAnswerConnection";
  items: Array<{
    __typename: "Answer";
    id: string;
    createdAt: string | null;
    answer: number | null;
    comment: string | null;
    file: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    userId: string;
    riskAction: RiskAction | null;
    frameworkName: string;
    questionId: string | null;
    date: number;
    assessmentId: string;
    assignmentID: string;
    left: number;
    right: number;
    isActionTaken: boolean | null;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type AnswersByAssessmentIdAndUserIdQuery = {
  __typename: "ModelAnswerConnection";
  items: Array<{
    __typename: "Answer";
    id: string;
    createdAt: string | null;
    answer: number | null;
    comment: string | null;
    file: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    userId: string;
    riskAction: RiskAction | null;
    frameworkName: string;
    questionId: string | null;
    date: number;
    assessmentId: string;
    assignmentID: string;
    left: number;
    right: number;
    isActionTaken: boolean | null;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type AnswersByAssessmentQuestionUserQuery = {
  __typename: "ModelAnswerConnection";
  items: Array<{
    __typename: "Answer";
    id: string;
    createdAt: string | null;
    answer: number | null;
    comment: string | null;
    file: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    userId: string;
    riskAction: RiskAction | null;
    frameworkName: string;
    questionId: string | null;
    date: number;
    assessmentId: string;
    assignmentID: string;
    left: number;
    right: number;
    isActionTaken: boolean | null;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type AnswersByUserIdQuery = {
  __typename: "ModelAnswerConnection";
  items: Array<{
    __typename: "Answer";
    id: string;
    createdAt: string | null;
    answer: number | null;
    comment: string | null;
    file: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    userId: string;
    riskAction: RiskAction | null;
    frameworkName: string;
    questionId: string | null;
    date: number;
    assessmentId: string;
    assignmentID: string;
    left: number;
    right: number;
    isActionTaken: boolean | null;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetAssignmentQuery = {
  __typename: "Assignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  left: number;
  right: number;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type ListAssignmentsQuery = {
  __typename: "ModelAssignmentConnection";
  items: Array<{
    __typename: "Assignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    left: number;
    right: number;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type AssignmentsByUserIdQuery = {
  __typename: "ModelAssignmentConnection";
  items: Array<{
    __typename: "Assignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    left: number;
    right: number;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type AssignmentsByAssessmentIdQuery = {
  __typename: "ModelAssignmentConnection";
  items: Array<{
    __typename: "Assignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    left: number;
    right: number;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type AssignmentsByUserIdAndAssessmentQuery = {
  __typename: "ModelAssignmentConnection";
  items: Array<{
    __typename: "Assignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    left: number;
    right: number;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListAssessmentsQuery = {
  __typename: "ModelAssessmentConnection";
  items: Array<{
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetAssessmentQuery = {
  __typename: "Assessment";
  id: string;
  standardList: Array<{
    __typename: "StandardFramework";
    id: string;
    type: StandardType | null;
    fileName: string | null;
    filter: {
      __typename: "StandardFilter";
      impact: ImpactEnum | null;
    } | null;
  } | null> | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  rootId: string;
  childId: string;
  scores: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  left: number | null;
  right: number | null;
  managers: Array<string | null> | null;
  exports: {
    __typename: "Exports";
    jiraEntityIds: Array<string | null> | null;
  } | null;
  standardFrameworkList: {
    __typename: "ModelAssessmentStandardFrameworkConnection";
    items: Array<{
      __typename: "AssessmentStandardFramework";
      id: string;
      key: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      assessmentId: string;
      managerId: string | null;
      assessment: {
        __typename: "Assessment";
        id: string;
        standardList: Array<{
          __typename: "StandardFramework";
          id: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
        } | null> | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        rootId: string;
        childId: string;
        scores: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
        left: number | null;
        right: number | null;
        managers: Array<string | null> | null;
        exports: {
          __typename: "Exports";
          jiraEntityIds: Array<string | null> | null;
        } | null;
        standardFrameworkList: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type: StandardType | null;
            fileName: string | null;
            assessmentId: string;
            managerId: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null;
      managers: {
        __typename: "ModelFrameworkManagerConnection";
        items: Array<{
          __typename: "FrameworkManager";
          id: string;
          assessmentId: string;
          standardFrameworkId: string;
          managerId: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type AssessmentsByRootIdQuery = {
  __typename: "ModelAssessmentConnection";
  items: Array<{
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type AssessmentsByChildIdQuery = {
  __typename: "ModelAssessmentConnection";
  items: Array<{
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetIntegrationMappingsQuery = {
  __typename: "IntegrationMappings";
  id: string;
  name: string;
  projectKey: string | null;
  fileName: string | null;
};

export type ListIntegrationMappingssQuery = {
  __typename: "ModelIntegrationMappingsConnection";
  items: Array<{
    __typename: "IntegrationMappings";
    id: string;
    name: string;
    projectKey: string | null;
    fileName: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type IntegrationMappingsByProjectKeyQuery = {
  __typename: "ModelIntegrationMappingsConnection";
  items: Array<{
    __typename: "IntegrationMappings";
    id: string;
    name: string;
    projectKey: string | null;
    fileName: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetQuestionSettingsQuery = {
  __typename: "QuestionSettings";
  questionId: string;
  assessmentId: string;
  isCollaborative: boolean | null;
  isArtifactsMandatory: boolean | null;
  isApprovalRequired: boolean | null;
};

export type ListQuestionSettingssQuery = {
  __typename: "ModelQuestionSettingsConnection";
  items: Array<{
    __typename: "QuestionSettings";
    questionId: string;
    assessmentId: string;
    isCollaborative: boolean | null;
    isArtifactsMandatory: boolean | null;
    isApprovalRequired: boolean | null;
  } | null> | null;
  nextToken: string | null;
};

export type QuestionSettingsByAssessmentIdQuery = {
  __typename: "ModelQuestionSettingsConnection";
  items: Array<{
    __typename: "QuestionSettings";
    questionId: string;
    assessmentId: string;
    isCollaborative: boolean | null;
    isArtifactsMandatory: boolean | null;
    isApprovalRequired: boolean | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetCustomTaskQuery = {
  __typename: "CustomTask";
  id: string;
  name: string | null;
  label: string | null;
  impact: ImpactEnum | null;
  description: string | null;
  standardsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  status: TaskStatusEnum | null;
  assessmentId: string;
};

export type ListCustomTasksQuery = {
  __typename: "ModelCustomTaskConnection";
  items: Array<{
    __typename: "CustomTask";
    id: string;
    name: string | null;
    label: string | null;
    impact: ImpactEnum | null;
    description: string | null;
    standardsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    status: TaskStatusEnum | null;
    assessmentId: string;
  } | null> | null;
  nextToken: string | null;
};

export type CustomTasksByAssessmentIdQuery = {
  __typename: "ModelCustomTaskConnection";
  items: Array<{
    __typename: "CustomTask";
    id: string;
    name: string | null;
    label: string | null;
    impact: ImpactEnum | null;
    description: string | null;
    standardsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    status: TaskStatusEnum | null;
    assessmentId: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetCustomTaskAssignmentQuery = {
  __typename: "CustomTaskAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type ListCustomTaskAssignmentsQuery = {
  __typename: "ModelCustomTaskAssignmentConnection";
  items: Array<{
    __typename: "CustomTaskAssignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type CustomTaskAssignmentsByAssessmentIdQuery = {
  __typename: "ModelCustomTaskAssignmentConnection";
  items: Array<{
    __typename: "CustomTaskAssignment";
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user: {
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetAssessmentStandardFrameworkQuery = {
  __typename: "AssessmentStandardFramework";
  id: string;
  key: string;
  type: StandardType | null;
  fileName: string | null;
  filter: {
    __typename: "StandardFilter";
    impact: ImpactEnum | null;
  } | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  assessmentId: string;
  managerId: string | null;
  assessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
  managers: {
    __typename: "ModelFrameworkManagerConnection";
    items: Array<{
      __typename: "FrameworkManager";
      id: string;
      assessmentId: string;
      standardFrameworkId: string;
      managerId: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type ListAssessmentStandardFrameworksQuery = {
  __typename: "ModelAssessmentStandardFrameworkConnection";
  items: Array<{
    __typename: "AssessmentStandardFramework";
    id: string;
    key: string;
    type: StandardType | null;
    fileName: string | null;
    filter: {
      __typename: "StandardFilter";
      impact: ImpactEnum | null;
    } | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    assessmentId: string;
    managerId: string | null;
    assessment: {
      __typename: "Assessment";
      id: string;
      standardList: Array<{
        __typename: "StandardFramework";
        id: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
      } | null> | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      rootId: string;
      childId: string;
      scores: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
      left: number | null;
      right: number | null;
      managers: Array<string | null> | null;
      exports: {
        __typename: "Exports";
        jiraEntityIds: Array<string | null> | null;
      } | null;
      standardFrameworkList: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          assessmentId: string;
          managerId: string | null;
          assessment: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left: number | null;
            right: number | null;
            managers: Array<string | null> | null;
          } | null;
          managers: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
    managers: {
      __typename: "ModelFrameworkManagerConnection";
      items: Array<{
        __typename: "FrameworkManager";
        id: string;
        assessmentId: string;
        standardFrameworkId: string;
        managerId: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type StandardFrameworksByAssessmentIdQuery = {
  __typename: "ModelAssessmentStandardFrameworkConnection";
  items: Array<{
    __typename: "AssessmentStandardFramework";
    id: string;
    key: string;
    type: StandardType | null;
    fileName: string | null;
    filter: {
      __typename: "StandardFilter";
      impact: ImpactEnum | null;
    } | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    assessmentId: string;
    managerId: string | null;
    assessment: {
      __typename: "Assessment";
      id: string;
      standardList: Array<{
        __typename: "StandardFramework";
        id: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
      } | null> | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      rootId: string;
      childId: string;
      scores: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
      left: number | null;
      right: number | null;
      managers: Array<string | null> | null;
      exports: {
        __typename: "Exports";
        jiraEntityIds: Array<string | null> | null;
      } | null;
      standardFrameworkList: {
        __typename: "ModelAssessmentStandardFrameworkConnection";
        items: Array<{
          __typename: "AssessmentStandardFramework";
          id: string;
          key: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          assessmentId: string;
          managerId: string | null;
          assessment: {
            __typename: "Assessment";
            id: string;
            rootId: string;
            childId: string;
            left: number | null;
            right: number | null;
            managers: Array<string | null> | null;
          } | null;
          managers: {
            __typename: "ModelFrameworkManagerConnection";
            nextToken: string | null;
          } | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
    managers: {
      __typename: "ModelFrameworkManagerConnection";
      items: Array<{
        __typename: "FrameworkManager";
        id: string;
        assessmentId: string;
        standardFrameworkId: string;
        managerId: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetFrameworkManagerQuery = {
  __typename: "FrameworkManager";
  id: string;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
};

export type ListFrameworkManagersQuery = {
  __typename: "ModelFrameworkManagerConnection";
  items: Array<{
    __typename: "FrameworkManager";
    id: string;
    assessmentId: string;
    standardFrameworkId: string;
    managerId: string;
  } | null> | null;
  nextToken: string | null;
};

export type FrameworkManagersByAssessmentIdQuery = {
  __typename: "ModelFrameworkManagerConnection";
  items: Array<{
    __typename: "FrameworkManager";
    id: string;
    assessmentId: string;
    standardFrameworkId: string;
    managerId: string;
  } | null> | null;
  nextToken: string | null;
};

export type FrameworkManagersByStandardFrameworkIdQuery = {
  __typename: "ModelFrameworkManagerConnection";
  items: Array<{
    __typename: "FrameworkManager";
    id: string;
    assessmentId: string;
    standardFrameworkId: string;
    managerId: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetCommentQuery = {
  __typename: "Comment";
  id: string;
  latestSource: ExportSourceEnum;
  comment: string;
  date: number | null;
  questionId: string | null;
  assessmentId: string | null;
  userId: string;
  user: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  exports: {
    __typename: "ModelExportConnection";
    items: Array<{
      __typename: "Export";
      id: string;
      destination: ExportSourceEnum;
      destinationCategoryId: string;
      category: CategoryEnum | null;
      categoryId: string;
      date: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type ListCommentsQuery = {
  __typename: "ModelCommentConnection";
  items: Array<{
    __typename: "Comment";
    id: string;
    latestSource: ExportSourceEnum;
    comment: string;
    date: number | null;
    questionId: string | null;
    assessmentId: string | null;
    userId: string;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role: RoleEnum;
        phone: string | null;
        entityId: string | null;
        isCognitoUser: boolean | null;
        questionMap: string | null;
        createdBy: string | null;
        createdAt: number | null;
        lastLogin: number | null;
        invitationDate: number | null;
        reminderDate: number | null;
        modifiedBy: string | null;
        modifiedAt: number | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    exports: {
      __typename: "ModelExportConnection";
      items: Array<{
        __typename: "Export";
        id: string;
        destination: ExportSourceEnum;
        destinationCategoryId: string;
        category: CategoryEnum | null;
        categoryId: string;
        date: number | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type CommentByAssessmentIdQuery = {
  __typename: "ModelCommentConnection";
  items: Array<{
    __typename: "Comment";
    id: string;
    latestSource: ExportSourceEnum;
    comment: string;
    date: number | null;
    questionId: string | null;
    assessmentId: string | null;
    userId: string;
    user: {
      __typename: "ModelUserConnection";
      items: Array<{
        __typename: "User";
        id: string;
        name: string;
        email: string;
        role: RoleEnum;
        phone: string | null;
        entityId: string | null;
        isCognitoUser: boolean | null;
        questionMap: string | null;
        createdBy: string | null;
        createdAt: number | null;
        lastLogin: number | null;
        invitationDate: number | null;
        reminderDate: number | null;
        modifiedBy: string | null;
        modifiedAt: number | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    exports: {
      __typename: "ModelExportConnection";
      items: Array<{
        __typename: "Export";
        id: string;
        destination: ExportSourceEnum;
        destinationCategoryId: string;
        category: CategoryEnum | null;
        categoryId: string;
        date: number | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetExportQuery = {
  __typename: "Export";
  id: string;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category: CategoryEnum | null;
  categoryId: string;
  date: number | null;
};

export type ListExportsQuery = {
  __typename: "ModelExportConnection";
  items: Array<{
    __typename: "Export";
    id: string;
    destination: ExportSourceEnum;
    destinationCategoryId: string;
    category: CategoryEnum | null;
    categoryId: string;
    date: number | null;
  } | null> | null;
  nextToken: string | null;
};

export type ExportsByCategoryIdQuery = {
  __typename: "ModelExportConnection";
  items: Array<{
    __typename: "Export";
    id: string;
    destination: ExportSourceEnum;
    destinationCategoryId: string;
    category: CategoryEnum | null;
    categoryId: string;
    date: number | null;
  } | null> | null;
  nextToken: string | null;
};

export type OnFrameworkScoresUpdatedSubscription = {
  __typename: "FrameworkScores";
  id: string;
  assessmentId: string;
  type: StandardType;
  total: number;
  target: number;
  collection: number;
  remediation: number;
  totalQuestions: number;
  completedQuestions: number;
  totalGaps: number;
  criticalGaps: number;
  notApplicable: boolean | null;
  assignmentCount: number | null;
  riskStandardsCount: number | null;
  complianceStandardsCount: number | null;
  standardsCount: number | null;
  top20: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type OnCreateUserSubscription = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role: RoleEnum;
  phone: string | null;
  entityId: string | null;
  isCognitoUser: boolean | null;
  questionMap: string | null;
  createdBy: string | null;
  createdAt: number | null;
  lastLogin: number | null;
  invitationDate: number | null;
  reminderDate: number | null;
  modifiedBy: string | null;
  modifiedAt: number | null;
};

export type OnUpdateUserSubscription = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role: RoleEnum;
  phone: string | null;
  entityId: string | null;
  isCognitoUser: boolean | null;
  questionMap: string | null;
  createdBy: string | null;
  createdAt: number | null;
  lastLogin: number | null;
  invitationDate: number | null;
  reminderDate: number | null;
  modifiedBy: string | null;
  modifiedAt: number | null;
};

export type OnDeleteUserSubscription = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  role: RoleEnum;
  phone: string | null;
  entityId: string | null;
  isCognitoUser: boolean | null;
  questionMap: string | null;
  createdBy: string | null;
  createdAt: number | null;
  lastLogin: number | null;
  invitationDate: number | null;
  reminderDate: number | null;
  modifiedBy: string | null;
  modifiedAt: number | null;
};

export type OnCreateEntitySubscription = {
  __typename: "Entity";
  id: string;
  name: string;
  users: Array<string | null> | null;
  completionStatus: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId: string | null;
  childEntityId: string | null;
  frameworkId: string | null;
  frameworkName: string | null;
  frameworkType: EntityTypeEnum | null;
  parentId: string | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  scores: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  entityType: EntityTypeEnum;
  projectManager: string | null;
  projectManagerName: string | null;
  projectDeadline: number | null;
  logo: {
    __typename: "S3File";
    id: string | null;
    name: string;
    names: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date: number | null;
    contentType: string | null;
    length: number | null;
    key: string | null;
  } | null;
  integrations: Array<{
    __typename: "Integrations";
    name: string;
    credentials: string | null;
    isEnable: boolean | null;
    isAuth: boolean | null;
    inheritFromParent: boolean | null;
  } | null> | null;
  externalFeed: Array<{
    __typename: "Integrations";
    name: string;
    credentials: string | null;
    isEnable: boolean | null;
    isAuth: boolean | null;
    inheritFromParent: boolean | null;
  } | null> | null;
  defaultSetting: {
    __typename: "DefaultSetting";
    dueDateInDays: number | null;
    frequency: string | null;
    surveyLevel: string | null;
    artifacts: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    standards: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    accessLevel: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
  } | null;
  createdBy: string | null;
  createdAt: number | null;
  modifiedBy: string | null;
  modifiedAt: number | null;
  industry: IndustryEnum | null;
  domain: string;
  activeAssessmentId: string;
  vendorDetails: {
    __typename: "VendorsDetail";
    id: string;
    impact: number | null;
    externalScan: number | null;
    financialRisk: number | null;
    criticalGaps: number | null;
    standardsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    status: VendorStatusEnum | null;
    probability: number | null;
    website: string | null;
    location: string | null;
    size: string | null;
    accessLevel: AccessLevelEnum | null;
    collectionStatus: CollectionStatusEnum | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    approvalDate: number | null;
    questionnaires: Array<{
      __typename: "Questionnaire";
      tag: string | null;
      status: {
        __typename: "QuestionnaireStatus";
        totalQuestions: number | null;
        completedQuestions: number | null;
      } | null;
      criticalGaps: Array<string | null> | null;
      allGaps: string | null;
    } | null> | null;
    artifacts: {
      __typename: "Artifacts";
      documents: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      scans: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      insurances: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      certifications: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
    } | null;
    primaryPoc: {
      __typename: "ShortRef";
      name: string | null;
      id: string | null;
      email: string | null;
    } | null;
    stage: VendorStatusEnum | null;
    activeScan: boolean | null;
    domain: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    intelligence: {
      __typename: "Intelligence";
      id: string;
      breaches: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date: number | null;
          name: string | null;
          description: string | null;
          leaks: number | null;
          data: Array<string | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      externalThreats: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source: string | null;
          findings: Array<string | null> | null;
          riskScore: string | null;
          details: Array<{
            __typename: "ThreatDetails";
            ip: string | null;
            port: number | null;
            cve: string | null;
            cvss: number | null;
          } | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      others: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name: string | null;
          label: string | null;
          severity: string | null;
          description: string | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
  activeAssessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnUpdateEntitySubscription = {
  __typename: "Entity";
  id: string;
  name: string;
  users: Array<string | null> | null;
  completionStatus: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId: string | null;
  childEntityId: string | null;
  frameworkId: string | null;
  frameworkName: string | null;
  frameworkType: EntityTypeEnum | null;
  parentId: string | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  scores: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  entityType: EntityTypeEnum;
  projectManager: string | null;
  projectManagerName: string | null;
  projectDeadline: number | null;
  logo: {
    __typename: "S3File";
    id: string | null;
    name: string;
    names: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date: number | null;
    contentType: string | null;
    length: number | null;
    key: string | null;
  } | null;
  integrations: Array<{
    __typename: "Integrations";
    name: string;
    credentials: string | null;
    isEnable: boolean | null;
    isAuth: boolean | null;
    inheritFromParent: boolean | null;
  } | null> | null;
  externalFeed: Array<{
    __typename: "Integrations";
    name: string;
    credentials: string | null;
    isEnable: boolean | null;
    isAuth: boolean | null;
    inheritFromParent: boolean | null;
  } | null> | null;
  defaultSetting: {
    __typename: "DefaultSetting";
    dueDateInDays: number | null;
    frequency: string | null;
    surveyLevel: string | null;
    artifacts: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    standards: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    accessLevel: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
  } | null;
  createdBy: string | null;
  createdAt: number | null;
  modifiedBy: string | null;
  modifiedAt: number | null;
  industry: IndustryEnum | null;
  domain: string;
  activeAssessmentId: string;
  vendorDetails: {
    __typename: "VendorsDetail";
    id: string;
    impact: number | null;
    externalScan: number | null;
    financialRisk: number | null;
    criticalGaps: number | null;
    standardsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    status: VendorStatusEnum | null;
    probability: number | null;
    website: string | null;
    location: string | null;
    size: string | null;
    accessLevel: AccessLevelEnum | null;
    collectionStatus: CollectionStatusEnum | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    approvalDate: number | null;
    questionnaires: Array<{
      __typename: "Questionnaire";
      tag: string | null;
      status: {
        __typename: "QuestionnaireStatus";
        totalQuestions: number | null;
        completedQuestions: number | null;
      } | null;
      criticalGaps: Array<string | null> | null;
      allGaps: string | null;
    } | null> | null;
    artifacts: {
      __typename: "Artifacts";
      documents: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      scans: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      insurances: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      certifications: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
    } | null;
    primaryPoc: {
      __typename: "ShortRef";
      name: string | null;
      id: string | null;
      email: string | null;
    } | null;
    stage: VendorStatusEnum | null;
    activeScan: boolean | null;
    domain: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    intelligence: {
      __typename: "Intelligence";
      id: string;
      breaches: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date: number | null;
          name: string | null;
          description: string | null;
          leaks: number | null;
          data: Array<string | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      externalThreats: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source: string | null;
          findings: Array<string | null> | null;
          riskScore: string | null;
          details: Array<{
            __typename: "ThreatDetails";
            ip: string | null;
            port: number | null;
            cve: string | null;
            cvss: number | null;
          } | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      others: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name: string | null;
          label: string | null;
          severity: string | null;
          description: string | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
  activeAssessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnDeleteEntitySubscription = {
  __typename: "Entity";
  id: string;
  name: string;
  users: Array<string | null> | null;
  completionStatus: CollectionStatusEnum | null;
  accessGroups: Array<string>;
  participantGroup: Array<string>;
  rootEntityId: string | null;
  childEntityId: string | null;
  frameworkId: string | null;
  frameworkName: string | null;
  frameworkType: EntityTypeEnum | null;
  parentId: string | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  scores: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  entityType: EntityTypeEnum;
  projectManager: string | null;
  projectManagerName: string | null;
  projectDeadline: number | null;
  logo: {
    __typename: "S3File";
    id: string | null;
    name: string;
    names: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date: number | null;
    contentType: string | null;
    length: number | null;
    key: string | null;
  } | null;
  integrations: Array<{
    __typename: "Integrations";
    name: string;
    credentials: string | null;
    isEnable: boolean | null;
    isAuth: boolean | null;
    inheritFromParent: boolean | null;
  } | null> | null;
  externalFeed: Array<{
    __typename: "Integrations";
    name: string;
    credentials: string | null;
    isEnable: boolean | null;
    isAuth: boolean | null;
    inheritFromParent: boolean | null;
  } | null> | null;
  defaultSetting: {
    __typename: "DefaultSetting";
    dueDateInDays: number | null;
    frequency: string | null;
    surveyLevel: string | null;
    artifacts: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    standards: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    accessLevel: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
  } | null;
  createdBy: string | null;
  createdAt: number | null;
  modifiedBy: string | null;
  modifiedAt: number | null;
  industry: IndustryEnum | null;
  domain: string;
  activeAssessmentId: string;
  vendorDetails: {
    __typename: "VendorsDetail";
    id: string;
    impact: number | null;
    externalScan: number | null;
    financialRisk: number | null;
    criticalGaps: number | null;
    standardsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    status: VendorStatusEnum | null;
    probability: number | null;
    website: string | null;
    location: string | null;
    size: string | null;
    accessLevel: AccessLevelEnum | null;
    collectionStatus: CollectionStatusEnum | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    approvalDate: number | null;
    questionnaires: Array<{
      __typename: "Questionnaire";
      tag: string | null;
      status: {
        __typename: "QuestionnaireStatus";
        totalQuestions: number | null;
        completedQuestions: number | null;
      } | null;
      criticalGaps: Array<string | null> | null;
      allGaps: string | null;
    } | null> | null;
    artifacts: {
      __typename: "Artifacts";
      documents: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      scans: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      insurances: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
      certifications: Array<{
        __typename: "S3File";
        id: string | null;
        name: string;
        names: string | null;
        entityId: string;
        fileType: FileTypeEnum;
        date: number | null;
        contentType: string | null;
        length: number | null;
        key: string | null;
      } | null> | null;
    } | null;
    primaryPoc: {
      __typename: "ShortRef";
      name: string | null;
      id: string | null;
      email: string | null;
    } | null;
    stage: VendorStatusEnum | null;
    activeScan: boolean | null;
    domain: string | null;
    privacyData: boolean | null;
    impactsQualify: Array<{
      __typename: "Qualification";
      name: string | null;
      isQualify: boolean | null;
    } | null> | null;
    intelligence: {
      __typename: "Intelligence";
      id: string;
      breaches: {
        __typename: "ModelBreachesConnection";
        items: Array<{
          __typename: "Breaches";
          id: string;
          vendorId: string;
          date: number | null;
          name: string | null;
          description: string | null;
          leaks: number | null;
          data: Array<string | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      externalThreats: {
        __typename: "ModelExternalThreatsConnection";
        items: Array<{
          __typename: "ExternalThreats";
          id: string;
          vendorId: string;
          source: string | null;
          findings: Array<string | null> | null;
          riskScore: string | null;
          details: Array<{
            __typename: "ThreatDetails";
            ip: string | null;
            port: number | null;
            cve: string | null;
            cvss: number | null;
          } | null> | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
      others: {
        __typename: "ModelOthersConnection";
        items: Array<{
          __typename: "Others";
          id: string;
          vendorId: string;
          name: string | null;
          label: string | null;
          severity: string | null;
          description: string | null;
          status: string | null;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null;
  } | null;
  activeAssessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnCreateVendorsDetailSubscription = {
  __typename: "VendorsDetail";
  id: string;
  impact: number | null;
  externalScan: number | null;
  financialRisk: number | null;
  criticalGaps: number | null;
  standardsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  status: VendorStatusEnum | null;
  probability: number | null;
  website: string | null;
  location: string | null;
  size: string | null;
  accessLevel: AccessLevelEnum | null;
  collectionStatus: CollectionStatusEnum | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  approvalDate: number | null;
  questionnaires: Array<{
    __typename: "Questionnaire";
    tag: string | null;
    status: {
      __typename: "QuestionnaireStatus";
      totalQuestions: number | null;
      completedQuestions: number | null;
    } | null;
    criticalGaps: Array<string | null> | null;
    allGaps: string | null;
  } | null> | null;
  artifacts: {
    __typename: "Artifacts";
    documents: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    scans: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    insurances: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    certifications: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
  } | null;
  primaryPoc: {
    __typename: "ShortRef";
    name: string | null;
    id: string | null;
    email: string | null;
  } | null;
  stage: VendorStatusEnum | null;
  activeScan: boolean | null;
  domain: string | null;
  privacyData: boolean | null;
  impactsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  intelligence: {
    __typename: "Intelligence";
    id: string;
    breaches: {
      __typename: "ModelBreachesConnection";
      items: Array<{
        __typename: "Breaches";
        id: string;
        vendorId: string;
        date: number | null;
        name: string | null;
        description: string | null;
        leaks: number | null;
        data: Array<string | null> | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    externalThreats: {
      __typename: "ModelExternalThreatsConnection";
      items: Array<{
        __typename: "ExternalThreats";
        id: string;
        vendorId: string;
        source: string | null;
        findings: Array<string | null> | null;
        riskScore: string | null;
        details: Array<{
          __typename: "ThreatDetails";
          ip: string | null;
          port: number | null;
          cve: string | null;
          cvss: number | null;
        } | null> | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    others: {
      __typename: "ModelOthersConnection";
      items: Array<{
        __typename: "Others";
        id: string;
        vendorId: string;
        name: string | null;
        label: string | null;
        severity: string | null;
        description: string | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnUpdateVendorsDetailSubscription = {
  __typename: "VendorsDetail";
  id: string;
  impact: number | null;
  externalScan: number | null;
  financialRisk: number | null;
  criticalGaps: number | null;
  standardsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  status: VendorStatusEnum | null;
  probability: number | null;
  website: string | null;
  location: string | null;
  size: string | null;
  accessLevel: AccessLevelEnum | null;
  collectionStatus: CollectionStatusEnum | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  approvalDate: number | null;
  questionnaires: Array<{
    __typename: "Questionnaire";
    tag: string | null;
    status: {
      __typename: "QuestionnaireStatus";
      totalQuestions: number | null;
      completedQuestions: number | null;
    } | null;
    criticalGaps: Array<string | null> | null;
    allGaps: string | null;
  } | null> | null;
  artifacts: {
    __typename: "Artifacts";
    documents: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    scans: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    insurances: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    certifications: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
  } | null;
  primaryPoc: {
    __typename: "ShortRef";
    name: string | null;
    id: string | null;
    email: string | null;
  } | null;
  stage: VendorStatusEnum | null;
  activeScan: boolean | null;
  domain: string | null;
  privacyData: boolean | null;
  impactsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  intelligence: {
    __typename: "Intelligence";
    id: string;
    breaches: {
      __typename: "ModelBreachesConnection";
      items: Array<{
        __typename: "Breaches";
        id: string;
        vendorId: string;
        date: number | null;
        name: string | null;
        description: string | null;
        leaks: number | null;
        data: Array<string | null> | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    externalThreats: {
      __typename: "ModelExternalThreatsConnection";
      items: Array<{
        __typename: "ExternalThreats";
        id: string;
        vendorId: string;
        source: string | null;
        findings: Array<string | null> | null;
        riskScore: string | null;
        details: Array<{
          __typename: "ThreatDetails";
          ip: string | null;
          port: number | null;
          cve: string | null;
          cvss: number | null;
        } | null> | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    others: {
      __typename: "ModelOthersConnection";
      items: Array<{
        __typename: "Others";
        id: string;
        vendorId: string;
        name: string | null;
        label: string | null;
        severity: string | null;
        description: string | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnDeleteVendorsDetailSubscription = {
  __typename: "VendorsDetail";
  id: string;
  impact: number | null;
  externalScan: number | null;
  financialRisk: number | null;
  criticalGaps: number | null;
  standardsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  status: VendorStatusEnum | null;
  probability: number | null;
  website: string | null;
  location: string | null;
  size: string | null;
  accessLevel: AccessLevelEnum | null;
  collectionStatus: CollectionStatusEnum | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  approvalDate: number | null;
  questionnaires: Array<{
    __typename: "Questionnaire";
    tag: string | null;
    status: {
      __typename: "QuestionnaireStatus";
      totalQuestions: number | null;
      completedQuestions: number | null;
    } | null;
    criticalGaps: Array<string | null> | null;
    allGaps: string | null;
  } | null> | null;
  artifacts: {
    __typename: "Artifacts";
    documents: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    scans: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    insurances: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
    certifications: Array<{
      __typename: "S3File";
      id: string | null;
      name: string;
      names: string | null;
      entityId: string;
      fileType: FileTypeEnum;
      date: number | null;
      contentType: string | null;
      length: number | null;
      key: string | null;
    } | null> | null;
  } | null;
  primaryPoc: {
    __typename: "ShortRef";
    name: string | null;
    id: string | null;
    email: string | null;
  } | null;
  stage: VendorStatusEnum | null;
  activeScan: boolean | null;
  domain: string | null;
  privacyData: boolean | null;
  impactsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  intelligence: {
    __typename: "Intelligence";
    id: string;
    breaches: {
      __typename: "ModelBreachesConnection";
      items: Array<{
        __typename: "Breaches";
        id: string;
        vendorId: string;
        date: number | null;
        name: string | null;
        description: string | null;
        leaks: number | null;
        data: Array<string | null> | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    externalThreats: {
      __typename: "ModelExternalThreatsConnection";
      items: Array<{
        __typename: "ExternalThreats";
        id: string;
        vendorId: string;
        source: string | null;
        findings: Array<string | null> | null;
        riskScore: string | null;
        details: Array<{
          __typename: "ThreatDetails";
          ip: string | null;
          port: number | null;
          cve: string | null;
          cvss: number | null;
        } | null> | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
    others: {
      __typename: "ModelOthersConnection";
      items: Array<{
        __typename: "Others";
        id: string;
        vendorId: string;
        name: string | null;
        label: string | null;
        severity: string | null;
        description: string | null;
        status: string | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnCreateIntelligenceSubscription = {
  __typename: "Intelligence";
  id: string;
  breaches: {
    __typename: "ModelBreachesConnection";
    items: Array<{
      __typename: "Breaches";
      id: string;
      vendorId: string;
      date: number | null;
      name: string | null;
      description: string | null;
      leaks: number | null;
      data: Array<string | null> | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  externalThreats: {
    __typename: "ModelExternalThreatsConnection";
    items: Array<{
      __typename: "ExternalThreats";
      id: string;
      vendorId: string;
      source: string | null;
      findings: Array<string | null> | null;
      riskScore: string | null;
      details: Array<{
        __typename: "ThreatDetails";
        ip: string | null;
        port: number | null;
        cve: string | null;
        cvss: number | null;
      } | null> | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  others: {
    __typename: "ModelOthersConnection";
    items: Array<{
      __typename: "Others";
      id: string;
      vendorId: string;
      name: string | null;
      label: string | null;
      severity: string | null;
      description: string | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnUpdateIntelligenceSubscription = {
  __typename: "Intelligence";
  id: string;
  breaches: {
    __typename: "ModelBreachesConnection";
    items: Array<{
      __typename: "Breaches";
      id: string;
      vendorId: string;
      date: number | null;
      name: string | null;
      description: string | null;
      leaks: number | null;
      data: Array<string | null> | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  externalThreats: {
    __typename: "ModelExternalThreatsConnection";
    items: Array<{
      __typename: "ExternalThreats";
      id: string;
      vendorId: string;
      source: string | null;
      findings: Array<string | null> | null;
      riskScore: string | null;
      details: Array<{
        __typename: "ThreatDetails";
        ip: string | null;
        port: number | null;
        cve: string | null;
        cvss: number | null;
      } | null> | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  others: {
    __typename: "ModelOthersConnection";
    items: Array<{
      __typename: "Others";
      id: string;
      vendorId: string;
      name: string | null;
      label: string | null;
      severity: string | null;
      description: string | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnDeleteIntelligenceSubscription = {
  __typename: "Intelligence";
  id: string;
  breaches: {
    __typename: "ModelBreachesConnection";
    items: Array<{
      __typename: "Breaches";
      id: string;
      vendorId: string;
      date: number | null;
      name: string | null;
      description: string | null;
      leaks: number | null;
      data: Array<string | null> | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  externalThreats: {
    __typename: "ModelExternalThreatsConnection";
    items: Array<{
      __typename: "ExternalThreats";
      id: string;
      vendorId: string;
      source: string | null;
      findings: Array<string | null> | null;
      riskScore: string | null;
      details: Array<{
        __typename: "ThreatDetails";
        ip: string | null;
        port: number | null;
        cve: string | null;
        cvss: number | null;
      } | null> | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  others: {
    __typename: "ModelOthersConnection";
    items: Array<{
      __typename: "Others";
      id: string;
      vendorId: string;
      name: string | null;
      label: string | null;
      severity: string | null;
      description: string | null;
      status: string | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnCreateBreachesSubscription = {
  __typename: "Breaches";
  id: string;
  vendorId: string;
  date: number | null;
  name: string | null;
  description: string | null;
  leaks: number | null;
  data: Array<string | null> | null;
  status: string | null;
};

export type OnUpdateBreachesSubscription = {
  __typename: "Breaches";
  id: string;
  vendorId: string;
  date: number | null;
  name: string | null;
  description: string | null;
  leaks: number | null;
  data: Array<string | null> | null;
  status: string | null;
};

export type OnDeleteBreachesSubscription = {
  __typename: "Breaches";
  id: string;
  vendorId: string;
  date: number | null;
  name: string | null;
  description: string | null;
  leaks: number | null;
  data: Array<string | null> | null;
  status: string | null;
};

export type OnCreateExternalThreatsSubscription = {
  __typename: "ExternalThreats";
  id: string;
  vendorId: string;
  source: string | null;
  findings: Array<string | null> | null;
  riskScore: string | null;
  details: Array<{
    __typename: "ThreatDetails";
    ip: string | null;
    port: number | null;
    cve: string | null;
    cvss: number | null;
  } | null> | null;
  status: string | null;
};

export type OnUpdateExternalThreatsSubscription = {
  __typename: "ExternalThreats";
  id: string;
  vendorId: string;
  source: string | null;
  findings: Array<string | null> | null;
  riskScore: string | null;
  details: Array<{
    __typename: "ThreatDetails";
    ip: string | null;
    port: number | null;
    cve: string | null;
    cvss: number | null;
  } | null> | null;
  status: string | null;
};

export type OnDeleteExternalThreatsSubscription = {
  __typename: "ExternalThreats";
  id: string;
  vendorId: string;
  source: string | null;
  findings: Array<string | null> | null;
  riskScore: string | null;
  details: Array<{
    __typename: "ThreatDetails";
    ip: string | null;
    port: number | null;
    cve: string | null;
    cvss: number | null;
  } | null> | null;
  status: string | null;
};

export type OnCreateOthersSubscription = {
  __typename: "Others";
  id: string;
  vendorId: string;
  name: string | null;
  label: string | null;
  severity: string | null;
  description: string | null;
  status: string | null;
};

export type OnUpdateOthersSubscription = {
  __typename: "Others";
  id: string;
  vendorId: string;
  name: string | null;
  label: string | null;
  severity: string | null;
  description: string | null;
  status: string | null;
};

export type OnDeleteOthersSubscription = {
  __typename: "Others";
  id: string;
  vendorId: string;
  name: string | null;
  label: string | null;
  severity: string | null;
  description: string | null;
  status: string | null;
};

export type OnCreateQuestionnaireCompletionSubscription = {
  __typename: "QuestionnaireCompletion";
  id: string | null;
  totalQuestions: number | null;
  completedQuestions: number | null;
  userId: string;
  assessmentId: string;
  assignmentMap: string | null;
  firstAnswer: number | null;
  lastAnswer: number | null;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
  assessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnUpdateQuestionnaireCompletionSubscription = {
  __typename: "QuestionnaireCompletion";
  id: string | null;
  totalQuestions: number | null;
  completedQuestions: number | null;
  userId: string;
  assessmentId: string;
  assignmentMap: string | null;
  firstAnswer: number | null;
  lastAnswer: number | null;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
  assessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnDeleteQuestionnaireCompletionSubscription = {
  __typename: "QuestionnaireCompletion";
  id: string | null;
  totalQuestions: number | null;
  completedQuestions: number | null;
  userId: string;
  assessmentId: string;
  assignmentMap: string | null;
  firstAnswer: number | null;
  lastAnswer: number | null;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
  assessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
};

export type OnCreateAnswerSubscription = {
  __typename: "Answer";
  id: string;
  createdAt: string | null;
  answer: number | null;
  comment: string | null;
  file: Array<{
    __typename: "S3File";
    id: string | null;
    name: string;
    names: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date: number | null;
    contentType: string | null;
    length: number | null;
    key: string | null;
  } | null> | null;
  userId: string;
  riskAction: RiskAction | null;
  frameworkName: string;
  questionId: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken: boolean | null;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type OnUpdateAnswerSubscription = {
  __typename: "Answer";
  id: string;
  createdAt: string | null;
  answer: number | null;
  comment: string | null;
  file: Array<{
    __typename: "S3File";
    id: string | null;
    name: string;
    names: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date: number | null;
    contentType: string | null;
    length: number | null;
    key: string | null;
  } | null> | null;
  userId: string;
  riskAction: RiskAction | null;
  frameworkName: string;
  questionId: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken: boolean | null;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type OnDeleteAnswerSubscription = {
  __typename: "Answer";
  id: string;
  createdAt: string | null;
  answer: number | null;
  comment: string | null;
  file: Array<{
    __typename: "S3File";
    id: string | null;
    name: string;
    names: string | null;
    entityId: string;
    fileType: FileTypeEnum;
    date: number | null;
    contentType: string | null;
    length: number | null;
    key: string | null;
  } | null> | null;
  userId: string;
  riskAction: RiskAction | null;
  frameworkName: string;
  questionId: string | null;
  date: number;
  assessmentId: string;
  assignmentID: string;
  left: number;
  right: number;
  isActionTaken: boolean | null;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type OnCreateAssignmentSubscription = {
  __typename: "Assignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  left: number;
  right: number;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type OnUpdateAssignmentSubscription = {
  __typename: "Assignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  left: number;
  right: number;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type OnDeleteAssignmentSubscription = {
  __typename: "Assignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  left: number;
  right: number;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type OnCreateAssessmentSubscription = {
  __typename: "Assessment";
  id: string;
  standardList: Array<{
    __typename: "StandardFramework";
    id: string;
    type: StandardType | null;
    fileName: string | null;
    filter: {
      __typename: "StandardFilter";
      impact: ImpactEnum | null;
    } | null;
  } | null> | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  rootId: string;
  childId: string;
  scores: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  left: number | null;
  right: number | null;
  managers: Array<string | null> | null;
  exports: {
    __typename: "Exports";
    jiraEntityIds: Array<string | null> | null;
  } | null;
  standardFrameworkList: {
    __typename: "ModelAssessmentStandardFrameworkConnection";
    items: Array<{
      __typename: "AssessmentStandardFramework";
      id: string;
      key: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      assessmentId: string;
      managerId: string | null;
      assessment: {
        __typename: "Assessment";
        id: string;
        standardList: Array<{
          __typename: "StandardFramework";
          id: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
        } | null> | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        rootId: string;
        childId: string;
        scores: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
        left: number | null;
        right: number | null;
        managers: Array<string | null> | null;
        exports: {
          __typename: "Exports";
          jiraEntityIds: Array<string | null> | null;
        } | null;
        standardFrameworkList: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type: StandardType | null;
            fileName: string | null;
            assessmentId: string;
            managerId: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null;
      managers: {
        __typename: "ModelFrameworkManagerConnection";
        items: Array<{
          __typename: "FrameworkManager";
          id: string;
          assessmentId: string;
          standardFrameworkId: string;
          managerId: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnUpdateAssessmentSubscription = {
  __typename: "Assessment";
  id: string;
  standardList: Array<{
    __typename: "StandardFramework";
    id: string;
    type: StandardType | null;
    fileName: string | null;
    filter: {
      __typename: "StandardFilter";
      impact: ImpactEnum | null;
    } | null;
  } | null> | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  rootId: string;
  childId: string;
  scores: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  left: number | null;
  right: number | null;
  managers: Array<string | null> | null;
  exports: {
    __typename: "Exports";
    jiraEntityIds: Array<string | null> | null;
  } | null;
  standardFrameworkList: {
    __typename: "ModelAssessmentStandardFrameworkConnection";
    items: Array<{
      __typename: "AssessmentStandardFramework";
      id: string;
      key: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      assessmentId: string;
      managerId: string | null;
      assessment: {
        __typename: "Assessment";
        id: string;
        standardList: Array<{
          __typename: "StandardFramework";
          id: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
        } | null> | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        rootId: string;
        childId: string;
        scores: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
        left: number | null;
        right: number | null;
        managers: Array<string | null> | null;
        exports: {
          __typename: "Exports";
          jiraEntityIds: Array<string | null> | null;
        } | null;
        standardFrameworkList: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type: StandardType | null;
            fileName: string | null;
            assessmentId: string;
            managerId: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null;
      managers: {
        __typename: "ModelFrameworkManagerConnection";
        items: Array<{
          __typename: "FrameworkManager";
          id: string;
          assessmentId: string;
          standardFrameworkId: string;
          managerId: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnDeleteAssessmentSubscription = {
  __typename: "Assessment";
  id: string;
  standardList: Array<{
    __typename: "StandardFramework";
    id: string;
    type: StandardType | null;
    fileName: string | null;
    filter: {
      __typename: "StandardFilter";
      impact: ImpactEnum | null;
    } | null;
  } | null> | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  rootId: string;
  childId: string;
  scores: {
    __typename: "Scores";
    total: number | null;
    target: number | null;
    collection: number | null;
    remediation: number | null;
    totalQuestions: number | null;
    completedQuestions: number | null;
    totalGaps: number | null;
    criticalGaps: number | null;
    notApplicable: boolean | null;
    top20: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  left: number | null;
  right: number | null;
  managers: Array<string | null> | null;
  exports: {
    __typename: "Exports";
    jiraEntityIds: Array<string | null> | null;
  } | null;
  standardFrameworkList: {
    __typename: "ModelAssessmentStandardFrameworkConnection";
    items: Array<{
      __typename: "AssessmentStandardFramework";
      id: string;
      key: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
      timeline: {
        __typename: "Timeline";
        initiationDate: number | null;
        collectionDate: number | null;
        nextReviewDate: number | null;
        frequency: FrequencyCheckEnum | null;
      } | null;
      assessmentId: string;
      managerId: string | null;
      assessment: {
        __typename: "Assessment";
        id: string;
        standardList: Array<{
          __typename: "StandardFramework";
          id: string;
          type: StandardType | null;
          fileName: string | null;
          filter: {
            __typename: "StandardFilter";
            impact: ImpactEnum | null;
          } | null;
        } | null> | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        rootId: string;
        childId: string;
        scores: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
        left: number | null;
        right: number | null;
        managers: Array<string | null> | null;
        exports: {
          __typename: "Exports";
          jiraEntityIds: Array<string | null> | null;
        } | null;
        standardFrameworkList: {
          __typename: "ModelAssessmentStandardFrameworkConnection";
          items: Array<{
            __typename: "AssessmentStandardFramework";
            id: string;
            key: string;
            type: StandardType | null;
            fileName: string | null;
            assessmentId: string;
            managerId: string | null;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null;
      managers: {
        __typename: "ModelFrameworkManagerConnection";
        items: Array<{
          __typename: "FrameworkManager";
          id: string;
          assessmentId: string;
          standardFrameworkId: string;
          managerId: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnCreateIntegrationMappingsSubscription = {
  __typename: "IntegrationMappings";
  id: string;
  name: string;
  projectKey: string | null;
  fileName: string | null;
};

export type OnUpdateIntegrationMappingsSubscription = {
  __typename: "IntegrationMappings";
  id: string;
  name: string;
  projectKey: string | null;
  fileName: string | null;
};

export type OnDeleteIntegrationMappingsSubscription = {
  __typename: "IntegrationMappings";
  id: string;
  name: string;
  projectKey: string | null;
  fileName: string | null;
};

export type OnCreateQuestionSettingsSubscription = {
  __typename: "QuestionSettings";
  questionId: string;
  assessmentId: string;
  isCollaborative: boolean | null;
  isArtifactsMandatory: boolean | null;
  isApprovalRequired: boolean | null;
};

export type OnUpdateQuestionSettingsSubscription = {
  __typename: "QuestionSettings";
  questionId: string;
  assessmentId: string;
  isCollaborative: boolean | null;
  isArtifactsMandatory: boolean | null;
  isApprovalRequired: boolean | null;
};

export type OnDeleteQuestionSettingsSubscription = {
  __typename: "QuestionSettings";
  questionId: string;
  assessmentId: string;
  isCollaborative: boolean | null;
  isArtifactsMandatory: boolean | null;
  isApprovalRequired: boolean | null;
};

export type OnCreateCustomTaskSubscription = {
  __typename: "CustomTask";
  id: string;
  name: string | null;
  label: string | null;
  impact: ImpactEnum | null;
  description: string | null;
  standardsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  status: TaskStatusEnum | null;
  assessmentId: string;
};

export type OnUpdateCustomTaskSubscription = {
  __typename: "CustomTask";
  id: string;
  name: string | null;
  label: string | null;
  impact: ImpactEnum | null;
  description: string | null;
  standardsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  status: TaskStatusEnum | null;
  assessmentId: string;
};

export type OnDeleteCustomTaskSubscription = {
  __typename: "CustomTask";
  id: string;
  name: string | null;
  label: string | null;
  impact: ImpactEnum | null;
  description: string | null;
  standardsQualify: Array<{
    __typename: "Qualification";
    name: string | null;
    isQualify: boolean | null;
  } | null> | null;
  status: TaskStatusEnum | null;
  assessmentId: string;
};

export type OnCreateCustomTaskAssignmentSubscription = {
  __typename: "CustomTaskAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type OnUpdateCustomTaskAssignmentSubscription = {
  __typename: "CustomTaskAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type OnDeleteCustomTaskAssignmentSubscription = {
  __typename: "CustomTaskAssignment";
  id: string;
  userId: string;
  targetId: string;
  assessmentId: string;
  user: {
    __typename: "User";
    id: string;
    name: string;
    email: string;
    role: RoleEnum;
    phone: string | null;
    entityId: string | null;
    isCognitoUser: boolean | null;
    questionMap: string | null;
    createdBy: string | null;
    createdAt: number | null;
    lastLogin: number | null;
    invitationDate: number | null;
    reminderDate: number | null;
    modifiedBy: string | null;
    modifiedAt: number | null;
  } | null;
};

export type OnCreateAssessmentStandardFrameworkSubscription = {
  __typename: "AssessmentStandardFramework";
  id: string;
  key: string;
  type: StandardType | null;
  fileName: string | null;
  filter: {
    __typename: "StandardFilter";
    impact: ImpactEnum | null;
  } | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  assessmentId: string;
  managerId: string | null;
  assessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
  managers: {
    __typename: "ModelFrameworkManagerConnection";
    items: Array<{
      __typename: "FrameworkManager";
      id: string;
      assessmentId: string;
      standardFrameworkId: string;
      managerId: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnUpdateAssessmentStandardFrameworkSubscription = {
  __typename: "AssessmentStandardFramework";
  id: string;
  key: string;
  type: StandardType | null;
  fileName: string | null;
  filter: {
    __typename: "StandardFilter";
    impact: ImpactEnum | null;
  } | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  assessmentId: string;
  managerId: string | null;
  assessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
  managers: {
    __typename: "ModelFrameworkManagerConnection";
    items: Array<{
      __typename: "FrameworkManager";
      id: string;
      assessmentId: string;
      standardFrameworkId: string;
      managerId: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnDeleteAssessmentStandardFrameworkSubscription = {
  __typename: "AssessmentStandardFramework";
  id: string;
  key: string;
  type: StandardType | null;
  fileName: string | null;
  filter: {
    __typename: "StandardFilter";
    impact: ImpactEnum | null;
  } | null;
  timeline: {
    __typename: "Timeline";
    initiationDate: number | null;
    collectionDate: number | null;
    nextReviewDate: number | null;
    frequency: FrequencyCheckEnum | null;
  } | null;
  assessmentId: string;
  managerId: string | null;
  assessment: {
    __typename: "Assessment";
    id: string;
    standardList: Array<{
      __typename: "StandardFramework";
      id: string;
      type: StandardType | null;
      fileName: string | null;
      filter: {
        __typename: "StandardFilter";
        impact: ImpactEnum | null;
      } | null;
    } | null> | null;
    timeline: {
      __typename: "Timeline";
      initiationDate: number | null;
      collectionDate: number | null;
      nextReviewDate: number | null;
      frequency: FrequencyCheckEnum | null;
    } | null;
    rootId: string;
    childId: string;
    scores: {
      __typename: "Scores";
      total: number | null;
      target: number | null;
      collection: number | null;
      remediation: number | null;
      totalQuestions: number | null;
      completedQuestions: number | null;
      totalGaps: number | null;
      criticalGaps: number | null;
      notApplicable: boolean | null;
      top20: {
        __typename: "Scores";
        total: number | null;
        target: number | null;
        collection: number | null;
        remediation: number | null;
        totalQuestions: number | null;
        completedQuestions: number | null;
        totalGaps: number | null;
        criticalGaps: number | null;
        notApplicable: boolean | null;
        top20: {
          __typename: "Scores";
          total: number | null;
          target: number | null;
          collection: number | null;
          remediation: number | null;
          totalQuestions: number | null;
          completedQuestions: number | null;
          totalGaps: number | null;
          criticalGaps: number | null;
          notApplicable: boolean | null;
          top20: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    left: number | null;
    right: number | null;
    managers: Array<string | null> | null;
    exports: {
      __typename: "Exports";
      jiraEntityIds: Array<string | null> | null;
    } | null;
    standardFrameworkList: {
      __typename: "ModelAssessmentStandardFrameworkConnection";
      items: Array<{
        __typename: "AssessmentStandardFramework";
        id: string;
        key: string;
        type: StandardType | null;
        fileName: string | null;
        filter: {
          __typename: "StandardFilter";
          impact: ImpactEnum | null;
        } | null;
        timeline: {
          __typename: "Timeline";
          initiationDate: number | null;
          collectionDate: number | null;
          nextReviewDate: number | null;
          frequency: FrequencyCheckEnum | null;
        } | null;
        assessmentId: string;
        managerId: string | null;
        assessment: {
          __typename: "Assessment";
          id: string;
          standardList: Array<{
            __typename: "StandardFramework";
            id: string;
            type: StandardType | null;
            fileName: string | null;
          } | null> | null;
          timeline: {
            __typename: "Timeline";
            initiationDate: number | null;
            collectionDate: number | null;
            nextReviewDate: number | null;
            frequency: FrequencyCheckEnum | null;
          } | null;
          rootId: string;
          childId: string;
          scores: {
            __typename: "Scores";
            total: number | null;
            target: number | null;
            collection: number | null;
            remediation: number | null;
            totalQuestions: number | null;
            completedQuestions: number | null;
            totalGaps: number | null;
            criticalGaps: number | null;
            notApplicable: boolean | null;
          } | null;
          left: number | null;
          right: number | null;
          managers: Array<string | null> | null;
          exports: {
            __typename: "Exports";
            jiraEntityIds: Array<string | null> | null;
          } | null;
          standardFrameworkList: {
            __typename: "ModelAssessmentStandardFrameworkConnection";
            nextToken: string | null;
          } | null;
        } | null;
        managers: {
          __typename: "ModelFrameworkManagerConnection";
          items: Array<{
            __typename: "FrameworkManager";
            id: string;
            assessmentId: string;
            standardFrameworkId: string;
            managerId: string;
          } | null> | null;
          nextToken: string | null;
        } | null;
      } | null> | null;
      nextToken: string | null;
    } | null;
  } | null;
  managers: {
    __typename: "ModelFrameworkManagerConnection";
    items: Array<{
      __typename: "FrameworkManager";
      id: string;
      assessmentId: string;
      standardFrameworkId: string;
      managerId: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnCreateFrameworkManagerSubscription = {
  __typename: "FrameworkManager";
  id: string;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
};

export type OnUpdateFrameworkManagerSubscription = {
  __typename: "FrameworkManager";
  id: string;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
};

export type OnDeleteFrameworkManagerSubscription = {
  __typename: "FrameworkManager";
  id: string;
  assessmentId: string;
  standardFrameworkId: string;
  managerId: string;
};

export type OnCreateCommentSubscription = {
  __typename: "Comment";
  id: string;
  latestSource: ExportSourceEnum;
  comment: string;
  date: number | null;
  questionId: string | null;
  assessmentId: string | null;
  userId: string;
  user: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  exports: {
    __typename: "ModelExportConnection";
    items: Array<{
      __typename: "Export";
      id: string;
      destination: ExportSourceEnum;
      destinationCategoryId: string;
      category: CategoryEnum | null;
      categoryId: string;
      date: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnUpdateCommentSubscription = {
  __typename: "Comment";
  id: string;
  latestSource: ExportSourceEnum;
  comment: string;
  date: number | null;
  questionId: string | null;
  assessmentId: string | null;
  userId: string;
  user: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  exports: {
    __typename: "ModelExportConnection";
    items: Array<{
      __typename: "Export";
      id: string;
      destination: ExportSourceEnum;
      destinationCategoryId: string;
      category: CategoryEnum | null;
      categoryId: string;
      date: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnDeleteCommentSubscription = {
  __typename: "Comment";
  id: string;
  latestSource: ExportSourceEnum;
  comment: string;
  date: number | null;
  questionId: string | null;
  assessmentId: string | null;
  userId: string;
  user: {
    __typename: "ModelUserConnection";
    items: Array<{
      __typename: "User";
      id: string;
      name: string;
      email: string;
      role: RoleEnum;
      phone: string | null;
      entityId: string | null;
      isCognitoUser: boolean | null;
      questionMap: string | null;
      createdBy: string | null;
      createdAt: number | null;
      lastLogin: number | null;
      invitationDate: number | null;
      reminderDate: number | null;
      modifiedBy: string | null;
      modifiedAt: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
  exports: {
    __typename: "ModelExportConnection";
    items: Array<{
      __typename: "Export";
      id: string;
      destination: ExportSourceEnum;
      destinationCategoryId: string;
      category: CategoryEnum | null;
      categoryId: string;
      date: number | null;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type OnCreateExportSubscription = {
  __typename: "Export";
  id: string;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category: CategoryEnum | null;
  categoryId: string;
  date: number | null;
};

export type OnUpdateExportSubscription = {
  __typename: "Export";
  id: string;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category: CategoryEnum | null;
  categoryId: string;
  date: number | null;
};

export type OnDeleteExportSubscription = {
  __typename: "Export";
  id: string;
  destination: ExportSourceEnum;
  destinationCategoryId: string;
  category: CategoryEnum | null;
  categoryId: string;
  date: number | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  OnUpdateEntityScoreListener: Observable<
    OnUpdateEntityScoreSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEntityScore {
        onUpdateEntity {
          __typename
          id
        }
      }`
    )
  ) as Observable<OnUpdateEntityScoreSubscription>;

  onFrameworkScoresUpdatedListener: Observable<
    onFrameworkScoresUpdatedSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription onFrameworkScoresUpdated($id: ID!) {
        onFrameworkScoresUpdated(id: $id) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
        }
      }`
    )
  ) as Observable<onFrameworkScoresUpdatedSubscription>;

  async CreateFakeData(entityId: string): Promise<boolean | null> {
    const statement = `mutation CreateFakeData($entityId: String!) {
        createFakeData(entityId: $entityId)
      }`;
    const gqlAPIServiceArguments: any = {
      entityId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <boolean | null>response.data.createFakeData;
  }
  async CreateFrameworksNodes(
    input?: CreateFrameworksNodesInput
  ): Promise<CreateFrameworksNodesMutation> {
    const statement = `mutation CreateFrameworksNodes($input: CreateFrameworksNodesInput) {
        createFrameworksNodes(input: $input) {
          __typename
          ids
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateFrameworksNodesMutation>response.data.createFrameworksNodes;
  }
  async AddCognitoUser(user: string, domain: string): Promise<string | null> {
    const statement = `mutation AddCognitoUser($user: String!, $domain: String!) {
        addCognitoUser(user: $user, domain: $domain)
      }`;
    const gqlAPIServiceArguments: any = {
      user,
      domain
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.addCognitoUser;
  }
  async SendInviteEmail(
    userId: string,
    userEmail: string,
    userName: string,
    domain: string
  ): Promise<string | null> {
    const statement = `mutation SendInviteEmail($userId: String!, $userEmail: String!, $userName: String!, $domain: String!) {
        sendInviteEmail(userId: $userId, userEmail: $userEmail, userName: $userName, domain: $domain)
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
      userEmail,
      userName,
      domain
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.sendInviteEmail;
  }
  async SendReminderEmail(
    userId: string,
    subEntityId: string
  ): Promise<string | null> {
    const statement = `mutation SendReminderEmail($userId: String!, $subEntityId: String!) {
        sendReminderEmail(userId: $userId, subEntityId: $subEntityId)
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
      subEntityId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.sendReminderEmail;
  }
  async AddCognitoGroup(
    input: AddGroupInput,
    shouldAddUser: boolean
  ): Promise<string | null> {
    const statement = `mutation AddCognitoGroup($input: AddGroupInput!, $shouldAddUser: Boolean!) {
        addCognitoGroup(input: $input, shouldAddUser: $shouldAddUser)
      }`;
    const gqlAPIServiceArguments: any = {
      input,
      shouldAddUser
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.addCognitoGroup;
  }
  async AssignUser(input: AssignUserInput): Promise<AssignUserMutation> {
    const statement = `mutation AssignUser($input: AssignUserInput!) {
        assignUser(input: $input) {
          __typename
          createdAssignments {
            __typename
            id
            userId
            targetId
            assessmentId
            left
            right
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
          }
          deletedAssignments {
            __typename
            id
            userId
            targetId
            assessmentId
            left
            right
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AssignUserMutation>response.data.assignUser;
  }
  async AssignQuestion(
    questionId: string,
    userId: string,
    assessmentId: string,
    framework: string
  ): Promise<string | null> {
    const statement = `mutation AssignQuestion($questionId: String!, $userId: String!, $assessmentId: String!, $framework: String!) {
        assignQuestion(questionId: $questionId, userId: $userId, assessmentId: $assessmentId, framework: $framework)
      }`;
    const gqlAPIServiceArguments: any = {
      questionId,
      userId,
      assessmentId,
      framework
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.assignQuestion;
  }
  async CalcAssessmentScore(
    assessmentId: string,
    framework: string
  ): Promise<string | null> {
    const statement = `mutation CalcAssessmentScore($assessmentId: String!, $framework: String!) {
        calcAssessmentScore(assessmentId: $assessmentId, framework: $framework)
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
      framework
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.calcAssessmentScore;
  }
  async AssignAssessment(
    assessmentId: string,
    userId: string
  ): Promise<string | null> {
    const statement = `mutation AssignAssessment($assessmentId: String!, $userId: String!) {
        assignAssessment(assessmentId: $assessmentId, userId: $userId)
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
      userId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.assignAssessment;
  }
  async SaveIntegrationDetails(
    entityId: string,
    details: IntegrationDetailsInput
  ): Promise<string | null> {
    const statement = `mutation SaveIntegrationDetails($entityId: String!, $details: IntegrationDetailsInput!) {
        saveIntegrationDetails(entityId: $entityId, details: $details)
      }`;
    const gqlAPIServiceArguments: any = {
      entityId,
      details
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.saveIntegrationDetails;
  }
  async IncrementQuestionnaireAnswer(
    id: string,
    value?: number
  ): Promise<IncrementQuestionnaireAnswerMutation> {
    const statement = `mutation IncrementQuestionnaireAnswer($id: String!, $value: Int) {
        incrementQuestionnaireAnswer(id: $id, value: $value) {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          assignmentMap
          firstAnswer
          lastAnswer
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    if (value) {
      gqlAPIServiceArguments.value = value;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IncrementQuestionnaireAnswerMutation>(
      response.data.incrementQuestionnaireAnswer
    );
  }
  async RemoveUserAssignment(
    input: AssignUserInput
  ): Promise<RemoveUserAssignmentMutation> {
    const statement = `mutation RemoveUserAssignment($input: AssignUserInput!) {
        removeUserAssignment(input: $input) {
          __typename
          createdAssignments {
            __typename
            id
            userId
            targetId
            assessmentId
            left
            right
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
          }
          deletedAssignments {
            __typename
            id
            userId
            targetId
            assessmentId
            left
            right
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RemoveUserAssignmentMutation>response.data.removeUserAssignment;
  }
  async GenerateEntityData(
    input: GenerateEntityDataInput
  ): Promise<string | null> {
    const statement = `mutation GenerateEntityData($input: GenerateEntityDataInput!) {
        generateEntityData(input: $input)
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.generateEntityData;
  }
  async CreateUser(
    input: CreateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<CreateUserMutation> {
    const statement = `mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
        createUser(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserMutation>response.data.createUser;
  }
  async UpdateUser(
    input: UpdateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<UpdateUserMutation> {
    const statement = `mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
        updateUser(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserMutation>response.data.updateUser;
  }
  async DeleteUser(
    input: DeleteUserInput,
    condition?: ModelUserConditionInput
  ): Promise<DeleteUserMutation> {
    const statement = `mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
        deleteUser(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserMutation>response.data.deleteUser;
  }
  async CreateEntity(
    input: CreateEntityInput,
    condition?: ModelEntityConditionInput
  ): Promise<CreateEntityMutation> {
    const statement = `mutation CreateEntity($input: CreateEntityInput!, $condition: ModelEntityConditionInput) {
        createEntity(input: $input, condition: $condition) {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            isEnable
            isAuth
            inheritFromParent
          }
          externalFeed {
            __typename
            name
            credentials
            isEnable
            isAuth
            inheritFromParent
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
            }
            standards {
              __typename
              name
              isQualify
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          industry
          domain
          activeAssessmentId
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
            }
            status
            probability
            website
            location
            size
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  name
                  description
                  leaks
                  data
                  status
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                }
                nextToken
              }
            }
          }
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEntityMutation>response.data.createEntity;
  }
  async UpdateEntity(
    input: UpdateEntityInput,
    condition?: ModelEntityConditionInput
  ): Promise<UpdateEntityMutation> {
    const statement = `mutation UpdateEntity($input: UpdateEntityInput!, $condition: ModelEntityConditionInput) {
        updateEntity(input: $input, condition: $condition) {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            isEnable
            isAuth
            inheritFromParent
          }
          externalFeed {
            __typename
            name
            credentials
            isEnable
            isAuth
            inheritFromParent
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
            }
            standards {
              __typename
              name
              isQualify
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          industry
          domain
          activeAssessmentId
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
            }
            status
            probability
            website
            location
            size
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  name
                  description
                  leaks
                  data
                  status
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                }
                nextToken
              }
            }
          }
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEntityMutation>response.data.updateEntity;
  }
  async DeleteEntity(
    input: DeleteEntityInput,
    condition?: ModelEntityConditionInput
  ): Promise<DeleteEntityMutation> {
    const statement = `mutation DeleteEntity($input: DeleteEntityInput!, $condition: ModelEntityConditionInput) {
        deleteEntity(input: $input, condition: $condition) {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            isEnable
            isAuth
            inheritFromParent
          }
          externalFeed {
            __typename
            name
            credentials
            isEnable
            isAuth
            inheritFromParent
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
            }
            standards {
              __typename
              name
              isQualify
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          industry
          domain
          activeAssessmentId
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
            }
            status
            probability
            website
            location
            size
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  name
                  description
                  leaks
                  data
                  status
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                }
                nextToken
              }
            }
          }
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEntityMutation>response.data.deleteEntity;
  }
  async CreateVendorsDetail(
    input: CreateVendorsDetailInput,
    condition?: ModelVendorsDetailConditionInput
  ): Promise<CreateVendorsDetailMutation> {
    const statement = `mutation CreateVendorsDetail($input: CreateVendorsDetailInput!, $condition: ModelVendorsDetailConditionInput) {
        createVendorsDetail(input: $input, condition: $condition) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
          }
          status
          probability
          website
          location
          size
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                name
                description
                leaks
                data
                status
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVendorsDetailMutation>response.data.createVendorsDetail;
  }
  async UpdateVendorsDetail(
    input: UpdateVendorsDetailInput,
    condition?: ModelVendorsDetailConditionInput
  ): Promise<UpdateVendorsDetailMutation> {
    const statement = `mutation UpdateVendorsDetail($input: UpdateVendorsDetailInput!, $condition: ModelVendorsDetailConditionInput) {
        updateVendorsDetail(input: $input, condition: $condition) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
          }
          status
          probability
          website
          location
          size
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                name
                description
                leaks
                data
                status
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVendorsDetailMutation>response.data.updateVendorsDetail;
  }
  async DeleteVendorsDetail(
    input: DeleteVendorsDetailInput,
    condition?: ModelVendorsDetailConditionInput
  ): Promise<DeleteVendorsDetailMutation> {
    const statement = `mutation DeleteVendorsDetail($input: DeleteVendorsDetailInput!, $condition: ModelVendorsDetailConditionInput) {
        deleteVendorsDetail(input: $input, condition: $condition) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
          }
          status
          probability
          website
          location
          size
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                name
                description
                leaks
                data
                status
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVendorsDetailMutation>response.data.deleteVendorsDetail;
  }
  async CreateFrameworkScores(
    input: CreateFrameworkScoresInput,
    condition?: ModelFrameworkScoresConditionInput
  ): Promise<CreateFrameworkScoresMutation> {
    const statement = `mutation CreateFrameworkScores($input: CreateFrameworkScoresInput!, $condition: ModelFrameworkScoresConditionInput) {
        createFrameworkScores(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateFrameworkScoresMutation>response.data.createFrameworkScores;
  }
  async UpdateFrameworkScores(
    input: UpdateFrameworkScoresInput,
    condition?: ModelFrameworkScoresConditionInput
  ): Promise<UpdateFrameworkScoresMutation> {
    const statement = `mutation UpdateFrameworkScores($input: UpdateFrameworkScoresInput!, $condition: ModelFrameworkScoresConditionInput) {
        updateFrameworkScores(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateFrameworkScoresMutation>response.data.updateFrameworkScores;
  }
  async DeleteFrameworkScores(
    input: DeleteFrameworkScoresInput,
    condition?: ModelFrameworkScoresConditionInput
  ): Promise<DeleteFrameworkScoresMutation> {
    const statement = `mutation DeleteFrameworkScores($input: DeleteFrameworkScoresInput!, $condition: ModelFrameworkScoresConditionInput) {
        deleteFrameworkScores(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteFrameworkScoresMutation>response.data.deleteFrameworkScores;
  }
  async CreateIntelligence(
    input: CreateIntelligenceInput,
    condition?: ModelIntelligenceConditionInput
  ): Promise<CreateIntelligenceMutation> {
    const statement = `mutation CreateIntelligence($input: CreateIntelligenceInput!, $condition: ModelIntelligenceConditionInput) {
        createIntelligence(input: $input, condition: $condition) {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              name
              description
              leaks
              data
              status
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIntelligenceMutation>response.data.createIntelligence;
  }
  async UpdateIntelligence(
    input: UpdateIntelligenceInput,
    condition?: ModelIntelligenceConditionInput
  ): Promise<UpdateIntelligenceMutation> {
    const statement = `mutation UpdateIntelligence($input: UpdateIntelligenceInput!, $condition: ModelIntelligenceConditionInput) {
        updateIntelligence(input: $input, condition: $condition) {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              name
              description
              leaks
              data
              status
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIntelligenceMutation>response.data.updateIntelligence;
  }
  async DeleteIntelligence(
    input: DeleteIntelligenceInput,
    condition?: ModelIntelligenceConditionInput
  ): Promise<DeleteIntelligenceMutation> {
    const statement = `mutation DeleteIntelligence($input: DeleteIntelligenceInput!, $condition: ModelIntelligenceConditionInput) {
        deleteIntelligence(input: $input, condition: $condition) {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              name
              description
              leaks
              data
              status
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIntelligenceMutation>response.data.deleteIntelligence;
  }
  async CreateBreaches(
    input: CreateBreachesInput,
    condition?: ModelBreachesConditionInput
  ): Promise<CreateBreachesMutation> {
    const statement = `mutation CreateBreaches($input: CreateBreachesInput!, $condition: ModelBreachesConditionInput) {
        createBreaches(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          date
          name
          description
          leaks
          data
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateBreachesMutation>response.data.createBreaches;
  }
  async UpdateBreaches(
    input: UpdateBreachesInput,
    condition?: ModelBreachesConditionInput
  ): Promise<UpdateBreachesMutation> {
    const statement = `mutation UpdateBreaches($input: UpdateBreachesInput!, $condition: ModelBreachesConditionInput) {
        updateBreaches(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          date
          name
          description
          leaks
          data
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateBreachesMutation>response.data.updateBreaches;
  }
  async DeleteBreaches(
    input: DeleteBreachesInput,
    condition?: ModelBreachesConditionInput
  ): Promise<DeleteBreachesMutation> {
    const statement = `mutation DeleteBreaches($input: DeleteBreachesInput!, $condition: ModelBreachesConditionInput) {
        deleteBreaches(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          date
          name
          description
          leaks
          data
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteBreachesMutation>response.data.deleteBreaches;
  }
  async CreateExternalThreats(
    input: CreateExternalThreatsInput,
    condition?: ModelExternalThreatsConditionInput
  ): Promise<CreateExternalThreatsMutation> {
    const statement = `mutation CreateExternalThreats($input: CreateExternalThreatsInput!, $condition: ModelExternalThreatsConditionInput) {
        createExternalThreats(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          source
          findings
          riskScore
          details {
            __typename
            ip
            port
            cve
            cvss
          }
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateExternalThreatsMutation>response.data.createExternalThreats;
  }
  async UpdateExternalThreats(
    input: UpdateExternalThreatsInput,
    condition?: ModelExternalThreatsConditionInput
  ): Promise<UpdateExternalThreatsMutation> {
    const statement = `mutation UpdateExternalThreats($input: UpdateExternalThreatsInput!, $condition: ModelExternalThreatsConditionInput) {
        updateExternalThreats(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          source
          findings
          riskScore
          details {
            __typename
            ip
            port
            cve
            cvss
          }
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateExternalThreatsMutation>response.data.updateExternalThreats;
  }
  async DeleteExternalThreats(
    input: DeleteExternalThreatsInput,
    condition?: ModelExternalThreatsConditionInput
  ): Promise<DeleteExternalThreatsMutation> {
    const statement = `mutation DeleteExternalThreats($input: DeleteExternalThreatsInput!, $condition: ModelExternalThreatsConditionInput) {
        deleteExternalThreats(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          source
          findings
          riskScore
          details {
            __typename
            ip
            port
            cve
            cvss
          }
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteExternalThreatsMutation>response.data.deleteExternalThreats;
  }
  async CreateOthers(
    input: CreateOthersInput,
    condition?: ModelOthersConditionInput
  ): Promise<CreateOthersMutation> {
    const statement = `mutation CreateOthers($input: CreateOthersInput!, $condition: ModelOthersConditionInput) {
        createOthers(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          name
          label
          severity
          description
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOthersMutation>response.data.createOthers;
  }
  async UpdateOthers(
    input: UpdateOthersInput,
    condition?: ModelOthersConditionInput
  ): Promise<UpdateOthersMutation> {
    const statement = `mutation UpdateOthers($input: UpdateOthersInput!, $condition: ModelOthersConditionInput) {
        updateOthers(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          name
          label
          severity
          description
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOthersMutation>response.data.updateOthers;
  }
  async DeleteOthers(
    input: DeleteOthersInput,
    condition?: ModelOthersConditionInput
  ): Promise<DeleteOthersMutation> {
    const statement = `mutation DeleteOthers($input: DeleteOthersInput!, $condition: ModelOthersConditionInput) {
        deleteOthers(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          name
          label
          severity
          description
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOthersMutation>response.data.deleteOthers;
  }
  async CreateQuestionnaireCompletion(
    input: CreateQuestionnaireCompletionInput,
    condition?: ModelQuestionnaireCompletionConditionInput
  ): Promise<CreateQuestionnaireCompletionMutation> {
    const statement = `mutation CreateQuestionnaireCompletion($input: CreateQuestionnaireCompletionInput!, $condition: ModelQuestionnaireCompletionConditionInput) {
        createQuestionnaireCompletion(input: $input, condition: $condition) {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          assignmentMap
          firstAnswer
          lastAnswer
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateQuestionnaireCompletionMutation>(
      response.data.createQuestionnaireCompletion
    );
  }
  async UpdateQuestionnaireCompletion(
    input: UpdateQuestionnaireCompletionInput,
    condition?: ModelQuestionnaireCompletionConditionInput
  ): Promise<UpdateQuestionnaireCompletionMutation> {
    const statement = `mutation UpdateQuestionnaireCompletion($input: UpdateQuestionnaireCompletionInput!, $condition: ModelQuestionnaireCompletionConditionInput) {
        updateQuestionnaireCompletion(input: $input, condition: $condition) {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          assignmentMap
          firstAnswer
          lastAnswer
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateQuestionnaireCompletionMutation>(
      response.data.updateQuestionnaireCompletion
    );
  }
  async DeleteQuestionnaireCompletion(
    input: DeleteQuestionnaireCompletionInput,
    condition?: ModelQuestionnaireCompletionConditionInput
  ): Promise<DeleteQuestionnaireCompletionMutation> {
    const statement = `mutation DeleteQuestionnaireCompletion($input: DeleteQuestionnaireCompletionInput!, $condition: ModelQuestionnaireCompletionConditionInput) {
        deleteQuestionnaireCompletion(input: $input, condition: $condition) {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          assignmentMap
          firstAnswer
          lastAnswer
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteQuestionnaireCompletionMutation>(
      response.data.deleteQuestionnaireCompletion
    );
  }
  async CreateAnswer(
    input: CreateAnswerInput,
    condition?: ModelAnswerConditionInput
  ): Promise<CreateAnswerMutation> {
    const statement = `mutation CreateAnswer($input: CreateAnswerInput!, $condition: ModelAnswerConditionInput) {
        createAnswer(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          answer
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAnswerMutation>response.data.createAnswer;
  }
  async UpdateAnswer(
    input: UpdateAnswerInput,
    condition?: ModelAnswerConditionInput
  ): Promise<UpdateAnswerMutation> {
    const statement = `mutation UpdateAnswer($input: UpdateAnswerInput!, $condition: ModelAnswerConditionInput) {
        updateAnswer(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          answer
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAnswerMutation>response.data.updateAnswer;
  }
  async DeleteAnswer(
    input: DeleteAnswerInput,
    condition?: ModelAnswerConditionInput
  ): Promise<DeleteAnswerMutation> {
    const statement = `mutation DeleteAnswer($input: DeleteAnswerInput!, $condition: ModelAnswerConditionInput) {
        deleteAnswer(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          answer
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAnswerMutation>response.data.deleteAnswer;
  }
  async CreateAssignment(
    input: CreateAssignmentInput,
    condition?: ModelAssignmentConditionInput
  ): Promise<CreateAssignmentMutation> {
    const statement = `mutation CreateAssignment($input: CreateAssignmentInput!, $condition: ModelAssignmentConditionInput) {
        createAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          left
          right
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAssignmentMutation>response.data.createAssignment;
  }
  async UpdateAssignment(
    input: UpdateAssignmentInput,
    condition?: ModelAssignmentConditionInput
  ): Promise<UpdateAssignmentMutation> {
    const statement = `mutation UpdateAssignment($input: UpdateAssignmentInput!, $condition: ModelAssignmentConditionInput) {
        updateAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          left
          right
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAssignmentMutation>response.data.updateAssignment;
  }
  async DeleteAssignment(
    input: DeleteAssignmentInput,
    condition?: ModelAssignmentConditionInput
  ): Promise<DeleteAssignmentMutation> {
    const statement = `mutation DeleteAssignment($input: DeleteAssignmentInput!, $condition: ModelAssignmentConditionInput) {
        deleteAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          left
          right
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAssignmentMutation>response.data.deleteAssignment;
  }
  async CreateAssessment(
    input: CreateAssessmentInput,
    condition?: ModelAssessmentConditionInput
  ): Promise<CreateAssessmentMutation> {
    const statement = `mutation CreateAssessment($input: CreateAssessmentInput!, $condition: ModelAssessmentConditionInput) {
        createAssessment(input: $input, condition: $condition) {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                  }
                  nextToken
                }
              }
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                }
                nextToken
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAssessmentMutation>response.data.createAssessment;
  }
  async UpdateAssessment(
    input: UpdateAssessmentInput,
    condition?: ModelAssessmentConditionInput
  ): Promise<UpdateAssessmentMutation> {
    const statement = `mutation UpdateAssessment($input: UpdateAssessmentInput!, $condition: ModelAssessmentConditionInput) {
        updateAssessment(input: $input, condition: $condition) {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                  }
                  nextToken
                }
              }
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                }
                nextToken
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAssessmentMutation>response.data.updateAssessment;
  }
  async DeleteAssessment(
    input: DeleteAssessmentInput,
    condition?: ModelAssessmentConditionInput
  ): Promise<DeleteAssessmentMutation> {
    const statement = `mutation DeleteAssessment($input: DeleteAssessmentInput!, $condition: ModelAssessmentConditionInput) {
        deleteAssessment(input: $input, condition: $condition) {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                  }
                  nextToken
                }
              }
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                }
                nextToken
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAssessmentMutation>response.data.deleteAssessment;
  }
  async CreateIntegrationMappings(
    input: CreateIntegrationMappingsInput,
    condition?: ModelIntegrationMappingsConditionInput
  ): Promise<CreateIntegrationMappingsMutation> {
    const statement = `mutation CreateIntegrationMappings($input: CreateIntegrationMappingsInput!, $condition: ModelIntegrationMappingsConditionInput) {
        createIntegrationMappings(input: $input, condition: $condition) {
          __typename
          id
          name
          projectKey
          fileName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateIntegrationMappingsMutation>(
      response.data.createIntegrationMappings
    );
  }
  async UpdateIntegrationMappings(
    input: UpdateIntegrationMappingsInput,
    condition?: ModelIntegrationMappingsConditionInput
  ): Promise<UpdateIntegrationMappingsMutation> {
    const statement = `mutation UpdateIntegrationMappings($input: UpdateIntegrationMappingsInput!, $condition: ModelIntegrationMappingsConditionInput) {
        updateIntegrationMappings(input: $input, condition: $condition) {
          __typename
          id
          name
          projectKey
          fileName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateIntegrationMappingsMutation>(
      response.data.updateIntegrationMappings
    );
  }
  async DeleteIntegrationMappings(
    input: DeleteIntegrationMappingsInput,
    condition?: ModelIntegrationMappingsConditionInput
  ): Promise<DeleteIntegrationMappingsMutation> {
    const statement = `mutation DeleteIntegrationMappings($input: DeleteIntegrationMappingsInput!, $condition: ModelIntegrationMappingsConditionInput) {
        deleteIntegrationMappings(input: $input, condition: $condition) {
          __typename
          id
          name
          projectKey
          fileName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteIntegrationMappingsMutation>(
      response.data.deleteIntegrationMappings
    );
  }
  async CreateQuestionSettings(
    input: CreateQuestionSettingsInput,
    condition?: ModelQuestionSettingsConditionInput
  ): Promise<CreateQuestionSettingsMutation> {
    const statement = `mutation CreateQuestionSettings($input: CreateQuestionSettingsInput!, $condition: ModelQuestionSettingsConditionInput) {
        createQuestionSettings(input: $input, condition: $condition) {
          __typename
          questionId
          assessmentId
          isCollaborative
          isArtifactsMandatory
          isApprovalRequired
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateQuestionSettingsMutation>response.data.createQuestionSettings;
  }
  async UpdateQuestionSettings(
    input: UpdateQuestionSettingsInput,
    condition?: ModelQuestionSettingsConditionInput
  ): Promise<UpdateQuestionSettingsMutation> {
    const statement = `mutation UpdateQuestionSettings($input: UpdateQuestionSettingsInput!, $condition: ModelQuestionSettingsConditionInput) {
        updateQuestionSettings(input: $input, condition: $condition) {
          __typename
          questionId
          assessmentId
          isCollaborative
          isArtifactsMandatory
          isApprovalRequired
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateQuestionSettingsMutation>response.data.updateQuestionSettings;
  }
  async DeleteQuestionSettings(
    input: DeleteQuestionSettingsInput,
    condition?: ModelQuestionSettingsConditionInput
  ): Promise<DeleteQuestionSettingsMutation> {
    const statement = `mutation DeleteQuestionSettings($input: DeleteQuestionSettingsInput!, $condition: ModelQuestionSettingsConditionInput) {
        deleteQuestionSettings(input: $input, condition: $condition) {
          __typename
          questionId
          assessmentId
          isCollaborative
          isArtifactsMandatory
          isApprovalRequired
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteQuestionSettingsMutation>response.data.deleteQuestionSettings;
  }
  async CreateCustomTask(
    input: CreateCustomTaskInput,
    condition?: ModelCustomTaskConditionInput
  ): Promise<CreateCustomTaskMutation> {
    const statement = `mutation CreateCustomTask($input: CreateCustomTaskInput!, $condition: ModelCustomTaskConditionInput) {
        createCustomTask(input: $input, condition: $condition) {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
          }
          status
          assessmentId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCustomTaskMutation>response.data.createCustomTask;
  }
  async UpdateCustomTask(
    input: UpdateCustomTaskInput,
    condition?: ModelCustomTaskConditionInput
  ): Promise<UpdateCustomTaskMutation> {
    const statement = `mutation UpdateCustomTask($input: UpdateCustomTaskInput!, $condition: ModelCustomTaskConditionInput) {
        updateCustomTask(input: $input, condition: $condition) {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
          }
          status
          assessmentId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCustomTaskMutation>response.data.updateCustomTask;
  }
  async DeleteCustomTask(
    input: DeleteCustomTaskInput,
    condition?: ModelCustomTaskConditionInput
  ): Promise<DeleteCustomTaskMutation> {
    const statement = `mutation DeleteCustomTask($input: DeleteCustomTaskInput!, $condition: ModelCustomTaskConditionInput) {
        deleteCustomTask(input: $input, condition: $condition) {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
          }
          status
          assessmentId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCustomTaskMutation>response.data.deleteCustomTask;
  }
  async CreateCustomTaskAssignment(
    input: CreateCustomTaskAssignmentInput,
    condition?: ModelCustomTaskAssignmentConditionInput
  ): Promise<CreateCustomTaskAssignmentMutation> {
    const statement = `mutation CreateCustomTaskAssignment($input: CreateCustomTaskAssignmentInput!, $condition: ModelCustomTaskAssignmentConditionInput) {
        createCustomTaskAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCustomTaskAssignmentMutation>(
      response.data.createCustomTaskAssignment
    );
  }
  async UpdateCustomTaskAssignment(
    input: UpdateCustomTaskAssignmentInput,
    condition?: ModelCustomTaskAssignmentConditionInput
  ): Promise<UpdateCustomTaskAssignmentMutation> {
    const statement = `mutation UpdateCustomTaskAssignment($input: UpdateCustomTaskAssignmentInput!, $condition: ModelCustomTaskAssignmentConditionInput) {
        updateCustomTaskAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCustomTaskAssignmentMutation>(
      response.data.updateCustomTaskAssignment
    );
  }
  async DeleteCustomTaskAssignment(
    input: DeleteCustomTaskAssignmentInput,
    condition?: ModelCustomTaskAssignmentConditionInput
  ): Promise<DeleteCustomTaskAssignmentMutation> {
    const statement = `mutation DeleteCustomTaskAssignment($input: DeleteCustomTaskAssignmentInput!, $condition: ModelCustomTaskAssignmentConditionInput) {
        deleteCustomTaskAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCustomTaskAssignmentMutation>(
      response.data.deleteCustomTaskAssignment
    );
  }
  async CreateAssessmentStandardFramework(
    input: CreateAssessmentStandardFrameworkInput,
    condition?: ModelAssessmentStandardFrameworkConditionInput
  ): Promise<CreateAssessmentStandardFrameworkMutation> {
    const statement = `mutation CreateAssessmentStandardFramework($input: CreateAssessmentStandardFrameworkInput!, $condition: ModelAssessmentStandardFrameworkConditionInput) {
        createAssessmentStandardFramework(input: $input, condition: $condition) {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAssessmentStandardFrameworkMutation>(
      response.data.createAssessmentStandardFramework
    );
  }
  async UpdateAssessmentStandardFramework(
    input: UpdateAssessmentStandardFrameworkInput,
    condition?: ModelAssessmentStandardFrameworkConditionInput
  ): Promise<UpdateAssessmentStandardFrameworkMutation> {
    const statement = `mutation UpdateAssessmentStandardFramework($input: UpdateAssessmentStandardFrameworkInput!, $condition: ModelAssessmentStandardFrameworkConditionInput) {
        updateAssessmentStandardFramework(input: $input, condition: $condition) {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAssessmentStandardFrameworkMutation>(
      response.data.updateAssessmentStandardFramework
    );
  }
  async DeleteAssessmentStandardFramework(
    input: DeleteAssessmentStandardFrameworkInput,
    condition?: ModelAssessmentStandardFrameworkConditionInput
  ): Promise<DeleteAssessmentStandardFrameworkMutation> {
    const statement = `mutation DeleteAssessmentStandardFramework($input: DeleteAssessmentStandardFrameworkInput!, $condition: ModelAssessmentStandardFrameworkConditionInput) {
        deleteAssessmentStandardFramework(input: $input, condition: $condition) {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAssessmentStandardFrameworkMutation>(
      response.data.deleteAssessmentStandardFramework
    );
  }
  async CreateFrameworkManager(
    input: CreateFrameworkManagerInput,
    condition?: ModelFrameworkManagerConditionInput
  ): Promise<CreateFrameworkManagerMutation> {
    const statement = `mutation CreateFrameworkManager($input: CreateFrameworkManagerInput!, $condition: ModelFrameworkManagerConditionInput) {
        createFrameworkManager(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          standardFrameworkId
          managerId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateFrameworkManagerMutation>response.data.createFrameworkManager;
  }
  async UpdateFrameworkManager(
    input: UpdateFrameworkManagerInput,
    condition?: ModelFrameworkManagerConditionInput
  ): Promise<UpdateFrameworkManagerMutation> {
    const statement = `mutation UpdateFrameworkManager($input: UpdateFrameworkManagerInput!, $condition: ModelFrameworkManagerConditionInput) {
        updateFrameworkManager(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          standardFrameworkId
          managerId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateFrameworkManagerMutation>response.data.updateFrameworkManager;
  }
  async DeleteFrameworkManager(
    input: DeleteFrameworkManagerInput,
    condition?: ModelFrameworkManagerConditionInput
  ): Promise<DeleteFrameworkManagerMutation> {
    const statement = `mutation DeleteFrameworkManager($input: DeleteFrameworkManagerInput!, $condition: ModelFrameworkManagerConditionInput) {
        deleteFrameworkManager(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          standardFrameworkId
          managerId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteFrameworkManagerMutation>response.data.deleteFrameworkManager;
  }
  async CreateComment(
    input: CreateCommentInput,
    condition?: ModelCommentConditionInput
  ): Promise<CreateCommentMutation> {
    const statement = `mutation CreateComment($input: CreateCommentInput!, $condition: ModelCommentConditionInput) {
        createComment(input: $input, condition: $condition) {
          __typename
          id
          latestSource
          comment
          date
          questionId
          assessmentId
          userId
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
            nextToken
          }
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCommentMutation>response.data.createComment;
  }
  async UpdateComment(
    input: UpdateCommentInput,
    condition?: ModelCommentConditionInput
  ): Promise<UpdateCommentMutation> {
    const statement = `mutation UpdateComment($input: UpdateCommentInput!, $condition: ModelCommentConditionInput) {
        updateComment(input: $input, condition: $condition) {
          __typename
          id
          latestSource
          comment
          date
          questionId
          assessmentId
          userId
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
            nextToken
          }
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCommentMutation>response.data.updateComment;
  }
  async DeleteComment(
    input: DeleteCommentInput,
    condition?: ModelCommentConditionInput
  ): Promise<DeleteCommentMutation> {
    const statement = `mutation DeleteComment($input: DeleteCommentInput!, $condition: ModelCommentConditionInput) {
        deleteComment(input: $input, condition: $condition) {
          __typename
          id
          latestSource
          comment
          date
          questionId
          assessmentId
          userId
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
            nextToken
          }
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCommentMutation>response.data.deleteComment;
  }
  async CreateExport(
    input: CreateExportInput,
    condition?: ModelExportConditionInput
  ): Promise<CreateExportMutation> {
    const statement = `mutation CreateExport($input: CreateExportInput!, $condition: ModelExportConditionInput) {
        createExport(input: $input, condition: $condition) {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateExportMutation>response.data.createExport;
  }
  async UpdateExport(
    input: UpdateExportInput,
    condition?: ModelExportConditionInput
  ): Promise<UpdateExportMutation> {
    const statement = `mutation UpdateExport($input: UpdateExportInput!, $condition: ModelExportConditionInput) {
        updateExport(input: $input, condition: $condition) {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateExportMutation>response.data.updateExport;
  }
  async DeleteExport(
    input: DeleteExportInput,
    condition?: ModelExportConditionInput
  ): Promise<DeleteExportMutation> {
    const statement = `mutation DeleteExport($input: DeleteExportInput!, $condition: ModelExportConditionInput) {
        deleteExport(input: $input, condition: $condition) {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteExportMutation>response.data.deleteExport;
  }
  async GetRevision(
    entityId: string,
    date: string
  ): Promise<Array<GetRevisionQuery>> {
    const statement = `query GetRevision($entityId: String!, $date: String!) {
        getRevision(entityId: $entityId, date: $date) {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            isEnable
            isAuth
            inheritFromParent
          }
          externalFeed {
            __typename
            name
            credentials
            isEnable
            isAuth
            inheritFromParent
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
            }
            standards {
              __typename
              name
              isQualify
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          industry
          domain
          activeAssessmentId
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
            }
            status
            probability
            website
            location
            size
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  name
                  description
                  leaks
                  data
                  status
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                }
                nextToken
              }
            }
          }
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId,
      date
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetRevisionQuery>>response.data.getRevision;
  }
  async ListAlerts(
    industry: IndustryEnum,
    fileType: FileTypeEnum
  ): Promise<Array<ListAlertsQuery>> {
    const statement = `query ListAlerts($industry: IndustryEnum!, $fileType: FileTypeEnum!) {
        listAlerts(industry: $industry, fileType: $fileType) {
          __typename
          id
          type
          content
          priority
        }
      }`;
    const gqlAPIServiceArguments: any = {
      industry,
      fileType
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ListAlertsQuery>>response.data.listAlerts;
  }
  async CreateNewRevision(): Promise<boolean | null> {
    const statement = `query CreateNewRevision {
        createNewRevision
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <boolean | null>response.data.createNewRevision;
  }
  async IsJiraAuthValid(jira: JiraAuthInput): Promise<boolean | null> {
    const statement = `query IsJiraAuthValid($jira: JiraAuthInput!) {
        isJiraAuthValid(jira: $jira)
      }`;
    const gqlAPIServiceArguments: any = {
      jira
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <boolean | null>response.data.isJiraAuthValid;
  }
  async ValidateJiraAuth(jira: JiraAuthInput): Promise<string | null> {
    const statement = `query ValidateJiraAuth($jira: JiraAuthInput!) {
        validateJiraAuth(jira: $jira)
      }`;
    const gqlAPIServiceArguments: any = {
      jira
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.validateJiraAuth;
  }
  async ExportToJira(
    managerId: string,
    assessment: string,
    jira: JiraAuthInput,
    entityId: string
  ): Promise<string | null> {
    const statement = `query ExportToJira($managerId: String!, $assessment: AWSJSON!, $jira: JiraAuthInput!, $entityId: String!) {
        exportToJira(managerId: $managerId, assessment: $assessment, jira: $jira, entityId: $entityId)
      }`;
    const gqlAPIServiceArguments: any = {
      managerId,
      assessment,
      jira,
      entityId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.exportToJira;
  }
  async UpdateJiraTicketStatus(
    questionId: string,
    assessmentId: string,
    jira: JiraAuthInput
  ): Promise<string | null> {
    const statement = `query UpdateJiraTicketStatus($questionId: String!, $assessmentId: String!, $jira: JiraAuthInput!) {
        updateJiraTicketStatus(questionId: $questionId, assessmentId: $assessmentId, jira: $jira)
      }`;
    const gqlAPIServiceArguments: any = {
      questionId,
      assessmentId,
      jira
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.updateJiraTicketStatus;
  }
  async GetRapid7Data(data?: Rapid7Input): Promise<string | null> {
    const statement = `query GetRapid7Data($data: Rapid7Input) {
        getRapid7Data(data: $data)
      }`;
    const gqlAPIServiceArguments: any = {};
    if (data) {
      gqlAPIServiceArguments.data = data;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.getRapid7Data;
  }
  async TenableIntegration(
    actions?: string,
    tenable?: TenableDetailsInput
  ): Promise<string | null> {
    const statement = `query TenableIntegration($actions: String, $tenable: TenableDetailsInput) {
        tenableIntegration(actions: $actions, tenable: $tenable)
      }`;
    const gqlAPIServiceArguments: any = {};
    if (actions) {
      gqlAPIServiceArguments.actions = actions;
    }
    if (tenable) {
      gqlAPIServiceArguments.tenable = tenable;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.tenableIntegration;
  }
  async GetUser(id: string): Promise<GetUserQuery> {
    const statement = `query GetUser($id: ID!) {
        getUser(id: $id) {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async ListUsers(
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsersQuery> {
    const statement = `query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersQuery>response.data.listUsers;
  }
  async UserRole(
    role?: RoleEnum,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserRoleQuery> {
    const statement = `query UserRole($role: RoleEnum, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userRole(role: $role, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (role) {
      gqlAPIServiceArguments.role = role;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserRoleQuery>response.data.userRole;
  }
  async UserByEntityId(
    entityId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserByEntityIdQuery> {
    const statement = `query UserByEntityId($entityId: ID, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userByEntityId(entityId: $entityId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (entityId) {
      gqlAPIServiceArguments.entityId = entityId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserByEntityIdQuery>response.data.userByEntityId;
  }
  async GetEntity(id: string): Promise<GetEntityQuery> {
    const statement = `query GetEntity($id: ID!) {
        getEntity(id: $id) {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            isEnable
            isAuth
            inheritFromParent
          }
          externalFeed {
            __typename
            name
            credentials
            isEnable
            isAuth
            inheritFromParent
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
            }
            standards {
              __typename
              name
              isQualify
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          industry
          domain
          activeAssessmentId
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
            }
            status
            probability
            website
            location
            size
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  name
                  description
                  leaks
                  data
                  status
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                }
                nextToken
              }
            }
          }
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEntityQuery>response.data.getEntity;
  }
  async ListEntitys(
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEntitysQuery> {
    const statement = `query ListEntitys($filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        listEntitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              isEnable
              isAuth
              inheritFromParent
            }
            externalFeed {
              __typename
              name
              credentials
              isEnable
              isAuth
              inheritFromParent
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
              }
              standards {
                __typename
                name
                isQualify
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
              }
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            industry
            domain
            activeAssessmentId
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
              }
              status
              probability
              website
              location
              size
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    name
                    description
                    leaks
                    data
                    status
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                  }
                  nextToken
                }
              }
            }
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                  }
                  managers {
                    __typename
                    nextToken
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEntitysQuery>response.data.listEntitys;
  }
  async EntityParent(
    parentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityParentQuery> {
    const statement = `query EntityParent($parentId: ID, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityParent(parentId: $parentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              isEnable
              isAuth
              inheritFromParent
            }
            externalFeed {
              __typename
              name
              credentials
              isEnable
              isAuth
              inheritFromParent
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
              }
              standards {
                __typename
                name
                isQualify
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
              }
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            industry
            domain
            activeAssessmentId
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
              }
              status
              probability
              website
              location
              size
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    name
                    description
                    leaks
                    data
                    status
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                  }
                  nextToken
                }
              }
            }
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                  }
                  managers {
                    __typename
                    nextToken
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (parentId) {
      gqlAPIServiceArguments.parentId = parentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntityParentQuery>response.data.entityParent;
  }
  async EntityRootEntityByType(
    rootEntityId?: string,
    frameworkType?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityRootEntityByTypeQuery> {
    const statement = `query EntityRootEntityByType($rootEntityId: ID, $frameworkType: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityRootEntityByType(rootEntityId: $rootEntityId, frameworkType: $frameworkType, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              isEnable
              isAuth
              inheritFromParent
            }
            externalFeed {
              __typename
              name
              credentials
              isEnable
              isAuth
              inheritFromParent
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
              }
              standards {
                __typename
                name
                isQualify
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
              }
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            industry
            domain
            activeAssessmentId
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
              }
              status
              probability
              website
              location
              size
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    name
                    description
                    leaks
                    data
                    status
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                  }
                  nextToken
                }
              }
            }
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                  }
                  managers {
                    __typename
                    nextToken
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (rootEntityId) {
      gqlAPIServiceArguments.rootEntityId = rootEntityId;
    }
    if (frameworkType) {
      gqlAPIServiceArguments.frameworkType = frameworkType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntityRootEntityByTypeQuery>response.data.entityRootEntityByType;
  }
  async EntityChildEntityByType(
    childEntityId?: string,
    frameworkType?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityChildEntityByTypeQuery> {
    const statement = `query EntityChildEntityByType($childEntityId: ID, $frameworkType: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityChildEntityByType(childEntityId: $childEntityId, frameworkType: $frameworkType, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              isEnable
              isAuth
              inheritFromParent
            }
            externalFeed {
              __typename
              name
              credentials
              isEnable
              isAuth
              inheritFromParent
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
              }
              standards {
                __typename
                name
                isQualify
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
              }
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            industry
            domain
            activeAssessmentId
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
              }
              status
              probability
              website
              location
              size
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    name
                    description
                    leaks
                    data
                    status
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                  }
                  nextToken
                }
              }
            }
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                  }
                  managers {
                    __typename
                    nextToken
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (childEntityId) {
      gqlAPIServiceArguments.childEntityId = childEntityId;
    }
    if (frameworkType) {
      gqlAPIServiceArguments.frameworkType = frameworkType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntityChildEntityByTypeQuery>response.data.entityChildEntityByType;
  }
  async EntityFramework(
    frameworkId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityFrameworkQuery> {
    const statement = `query EntityFramework($frameworkId: ID, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityFramework(frameworkId: $frameworkId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              isEnable
              isAuth
              inheritFromParent
            }
            externalFeed {
              __typename
              name
              credentials
              isEnable
              isAuth
              inheritFromParent
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
              }
              standards {
                __typename
                name
                isQualify
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
              }
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            industry
            domain
            activeAssessmentId
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
              }
              status
              probability
              website
              location
              size
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    name
                    description
                    leaks
                    data
                    status
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                  }
                  nextToken
                }
              }
            }
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                  }
                  managers {
                    __typename
                    nextToken
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (frameworkId) {
      gqlAPIServiceArguments.frameworkId = frameworkId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntityFrameworkQuery>response.data.entityFramework;
  }
  async EntitiesByEntityType(
    entityType?: EntityTypeEnum,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntitiesByEntityTypeQuery> {
    const statement = `query EntitiesByEntityType($entityType: EntityTypeEnum, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entitiesByEntityType(entityType: $entityType, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              isEnable
              isAuth
              inheritFromParent
            }
            externalFeed {
              __typename
              name
              credentials
              isEnable
              isAuth
              inheritFromParent
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
              }
              standards {
                __typename
                name
                isQualify
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
              }
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            industry
            domain
            activeAssessmentId
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
              }
              status
              probability
              website
              location
              size
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    name
                    description
                    leaks
                    data
                    status
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                  }
                  nextToken
                }
              }
            }
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                  }
                  managers {
                    __typename
                    nextToken
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (entityType) {
      gqlAPIServiceArguments.entityType = entityType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntitiesByEntityTypeQuery>response.data.entitiesByEntityType;
  }
  async EntitiesByDomainAndEntityType(
    domain?: string,
    entityType?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntitiesByDomainAndEntityTypeQuery> {
    const statement = `query EntitiesByDomainAndEntityType($domain: String, $entityType: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entitiesByDomainAndEntityType(domain: $domain, entityType: $entityType, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              isEnable
              isAuth
              inheritFromParent
            }
            externalFeed {
              __typename
              name
              credentials
              isEnable
              isAuth
              inheritFromParent
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
              }
              standards {
                __typename
                name
                isQualify
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
              }
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            industry
            domain
            activeAssessmentId
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
              }
              status
              probability
              website
              location
              size
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    name
                    description
                    leaks
                    data
                    status
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                  }
                  nextToken
                }
              }
            }
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                  }
                  managers {
                    __typename
                    nextToken
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (domain) {
      gqlAPIServiceArguments.domain = domain;
    }
    if (entityType) {
      gqlAPIServiceArguments.entityType = entityType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntitiesByDomainAndEntityTypeQuery>(
      response.data.entitiesByDomainAndEntityType
    );
  }
  async EntityRootEntityByTypeAndFrameworkName(
    rootEntityId?: string,
    frameworkTypeFrameworkName?: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityRootEntityByTypeAndFrameworkNameQuery> {
    const statement = `query EntityRootEntityByTypeAndFrameworkName($rootEntityId: ID, $frameworkTypeFrameworkName: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityRootEntityByTypeAndFrameworkName(rootEntityId: $rootEntityId, frameworkTypeFrameworkName: $frameworkTypeFrameworkName, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              isEnable
              isAuth
              inheritFromParent
            }
            externalFeed {
              __typename
              name
              credentials
              isEnable
              isAuth
              inheritFromParent
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
              }
              standards {
                __typename
                name
                isQualify
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
              }
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            industry
            domain
            activeAssessmentId
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
              }
              status
              probability
              website
              location
              size
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    name
                    description
                    leaks
                    data
                    status
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                  }
                  nextToken
                }
              }
            }
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                  }
                  managers {
                    __typename
                    nextToken
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (rootEntityId) {
      gqlAPIServiceArguments.rootEntityId = rootEntityId;
    }
    if (frameworkTypeFrameworkName) {
      gqlAPIServiceArguments.frameworkTypeFrameworkName = frameworkTypeFrameworkName;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EntityRootEntityByTypeAndFrameworkNameQuery>(
      response.data.entityRootEntityByTypeAndFrameworkName
    );
  }
  async ListVendorsDetails(
    filter?: ModelVendorsDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVendorsDetailsQuery> {
    const statement = `query ListVendorsDetails($filter: ModelVendorsDetailFilterInput, $limit: Int, $nextToken: String) {
        listVendorsDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
            }
            status
            probability
            website
            location
            size
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  name
                  description
                  leaks
                  data
                  status
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVendorsDetailsQuery>response.data.listVendorsDetails;
  }
  async GetVendorsDetail(id: string): Promise<GetVendorsDetailQuery> {
    const statement = `query GetVendorsDetail($id: ID!) {
        getVendorsDetail(id: $id) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
          }
          status
          probability
          website
          location
          size
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                name
                description
                leaks
                data
                status
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVendorsDetailQuery>response.data.getVendorsDetail;
  }
  async GetFrameworkScores(id: string): Promise<GetFrameworkScoresQuery> {
    const statement = `query GetFrameworkScores($id: ID!) {
        getFrameworkScores(id: $id) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetFrameworkScoresQuery>response.data.getFrameworkScores;
  }
  async ListFrameworkScoress(
    filter?: ModelFrameworkScoresFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListFrameworkScoressQuery> {
    const statement = `query ListFrameworkScoress($filter: ModelFrameworkScoresFilterInput, $limit: Int, $nextToken: String) {
        listFrameworkScoress(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            assessmentId
            type
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            assignmentCount
            riskStandardsCount
            complianceStandardsCount
            standardsCount
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListFrameworkScoressQuery>response.data.listFrameworkScoress;
  }
  async FrameworkScoresByAssessment(
    assessmentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelFrameworkScoresFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<FrameworkScoresByAssessmentQuery> {
    const statement = `query FrameworkScoresByAssessment($assessmentId: ID, $sortDirection: ModelSortDirection, $filter: ModelFrameworkScoresFilterInput, $limit: Int, $nextToken: String) {
        FrameworkScoresByAssessment(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            assessmentId
            type
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            assignmentCount
            riskStandardsCount
            complianceStandardsCount
            standardsCount
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <FrameworkScoresByAssessmentQuery>(
      response.data.FrameworkScoresByAssessment
    );
  }
  async ListIntelligences(
    filter?: ModelIntelligenceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIntelligencesQuery> {
    const statement = `query ListIntelligences($filter: ModelIntelligenceFilterInput, $limit: Int, $nextToken: String) {
        listIntelligences(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                name
                description
                leaks
                data
                status
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIntelligencesQuery>response.data.listIntelligences;
  }
  async GetIntelligence(id: string): Promise<GetIntelligenceQuery> {
    const statement = `query GetIntelligence($id: ID!) {
        getIntelligence(id: $id) {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              name
              description
              leaks
              data
              status
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIntelligenceQuery>response.data.getIntelligence;
  }
  async GetBreaches(id: string): Promise<GetBreachesQuery> {
    const statement = `query GetBreaches($id: ID!) {
        getBreaches(id: $id) {
          __typename
          id
          vendorId
          date
          name
          description
          leaks
          data
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetBreachesQuery>response.data.getBreaches;
  }
  async ListBreachess(
    filter?: ModelBreachesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListBreachessQuery> {
    const statement = `query ListBreachess($filter: ModelBreachesFilterInput, $limit: Int, $nextToken: String) {
        listBreachess(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vendorId
            date
            name
            description
            leaks
            data
            status
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListBreachessQuery>response.data.listBreachess;
  }
  async BreachesByVendorIdAndStatus(
    vendorId?: string,
    status?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelBreachesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<BreachesByVendorIdAndStatusQuery> {
    const statement = `query BreachesByVendorIdAndStatus($vendorId: ID, $status: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelBreachesFilterInput, $limit: Int, $nextToken: String) {
        BreachesByVendorIdAndStatus(vendorId: $vendorId, status: $status, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vendorId
            date
            name
            description
            leaks
            data
            status
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (vendorId) {
      gqlAPIServiceArguments.vendorId = vendorId;
    }
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BreachesByVendorIdAndStatusQuery>(
      response.data.BreachesByVendorIdAndStatus
    );
  }
  async GetExternalThreats(id: string): Promise<GetExternalThreatsQuery> {
    const statement = `query GetExternalThreats($id: ID!) {
        getExternalThreats(id: $id) {
          __typename
          id
          vendorId
          source
          findings
          riskScore
          details {
            __typename
            ip
            port
            cve
            cvss
          }
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetExternalThreatsQuery>response.data.getExternalThreats;
  }
  async ListExternalThreatss(
    filter?: ModelExternalThreatsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListExternalThreatssQuery> {
    const statement = `query ListExternalThreatss($filter: ModelExternalThreatsFilterInput, $limit: Int, $nextToken: String) {
        listExternalThreatss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vendorId
            source
            findings
            riskScore
            details {
              __typename
              ip
              port
              cve
              cvss
            }
            status
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListExternalThreatssQuery>response.data.listExternalThreatss;
  }
  async ExternalThreatsByVendorIdAndStatus(
    vendorId?: string,
    status?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelExternalThreatsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ExternalThreatsByVendorIdAndStatusQuery> {
    const statement = `query ExternalThreatsByVendorIdAndStatus($vendorId: ID, $status: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelExternalThreatsFilterInput, $limit: Int, $nextToken: String) {
        ExternalThreatsByVendorIdAndStatus(vendorId: $vendorId, status: $status, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vendorId
            source
            findings
            riskScore
            details {
              __typename
              ip
              port
              cve
              cvss
            }
            status
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (vendorId) {
      gqlAPIServiceArguments.vendorId = vendorId;
    }
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ExternalThreatsByVendorIdAndStatusQuery>(
      response.data.ExternalThreatsByVendorIdAndStatus
    );
  }
  async GetOthers(id: string): Promise<GetOthersQuery> {
    const statement = `query GetOthers($id: ID!) {
        getOthers(id: $id) {
          __typename
          id
          vendorId
          name
          label
          severity
          description
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOthersQuery>response.data.getOthers;
  }
  async ListOtherss(
    filter?: ModelOthersFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOtherssQuery> {
    const statement = `query ListOtherss($filter: ModelOthersFilterInput, $limit: Int, $nextToken: String) {
        listOtherss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vendorId
            name
            label
            severity
            description
            status
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOtherssQuery>response.data.listOtherss;
  }
  async OthersByVendorIdAndStatus(
    vendorId?: string,
    status?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelOthersFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OthersByVendorIdAndStatusQuery> {
    const statement = `query OthersByVendorIdAndStatus($vendorId: ID, $status: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelOthersFilterInput, $limit: Int, $nextToken: String) {
        OthersByVendorIdAndStatus(vendorId: $vendorId, status: $status, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vendorId
            name
            label
            severity
            description
            status
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (vendorId) {
      gqlAPIServiceArguments.vendorId = vendorId;
    }
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OthersByVendorIdAndStatusQuery>(
      response.data.OthersByVendorIdAndStatus
    );
  }
  async GetQuestionnaireCompletion(
    id: string
  ): Promise<GetQuestionnaireCompletionQuery> {
    const statement = `query GetQuestionnaireCompletion($id: ID!) {
        getQuestionnaireCompletion(id: $id) {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          assignmentMap
          firstAnswer
          lastAnswer
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetQuestionnaireCompletionQuery>(
      response.data.getQuestionnaireCompletion
    );
  }
  async ListQuestionnaireCompletions(
    filter?: ModelQuestionnaireCompletionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListQuestionnaireCompletionsQuery> {
    const statement = `query ListQuestionnaireCompletions($filter: ModelQuestionnaireCompletionFilterInput, $limit: Int, $nextToken: String) {
        listQuestionnaireCompletions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            totalQuestions
            completedQuestions
            userId
            assessmentId
            assignmentMap
            firstAnswer
            lastAnswer
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                  }
                  managers {
                    __typename
                    nextToken
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListQuestionnaireCompletionsQuery>(
      response.data.listQuestionnaireCompletions
    );
  }
  async CompletionByAssessment(
    assessmentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelQuestionnaireCompletionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CompletionByAssessmentQuery> {
    const statement = `query CompletionByAssessment($assessmentId: ID, $sortDirection: ModelSortDirection, $filter: ModelQuestionnaireCompletionFilterInput, $limit: Int, $nextToken: String) {
        CompletionByAssessment(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            totalQuestions
            completedQuestions
            userId
            assessmentId
            assignmentMap
            firstAnswer
            lastAnswer
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                  }
                  managers {
                    __typename
                    nextToken
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CompletionByAssessmentQuery>response.data.CompletionByAssessment;
  }
  async CompletionByUser(
    userId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelQuestionnaireCompletionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CompletionByUserQuery> {
    const statement = `query CompletionByUser($userId: ID, $sortDirection: ModelSortDirection, $filter: ModelQuestionnaireCompletionFilterInput, $limit: Int, $nextToken: String) {
        CompletionByUser(userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            totalQuestions
            completedQuestions
            userId
            assessmentId
            assignmentMap
            firstAnswer
            lastAnswer
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                  }
                  managers {
                    __typename
                    nextToken
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CompletionByUserQuery>response.data.CompletionByUser;
  }
  async GetAnswer(id: string): Promise<GetAnswerQuery> {
    const statement = `query GetAnswer($id: ID!) {
        getAnswer(id: $id) {
          __typename
          id
          createdAt
          answer
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAnswerQuery>response.data.getAnswer;
  }
  async ListAnswers(
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAnswersQuery> {
    const statement = `query ListAnswers($filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
        listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            answer
            comment
            file {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            userId
            riskAction
            frameworkName
            questionId
            date
            assessmentId
            assignmentID
            left
            right
            isActionTaken
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAnswersQuery>response.data.listAnswers;
  }
  async AnswersByQuestionIdAndUser(
    questionId?: string,
    userId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByQuestionIdAndUserQuery> {
    const statement = `query AnswersByQuestionIdAndUser($questionId: ID, $userId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
        answersByQuestionIdAndUser(questionId: $questionId, userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            answer
            comment
            file {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            userId
            riskAction
            frameworkName
            questionId
            date
            assessmentId
            assignmentID
            left
            right
            isActionTaken
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (questionId) {
      gqlAPIServiceArguments.questionId = questionId;
    }
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AnswersByQuestionIdAndUserQuery>(
      response.data.answersByQuestionIdAndUser
    );
  }
  async AnswersByAssessmentIdAndUserId(
    assessmentId?: string,
    userId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByAssessmentIdAndUserIdQuery> {
    const statement = `query AnswersByAssessmentIdAndUserId($assessmentId: ID, $userId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
        answersByAssessmentIdAndUserId(assessmentId: $assessmentId, userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            answer
            comment
            file {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            userId
            riskAction
            frameworkName
            questionId
            date
            assessmentId
            assignmentID
            left
            right
            isActionTaken
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AnswersByAssessmentIdAndUserIdQuery>(
      response.data.answersByAssessmentIdAndUserId
    );
  }
  async AnswersByAssessmentQuestionUser(
    assessmentId?: string,
    questionIdUserId?: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByAssessmentQuestionUserQuery> {
    const statement = `query AnswersByAssessmentQuestionUser($assessmentId: ID, $questionIdUserId: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
        answersByAssessmentQuestionUser(assessmentId: $assessmentId, questionIdUserId: $questionIdUserId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            answer
            comment
            file {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            userId
            riskAction
            frameworkName
            questionId
            date
            assessmentId
            assignmentID
            left
            right
            isActionTaken
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (questionIdUserId) {
      gqlAPIServiceArguments.questionIdUserId = questionIdUserId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AnswersByAssessmentQuestionUserQuery>(
      response.data.answersByAssessmentQuestionUser
    );
  }
  async AnswersByUserId(
    userId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByUserIdQuery> {
    const statement = `query AnswersByUserId($userId: ID, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
        answersByUserId(userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            answer
            comment
            file {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            userId
            riskAction
            frameworkName
            questionId
            date
            assessmentId
            assignmentID
            left
            right
            isActionTaken
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AnswersByUserIdQuery>response.data.answersByUserId;
  }
  async GetAssignment(id: string): Promise<GetAssignmentQuery> {
    const statement = `query GetAssignment($id: ID!) {
        getAssignment(id: $id) {
          __typename
          id
          userId
          targetId
          assessmentId
          left
          right
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAssignmentQuery>response.data.getAssignment;
  }
  async ListAssignments(
    filter?: ModelAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAssignmentsQuery> {
    const statement = `query ListAssignments($filter: ModelAssignmentFilterInput, $limit: Int, $nextToken: String) {
        listAssignments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            left
            right
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAssignmentsQuery>response.data.listAssignments;
  }
  async AssignmentsByUserId(
    userId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssignmentsByUserIdQuery> {
    const statement = `query AssignmentsByUserId($userId: ID, $sortDirection: ModelSortDirection, $filter: ModelAssignmentFilterInput, $limit: Int, $nextToken: String) {
        assignmentsByUserId(userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            left
            right
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AssignmentsByUserIdQuery>response.data.assignmentsByUserId;
  }
  async AssignmentsByAssessmentId(
    assessmentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssignmentsByAssessmentIdQuery> {
    const statement = `query AssignmentsByAssessmentId($assessmentId: ID, $sortDirection: ModelSortDirection, $filter: ModelAssignmentFilterInput, $limit: Int, $nextToken: String) {
        assignmentsByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            left
            right
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AssignmentsByAssessmentIdQuery>(
      response.data.assignmentsByAssessmentId
    );
  }
  async AssignmentsByUserIdAndAssessment(
    userId?: string,
    assessmentId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssignmentsByUserIdAndAssessmentQuery> {
    const statement = `query AssignmentsByUserIdAndAssessment($userId: ID, $assessmentId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAssignmentFilterInput, $limit: Int, $nextToken: String) {
        assignmentsByUserIdAndAssessment(userId: $userId, assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            left
            right
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AssignmentsByUserIdAndAssessmentQuery>(
      response.data.assignmentsByUserIdAndAssessment
    );
  }
  async ListAssessments(
    filter?: ModelAssessmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAssessmentsQuery> {
    const statement = `query ListAssessments($filter: ModelAssessmentFilterInput, $limit: Int, $nextToken: String) {
        listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAssessmentsQuery>response.data.listAssessments;
  }
  async GetAssessment(id: string): Promise<GetAssessmentQuery> {
    const statement = `query GetAssessment($id: ID!) {
        getAssessment(id: $id) {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                  }
                  nextToken
                }
              }
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                }
                nextToken
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAssessmentQuery>response.data.getAssessment;
  }
  async AssessmentsByRootId(
    rootId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssessmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssessmentsByRootIdQuery> {
    const statement = `query AssessmentsByRootId($rootId: ID, $sortDirection: ModelSortDirection, $filter: ModelAssessmentFilterInput, $limit: Int, $nextToken: String) {
        assessmentsByRootId(rootId: $rootId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (rootId) {
      gqlAPIServiceArguments.rootId = rootId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AssessmentsByRootIdQuery>response.data.assessmentsByRootId;
  }
  async AssessmentsByChildId(
    childId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssessmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssessmentsByChildIdQuery> {
    const statement = `query AssessmentsByChildId($childId: ID, $sortDirection: ModelSortDirection, $filter: ModelAssessmentFilterInput, $limit: Int, $nextToken: String) {
        assessmentsByChildId(childId: $childId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (childId) {
      gqlAPIServiceArguments.childId = childId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AssessmentsByChildIdQuery>response.data.assessmentsByChildId;
  }
  async GetIntegrationMappings(
    id: string
  ): Promise<GetIntegrationMappingsQuery> {
    const statement = `query GetIntegrationMappings($id: ID!) {
        getIntegrationMappings(id: $id) {
          __typename
          id
          name
          projectKey
          fileName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetIntegrationMappingsQuery>response.data.getIntegrationMappings;
  }
  async ListIntegrationMappingss(
    filter?: ModelIntegrationMappingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIntegrationMappingssQuery> {
    const statement = `query ListIntegrationMappingss($filter: ModelIntegrationMappingsFilterInput, $limit: Int, $nextToken: String) {
        listIntegrationMappingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            projectKey
            fileName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListIntegrationMappingssQuery>(
      response.data.listIntegrationMappingss
    );
  }
  async IntegrationMappingsByProjectKey(
    projectKey?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIntegrationMappingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IntegrationMappingsByProjectKeyQuery> {
    const statement = `query IntegrationMappingsByProjectKey($projectKey: String, $sortDirection: ModelSortDirection, $filter: ModelIntegrationMappingsFilterInput, $limit: Int, $nextToken: String) {
        integrationMappingsByProjectKey(projectKey: $projectKey, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            projectKey
            fileName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (projectKey) {
      gqlAPIServiceArguments.projectKey = projectKey;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IntegrationMappingsByProjectKeyQuery>(
      response.data.integrationMappingsByProjectKey
    );
  }
  async GetQuestionSettings(id: string): Promise<GetQuestionSettingsQuery> {
    const statement = `query GetQuestionSettings($id: ID!) {
        getQuestionSettings(id: $id) {
          __typename
          questionId
          assessmentId
          isCollaborative
          isArtifactsMandatory
          isApprovalRequired
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetQuestionSettingsQuery>response.data.getQuestionSettings;
  }
  async ListQuestionSettingss(
    filter?: ModelQuestionSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListQuestionSettingssQuery> {
    const statement = `query ListQuestionSettingss($filter: ModelQuestionSettingsFilterInput, $limit: Int, $nextToken: String) {
        listQuestionSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            questionId
            assessmentId
            isCollaborative
            isArtifactsMandatory
            isApprovalRequired
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListQuestionSettingssQuery>response.data.listQuestionSettingss;
  }
  async QuestionSettingsByAssessmentId(
    assessmentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelQuestionSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<QuestionSettingsByAssessmentIdQuery> {
    const statement = `query QuestionSettingsByAssessmentId($assessmentId: ID, $sortDirection: ModelSortDirection, $filter: ModelQuestionSettingsFilterInput, $limit: Int, $nextToken: String) {
        QuestionSettingsByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            questionId
            assessmentId
            isCollaborative
            isArtifactsMandatory
            isApprovalRequired
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <QuestionSettingsByAssessmentIdQuery>(
      response.data.QuestionSettingsByAssessmentId
    );
  }
  async GetCustomTask(id: string): Promise<GetCustomTaskQuery> {
    const statement = `query GetCustomTask($id: ID!) {
        getCustomTask(id: $id) {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
          }
          status
          assessmentId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCustomTaskQuery>response.data.getCustomTask;
  }
  async ListCustomTasks(
    filter?: ModelCustomTaskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCustomTasksQuery> {
    const statement = `query ListCustomTasks($filter: ModelCustomTaskFilterInput, $limit: Int, $nextToken: String) {
        listCustomTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            label
            impact
            description
            standardsQualify {
              __typename
              name
              isQualify
            }
            status
            assessmentId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomTasksQuery>response.data.listCustomTasks;
  }
  async CustomTasksByAssessmentId(
    assessmentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomTaskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomTasksByAssessmentIdQuery> {
    const statement = `query CustomTasksByAssessmentId($assessmentId: ID, $sortDirection: ModelSortDirection, $filter: ModelCustomTaskFilterInput, $limit: Int, $nextToken: String) {
        CustomTasksByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            label
            impact
            description
            standardsQualify {
              __typename
              name
              isQualify
            }
            status
            assessmentId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomTasksByAssessmentIdQuery>(
      response.data.CustomTasksByAssessmentId
    );
  }
  async GetCustomTaskAssignment(
    id: string
  ): Promise<GetCustomTaskAssignmentQuery> {
    const statement = `query GetCustomTaskAssignment($id: ID!) {
        getCustomTaskAssignment(id: $id) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCustomTaskAssignmentQuery>response.data.getCustomTaskAssignment;
  }
  async ListCustomTaskAssignments(
    filter?: ModelCustomTaskAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCustomTaskAssignmentsQuery> {
    const statement = `query ListCustomTaskAssignments($filter: ModelCustomTaskAssignmentFilterInput, $limit: Int, $nextToken: String) {
        listCustomTaskAssignments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomTaskAssignmentsQuery>(
      response.data.listCustomTaskAssignments
    );
  }
  async CustomTaskAssignmentsByAssessmentId(
    assessmentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomTaskAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomTaskAssignmentsByAssessmentIdQuery> {
    const statement = `query CustomTaskAssignmentsByAssessmentId($assessmentId: ID, $sortDirection: ModelSortDirection, $filter: ModelCustomTaskAssignmentFilterInput, $limit: Int, $nextToken: String) {
        CustomTaskAssignmentsByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CustomTaskAssignmentsByAssessmentIdQuery>(
      response.data.CustomTaskAssignmentsByAssessmentId
    );
  }
  async GetAssessmentStandardFramework(
    id: string
  ): Promise<GetAssessmentStandardFrameworkQuery> {
    const statement = `query GetAssessmentStandardFramework($id: ID!) {
        getAssessmentStandardFramework(id: $id) {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAssessmentStandardFrameworkQuery>(
      response.data.getAssessmentStandardFramework
    );
  }
  async ListAssessmentStandardFrameworks(
    filter?: ModelAssessmentStandardFrameworkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAssessmentStandardFrameworksQuery> {
    const statement = `query ListAssessmentStandardFrameworks($filter: ModelAssessmentStandardFrameworkFilterInput, $limit: Int, $nextToken: String) {
        listAssessmentStandardFrameworks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            key
            type
            fileName
            filter {
              __typename
              impact
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            assessmentId
            managerId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                  }
                  managers {
                    __typename
                    nextToken
                  }
                }
                nextToken
              }
            }
            managers {
              __typename
              items {
                __typename
                id
                assessmentId
                standardFrameworkId
                managerId
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAssessmentStandardFrameworksQuery>(
      response.data.listAssessmentStandardFrameworks
    );
  }
  async StandardFrameworksByAssessmentId(
    assessmentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssessmentStandardFrameworkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<StandardFrameworksByAssessmentIdQuery> {
    const statement = `query StandardFrameworksByAssessmentId($assessmentId: ID, $sortDirection: ModelSortDirection, $filter: ModelAssessmentStandardFrameworkFilterInput, $limit: Int, $nextToken: String) {
        standardFrameworksByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            key
            type
            fileName
            filter {
              __typename
              impact
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            assessmentId
            managerId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                  }
                  managers {
                    __typename
                    nextToken
                  }
                }
                nextToken
              }
            }
            managers {
              __typename
              items {
                __typename
                id
                assessmentId
                standardFrameworkId
                managerId
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <StandardFrameworksByAssessmentIdQuery>(
      response.data.standardFrameworksByAssessmentId
    );
  }
  async GetFrameworkManager(id: string): Promise<GetFrameworkManagerQuery> {
    const statement = `query GetFrameworkManager($id: ID!) {
        getFrameworkManager(id: $id) {
          __typename
          id
          assessmentId
          standardFrameworkId
          managerId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetFrameworkManagerQuery>response.data.getFrameworkManager;
  }
  async ListFrameworkManagers(
    filter?: ModelFrameworkManagerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListFrameworkManagersQuery> {
    const statement = `query ListFrameworkManagers($filter: ModelFrameworkManagerFilterInput, $limit: Int, $nextToken: String) {
        listFrameworkManagers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            assessmentId
            standardFrameworkId
            managerId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListFrameworkManagersQuery>response.data.listFrameworkManagers;
  }
  async FrameworkManagersByAssessmentId(
    assessmentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelFrameworkManagerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<FrameworkManagersByAssessmentIdQuery> {
    const statement = `query FrameworkManagersByAssessmentId($assessmentId: ID, $sortDirection: ModelSortDirection, $filter: ModelFrameworkManagerFilterInput, $limit: Int, $nextToken: String) {
        frameworkManagersByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            assessmentId
            standardFrameworkId
            managerId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <FrameworkManagersByAssessmentIdQuery>(
      response.data.frameworkManagersByAssessmentId
    );
  }
  async FrameworkManagersByStandardFrameworkId(
    standardFrameworkId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelFrameworkManagerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<FrameworkManagersByStandardFrameworkIdQuery> {
    const statement = `query FrameworkManagersByStandardFrameworkId($standardFrameworkId: String, $sortDirection: ModelSortDirection, $filter: ModelFrameworkManagerFilterInput, $limit: Int, $nextToken: String) {
        frameworkManagersByStandardFrameworkId(standardFrameworkId: $standardFrameworkId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            assessmentId
            standardFrameworkId
            managerId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (standardFrameworkId) {
      gqlAPIServiceArguments.standardFrameworkId = standardFrameworkId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <FrameworkManagersByStandardFrameworkIdQuery>(
      response.data.frameworkManagersByStandardFrameworkId
    );
  }
  async GetComment(id: string): Promise<GetCommentQuery> {
    const statement = `query GetComment($id: ID!) {
        getComment(id: $id) {
          __typename
          id
          latestSource
          comment
          date
          questionId
          assessmentId
          userId
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
            nextToken
          }
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCommentQuery>response.data.getComment;
  }
  async ListComments(
    filter?: ModelCommentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCommentsQuery> {
    const statement = `query ListComments($filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
        listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            latestSource
            comment
            date
            questionId
            assessmentId
            userId
            user {
              __typename
              items {
                __typename
                id
                name
                email
                role
                phone
                entityId
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
              }
              nextToken
            }
            exports {
              __typename
              items {
                __typename
                id
                destination
                destinationCategoryId
                category
                categoryId
                date
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCommentsQuery>response.data.listComments;
  }
  async CommentByAssessmentId(
    assessmentId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCommentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CommentByAssessmentIdQuery> {
    const statement = `query CommentByAssessmentId($assessmentId: String, $sortDirection: ModelSortDirection, $filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
        CommentByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            latestSource
            comment
            date
            questionId
            assessmentId
            userId
            user {
              __typename
              items {
                __typename
                id
                name
                email
                role
                phone
                entityId
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
              }
              nextToken
            }
            exports {
              __typename
              items {
                __typename
                id
                destination
                destinationCategoryId
                category
                categoryId
                date
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CommentByAssessmentIdQuery>response.data.CommentByAssessmentId;
  }
  async GetExport(id: string): Promise<GetExportQuery> {
    const statement = `query GetExport($id: ID!) {
        getExport(id: $id) {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetExportQuery>response.data.getExport;
  }
  async ListExports(
    filter?: ModelExportFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListExportsQuery> {
    const statement = `query ListExports($filter: ModelExportFilterInput, $limit: Int, $nextToken: String) {
        listExports(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            destination
            destinationCategoryId
            category
            categoryId
            date
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListExportsQuery>response.data.listExports;
  }
  async ExportsByCategoryId(
    categoryId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelExportFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ExportsByCategoryIdQuery> {
    const statement = `query ExportsByCategoryId($categoryId: String, $sortDirection: ModelSortDirection, $filter: ModelExportFilterInput, $limit: Int, $nextToken: String) {
        exportsByCategoryId(categoryId: $categoryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            destination
            destinationCategoryId
            category
            categoryId
            date
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (categoryId) {
      gqlAPIServiceArguments.categoryId = categoryId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ExportsByCategoryIdQuery>response.data.exportsByCategoryId;
  }
  OnFrameworkScoresUpdatedListener: Observable<
    OnFrameworkScoresUpdatedSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnFrameworkScoresUpdated($id: ID!) {
        onFrameworkScoresUpdated(id: $id) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
        }
      }`
    )
  ) as Observable<OnFrameworkScoresUpdatedSubscription>;

  OnCreateUserListener: Observable<OnCreateUserSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateUser {
        onCreateUser {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
        }
      }`
    )
  ) as Observable<OnCreateUserSubscription>;

  OnUpdateUserListener: Observable<OnUpdateUserSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUser {
        onUpdateUser {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
        }
      }`
    )
  ) as Observable<OnUpdateUserSubscription>;

  OnDeleteUserListener: Observable<OnDeleteUserSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUser {
        onDeleteUser {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
        }
      }`
    )
  ) as Observable<OnDeleteUserSubscription>;

  OnCreateEntityListener: Observable<OnCreateEntitySubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateEntity {
        onCreateEntity {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            isEnable
            isAuth
            inheritFromParent
          }
          externalFeed {
            __typename
            name
            credentials
            isEnable
            isAuth
            inheritFromParent
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
            }
            standards {
              __typename
              name
              isQualify
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          industry
          domain
          activeAssessmentId
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
            }
            status
            probability
            website
            location
            size
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  name
                  description
                  leaks
                  data
                  status
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                }
                nextToken
              }
            }
          }
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
        }
      }`
    )
  ) as Observable<OnCreateEntitySubscription>;

  OnUpdateEntityListener: Observable<OnUpdateEntitySubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEntity {
        onUpdateEntity {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            isEnable
            isAuth
            inheritFromParent
          }
          externalFeed {
            __typename
            name
            credentials
            isEnable
            isAuth
            inheritFromParent
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
            }
            standards {
              __typename
              name
              isQualify
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          industry
          domain
          activeAssessmentId
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
            }
            status
            probability
            website
            location
            size
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  name
                  description
                  leaks
                  data
                  status
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                }
                nextToken
              }
            }
          }
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
        }
      }`
    )
  ) as Observable<OnUpdateEntitySubscription>;

  OnDeleteEntityListener: Observable<OnDeleteEntitySubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteEntity {
        onDeleteEntity {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            isEnable
            isAuth
            inheritFromParent
          }
          externalFeed {
            __typename
            name
            credentials
            isEnable
            isAuth
            inheritFromParent
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
            }
            standards {
              __typename
              name
              isQualify
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          industry
          domain
          activeAssessmentId
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
            }
            status
            probability
            website
            location
            size
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  name
                  description
                  leaks
                  data
                  status
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                }
                nextToken
              }
            }
          }
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
        }
      }`
    )
  ) as Observable<OnDeleteEntitySubscription>;

  OnCreateVendorsDetailListener: Observable<
    OnCreateVendorsDetailSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateVendorsDetail {
        onCreateVendorsDetail {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
          }
          status
          probability
          website
          location
          size
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                name
                description
                leaks
                data
                status
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
              }
              nextToken
            }
          }
        }
      }`
    )
  ) as Observable<OnCreateVendorsDetailSubscription>;

  OnUpdateVendorsDetailListener: Observable<
    OnUpdateVendorsDetailSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateVendorsDetail {
        onUpdateVendorsDetail {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
          }
          status
          probability
          website
          location
          size
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                name
                description
                leaks
                data
                status
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
              }
              nextToken
            }
          }
        }
      }`
    )
  ) as Observable<OnUpdateVendorsDetailSubscription>;

  OnDeleteVendorsDetailListener: Observable<
    OnDeleteVendorsDetailSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteVendorsDetail {
        onDeleteVendorsDetail {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
          }
          status
          probability
          website
          location
          size
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                name
                description
                leaks
                data
                status
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
              }
              nextToken
            }
          }
        }
      }`
    )
  ) as Observable<OnDeleteVendorsDetailSubscription>;

  OnCreateIntelligenceListener: Observable<
    OnCreateIntelligenceSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateIntelligence {
        onCreateIntelligence {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              name
              description
              leaks
              data
              status
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnCreateIntelligenceSubscription>;

  OnUpdateIntelligenceListener: Observable<
    OnUpdateIntelligenceSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateIntelligence {
        onUpdateIntelligence {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              name
              description
              leaks
              data
              status
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnUpdateIntelligenceSubscription>;

  OnDeleteIntelligenceListener: Observable<
    OnDeleteIntelligenceSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteIntelligence {
        onDeleteIntelligence {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              name
              description
              leaks
              data
              status
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnDeleteIntelligenceSubscription>;

  OnCreateBreachesListener: Observable<
    OnCreateBreachesSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateBreaches {
        onCreateBreaches {
          __typename
          id
          vendorId
          date
          name
          description
          leaks
          data
          status
        }
      }`
    )
  ) as Observable<OnCreateBreachesSubscription>;

  OnUpdateBreachesListener: Observable<
    OnUpdateBreachesSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateBreaches {
        onUpdateBreaches {
          __typename
          id
          vendorId
          date
          name
          description
          leaks
          data
          status
        }
      }`
    )
  ) as Observable<OnUpdateBreachesSubscription>;

  OnDeleteBreachesListener: Observable<
    OnDeleteBreachesSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteBreaches {
        onDeleteBreaches {
          __typename
          id
          vendorId
          date
          name
          description
          leaks
          data
          status
        }
      }`
    )
  ) as Observable<OnDeleteBreachesSubscription>;

  OnCreateExternalThreatsListener: Observable<
    OnCreateExternalThreatsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateExternalThreats {
        onCreateExternalThreats {
          __typename
          id
          vendorId
          source
          findings
          riskScore
          details {
            __typename
            ip
            port
            cve
            cvss
          }
          status
        }
      }`
    )
  ) as Observable<OnCreateExternalThreatsSubscription>;

  OnUpdateExternalThreatsListener: Observable<
    OnUpdateExternalThreatsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateExternalThreats {
        onUpdateExternalThreats {
          __typename
          id
          vendorId
          source
          findings
          riskScore
          details {
            __typename
            ip
            port
            cve
            cvss
          }
          status
        }
      }`
    )
  ) as Observable<OnUpdateExternalThreatsSubscription>;

  OnDeleteExternalThreatsListener: Observable<
    OnDeleteExternalThreatsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteExternalThreats {
        onDeleteExternalThreats {
          __typename
          id
          vendorId
          source
          findings
          riskScore
          details {
            __typename
            ip
            port
            cve
            cvss
          }
          status
        }
      }`
    )
  ) as Observable<OnDeleteExternalThreatsSubscription>;

  OnCreateOthersListener: Observable<OnCreateOthersSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateOthers {
        onCreateOthers {
          __typename
          id
          vendorId
          name
          label
          severity
          description
          status
        }
      }`
    )
  ) as Observable<OnCreateOthersSubscription>;

  OnUpdateOthersListener: Observable<OnUpdateOthersSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateOthers {
        onUpdateOthers {
          __typename
          id
          vendorId
          name
          label
          severity
          description
          status
        }
      }`
    )
  ) as Observable<OnUpdateOthersSubscription>;

  OnDeleteOthersListener: Observable<OnDeleteOthersSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteOthers {
        onDeleteOthers {
          __typename
          id
          vendorId
          name
          label
          severity
          description
          status
        }
      }`
    )
  ) as Observable<OnDeleteOthersSubscription>;

  OnCreateQuestionnaireCompletionListener: Observable<
    OnCreateQuestionnaireCompletionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateQuestionnaireCompletion {
        onCreateQuestionnaireCompletion {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          assignmentMap
          firstAnswer
          lastAnswer
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
        }
      }`
    )
  ) as Observable<OnCreateQuestionnaireCompletionSubscription>;

  OnUpdateQuestionnaireCompletionListener: Observable<
    OnUpdateQuestionnaireCompletionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateQuestionnaireCompletion {
        onUpdateQuestionnaireCompletion {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          assignmentMap
          firstAnswer
          lastAnswer
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
        }
      }`
    )
  ) as Observable<OnUpdateQuestionnaireCompletionSubscription>;

  OnDeleteQuestionnaireCompletionListener: Observable<
    OnDeleteQuestionnaireCompletionSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteQuestionnaireCompletion {
        onDeleteQuestionnaireCompletion {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          assignmentMap
          firstAnswer
          lastAnswer
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
        }
      }`
    )
  ) as Observable<OnDeleteQuestionnaireCompletionSubscription>;

  OnCreateAnswerListener: Observable<OnCreateAnswerSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateAnswer {
        onCreateAnswer {
          __typename
          id
          createdAt
          answer
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`
    )
  ) as Observable<OnCreateAnswerSubscription>;

  OnUpdateAnswerListener: Observable<OnUpdateAnswerSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAnswer {
        onUpdateAnswer {
          __typename
          id
          createdAt
          answer
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`
    )
  ) as Observable<OnUpdateAnswerSubscription>;

  OnDeleteAnswerListener: Observable<OnDeleteAnswerSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAnswer {
        onDeleteAnswer {
          __typename
          id
          createdAt
          answer
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`
    )
  ) as Observable<OnDeleteAnswerSubscription>;

  OnCreateAssignmentListener: Observable<
    OnCreateAssignmentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAssignment {
        onCreateAssignment {
          __typename
          id
          userId
          targetId
          assessmentId
          left
          right
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`
    )
  ) as Observable<OnCreateAssignmentSubscription>;

  OnUpdateAssignmentListener: Observable<
    OnUpdateAssignmentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAssignment {
        onUpdateAssignment {
          __typename
          id
          userId
          targetId
          assessmentId
          left
          right
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`
    )
  ) as Observable<OnUpdateAssignmentSubscription>;

  OnDeleteAssignmentListener: Observable<
    OnDeleteAssignmentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAssignment {
        onDeleteAssignment {
          __typename
          id
          userId
          targetId
          assessmentId
          left
          right
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`
    )
  ) as Observable<OnDeleteAssignmentSubscription>;

  OnCreateAssessmentListener: Observable<
    OnCreateAssessmentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAssessment {
        onCreateAssessment {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                  }
                  nextToken
                }
              }
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                }
                nextToken
              }
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnCreateAssessmentSubscription>;

  OnUpdateAssessmentListener: Observable<
    OnUpdateAssessmentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAssessment {
        onUpdateAssessment {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                  }
                  nextToken
                }
              }
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                }
                nextToken
              }
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnUpdateAssessmentSubscription>;

  OnDeleteAssessmentListener: Observable<
    OnDeleteAssessmentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAssessment {
        onDeleteAssessment {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                  }
                  nextToken
                }
              }
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                }
                nextToken
              }
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnDeleteAssessmentSubscription>;

  OnCreateIntegrationMappingsListener: Observable<
    OnCreateIntegrationMappingsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateIntegrationMappings {
        onCreateIntegrationMappings {
          __typename
          id
          name
          projectKey
          fileName
        }
      }`
    )
  ) as Observable<OnCreateIntegrationMappingsSubscription>;

  OnUpdateIntegrationMappingsListener: Observable<
    OnUpdateIntegrationMappingsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateIntegrationMappings {
        onUpdateIntegrationMappings {
          __typename
          id
          name
          projectKey
          fileName
        }
      }`
    )
  ) as Observable<OnUpdateIntegrationMappingsSubscription>;

  OnDeleteIntegrationMappingsListener: Observable<
    OnDeleteIntegrationMappingsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteIntegrationMappings {
        onDeleteIntegrationMappings {
          __typename
          id
          name
          projectKey
          fileName
        }
      }`
    )
  ) as Observable<OnDeleteIntegrationMappingsSubscription>;

  OnCreateQuestionSettingsListener: Observable<
    OnCreateQuestionSettingsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateQuestionSettings {
        onCreateQuestionSettings {
          __typename
          questionId
          assessmentId
          isCollaborative
          isArtifactsMandatory
          isApprovalRequired
        }
      }`
    )
  ) as Observable<OnCreateQuestionSettingsSubscription>;

  OnUpdateQuestionSettingsListener: Observable<
    OnUpdateQuestionSettingsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateQuestionSettings {
        onUpdateQuestionSettings {
          __typename
          questionId
          assessmentId
          isCollaborative
          isArtifactsMandatory
          isApprovalRequired
        }
      }`
    )
  ) as Observable<OnUpdateQuestionSettingsSubscription>;

  OnDeleteQuestionSettingsListener: Observable<
    OnDeleteQuestionSettingsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteQuestionSettings {
        onDeleteQuestionSettings {
          __typename
          questionId
          assessmentId
          isCollaborative
          isArtifactsMandatory
          isApprovalRequired
        }
      }`
    )
  ) as Observable<OnDeleteQuestionSettingsSubscription>;

  OnCreateCustomTaskListener: Observable<
    OnCreateCustomTaskSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCustomTask {
        onCreateCustomTask {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
          }
          status
          assessmentId
        }
      }`
    )
  ) as Observable<OnCreateCustomTaskSubscription>;

  OnUpdateCustomTaskListener: Observable<
    OnUpdateCustomTaskSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCustomTask {
        onUpdateCustomTask {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
          }
          status
          assessmentId
        }
      }`
    )
  ) as Observable<OnUpdateCustomTaskSubscription>;

  OnDeleteCustomTaskListener: Observable<
    OnDeleteCustomTaskSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCustomTask {
        onDeleteCustomTask {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
          }
          status
          assessmentId
        }
      }`
    )
  ) as Observable<OnDeleteCustomTaskSubscription>;

  OnCreateCustomTaskAssignmentListener: Observable<
    OnCreateCustomTaskAssignmentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCustomTaskAssignment {
        onCreateCustomTaskAssignment {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`
    )
  ) as Observable<OnCreateCustomTaskAssignmentSubscription>;

  OnUpdateCustomTaskAssignmentListener: Observable<
    OnUpdateCustomTaskAssignmentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCustomTaskAssignment {
        onUpdateCustomTaskAssignment {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`
    )
  ) as Observable<OnUpdateCustomTaskAssignmentSubscription>;

  OnDeleteCustomTaskAssignmentListener: Observable<
    OnDeleteCustomTaskAssignmentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCustomTaskAssignment {
        onDeleteCustomTaskAssignment {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
          }
        }
      }`
    )
  ) as Observable<OnDeleteCustomTaskAssignmentSubscription>;

  OnCreateAssessmentStandardFrameworkListener: Observable<
    OnCreateAssessmentStandardFrameworkSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAssessmentStandardFramework {
        onCreateAssessmentStandardFramework {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnCreateAssessmentStandardFrameworkSubscription>;

  OnUpdateAssessmentStandardFrameworkListener: Observable<
    OnUpdateAssessmentStandardFrameworkSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAssessmentStandardFramework {
        onUpdateAssessmentStandardFramework {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnUpdateAssessmentStandardFrameworkSubscription>;

  OnDeleteAssessmentStandardFrameworkListener: Observable<
    OnDeleteAssessmentStandardFrameworkSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAssessmentStandardFramework {
        onDeleteAssessmentStandardFramework {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                }
              }
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                }
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnDeleteAssessmentStandardFrameworkSubscription>;

  OnCreateFrameworkManagerListener: Observable<
    OnCreateFrameworkManagerSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateFrameworkManager {
        onCreateFrameworkManager {
          __typename
          id
          assessmentId
          standardFrameworkId
          managerId
        }
      }`
    )
  ) as Observable<OnCreateFrameworkManagerSubscription>;

  OnUpdateFrameworkManagerListener: Observable<
    OnUpdateFrameworkManagerSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateFrameworkManager {
        onUpdateFrameworkManager {
          __typename
          id
          assessmentId
          standardFrameworkId
          managerId
        }
      }`
    )
  ) as Observable<OnUpdateFrameworkManagerSubscription>;

  OnDeleteFrameworkManagerListener: Observable<
    OnDeleteFrameworkManagerSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteFrameworkManager {
        onDeleteFrameworkManager {
          __typename
          id
          assessmentId
          standardFrameworkId
          managerId
        }
      }`
    )
  ) as Observable<OnDeleteFrameworkManagerSubscription>;

  OnCreateCommentListener: Observable<
    OnCreateCommentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateComment {
        onCreateComment {
          __typename
          id
          latestSource
          comment
          date
          questionId
          assessmentId
          userId
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
            nextToken
          }
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnCreateCommentSubscription>;

  OnUpdateCommentListener: Observable<
    OnUpdateCommentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateComment {
        onUpdateComment {
          __typename
          id
          latestSource
          comment
          date
          questionId
          assessmentId
          userId
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
            nextToken
          }
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnUpdateCommentSubscription>;

  OnDeleteCommentListener: Observable<
    OnDeleteCommentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteComment {
        onDeleteComment {
          __typename
          id
          latestSource
          comment
          date
          questionId
          assessmentId
          userId
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
            }
            nextToken
          }
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<OnDeleteCommentSubscription>;

  OnCreateExportListener: Observable<OnCreateExportSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateExport {
        onCreateExport {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
        }
      }`
    )
  ) as Observable<OnCreateExportSubscription>;

  OnUpdateExportListener: Observable<OnUpdateExportSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateExport {
        onUpdateExport {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
        }
      }`
    )
  ) as Observable<OnUpdateExportSubscription>;

  OnDeleteExportListener: Observable<OnDeleteExportSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteExport {
        onDeleteExport {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
        }
      }`
    )
  ) as Observable<OnDeleteExportSubscription>;
}
