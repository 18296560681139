import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from 'app/shared/utils.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GetEntityQuery, StandardType } from 'app/API.service';
import { EntityService } from 'app/shared/entity.service';
import { NGXLogger } from 'ngx-logger';

class QuestionnaireStatitics {
  tag: string;
  criticalGaps = 0;
  allGaps = 0;
  status: QuestionnaireStatus;
  constructor() {
    this.status = new QuestionnaireStatus();
  }
}

class QuestionnaireStatus {
  totalQuestions = 0;
  completedQuestions = 0;
}

@Component({
  selector: 'cygov-vendor-questionnaire',
  templateUrl: './vendor-questionnaire.component.html',
  styleUrls: ['./vendor-questionnaire.component.scss'],
})
export class QuestionnaireVendorComponent implements OnInit {
  @Input() vendor: GetEntityQuery;
  readinessQuestionnaires;
  questionnaires: QuestionnaireStatitics[] = [];
  isLoading = true;
  headElements = ['Questionnaire Tag', 'Status', 'Critical Gaps', 'All Gaps', ''];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private entityService: EntityService,
    private logger: NGXLogger
  ) {}

  async ngOnInit() {
    try {
      this.questionnaires = await this.buildQuestionnaireStatistics(this.vendor);

      this.isLoading = false;
    } catch (error) {
      this.logger.error('Error : ', error);
      this.isLoading = false;
    }
  }

  async buildQuestionnaireStatistics(vendor: GetEntityQuery): Promise<QuestionnaireStatitics[]> {
    const standards = vendor.activeAssessment.standardFrameworkList.items;
    const frameworkScores = await Promise.all(
      standards.map(async standard => {
        const scores = await this.entityService.getFrameworkScores(
          vendor.activeAssessmentId,
          standard.key
        );
        return { ...scores, type: standard.type };
      })
    );

    const questionnaires: QuestionnaireStatitics[] = frameworkScores.map(
      (frameworkScore, index) => {
        const questionnaireStat: QuestionnaireStatitics = {
          tag:
            frameworkScore.type === StandardType.RISK_FRAMEWORK
              ? 'technology'
              : standards[index].key,
          allGaps: frameworkScore.totalGaps,
          criticalGaps: frameworkScore.criticalGaps,
          status: {
            completedQuestions: frameworkScore.completedQuestions,
            totalQuestions: frameworkScore.totalQuestions,
          },
        };
        return questionnaireStat;
      }
    );

    return questionnaires;
  }

  goToVendorQuestionnaire(frameworkName: string): void {
    frameworkName = frameworkName.toLowerCase() === 'technology' ? 'nist_csf' : frameworkName;
    this.toastr.info('Loading Questionnaire');
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.router.navigate([`third-party/${entityId}/collection`, this.vendor.id], {
      queryParams: { userId: this.vendor.projectManager, frameworkName },
    });
  }

  goToGaps(filter: string): void {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    if (filter === 'all') {
      this.router.navigate([`third-party/${entityId}/gaps/${this.vendor.id}`]);
    } else {
      this.router.navigate([`third-party/${entityId}/gaps/${this.vendor.id}`], {
        queryParams: { filter },
      });
    }
  }
}
