import { ConfirmationModalModule } from './../shared/confirmation-modal/confirmation-modal.module';
import { ScanDetailsModule } from './../shared/scan-details/scan-details.module';
import { NewTaskModalModule } from './../shared/new-task-modal/new-task-modal.module';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { SelectModule } from './../shared/select/select.module';
import { ThirdPartyProgressBarModule } from '../shared/third-party-progress-bar/third-party-progress-bar.module';
import { HeatMapComponent } from './risk-management-heatmap/heat-map/heat-map.component';
import { ScoreColorModule } from '../shared/score-color/score-color.module';
import { CircularProgressModule } from '../shared/circular-progress/circular-progress.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThirdPartyComponent } from './third-party.component';
import { ThirdPartyRoutes } from './third-party.routes';
import { EntityLeaderRoleGuard } from 'app/shared/guards/entity-leader-role.guard';
import { StatusComponent } from './third-party-overview/status-widget/status/status.component';
import { NgbModule, NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CygovLoaderModule } from 'app/shared/cygov-loader/cygov-loader.module';
import { QuestionnaireVendorComponent } from './all-vendor-list/vendor-detail/vendor-questionnaire/vendor-questionnaire.component';
import { TotalVendorWidgetComponent } from './third-party-overview/total-vendor-widget/total-vendor-widget.component';
import { SummaryWidgetComponent } from './third-party-overview/summary-widget/summary-widget.component';
import { ImpactWidgetComponent } from './third-party-overview/impact-widget/impact-widget.component';
import { StatusWidgetComponent } from './third-party-overview/status-widget/status-widget.component';
import { ExternalScanWidgetComponent } from './third-party-overview/external-scan-widget/external-scan-widget.component';
import { AllVendorListComponent } from './all-vendor-list/all-vendor-list.component';
import { VendorItemComponent } from './all-vendor-list/vendor-item/vendor-item.component';
import { VendorTableComponent } from './risk-management-heatmap/vendor-table/vendor-table.component';
import { RiskManagementHeatmapComponent } from './risk-management-heatmap/risk-management-heatmap.component';
import { IntelligenceComponent } from './all-vendor-list/vendor-detail/intelligence/intelligence.component';
import { ArtifactsComponent } from './all-vendor-list/vendor-detail/artifacts/artifacts.component';
import { VendorListHeaderComponent } from './all-vendor-list/vendor-list-header/vendor-list-header.component';
import { VendorDetailComponent } from './all-vendor-list/vendor-detail/vendor-detail.component';
import { VendorListComponent } from './all-vendor-list/vendor-list/vendor-list.component';
import { VendorOverviewComponent } from './all-vendor-list/vendor-detail/vendor-overview/vendor-overview.component';
import { SvgIconModule } from 'app/shared/svg-icon/svg-icon.module';
import { FollowUpModalComponent } from './all-vendor-list/vendor-detail/vendor-overview/follow-up-modal/follow-up-modal.component';

import { UiToolsModule } from 'app/shared/ui-tools/ui-tools.module';
import { PipesModule } from '../shared/pipes/pipes.module';
import { ToggleButtonModule } from 'app/shared/toggle-button/toggle-button.module';
import { CheckboxModule } from 'app/shared/checkbox/checkbox.module';
import { ThirdPartyOverviewComponent } from './third-party-overview/third-party-overview.component';
import { AddVendorModalComponent } from './add-vendor/add-vendor-modal/add-vendor-modal.component';
import { FormsModule } from '@angular/forms';
import { ScanChartModule } from 'app/shared/scan-chart/scan-chart.module';
import { EntityStandardsModule } from 'app/shared/entity-standards/entity-standards.module';
import { DefaultSettingModalComponent } from './default-setting-modal/default-setting-modal.component';
import { VendorLeaderRoleGuard } from 'app/shared/guards/vendor-leader-role.guard';
import { RouterExtService } from '../shared/router-external.service';
import { AddVendorComponent } from './add-vendor/add-vendor.component';
import { AddCrbVendorModalComponent } from './add-vendor/add-crb-vendor-modal/add-crb-vendor-modal.component';

@NgModule({
  declarations: [
    ThirdPartyComponent,
    StatusComponent,
    VendorOverviewComponent,
    HeatMapComponent,
    VendorTableComponent,
    QuestionnaireVendorComponent,
    TotalVendorWidgetComponent,
    SummaryWidgetComponent,
    ImpactWidgetComponent,
    StatusWidgetComponent,
    ExternalScanWidgetComponent,
    AllVendorListComponent,
    VendorItemComponent,
    VendorTableComponent,
    RiskManagementHeatmapComponent,
    ArtifactsComponent,
    IntelligenceComponent,
    VendorListHeaderComponent,
    VendorDetailComponent,
    VendorListComponent,
    VendorListHeaderComponent,
    FollowUpModalComponent,
    ThirdPartyOverviewComponent,
    AddVendorModalComponent,
    DefaultSettingModalComponent,
    AddVendorComponent,
    AddCrbVendorModalComponent,
  ],
  imports: [
    CommonModule,
    ThirdPartyRoutes,
    CircularProgressModule,
    ScoreColorModule,
    NgbModule,
    CygovLoaderModule,
    ThirdPartyProgressBarModule,
    SvgIconModule,
    UiToolsModule,
    PipesModule,
    ToggleButtonModule,
    CheckboxModule,
    FormsModule,
    ScanChartModule,
    EntityStandardsModule,
    NgbDatepickerModule,
    NgbTooltipModule,
    SelectModule,
    VirtualScrollerModule,
    ConfirmationModalModule,
    ScanDetailsModule,
    NewTaskModalModule,
  ],
  entryComponents: [
    FollowUpModalComponent,
    DefaultSettingModalComponent,
    AddVendorModalComponent,
    AddCrbVendorModalComponent,
    DefaultSettingModalComponent,
  ],
  exports: [DefaultSettingModalComponent],
  providers: [EntityLeaderRoleGuard, VendorLeaderRoleGuard, RouterExtService],
})
export class ThirdPartyModule {}
