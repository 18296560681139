export class BudgetChartData {
  text: string;
  value: number;
}

export class BudgetChart {
  arcPath: string;
  innerLinePath: string;
  outereLinePath: string;
  circleCoordinates: ChartCoordinates;
  textCoordinates: ChartCoordinates;
  textAnchor: string;
  text: string;
  value: string;
}

export class ChartCoordinates {
  x: string;
  y: string;
  angle: number;

  constructor(x: string, y: string, angle = 0) {
    this.x = x;
    this.y = y;
    this.angle = angle;
  }
}

export class LineData {
  x: number;
  y: number;
  isOccupied: boolean;
  distance: number;
}
