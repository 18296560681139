import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

interface Coordinates {
  x: number;
  y: number;
}

@Component({
  selector: 'cygov-circular-progress',
  templateUrl: './circular-progress.component.html',
  styleUrls: ['./circular-progress.component.scss'],
})
export class CircularProgressComponent implements OnInit {
  @Input() score: number;
  @Input() target: number;
  @Input() average: number;
  @Input() svgHeight = 440;
  @Input() svgWidth = 440;
  @Input() circleXDivisor = 2;
  @Input() circleYDivisor = 2;
  @Input() showTarget = true;

  MIN_SCORE = 0;
  MAX_SCORE = 10;

  STARTING_ANGLE = 35;
  ENDING_ANGLE = 325;

  // For Overview Vendor Widget only.
  SVG_HEIGHT_OFFSET = -100;
  SVG_WIDTH_OFFSET = -100;
  SVG_X_CROP = 70;
  SVG_Y_CROP = 50;

  BG_ARC_STROKE = 6;
  FG_ARC_STROKE = 12;
  LINE_STROKE = 4;
  ARC_RADIUS = 120;
  LINE_LENGTH = 25;
  SCORE_LABEL_OFFSET = 10;
  svgViewBox: string;
  lineLabelRadius: number;

  availableAngle: number;
  centerX: number;
  centerY: number;

  minScoreAxisX: number;
  minScoreAxisY: number;
  maxScoreAxisX: number;
  maxScoreAxisY: number;
  backgroundArcData: string;
  scoreArcData: string;

  targetLabel: string;
  targetCoordinates: Coordinates;

  averageLabel: string;
  averageCoordinates: Coordinates;

  scoreStrokeClassColor: string;

  constructor(private toastr: ToastrService) {}

  ngOnInit() {
    if (this.score < this.MIN_SCORE || this.score > this.MAX_SCORE) {
      this.toastr.error('Invalid Score Value');
      return;
    }
    this.initializeAttributes();

    if (!this.showTarget) {
      this.updateSVGViewBox();
    }

    this.setBackgroundArc();
    this.setScoreArc();
    this.setTarget();
    this.setAverage();
  }

  private initializeAttributes() {
    this.svgViewBox = `0 0 ${this.svgWidth} ${this.svgHeight}`;
    this.lineLabelRadius = this.ARC_RADIUS + this.LINE_LENGTH + 15;
    this.availableAngle = this.ENDING_ANGLE - this.STARTING_ANGLE;
    this.centerX = this.svgWidth / this.circleXDivisor;
    this.centerY = this.svgHeight / this.circleYDivisor;
  }

  private setBackgroundArc() {
    this.backgroundArcData = this.describeArc(
      this.centerX,
      this.centerY,
      this.ARC_RADIUS,
      this.STARTING_ANGLE,
      this.ENDING_ANGLE
    );

    const minScoreAxis = this.polarToCartesian(
      this.centerX,
      this.centerY,
      this.ARC_RADIUS,
      this.STARTING_ANGLE - this.SCORE_LABEL_OFFSET
    );
    this.minScoreAxisX = minScoreAxis.x;
    this.minScoreAxisY = minScoreAxis.y;

    const maxScoreAxis = this.polarToCartesian(
      this.centerX,
      this.centerY,
      this.ARC_RADIUS,
      this.ENDING_ANGLE + this.SCORE_LABEL_OFFSET
    );
    this.maxScoreAxisX = maxScoreAxis.x;
    this.maxScoreAxisY = maxScoreAxis.y;
  }

  private setScoreArc() {
    const relativeScore = this.score * (this.availableAngle / this.MAX_SCORE) + this.STARTING_ANGLE;
    this.scoreArcData = this.describeArc(
      this.centerX,
      this.centerY,
      this.ARC_RADIUS,
      this.STARTING_ANGLE,
      relativeScore
    );
    this.scoreStrokeClassColor = this.getScoreClass(this.score);
  }

  private setTarget() {
    const targetAngle = this.target * (this.availableAngle / this.MAX_SCORE) + this.STARTING_ANGLE;
    this.targetCoordinates = this.describeCircle(
      this.centerX,
      this.centerY,
      this.ARC_RADIUS,
      targetAngle
    );
    this.targetLabel = this.getTextLabel('Target:', targetAngle);
    this.targetLabel = this.targetLabel
      ? `${this.targetLabel} ${this.target.toFixed(1)}`
      : this.targetLabel;
  }

  private setAverage(): void {
    const avgAngle = this.average * (this.availableAngle / this.MAX_SCORE) + this.STARTING_ANGLE;
    this.averageCoordinates = this.describeCircle(
      this.centerX,
      this.centerY,
      this.ARC_RADIUS,
      avgAngle
    );
    this.averageLabel = this.getTextLabel('Average:', avgAngle);
    this.averageLabel = this.averageLabel
      ? `${this.averageLabel} ${this.average}`
      : this.averageLabel;
  }

  private getScoreClass(score: number): string {
    let className = null;
    switch (true) {
      case score <= 3.33:
        className = 'score-low-stroke';
        break;
      case score < 6.66:
        className = 'score-medium-stroke';
        break;
      case score >= 6.66:
        className = 'score-high-stroke';
        break;
    }
    return className ? className : '';
  }

  private getLabelAnchor(angle): string {
    if (angle <= 70) {
      return 'start';
    } else if (angle <= 100) {
      return 'middle';
    } else if (angle <= 260) {
      return 'end';
    } else if (angle <= 280) {
      return 'middle';
    } else {
      return 'start';
    }
  }

  private getTextLabel(label: string, angle: number): string {
    if ((angle >= 50 && angle <= 133) || (angle >= 230 && angle <= 315)) {
      return label;
    }
    return '';
  }

  private polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = (angleInDegrees * Math.PI) / 180;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }

  private describeArc(x, y, radius, startAngle, endAngle) {
    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);
    const arcSweep = endAngle - startAngle <= 180 ? '0' : '1';
    const d = ['M', start.x, start.y, 'A', radius, radius, 0, arcSweep, 0, end.x, end.y].join(' ');
    return d;
  }

  private describeCircle(x, y, radius, angle): Coordinates {
    const startLine = this.polarToCartesian(x, y, radius, angle);
    return { x: startLine.x, y: startLine.y };
  }

  private updateSVGViewBox(): void {
    this.svgViewBox = `${this.SVG_X_CROP} ${this.SVG_Y_CROP} ${this.svgWidth +
      this.SVG_WIDTH_OFFSET} ${this.svgHeight + this.SVG_HEIGHT_OFFSET}`;
  }
}
