import {
  VendorStatusEnum,
  GetEntityQuery,
  ModelEntityFilterInput,
  EntityTypeEnum,
  ImpactEnum,
  StandardType,
} from './../API.service';
import { HeatMapCoordinate } from './../../models/heatmap.model';
import { StatusCount } from '../../models/status-count.model';
import { ExternalScanCount } from '../../models/external-scan-count.model';
import { Injectable } from '@angular/core';
import { HeatMap } from 'models/heatmap.model';
import { ImpactNumEnum, ImpactTextEnum } from '../shared/enums/impact.enum';
import { ImpactCount } from 'models/impact-count.model';
import { HeatmapSectionEnum } from 'app/shared/enums/heatmap-sections.enum';
import { TableHeader } from 'models/table-header.model';
import { SortDirectionEnum } from 'app/shared/enums/sort-direction.enum';
import { FileService } from 'app/shared/file.service';
import { GetEntityQueryExtended, EntityService } from 'app/shared/entity.service';

// const SORT_NAME = 'name';
// const SORT_SCORE = 'score';
// const SORT_TARGET = 'target';

const CYGOV_HEATMAP_SECTIONS = 'cygov_heatmap_sections_storage';

@Injectable({
  providedIn: 'root',
})
export class ThirdPartyService {
  isVendorListExpanded = false;
  storage: Storage;
  newVendorCreated = false;

  constructor(private entityService: EntityService, private fileService: FileService) {
    this.storage = localStorage;
  }

  static getTotalVendors(vendors: GetEntityQuery[]): number {
    return vendors.length;
  }

  static getRiskScoreAverage(vendors: GetEntityQuery[]): number {
    return (
      vendors.reduce((average, vendor) => (average += vendor.scores.total), 0) /
      this.getTotalVendors(vendors)
    );
  }

  static getTarget(vendors: GetEntityQuery[]): number {
    return 9.5;
  }

  static getImpactCounts(vendors: GetEntityQuery[]): ImpactCount {
    const impactCount = new ImpactCount();
    vendors.forEach(vendor => {
      const impact = vendor.vendorDetails.impact;

      if (impact >= 0 && impact <= ImpactNumEnum.LOW) {
        impactCount.low++;
      } else if (impact <= ImpactNumEnum.MEDIUM) {
        impactCount.medium++;
      } else if (impact <= ImpactNumEnum.HIGH) {
        impactCount.high++;
      } else {
        impactCount.veryHigh++;
      }
    });

    return impactCount;
  }

  static getImpactFromVendor(vendor: GetEntityQueryExtended): ImpactEnum {
    const riskFramework: any = vendor.activeAssessment.standardFrameworkList.items.find(
      standard => standard.type === StandardType.RISK_FRAMEWORK
    );
    return riskFramework.filter.impact;
  }

  static getExternalScanCounts(
    vendors: GetEntityQuery[],
    externalScanCount: ExternalScanCount
  ): void {
    vendors.forEach(vendor => {
      const externalScan = vendor.vendorDetails.externalScan;

      if (externalScan > 0) {
        externalScanCount.failed++;
      } else {
        externalScanCount.cleared++;
      }
    });
  }

  static getStatusCounts(vendors: GetEntityQuery[]): StatusCount {
    const newStatusCount: StatusCount = new StatusCount();
    vendors.forEach(vendor => {
      switch (true) {
        case vendor.vendorDetails.status === VendorStatusEnum.IN_PROCESS:
          newStatusCount.inProcess++;
          break;

        case vendor.vendorDetails.status === VendorStatusEnum.PENDING:
          newStatusCount.pending++;
          break;

        case vendor.vendorDetails.status === VendorStatusEnum.APPROVED:
          newStatusCount.approved++;
          break;

        case vendor.vendorDetails.status === VendorStatusEnum.DENIED:
          newStatusCount.denied++;
          break;
      }
    });
    return newStatusCount;
  }

  static getHeatMapData(vendors: GetEntityQuery[], heatMap: HeatMap[]): void {
    vendors.forEach(vendor => {
      const coordinate = new HeatMap();
      coordinate.probability = vendor.vendorDetails.probability;
      coordinate.impactValue = vendor.vendorDetails.impact;
      coordinate.impact = ThirdPartyService.getImpact(
        coordinate.impactValue,
        coordinate.probability
      );
      coordinate.vendorName = vendor.name;
      coordinate.vendorId = vendor.id;
      heatMap.push(coordinate);
    });
  }

  static getHeatMapCoordinates(
    heatMapData: HeatMap[],
    heatMapCoordinate: HeatMapCoordinate[],
    minX: number,
    minY: number,
    chartWidth: number,
    chartHeight: number
  ): void {
    heatMapData.forEach(heatMap => {
      if (heatMap.probability < 0.7) {
        heatMap.probability = 0.7;
      } else if (heatMap.probability > 99.3) {
        heatMap.probability = 99.3;
      }
      if (heatMap.impactValue < 1.3) {
        heatMap.impactValue = 1.3;
      } else if (heatMap.impactValue >= 98.7) {
        heatMap.impactValue = 98.7;
      }
      const coordinateX = parseFloat((minX + (chartWidth / 100) * heatMap.probability).toFixed(2));
      const coordinateY = parseFloat(
        (minY + chartHeight - (chartHeight / 100) * heatMap.impactValue).toFixed(2)
      );
      const color =
        heatMap.impact === ImpactTextEnum.VERY_HIGH
          ? 'url(#Gradient-DarkRed)'
          : heatMap.impact === ImpactTextEnum.HIGH
          ? 'url(#Gradient-Red)'
          : heatMap.impact === ImpactTextEnum.MEDIUM
          ? 'url(#Gradient-Yellow)'
          : 'url(#Gradient-Green)';
      heatMapCoordinate.push(
        new HeatMapCoordinate(coordinateX, coordinateY, color, heatMap.vendorId, heatMap.vendorName)
      );
    });
  }

  static getImpact(impact: number, probability: number): string {
    if (
      (impact >= 0 &&
        impact <= ImpactNumEnum.LOW &&
        probability >= 0 &&
        probability <= ImpactNumEnum.LOW) ||
      (impact >= 0 &&
        impact <= ImpactNumEnum.LOW &&
        probability > ImpactNumEnum.LOW &&
        probability <= ImpactNumEnum.MEDIUM) ||
      (impact > ImpactNumEnum.LOW &&
        impact <= ImpactNumEnum.MEDIUM &&
        probability >= 0 &&
        probability <= ImpactNumEnum.LOW)
    ) {
      return ImpactTextEnum.LOW;
    }
    if (
      (impact >= 0 &&
        impact <= ImpactNumEnum.LOW &&
        probability > ImpactNumEnum.MEDIUM &&
        probability <= ImpactNumEnum.HIGH) ||
      (impact > ImpactNumEnum.LOW &&
        impact <= ImpactNumEnum.MEDIUM &&
        probability > ImpactNumEnum.LOW &&
        probability <= ImpactNumEnum.MEDIUM) ||
      (impact > ImpactNumEnum.MEDIUM &&
        impact <= ImpactNumEnum.HIGH &&
        probability >= 0 &&
        probability <= ImpactNumEnum.LOW)
    ) {
      return ImpactTextEnum.MEDIUM;
    }
    if (
      (impact >= 0 &&
        impact <= ImpactNumEnum.LOW &&
        probability > ImpactNumEnum.HIGH &&
        probability <= ImpactNumEnum.VERY_HIGH) ||
      (impact > ImpactNumEnum.LOW &&
        impact <= ImpactNumEnum.MEDIUM &&
        probability > ImpactNumEnum.MEDIUM &&
        probability <= ImpactNumEnum.HIGH) ||
      (impact > ImpactNumEnum.LOW &&
        impact <= ImpactNumEnum.MEDIUM &&
        probability > ImpactNumEnum.HIGH &&
        probability <= ImpactNumEnum.VERY_HIGH) ||
      (impact > ImpactNumEnum.MEDIUM &&
        impact <= ImpactNumEnum.HIGH &&
        probability > ImpactNumEnum.LOW &&
        probability <= ImpactNumEnum.MEDIUM) ||
      (impact > ImpactNumEnum.MEDIUM &&
        impact <= ImpactNumEnum.HIGH &&
        probability > ImpactNumEnum.MEDIUM &&
        probability <= ImpactNumEnum.HIGH) ||
      (impact > ImpactNumEnum.HIGH &&
        impact <= ImpactNumEnum.VERY_HIGH &&
        probability >= 0 &&
        probability <= ImpactNumEnum.LOW) ||
      (impact > ImpactNumEnum.HIGH &&
        impact <= ImpactNumEnum.VERY_HIGH &&
        probability > ImpactNumEnum.LOW &&
        probability <= ImpactNumEnum.MEDIUM)
    ) {
      return ImpactTextEnum.HIGH;
    }
    if (
      (impact > ImpactNumEnum.MEDIUM &&
        impact <= ImpactNumEnum.HIGH &&
        probability > ImpactNumEnum.HIGH &&
        probability <= ImpactNumEnum.VERY_HIGH) ||
      (impact > ImpactNumEnum.HIGH &&
        impact <= ImpactNumEnum.VERY_HIGH &&
        probability > ImpactNumEnum.MEDIUM &&
        probability <= ImpactNumEnum.HIGH) ||
      (impact > ImpactNumEnum.HIGH &&
        impact <= ImpactNumEnum.VERY_HIGH &&
        probability > ImpactNumEnum.HIGH &&
        probability <= ImpactNumEnum.VERY_HIGH)
    ) {
      return ImpactTextEnum.VERY_HIGH;
    }
    return ImpactTextEnum.VERY_HIGH;
  }

  static getImpactText(impact: number): string {
    if (impact >= 0 && impact <= ImpactNumEnum.LOW) {
      return ImpactTextEnum.LOW;
    } else if (impact <= ImpactNumEnum.MEDIUM) {
      return ImpactTextEnum.MEDIUM;
    } else if (impact <= ImpactNumEnum.HIGH) {
      return ImpactTextEnum.HIGH;
    } else {
      return ImpactTextEnum.VERY_HIGH.split(' ').join('-');
    }
  }

  static getImpactEnumFromImpactNum(impact: number): ImpactEnum {
    if (impact >= 0 && impact <= ImpactNumEnum.LOW) {
      return ImpactEnum.LOW;
    } else if (impact <= ImpactNumEnum.MEDIUM) {
      return ImpactEnum.MEDIUM;
    } else if (impact <= ImpactNumEnum.HIGH) {
      return ImpactEnum.HIGH;
    } else {
      return ImpactEnum.VERY_HIGH;
    }
  }

  static sortArray(sortData: TableHeader, data: GetEntityQuery[]): any {
    const ascendingOrder = sortData.sortDirection === SortDirectionEnum.ASCENDING ? 1 : -1;
    const keys: string[] = sortData.value.split('.');

    switch (keys.length) {
      case 1:
        data.sort((a, b) => {
          return a[keys[0]] < b[keys[0]]
            ? ascendingOrder * -1
            : b[keys[0]] < a[keys[0]]
            ? ascendingOrder
            : 0;
        });
        break;
      case 2:
        data.sort((a, b) => {
          return a[keys[0]][keys[1]] < b[keys[0]][keys[1]]
            ? ascendingOrder * -1
            : b[keys[0]][keys[1]] < a[keys[0]][keys[1]]
            ? ascendingOrder
            : 0;
        });
        break;
      case 3:
        data.sort((a, b) => {
          return a[keys[0]][keys[1]][keys[2]] < b[keys[0]][keys[1]][keys[2]]
            ? ascendingOrder * -1
            : b[keys[0]][keys[1]][keys[2]] < a[keys[0]][keys[1]][keys[2]]
            ? ascendingOrder
            : 0;
        });
        break;
      default:
    }
  }

  toggleVendorList(isExpanded: boolean): void {
    this.isVendorListExpanded = isExpanded;
  }

  setNewVendorAdded(event: boolean): void {
    this.newVendorCreated = event;
  }

  getNewVendorAdded(): boolean {
    return this.newVendorCreated;
  }

  getHeatmapDefaultView(): number {
    const sections = Number(this.storage.getItem(CYGOV_HEATMAP_SECTIONS) || 0);
    return sections ? sections : HeatmapSectionEnum.None;
  }

  setHeatmapDefaultView(sections: HeatmapSectionEnum): void {
    this.storage.setItem(CYGOV_HEATMAP_SECTIONS, sections.toString());
  }

  async getExtendVendorsListByParentId(entityId): Promise<GetEntityQueryExtended[]> {
    const filter: ModelEntityFilterInput = {
      entityType: { eq: EntityTypeEnum.VENDOR },
    };
    const entityList: GetEntityQuery[] = await this.entityService.listEntitysByParentId(
      entityId,
      filter
    );
    const promises = entityList.map(entity => this.setLogoUrl(entity));
    return await Promise.all(promises);
  }

  async setLogoUrl(entity: GetEntityQuery): Promise<GetEntityQueryExtended> {
    let logoUrl = '';
    if (entity.logo) {
      logoUrl = (await this.fileService.downloadFromS3(entity.logo)) as string;
    }

    return {
      ...entity,
      logoUrl,
    };
  }
}
