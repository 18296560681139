import { GetEntityQuery, GetUserQuery } from './../../API.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from 'app/shared/utils.service';
import { EntityService } from 'app/shared/entity.service';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from 'app/auth/auth.service';
import { ThirdPartyService } from '../third-party.service';
import { AddVendorModalComponent } from './add-vendor-modal/add-vendor-modal.component';
import { AddCrbVendorModalComponent } from './add-crb-vendor-modal/add-crb-vendor-modal.component';

@Component({
  selector: 'cygov-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.scss'],
})
export class AddVendorComponent implements OnInit {
  @Output() reload = new EventEmitter<boolean>();
  projectManager: GetUserQuery;
  entity: GetEntityQuery;
  isLoading = true;
  isCrbAdjustments = UtilsService.isCRB;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private entityService: EntityService,
    private logger: NGXLogger,
    private authService: AuthService,
    private thirdPartyService: ThirdPartyService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      this.projectManager = await this.authService.getCurrentUser();
      this.entity = entityId ? await this.entityService.getEntity(entityId) : null;
      this.isLoading = false;
    } catch (error) {
      this.logger.error('Error : ', error);
      this.isLoading = false;
    }
  }
  openAddVendorModal(): void {
    const modalComponent = this.isCrbAdjustments
      ? AddCrbVendorModalComponent
      : AddVendorModalComponent;
    const modalRef = this.modalService.open(modalComponent, {
      centered: true,
      size: 'lg',
      windowClass: this.isCrbAdjustments
        ? 'add-vendor-modal add-crb-vendor-modal'
        : 'add-vendor-modal',
      backdropClass: 'add-vendor-backdrop',
    });
    modalRef.componentInstance.entity = this.entity;
    modalRef.componentInstance.projectManager = this.projectManager;
    modalRef.componentInstance.modalResult.subscribe((event: boolean) => {
      this.reload.emit(event);
      this.thirdPartyService.setNewVendorAdded(event);
      modalRef.close();
    });
  }
}
