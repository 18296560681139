import { environment } from 'environments/environment';
import {
  CreateExternalThreatsInput,
  CreateOthersInput,
  APIService,
  CreateIntelligenceInput,
  CreateCustomTaskInput,
  TaskStatusEnum,
  GetCustomTaskQuery,
  CreateCustomTaskAssignmentInput,
} from './../../../../API.service';
import { ConfirmationModalComponent } from './../../../../shared/confirmation-modal/confirmation-modal.component';
import { ScanDetailsComponent } from './../../../../shared/scan-details/scan-details.component';
import { NewTaskModalComponent } from './../../../../shared/new-task-modal/new-task-modal.component';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from './../../../../shared/utils.service';
import { StatusEnum } from './../../../../shared/enums/status.enum';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { RoleEnum, CreateBreachesInput, ImpactEnum, GetUserQuery } from 'app/API.service';
import { GetEntityQueryExtended, EntityService } from 'app/shared/entity.service';
import { ThirdPartyService } from 'app/third-party/third-party.service';
import * as uuid from 'uuid';

interface BreachesInputExtended extends CreateBreachesInput {
  selectedDate;
}
interface ExternalThreatsInputExtended extends CreateExternalThreatsInput {
  selectedDate;
}
interface OthersInputExtended extends CreateOthersInput {
  selectedDate;
}

@Component({
  selector: 'cygov-intelligence',
  templateUrl: './intelligence.component.html',
  styleUrls: ['./intelligence.component.scss'],
})
export class IntelligenceComponent implements OnInit {
  @Input() vendor: GetEntityQueryExtended;
  @Input() vendorIntelligence;
  @Output() update = new EventEmitter<GetEntityQueryExtended>();
  isLoading = true;

  statusEnum = StatusEnum;
  headElementsBreaches = ['Date', 'Name', 'Description', 'Leaks', 'Data', 'Status'];
  headElementsThreats = ['Source', 'Findings', 'Risk Score', 'Details'];
  headElementsOthers = ['Name', 'Label', 'Severity', 'Description'];
  isEntityLeader = false;
  isBreachesReadOnly = false;
  isThreatsReadOnly = false;
  isOthersReadOnly = false;
  showBreaches = true;
  showThreats = false;
  showOthers = false;
  newBreaches: BreachesInputExtended[] = [];
  newThreats: ExternalThreatsInputExtended[] = [];
  newOthers: OthersInputExtended[] = [];

  severityOpt = Object.keys(ImpactEnum).map(key => ImpactEnum[key]);
  impactEnum = ImpactEnum;

  isAdmin = false;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private api: APIService,
    private entityService: EntityService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isAdmin = await this.authService.hasPermission(RoleEnum.ADMIN);
    this.isEntityLeader = await this.authService.hasPermission(RoleEnum.LEADER);
    await this.loadCompleteIntelligence();
    this.isLoading = false;
  }

  async loadCompleteIntelligence(): Promise<void> {
    if (UtilsService.isDefined(this.vendor.vendorDetails.intelligence)) {
      const intelligence = this.vendor.vendorDetails.intelligence;

      // ToDo: Need to use Promise.all
      if (intelligence.breaches && intelligence.breaches.nextToken) {
        let result = { ...intelligence.breaches };
        while (result.nextToken) {
          result = await this.api.BreachesByVendorIdAndStatus(
            this.vendor.id,
            null,
            null,
            null,
            environment.queryListLimit,
            result.nextToken
          );
          intelligence.breaches.items = intelligence.breaches.items.concat(result.items);
        }
      }
      if (intelligence.externalThreats) {
        // Threats details donot load automatically in vendor so we need to load it from start
        intelligence.externalThreats.items = [];
        let result = await this.api.ExternalThreatsByVendorIdAndStatus(
          this.vendor.id,
          null,
          null,
          null,
          environment.queryListLimit
        );
        intelligence.externalThreats.items = result.items;
        while (result.nextToken) {
          result = await this.api.ExternalThreatsByVendorIdAndStatus(
            this.vendor.id,
            null,
            null,
            null,
            environment.queryListLimit,
            result.nextToken
          );
          intelligence.externalThreats.items = intelligence.externalThreats.items.concat(
            result.items
          );
        }
      }
      if (intelligence.others && intelligence.others.nextToken) {
        let result = { ...intelligence.others };
        while (result.nextToken) {
          result = await this.api.OthersByVendorIdAndStatus(
            this.vendor.id,
            null,
            null,
            null,
            environment.queryListLimit,
            result.nextToken
          );
          intelligence.others.items = intelligence.others.items.concat(result.items);
        }
      }
    }
  }
  addRow(type: string): void {
    switch (type) {
      case 'breach':
        this.newBreaches.unshift({
          vendorId: this.vendor.id,
          selectedDate: null,
          status: StatusEnum.OPEN,
        }); // new Breaches()
        this.isBreachesReadOnly = false;
        break;

      case 'threat':
        this.newThreats.unshift({
          vendorId: this.vendor.id,
          selectedDate: null,
          status: StatusEnum.OPEN,
        }); // new ExternalThreats()
        this.isThreatsReadOnly = false;
        break;

      case 'other':
        this.newOthers.unshift({
          vendorId: this.vendor.id,
          selectedDate: null,
          status: StatusEnum.OPEN,
        }); // new Others()
        this.isOthersReadOnly = false;
        break;
    }
  }

  async removeRow(type: string, index: number, isNew = true): Promise<void> {
    let taskIdToUpdate;
    switch (type) {
      case 'breach':
        if (isNew) {
          this.newBreaches.splice(index, 1);
        } else {
          const breachToUpdate = this.vendor.vendorDetails.intelligence.breaches.items[index];
          breachToUpdate.status = StatusEnum.DELETE;
          taskIdToUpdate = breachToUpdate.id;
          await this.api.UpdateBreaches({ id: breachToUpdate.id, status: breachToUpdate.status });
        }
        break;

      case 'threat':
        if (isNew) {
          this.newThreats.splice(index, 1);
        } else {
          const threatToUpdate = this.vendor.vendorDetails.intelligence.externalThreats.items[
            index
          ];
          threatToUpdate.status = StatusEnum.DELETE;
          taskIdToUpdate = threatToUpdate.id;
          await this.api.UpdateExternalThreats({
            id: threatToUpdate.id,
            status: threatToUpdate.status,
          });
        }
        break;

      case 'other':
        if (isNew) {
          this.newOthers.splice(index, 1);
        } else {
          const otherToUpdate = this.vendor.vendorDetails.intelligence.others.items[index];
          otherToUpdate.status = StatusEnum.DELETE;
          taskIdToUpdate = otherToUpdate.id;
          await this.api.UpdateOthers({ id: otherToUpdate.id, status: otherToUpdate.status });
        }
        break;
    }
    if (!isNew) {
      // Change status of task to close if its parent is deleted
      let task = await this.api.GetCustomTask(taskIdToUpdate);
      if (task && task.status !== TaskStatusEnum.CLOSED) {
        task.status = TaskStatusEnum.CLOSED;
        task = this.removeTypenameFromTask(task);
        await this.api.UpdateCustomTask(task);
      }
      this.toastr.success('Deleted successfully');
    }
  }

  getDueDate(index: number): void {
    const selectedDate = this.newBreaches[index].selectedDate;
    this.newBreaches[index].date = new Date(
      selectedDate.year,
      selectedDate.month - 1,
      selectedDate.day
    ).getTime();
  }

  getImpactText(impact: number, isThreatScore = false): string {
    if (isThreatScore) {
      impact *= 10;
    }
    return ThirdPartyService.getImpactText(impact);
  }

  async markAsGap(type: string, index: number): Promise<void> {
    this.toastr.info('Opening Gap', '', { timeOut: 2000 });

    try {
      let task: GetCustomTaskQuery = null;
      const newTask: CreateCustomTaskInput = { assessmentId: this.vendor.activeAssessmentId };
      newTask.status = TaskStatusEnum.OPEN;
      switch (type) {
        case 'breach':
          task = await this.api.GetCustomTask(
            this.vendor.vendorDetails.intelligence.breaches.items[index].id
          );

          if (!task) {
            newTask.id = this.vendor.vendorDetails.intelligence.breaches.items[index].id;
            newTask.name = this.vendor.vendorDetails.intelligence.breaches.items[index].name;
            newTask.description = this.vendor.vendorDetails.intelligence.breaches.items[
              index
            ].description;
            newTask.impact = ImpactEnum.HIGH;
          }

          break;

        case 'threat':
          task = await this.api.GetCustomTask(
            this.vendor.vendorDetails.intelligence.externalThreats.items[index].id
          );
          if (!task) {
            newTask.id = this.vendor.vendorDetails.intelligence.externalThreats.items[index].id;
            newTask.name = this.vendor.vendorDetails.intelligence.externalThreats.items[
              index
            ].source;
            newTask.label = '';
            newTask.impact = ImpactEnum.HIGH;
            newTask.description = this.vendor.vendorDetails.intelligence.externalThreats.items[
              index
            ].findings
              .map(finding => `${finding}`)
              .join(', ');
          }
          break;

        case 'other':
          task = await this.api.GetCustomTask(
            this.vendor.vendorDetails.intelligence.others.items[index].id
          );
          if (!task) {
            newTask.id = this.vendor.vendorDetails.intelligence.others.items[index].id;
            newTask.name = this.vendor.vendorDetails.intelligence.others.items[index].name;
            newTask.label = this.vendor.vendorDetails.intelligence.others.items[index].label;
            newTask.impact =
              this.severityOpt.find(
                opt => opt === this.vendor.vendorDetails.intelligence.others.items[index].severity
              ) || ImpactEnum.HIGH;
            newTask.description = this.vendor.vendorDetails.intelligence.others.items[
              index
            ].description;
          }
          break;
      }

      if (task) {
        const taskToSave = this.removeTypenameFromTask(task);
        this.openNewTaskModal(taskToSave, false);
      } else {
        this.openNewTaskModal(newTask, true);
      }
    } catch (err) {
      console.log('Error: ', err);
      this.toastr.error('Error Saving Gap ');
    }
  }

  openNewTaskModal(taskToGap: CreateCustomTaskInput, isNew = true): void {
    const modalRef = this.modalService.open(NewTaskModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'new-task-modal',
    });

    modalRef.componentInstance.customTask = taskToGap;
    modalRef.componentInstance.modalResult.subscribe(async (task: CreateCustomTaskInput) => {
      this.toastr.info('Saving Gap');
      modalRef.close();

      try {
        if (isNew) {
          // Saving new task
          await this.api.CreateCustomTask(task);
          const users: GetUserQuery[] = await this.entityService.getUsersByEntityId(this.vendor.id);
          users.map(user => this.assignUserToTask(user, task));
        } else {
          // Updating already created task
          await this.api.UpdateCustomTask({ ...task, id: task.id, status: TaskStatusEnum.OPEN });
        }
        this.toastr.success('Gap saved successfully');
      } catch (err) {
        console.log('Error: ', err);

        this.toastr.error('Error Saving Gap');
      }
    });
  }

  async assignUserToTask(user: GetUserQuery, task): Promise<any> {
    const taskAssignmentToCreate: CreateCustomTaskAssignmentInput = {
      id: uuid(),
      userId: user.id,
      assessmentId: this.vendor.activeAssessmentId,
      targetId: task.id,
    };
    await this.api.CreateCustomTaskAssignment(taskAssignmentToCreate);
  }

  openScanDetailsModal(details): void {
    const modalRef = this.modalService.open(ScanDetailsComponent, {
      centered: true,
      size: 'sm',
      windowClass: 'scan-details',
    });
    modalRef.componentInstance.threatDetails = details;
    modalRef.componentInstance.modalResult.subscribe(() => {
      modalRef.close();
    });
  }

  async markAsClose(type: string, index: number): Promise<void> {
    let objectToUpdate;
    let task: GetCustomTaskQuery = null;
    let taskIdToGet;
    try {
      switch (type) {
        case 'breach':
          objectToUpdate = this.vendor.vendorDetails.intelligence.breaches.items[index];
          objectToUpdate.status = StatusEnum.CLOSE;
          await this.api.UpdateBreaches({ id: objectToUpdate.id, status: objectToUpdate.status });
          taskIdToGet = objectToUpdate.id;

          break;

        case 'threat':
          objectToUpdate = this.vendor.vendorDetails.intelligence.externalThreats.items[index];
          objectToUpdate.status = StatusEnum.CLOSE;
          await this.api.UpdateExternalThreats({
            id: objectToUpdate.id,
            status: objectToUpdate.status,
          });
          break;

        case 'other':
          objectToUpdate = this.vendor.vendorDetails.intelligence.others.items[index];
          objectToUpdate.status = StatusEnum.CLOSE;
          await this.api.UpdateOthers({ id: objectToUpdate.id, status: objectToUpdate.status });
          break;
      }
      task = await this.api.GetCustomTask(taskIdToGet);
      if (task && task.status !== TaskStatusEnum.CLOSED) {
        task.status = TaskStatusEnum.CLOSED;
        task = this.removeTypenameFromTask(task);
        await this.api.UpdateCustomTask(task);
      }
      this.toastr.success('Status Changed');
    } catch (err) {
      this.toastr.error('Error while changing status');
    }
  }
  async markAsOpen(type: string, index: number): Promise<void> {
    let objectToUpdate;
    let task: GetCustomTaskQuery = null;
    let taskIdToGet;
    try {
      switch (type) {
        case 'breach':
          objectToUpdate = this.vendor.vendorDetails.intelligence.breaches.items[index];
          objectToUpdate.status = StatusEnum.OPEN;
          await this.api.UpdateBreaches({ id: objectToUpdate.id, status: objectToUpdate.status });
          taskIdToGet = objectToUpdate.id;

          break;

        case 'threat':
          objectToUpdate = this.vendor.vendorDetails.intelligence.externalThreats.items[index];
          objectToUpdate.status = StatusEnum.OPEN;
          await this.api.UpdateExternalThreats({
            id: objectToUpdate.id,
            status: objectToUpdate.status,
          });
          break;

        case 'other':
          objectToUpdate = this.vendor.vendorDetails.intelligence.others.items[index];
          objectToUpdate.status = StatusEnum.OPEN;
          await this.api.UpdateOthers({ id: objectToUpdate.id, status: objectToUpdate.status });
          break;
      }
      task = await this.api.GetCustomTask(taskIdToGet);
      if (task && task.status !== TaskStatusEnum.OPEN) {
        task.status = TaskStatusEnum.OPEN;
        task = this.removeTypenameFromTask(task);
        await this.api.UpdateCustomTask(task);
      }
      this.toastr.success('Status Changed');
    } catch (err) {
      this.toastr.error('Error while changing status');
    }
  }

  openConfirmationModal(type: string, index: number): void {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: 'sm',
      windowClass: 'confirmation-modal',
    });
    modalRef.componentInstance.message = 'Do you really want to delete?';
    modalRef.componentInstance.modalResult.subscribe((event: boolean) => {
      if (event) {
        this.removeRow(type, index, false);
      }
      modalRef.close();
    });
  }

  async onAddNewItem(type: string, index: number): Promise<any> {
    if (this.isDataValid(type, index)) {
      if (!UtilsService.isDefined(this.vendor.vendorDetails.intelligence)) {
        const newIntelligence: CreateIntelligenceInput = { id: this.vendor.id };
        this.vendor.vendorDetails.intelligence = await this.api.CreateIntelligence(newIntelligence);
      }
      switch (type) {
        case 'breach':
          delete this.newBreaches[index].selectedDate;
          this.vendor.vendorDetails.intelligence.breaches.items.unshift(
            await this.api.CreateBreaches(this.newBreaches[index])
          );
          this.newBreaches.splice(index, 1);
          break;
        case 'threat':
          delete this.newThreats[index].selectedDate;
          this.vendor.vendorDetails.intelligence.externalThreats.items.unshift(
            await this.api.CreateExternalThreats(this.newThreats[index])
          );
          this.newThreats.splice(index, 1);
          break;
        case 'other':
          delete this.newOthers[index].selectedDate;
          this.vendor.vendorDetails.intelligence.others.items.unshift(
            await this.api.CreateOthers(this.newOthers[index])
          );
          this.newOthers.splice(index, 1);
          break;
      }
    }
  }

  isDataValid(type: string, index: number): boolean {
    let isValid = true;
    let newRow;
    switch (type) {
      case 'breach':
        newRow = this.newBreaches[index];
        if (!UtilsService.isDefined(newRow.date)) {
          this.toastr.error('Date is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newRow.name)) {
          this.toastr.error('Name is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newRow.description)) {
          this.toastr.error('Description is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newRow.leaks)) {
          this.toastr.error('Leaks is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newRow.data)) {
          this.toastr.error('Data is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newRow.status)) {
          this.toastr.error('Status is required');
          isValid = false;
        }
        break;
      case 'threat':
        newRow = this.newThreats[index];
        if (!UtilsService.isDefined(newRow.source)) {
          this.toastr.error('Source is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newRow.riskScore)) {
          this.toastr.error('Risk Score is required');
          isValid = false;
        }
        break;
      case 'other':
        newRow = this.newOthers[index];
        if (!UtilsService.isDefined(newRow.name)) {
          this.toastr.error('Name is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newRow.label)) {
          this.toastr.error('Label is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newRow.severity)) {
          this.toastr.error('Severity is required');
          isValid = false;
        } else if (!UtilsService.isDefined(newRow.description)) {
          this.toastr.error('Description is required');
          isValid = false;
        }
        break;
    }
    return isValid;
  }

  removeTypenameFromTask(task: GetCustomTaskQuery): any {
    const taskWithoutTypename = { ...task };
    delete taskWithoutTypename.__typename;
    taskWithoutTypename.standardsQualify = taskWithoutTypename.standardsQualify.map(standard => {
      delete standard.__typename;
      return standard;
    });
    return taskWithoutTypename;
  }
}
