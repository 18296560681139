export class ImpactCount {
  low: number;
  medium: number;
  high: number;
  veryHigh: number;

  constructor() {
    this.low = this.medium = this.high = this.veryHigh = 0;
  }
}
