import { AccessLevelEnum } from './../shared/enums/accessLevel.enum';
import { ImpactNumEnum, ImpactTextEnum } from './../shared/enums/impact.enum';
import { FrequencyCheckEnum, TimelineInput, DefaultSettingInput } from './../API.service';
/* eslint-disable no-useless-escape */
import { CrbVendorConstant } from 'app/third-party/add-vendor/add-crb-vendor-modal/crb-vendor.constant';
import { StandardType } from './../API.service';
import { UtilsService } from 'app/shared/utils.service';
import { ArtifactsEnum } from './../shared/enums/artifacts.enum';
import { Vendor, VendorUser, VendorExtended } from './../../models/generator.model';
/* eslint-disable @typescript-eslint/prefer-for-of */
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Auth } from 'aws-amplify';
import { Lambda } from 'aws-sdk';
import { Storage } from 'aws-amplify';
import { EntityService } from 'app/shared/entity.service';
import {
  IndustryEnum,
  FileTypeEnum,
  EntityTypeEnum,
  ImpactEnum,
  QualificationInput,
} from 'app/API.service';
import awsmobile from 'aws-exports';
import { FileService } from 'app/shared/file.service';
import { RiskFrameworkEnum } from 'app/shared/enums/riskFramework.enum';
import { RootEntity, SubEntity, RiskOption, AnswerOption } from 'models/generator.model';
import { NgbDateParserFormatter, NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'cygov-generator',
  templateUrl: './generator.component.html',
  styleUrls: ['./generator.component.scss'],
})
export class GeneratorComponent implements OnInit {
  subEntityList = [];
  rootEntity: RootEntity;
  subEntity: SubEntity;
  vendor: Vendor;
  standardList = EntityService.initStandardList() as any;
  standardOptions: any[];
  vendorStandardOptions: any[];
  industryOpt = Object.keys(IndustryEnum);
  riskFrameWorkOpt: RiskOption[] = [];
  images: string[] = [];
  possibleAnswers: AnswerOption[] = [];
  vendorPossibleAnswers: AnswerOption[] = [];
  loading = false;
  selectedVendorDueDate = null;
  selectedSubEntityDueDate = null;
  projectDeadline: NgbDateStruct = null;
  priorityOpt = Object.keys(ImpactEnum).map(key => ImpactEnum[key]);
  mandatoryArtifacts: QualificationInput[];
  vendorUser: VendorUser;
  vendorsList: VendorExtended[] = [];
  isCRBAdjustments = false;
  impactOptions: QualificationInput[];
  selectedSurvey: ImpactEnum;
  currentDate: NgbDateStruct;
  constructor(
    private toastr: ToastrService,
    private fileService: FileService,
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private parserFormatter: NgbDateParserFormatter
  ) {}

  ngOnInit(): void {
    this.loadImages();
    this.initialize();
    this.getMandatoryArtifacts();
    this.isCRBAdjustments = UtilsService.isCRB;
    this.rootEntity = {} as RootEntity;
    this.rootEntity.defaultSetting = this.setDefaultSettings();

    this.vendor = this.initVendor();
    this.vendorUser = {} as VendorUser;
    this.selectedSurvey = ImpactEnum.LOW;

    this.impactOptions = CrbVendorConstant.impacts.map(name => ({
      isQualify: true,
      name,
    }));

    this.calcCrbVendorImpact();

    this.standardOptions = this.standardList.map(standard => ({
      isQualify: false,
      key: standard.key,
      name: standard.name,
    }));

    if (this.isCRBAdjustments) {
      this.vendorStandardOptions = Object.values(CrbVendorConstant.compliances).map(standard => ({
        isQualify: false,
        name: standard,
        key: standard,
      }));
    } else {
      this.vendorStandardOptions = JSON.parse(JSON.stringify(this.standardOptions));
    }

    this.prepareRiskFrameworkOptions();
    this.currentDate = UtilsService.getDateInNgbDateStructFormat(new Date());
    this.initSubEntity();
  }

  initSubEntity(): void {
    this.subEntity = {} as SubEntity;
    this.subEntity.entityType = EntityTypeEnum.CHILD_ENTITY;
    this.subEntity.riskFramework = this.riskFrameWorkOpt[0];
  }

  initialize(): void {
    this.possibleAnswers = [
      {
        name: 'EMPTY',
        value: [-1],
        selected: true,
      },
      {
        name: 'YES',
        value: [10],
        selected: true,
      },
      {
        name: 'NO',
        value: [1],
        selected: true,
      },
      {
        name: 'NOT_APPLICABLE',
        value: [0],
        selected: true,
      },
      {
        name: '1-3',
        value: [1, 2, 3],
        selected: true,
      },
      {
        name: '4-6',
        value: [4, 5, 6],
        selected: true,
      },
      {
        name: '7-9',
        value: [7, 8, 9],
        selected: true,
      },
    ];

    this.vendorPossibleAnswers = JSON.parse(JSON.stringify(this.possibleAnswers));
  }

  setDefaultSettings(): DefaultSettingInput {
    return {
      frequency: FrequencyCheckEnum.ANNUAL,
      surveyLevel: ImpactTextEnum.HIGH,
      accessLevel: AccessLevelEnum.PHYSICAL,
    };
  }

  initVendor(): Vendor {
    const vendor: Vendor = {} as Vendor;
    vendor.activeScan = true;
    vendor.privacyData = false;
    vendor.impact = 100;
    vendor.isCRB = this.isCRBAdjustments;
    vendor.entityType = EntityTypeEnum.VENDOR;
    vendor.riskFramework = RiskFrameworkEnum.NIST_CSF.replace(/ /g, '_');

    return vendor;
  }

  getMandatoryArtifacts(): void {
    if (this.isCRBAdjustments) {
      this.mandatoryArtifacts = Object.values(CrbVendorConstant.artifacts).map(name => ({
        isQualify: true,
        name,
      }));

      this.mandatoryArtifacts = JSON.parse(
        // eslint-disable-next-line id-blacklist
        JSON.stringify(this.mandatoryArtifacts, (k, v) => (k === '__typename' ? undefined : v))
      );
    } else {
      const artifactsWithOutCompliance = Object.keys(ArtifactsEnum).filter(
        key => !key.includes('COMPLIANCE')
      );
      this.mandatoryArtifacts = artifactsWithOutCompliance.map(key => {
        return {
          isQualify: false,
          name: ArtifactsEnum[key],
        };
      });
    }
  }

  prepareRiskFrameworkOptions(): void {
    for (const enumMember of Object.keys(RiskFrameworkEnum)) {
      this.riskFrameWorkOpt.push({
        key: enumMember,
        value: RiskFrameworkEnum[enumMember],
      });
    }
  }

  async addGenerator(): Promise<void> {
    // handling subentity
    let subEntityAdded = false;
    let fieldNotEmpty;

    // in case of no sub entity already exist in list
    if (this.subEntityList.length === 0) {
      if (!this.isValidSubEntity()) {
        return;
      } else {
        subEntityAdded = true;
        this.addSubEntity(false);
      }
    }
    /* if sub entities are already added, check if any of required fields of sub entity is filled
    we need to add that sub entity in the list on save button */
    if (this.subEntityList.length > 0 && !subEntityAdded) {
      fieldNotEmpty = this.checkIfFieldsNotEmpty();
      // if one of the field is filled
      if (fieldNotEmpty) {
        if (this.isValidSubEntity()) {
          this.addSubEntity(false);
        } else {
          return;
        }
      }
    }

    // handling vendor
    fieldNotEmpty = this.checkIfVendorFieldsNotEmpty();

    if (fieldNotEmpty) {
      if (this.isValidVendor()) {
        this.addVendor(false);
      } else {
        return;
      }
    }

    this.loading = true;
    this.toastr.info('Adding Generator');
    let logoName = '';
    if (this.rootEntity.logoName) {
      const urls = this.rootEntity.logoName.split('?')[0].split('/');
      logoName = urls[urls.length - 1];
      // eslint-disable-next-line prettier/prettier
      const name = logoName
        .substring(0, logoName.indexOf('.'))
        .split('_')
        .join(' ');
      this.rootEntity.name = name.toUpperCase();
    }
    const json = {
      arguments: {
        input: {
          root: { ...this.rootEntity, logoName },
          adminGroup: EntityService.getAdminGroup(),
          participantGroup: EntityService.getParticipantGroup(),
          children: this.subEntityList,
          vendors: this.vendorsList,
          userId: this.authService.getCurrentUserSync().id,
        },
      },
    };

    // console.log('json: ', json);
    const credentials = await Auth.currentCredentials();
    const lambda = new Lambda({
      credentials: Auth.essentialCredentials(credentials),
      region: awsmobile.aws_appsync_region,
    });
    const REGEX = /.*-(\w+)/;
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    const env = awsmobile.aws_content_delivery_bucket.match(REGEX)[1];
    const params = {
      FunctionName: `generator-${env}`,
      Payload: JSON.stringify({ ...json }),
    };
    try {
      const { Payload: result } = await lambda.invoke(params).promise();
      this.toastr.success('Generator data added successfully!');
      this.loading = false;
    } catch (error) {
      console.log('Error' + JSON.stringify(error));
      this.loading = false;
    }
    this.activeModal.close();
  }

  addSubEntity(resetData: boolean = true): void {
    const frameworkList = [];

    this.standardOptions.forEach(standard => {
      if (standard.isQualify) {
        frameworkList.push({
          key: standard.key,
          name: standard.name,
          type: EntityTypeEnum.COMPLIANCE_FRAMEWORK,
        });
        standard.isQualify = resetData ? false : standard.isQualify;
      }
    });
    frameworkList.push({
      key: this.subEntity.riskFramework.key,
      name: this.subEntity.riskFramework.value,
      type: EntityTypeEnum.RISK_FRAMEWORK,
    });
    const selectedUserAnswersObj = this.getSelectedUserAnswers(this.possibleAnswers);
    this.subEntity.userAnswers = selectedUserAnswersObj.selectedAnswers;
    this.subEntity.userAnswersLabels = selectedUserAnswersObj.selectedAnswersLabels;
    this.setSubentityTimeline();
    this.subEntityList.push({
      ...this.subEntity,
      frameworkList,
      isCollapsed: true,
      riskFrameWork: this.subEntity.riskFramework.key,
    });

    // reset subEntity data
    if (resetData) {
      // this.subEntity = {} as SubEntity;
      // this.subEntity.entityType = EntityTypeEnum.CHILD_ENTITY;
      // this.subEntity.riskFramework = this.riskFrameWorkOpt[0];
      this.initSubEntity();
      this.selectedSubEntityDueDate = null;
    }
  }

  toggleSubItem(index): void {
    this.subEntityList[index].isCollapsed = !this.subEntityList[index].isCollapsed;
  }

  isValid(): boolean {
    let valid = true;
    if (!this.rootEntity.logoName) {
      this.toastr.error('Root Entity Name is required');
      valid = false;
    }
    if (!this.rootEntity.industry) {
      this.toastr.error('Industry is required');
      valid = false;
    }
    // if (!this.rootEntity.projectManagerName) {
    //   this.toastr.error('Project Manager Name is required');
    //   valid = false;
    // }
    // if (!this.rootEntity.projectDeadline) {
    //   this.toastr.error('Project Deadline is required');
    //   valid = false;
    // }
    return valid;
  }

  isValidSubEntity(): boolean {
    let valid = true;
    if (!this.subEntity.name) {
      this.toastr.error('Company name is required');
      valid = false;
    }
    if (!this.subEntity.numberOfUsers) {
      this.toastr.error('Number of Users is required');
      valid = false;
    }
    if (!this.subEntity.dueDate) {
      this.toastr.error('Due Date is required');
      valid = false;
    }
    if (!this.subEntity.riskFramework) {
      this.toastr.error('Framework is required');
      valid = false;
    }
    return valid;
  }
  checkIfFieldsNotEmpty(): boolean {
    let valid = false;
    if ('name' in this.subEntity && this.subEntity.name) {
      valid = true;
    }
    if ('numberOfUsers' in this.subEntity && this.subEntity.numberOfUsers) {
      valid = true;
    }
    if ('riskFramework' in this.subEntity && this.subEntity.riskFramework) {
      valid = true;
    }
    return valid;
  }
  checkIfVendorFieldsNotEmpty(): boolean {
    if (
      this.vendor.dueDate ||
      this.vendor.domain ||
      this.vendor.logoName ||
      (this.vendor.users && this.vendor.users.length > 0)
    ) {
      return true;
    }

    return false;
  }

  async loadImages(): Promise<void> {
    const filePath = 'DEMO_LOGOS';
    const list = (
      await Storage.list(filePath, { level: 'public', contentType: 'image/png' })
    ).filter(item => {
      const itemKeySplit = item.key.split('/');
      if (itemKeySplit.length > 1 && itemKeySplit[1] !== '') {
        return true;
      }
      return false;
    });
    const promises = list.map(({ key }) =>
      this.fileService.downloadFromS3({
        key,
        contentType: 'image/png',
        fileType: FileTypeEnum.LOGO,
      })
    );
    this.images = await Promise.all(promises);
  }

  selectedUserAnswerNames(userAnswers: number[]): string {
    let selectedAnswers = '';
    this.possibleAnswers.forEach(element => {
      if (element.value.some(v => userAnswers.includes(v))) {
        selectedAnswers += element.name + ' ';
      }
    });
    return selectedAnswers;
  }
  getSelectedUserAnswers(possibleAnswers): any {
    let selectedAnswers = [];
    let selectedAnswersLabels = '';
    possibleAnswers.forEach(element => {
      if (element.selected) {
        selectedAnswers = [...selectedAnswers, ...element.value];
        selectedAnswersLabels += element.name + ' ';
      }
    });
    // if no answer is selected, then send all element array
    if (!selectedAnswers.length) {
      possibleAnswers.forEach(element => {
        selectedAnswers = [...selectedAnswers, ...element.value];
        selectedAnswersLabels += element.name + ' ';
      });
    }
    return {
      selectedAnswers,
      selectedAnswersLabels,
    };
  }

  getDateInFormat(dateStruct): Date {
    return new Date(this.parserFormatter.format(dateStruct));
  }

  setVendorDueDate(): void {
    this.vendor.dueDate = this.getDateInFormat(this.selectedVendorDueDate).getTime();
  }

  setSubEntityDueDate(): void {
    this.subEntity.dueDate = this.getDateInFormat(this.selectedSubEntityDueDate).getTime();
  }

  setProjectDeadline(): void {
    this.rootEntity.projectDeadline = this.getDateInFormat(this.projectDeadline).getTime();
  }

  setTimeline(): void {
    const date: Date = new Date();
    // For now we are setting frequency As Annual by default
    const frequency: FrequencyCheckEnum = FrequencyCheckEnum.ANNUAL;
    // Setting next review date to 12 month ahead due to Anual frequency for now

    const initiationDate = date.getTime();
    const nextReviewDate = date.setMonth(date.getMonth() + 12);

    this.vendor.timeline = {
      collectionDate: this.getDateInFormat(this.selectedVendorDueDate).getTime(),
      initiationDate,
      nextReviewDate,
      frequency,
    };
  }
  setSubentityTimeline(): void {
    if (!this.subEntity.timeline) {
      this.subEntity.timeline = {} as TimelineInput;
    }
    this.subEntity.timeline.collectionDate = this.getDateInFormat(
      this.selectedSubEntityDueDate
    ).getTime();
    const date: Date = new Date();
    this.subEntity.timeline.initiationDate = date.getTime();
  }

  addPOCRow(): void {
    if (this.isNameAndEmailValid(this.vendorUser.name, this.vendorUser.email)) {
      if (!UtilsService.isDefined(this.vendor.users)) {
        this.vendor.users = [];
      }
      this.vendor.users.push({ ...this.vendorUser });
      this.vendorUser.name = '';
      this.vendorUser.email = '';
    }
  }

  isNameAndEmailValid(name: string, email: string): boolean {
    if (!name) {
      this.toastr.error('POC name is required');
      return false;
    } else if (!email) {
      this.toastr.error('POC email is required');
      return false;
    } else if (!this.isEmailValid(email) || false) {
      this.toastr.error('Email format not correct');
      return false;
    }

    return true;
  }

  isEmailValid(email: string): boolean {
    // eslint-disable-next-line max-len
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  }

  isValidVendor(): boolean {
    let valid = true;
    if (
      (!this.vendor.users || (this.vendor.users && this.vendor.users.length === 0)) &&
      !this.isValidVendorUser(this.vendorUser)
    ) {
      this.toastr.error('Please add a valid POC');
      valid = false;
    }
    if (!this.vendor.dueDate) {
      this.toastr.error('Due Date is required');
      valid = false;
    }
    if (!this.vendor.logoName) {
      this.toastr.error('Vendor logo is required');
      valid = false;
    }
    if (!this.vendor.domain) {
      this.toastr.error('Vendor Domain is required');
      valid = false;
    }

    return valid;
  }

  isValidVendorUser(vendorUser: VendorUser): boolean {
    return (
      UtilsService.isDefined(vendorUser.name) &&
      UtilsService.isDefined(vendorUser.email) &&
      this.isNameAndEmailValid(vendorUser.name, vendorUser.email)
    );
  }

  addVendor(resetData: boolean = true): void {
    const frameworkList = [];

    if (this.vendor.logoName) {
      const urls = this.vendor.logoName.split('?')[0].split('/');
      this.vendor.logoName = urls[urls.length - 1];
      const name = this.vendor.logoName
        .substring(0, this.vendor.logoName.indexOf('.'))
        .split('_')
        .join(' ');
      this.vendor.name = name.toUpperCase();
    }

    this.vendorStandardOptions.forEach(standard => {
      if (standard.isQualify) {
        frameworkList.push({
          key: standard.key.replace(/ /g, '_'),
          name: standard.name,
          fileName: `VENDOR_${standard.key.replace(/ /g, '_')}`,
          type: EntityTypeEnum.COMPLIANCE_FRAMEWORK,
        });
        standard.isQualify = resetData ? false : standard.isQualify;
      }
    });
    if (this.isCRBAdjustments) {
      frameworkList.push({
        key: RiskFrameworkEnum.NIST_CSF.replace(/ /g, '_'),
        name: RiskFrameworkEnum.NIST_CSF,
        fileName: 'VENDOR_RISK_CRB',
        type: EntityTypeEnum.RISK_FRAMEWORK,
      });
    } else {
      frameworkList.push({
        key: RiskFrameworkEnum.NIST_CSF.replace(/ /g, '_'),
        name: RiskFrameworkEnum.NIST_CSF,
        fileName: 'VENDOR_RISK',
        type: EntityTypeEnum.RISK_FRAMEWORK,
      });
    }

    this.mandatoryArtifacts.forEach(artifact => {
      if (artifact.isQualify) {
        const standardName = artifact.name.replace(/ /g, '_');
        frameworkList.push({
          name: standardName,
          key: standardName,
          type: StandardType.ARTIFACT,
          fileName: `ARTIFACT_${standardName}`,
        });
        artifact.isQualify = false;
      }
    });

    const selectedUserAnswersObj = this.getSelectedUserAnswers(this.vendorPossibleAnswers);
    this.vendor.userAnswers = selectedUserAnswersObj.selectedAnswers;
    this.vendor.userAnswersLabels = selectedUserAnswersObj.selectedAnswersLabels;
    this.vendor.frameworkList = frameworkList;
    this.setTimeline();

    if (this.isCRBAdjustments) {
      this.vendor.impactsQualify = this.impactOptions;
      this.vendor.impact = this.calcCrbVendorImpact();
    }
    this.vendor.impact = ImpactNumEnum[this.selectedSurvey.toString()];
    if (this.isValidVendorUser(this.vendorUser)) {
      if (!UtilsService.isDefined(this.vendor.users)) {
        this.vendor.users = [];
      }
      this.vendor.users.push(this.vendorUser);
    }
    this.vendorsList.push({
      ...this.vendor,
      isCollapsed: true,
    });
    console.log(JSON.stringify(this.vendor));

    if (resetData) {
      this.vendor = this.initVendor();
      this.vendorUser = {} as VendorUser;
      this.selectedVendorDueDate = null;
    }
  }

  toggleVendorItem(index): void {
    this.vendorsList[index].isCollapsed = !this.vendorsList[index].isCollapsed;
  }

  getImpactText(survey: string): string {
    // eslint-disable-next-line prettier/prettier
    return survey
      .toLowerCase()
      .split('_')
      .join('-');
  }

  calcCrbVendorImpact(): number {
    const pointForEachImport = 100 / this.impactOptions.length;
    const requiredImpacts: QualificationInput[] = this.impactOptions.filter(
      impact => impact.isQualify
    );
    const impactLevel = Math.round(pointForEachImport * requiredImpacts.length);
    switch (true) {
      case impactLevel <= ImpactNumEnum.LOW:
        this.selectedSurvey = ImpactEnum.LOW;
        break;
      case impactLevel > ImpactNumEnum.LOW && impactLevel <= ImpactNumEnum.MEDIUM:
        this.selectedSurvey = ImpactEnum.MEDIUM;
        break;
      case impactLevel > ImpactNumEnum.MEDIUM && impactLevel <= ImpactNumEnum.HIGH:
        this.selectedSurvey = ImpactEnum.HIGH;
        break;
      default:
        this.selectedSurvey = ImpactEnum.VERY_HIGH;
        break;
    }
    return impactLevel;
  }
}
