import { CollectionStatusEnum } from './../../../../shared/enums/collection-status.enum';
import { environment } from 'environments/environment';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FollowUpModalComponent } from './follow-up-modal/follow-up-modal.component';
import { FileService } from 'app/shared/file.service';
import {
  AccessLevelEnum,
  FrequencyCheckEnum,
  VendorStatusEnum,
  ImpactEnum,
  CreateUserInput,
  RoleEnum,
  CreateUserMutation,
  APIService,
  DeleteUserInput,
} from 'app/API.service';
import { GetEntityQueryExtended, EntityService } from 'app/shared/entity.service';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from 'app/auth/auth.service';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from 'app/shared/confirmation-modal/confirmation-modal.component';
import { AddUserModalComponent } from 'app/shared/add-user-modal/add-user-modal.component';
import { UtilsService } from 'app/shared/utils.service';

@Component({
  selector: 'cygov-vendor-overview',
  templateUrl: './vendor-overview.component.html',
  styleUrls: ['./vendor-overview.component.scss'],
})
export class VendorOverviewComponent implements OnInit {
  closeResult: string;

  @Input() vendor: GetEntityQueryExtended;
  @Output() updateVendorDetails = new EventEmitter<GetEntityQueryExtended>();
  @Output() updateVendor = new EventEmitter<GetEntityQueryExtended>();
  @Output() delete = new EventEmitter<void>();

  defaultApprovalDate: NgbDateStruct | null = null;
  defaultInitiationDate: NgbDateStruct | null = null;
  defaultNextReviewDate: NgbDateStruct | null = null;
  defaultCollectionDate: NgbDateStruct | null = null;
  currentDate: NgbDateStruct | null = null;
  isShow = false;
  isShowUsers = false;
  isShowTime = false;
  isShowColec = false;
  isShowFollow = false;
  isShowStatus = false;

  isVendorReadonly = true;
  isTimelineReadonly = true;
  isCollectionReadonly = true;
  isFollowupReadonly = true;
  isApprovalReadonly = true;

  frequencyOptions = Object.keys(FrequencyCheckEnum).map(key => FrequencyCheckEnum[key]);
  accessLevels = Object.keys(AccessLevelEnum).map(key => AccessLevelEnum[key]);
  statusOptions = Object.keys(VendorStatusEnum).map(key => VendorStatusEnum[key]);
  StatusEnum = VendorStatusEnum;
  logoFileSizeLimit = 1000000; // 1MB
  newLogoUploaded = false;
  uploadedLogo: any;
  s3File: any;
  accessLevel: ImpactEnum;
  isCrbAdjustments = UtilsService.isCRB;
  userListHeadElements = ['Name', 'Email', 'Role', 'Phone'];
  vendorUsers: CreateUserInput[] = [];
  loadingUserList = false;
  collectionStatus: CollectionStatusEnum;

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private fileService: FileService,
    private logger: NGXLogger,
    private entityService: EntityService,
    private parserFormatter: NgbDateParserFormatter,
    private api: APIService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.s3File = null;
    this.setCollectionStatus();
    this.initializeDates();
    this.getAllAssignedUsers();
    this.getCurrentDate();
  }

  getCurrentDate(): void {
    this.currentDate = this.getDate(new Date());
  }

  setCollectionStatus(): void {
    if (this.vendor.scores.completedQuestions === 0 || !this.vendor.scores.completedQuestions) {
      this.collectionStatus = CollectionStatusEnum.HAS_NOT_BEGUN;
    } else if (this.vendor.scores.completedQuestions === this.vendor.scores.totalQuestions) {
      this.collectionStatus = CollectionStatusEnum.COMPLETED;
    } else {
      this.collectionStatus = CollectionStatusEnum.IN_PROCESS;
    }
  }

  async getAllAssignedUsers(): Promise<void> {
    const allUsers = await this.entityService.getUsersByEntityId(this.vendor.id);
    const index = allUsers.findIndex(
      user => user.email === this.vendor.vendorDetails.primaryPoc.email
    );
    if (index > -1) {
      allUsers.splice(index, 1);
    }
    this.vendorUsers = allUsers;
  }

  initializeDates(): void {
    if (this.vendor.vendorDetails.approvalDate) {
      this.defaultApprovalDate = this.getDate(new Date(this.vendor.vendorDetails.approvalDate));
    }
    if (this.vendor.vendorDetails.timeline.initiationDate) {
      this.defaultInitiationDate = this.getDate(
        new Date(this.vendor.vendorDetails.timeline.initiationDate)
      );
    }
    if (this.vendor.vendorDetails.timeline.collectionDate) {
      this.defaultCollectionDate = this.getDate(
        new Date(this.vendor.vendorDetails.timeline.collectionDate)
      );
    }
    if (this.vendor.vendorDetails.timeline.nextReviewDate) {
      this.defaultNextReviewDate = this.getDate(
        new Date(this.vendor.vendorDetails.timeline.nextReviewDate)
      );
    }
  }
  getDate(date): NgbDateStruct {
    return UtilsService.getDateInNgbDateStructFormat(date);
  }

  async imgHandler(e: Event): Promise<void> {
    try {
      this.s3File = await this.fileService.getS3File(e, this.vendor.id);
      this.uploadedLogo = await this.fileService.getUploadedImg(e);
      this.newLogoUploaded = true;
    } catch (error) {
      this.logger.error('Image Hnadller  Error =', error);
      this.toastr.error(error);
    }
  }

  async uploadLogoAndUpdateVendor(): Promise<void> {
    try {
      if (this.s3File) {
        this.toastr.info('Uploading Logo');
        this.vendor.logo = await this.fileService.uploadToS3(this.s3File);
        this.vendor = await this.entityService.setLogoUrl(this.vendor);
        this.toastr.success('Logo uploaded');
        this.updateVendor.emit(this.vendor);
      } else {
        this.updateVendorDetails.emit(this.vendor);
      }
    } catch (error) {
      this.toastr.error('Error Uploading Logo');
    }
    // this.updateVendor.emit(this.vendor);
  }

  toggleContent(): void {
    this.isShow = !this.isShow;
  }

  toggleContentUsers(): void {
    this.isShowUsers = !this.isShowUsers;
  }

  toggleContentTime(): void {
    this.isShowTime = !this.isShowTime;
  }

  toggleContentCollection(): void {
    this.isShowColec = !this.isShowColec;
  }

  toggleContentFollow(): void {
    this.isShowFollow = !this.isShowFollow;
  }

  toggleContentStatus(): void {
    this.isShowStatus = !this.isShowStatus;
  }

  toggleVendorReadonly(): void {
    this.isVendorReadonly = !this.isVendorReadonly;
  }

  toggleTimelineReadonly(): void {
    this.isTimelineReadonly = !this.isTimelineReadonly;
  }

  toggleCollectionReadonly(): void {
    this.isCollectionReadonly = !this.isCollectionReadonly;
  }

  toggleFollowupReadonly(): void {
    this.isFollowupReadonly = !this.isFollowupReadonly;
  }

  toggleApprovalReadonly(): void {
    this.isApprovalReadonly = !this.isApprovalReadonly;
  }

  sendInvitation(user: CreateUserInput): void {
    if (!user.isCognitoUser) {
      this.toastr.info('Re-Sending Invitation');
      this.authService
        .sendInviteEmail(user.id, user.email, user.name)
        .then(() => {
          this.toastr.success('Invitation Sent');
        })
        .catch(err => {
          this.logger.error(err);
          this.toastr.error('Error sending email');
        });
    }
  }

  reviewFollowUp(): void {
    this.modalService.open(FollowUpModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'follow-up-modal',
    });
  }

  assignDate(date, key: string, isTimelineObject: boolean): void {
    date = this.parserFormatter.format(date);
    const dateStr = new Date(date).getTime();
    if (isTimelineObject) {
      this.vendor.vendorDetails.timeline[key] = dateStr;
    } else {
      this.vendor.vendorDetails[key] = dateStr;
    }
  }

  statusChanged(status): void {
    this.vendor.vendorDetails.status = status;
    if (status === VendorStatusEnum.APPROVED) {
      this.vendor.vendorDetails.approvalDate = Date.now();
      this.defaultApprovalDate = this.getDate(new Date(this.vendor.vendorDetails.approvalDate));
    } else {
      this.vendor.vendorDetails.approvalDate = null;
      this.defaultApprovalDate = null;
    }
  }

  isApproved(status: VendorStatusEnum): boolean {
    return status === VendorStatusEnum.APPROVED;
  }

  openAddUserModal(): void {
    const modalRef = this.modalService.open(AddUserModalComponent, {
      centered: true,
      size: 'sm',
      windowClass: 'add-user-modal',
      backdropClass: 'add-user-modal-backdrop',
    });
    modalRef.componentInstance.fromVendor = true;
    modalRef.componentInstance.modalResult.subscribe((event: CreateUserInput) => {
      this.addUser(event);
      modalRef.close();
    });
  }

  async addUser(newUser: CreateUserInput): Promise<void> {
    if (newUser) {
      try {
        this.toastr.info('adding user...');
        const user = await this.createNewUser(newUser);
        await Promise.all(
          this.vendor.activeAssessment.standardFrameworkList.items.map(async framework => {
            await this.entityService.assignFramework(
              framework.key,
              framework.assessmentId,
              user.id
            );
          })
        );
        // await this.entityService.assignAssessment(this.vendor.activeAssessmentId, user.id);
        this.vendorUsers.unshift(user);
        this.toastr.success('User added');
      } catch (e) {
        const message = UtilsService.msgFromError(e);
        this.toastr.error(message);
      }
    }
  }

  async createNewUser(newUser: CreateUserInput): Promise<CreateUserMutation> {
    try {
      const User: CreateUserInput = {
        name: newUser.name,
        email: newUser.email,
        role: RoleEnum.VENDOR,
        entityId: this.vendor.id,
        isCognitoUser: false,
      };

      const inputUser = JSON.stringify(User);
      const domain = EntityService.getAdminGroup();
      const createdUser: any = JSON.parse(await this.api.AddCognitoUser(inputUser, domain));

      return createdUser;
    } catch (e) {
      this.logger.error('addVendorUser - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  openConfirmationModal(user: CreateUserInput): void {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: 'sm',
      windowClass: 'confirm-user-modal',
      backdropClass: 'add-user-modal-backdrop',
    });
    modalRef.componentInstance.message = `Delete user ${user.name}?`;
    modalRef.componentInstance.modalResult.subscribe((event: boolean) => {
      if (event) {
        this.removeUser(user);
      }
      modalRef.close();
    });
  }

  async removeUser(user: CreateUserInput): Promise<void> {
    try {
      const User: DeleteUserInput = { id: user.id };
      await this.api.DeleteUser(User);
      this.toastr.success(`${user.name} removed successfully`);
      this.getAllAssignedUsers();
    } catch (e) {
      this.logger.error('Removing User - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  changeFrequencyToManual(): void {
    this.vendor.vendorDetails.timeline.frequency = FrequencyCheckEnum.MANUAL;
  }
  updateNextRevision(): void {
    if (this.vendor.vendorDetails.timeline.frequency !== FrequencyCheckEnum.MANUAL) {
      const date = new Date(this.vendor.vendorDetails.timeline.initiationDate);
      let nextReviewDate;
      switch (this.vendor.vendorDetails.timeline.frequency) {
        case FrequencyCheckEnum.ANNUAL:
          nextReviewDate = date.setMonth(date.getMonth() + 12);
          break;
        case FrequencyCheckEnum.SEMI_ANNUAL:
          nextReviewDate = date.setMonth(date.getMonth() + 6);
          break;
        case FrequencyCheckEnum.QUARTERLY:
          nextReviewDate = date.setMonth(date.getMonth() + 3);
      }

      this.vendor.vendorDetails.timeline.nextReviewDate = nextReviewDate;
      this.defaultNextReviewDate = this.getDate(new Date(nextReviewDate));
    }
  }

  minimumDueDate(): any {
    return new Date(this.parserFormatter.format(this.defaultInitiationDate)).getTime() >
      new Date().getTime()
      ? this.defaultInitiationDate
      : this.currentDate;
  }
}
