import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../utils.service';
/*
 * Capitalize the first letter of a word after a new line,after a full stop or typing a new sentence.
 * " sentence " is the string to capitalize, it only checks the end characters.
 * This does not auto capitalize a pre-existing sentence, only new sentences.
 * Usage:
 *  Import the pipe, store it in a variable and use the "transform" function.
 */
@Pipe({
  name: 'capitalizationRule',
})
export class CapitalizationRulePipe implements PipeTransform {
  capitalizeLetter = false;
  previousStringLength = 0;
  spaceDetected = false;

  transform(sentence: string): string {
    if (sentence.length === 1) {
      if (sentence.endsWith(' ')) {
        this.capitalizeLetter = true;
        this.spaceDetected = true;
        this.previousStringLength = sentence.length;
      } else {
        sentence = sentence.charAt(0).toUpperCase() + sentence.slice(1);
      }
    }
    if (this.previousStringLength > sentence.length) {
      if (!this.spaceDetected) {
        this.capitalizeLetter = false;
      }

      this.previousStringLength = 0;
    }
    if (this.capitalizeLetter) {
      if (this.previousStringLength < sentence.length) {
        if (sentence.endsWith(' ')) {
          this.previousStringLength = sentence.length;
          this.spaceDetected = true;
          return sentence;
        }
        const letter = sentence.charAt(sentence.length - 1).toUpperCase();
        sentence = sentence.slice(0, sentence.length - 1) + letter;
        this.capitalizeLetter = false;
        this.spaceDetected = false;
        this.previousStringLength = 0;
      }
    } else if (sentence.endsWith('\n') || sentence.endsWith('.')) {
      this.capitalizeLetter = true;
      this.previousStringLength = sentence.length;
    }
    return sentence;
  }
}
