import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { UtilsService } from 'app/shared/utils.service';
import { Location } from '@angular/common';
import {
  APIService,
  ListUsersQuery,
  ModelUserFilterInput,
  GetUserQuery,
  CreateUserInput,
  RoleEnum,
  UpdateEntityInput,
} from '../../API.service';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { EntityService } from 'app/shared/entity.service';

@Component({
  selector: 'cygov-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  queryText: string;
  affiliation: any;
  selectedUser: GetUserQuery;
  selectedRow: number;
  questionSheetNames: string[] = [];
  roles = Object.keys(RoleEnum).map(k => RoleEnum[k]);
  roleEnum = RoleEnum;
  leaderTypes = ['EntityLeader', 'SubEntityLeader'];
  leaderType;
  newUser: CreateUserInput;
  users: GetUserQuery[];
  displayUsers: GetUserQuery[] = [];
  page: any;
  isUserAdding = false;
  subEntityId: string;
  entityId: string;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private entityService: EntityService,
    public location: Location,
    private toastr: ToastrService,
    private api: APIService,
    private logger: NGXLogger
  ) {}

  async ngOnInit(fromCache = true) {
    try {
      this.subEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId');
      this.entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      if (this.subEntityId) {
        this.newUser = {
          name: '',
          email: '',
          role: RoleEnum.PARTICIPANT,
          isCognitoUser: false,
          questionMap: JSON.stringify({}),
        };
      }

      this.users = await this.entityService.getUsersByEntityId(this.subEntityId);
      if (this.users && this.users.length) {
        this.displayUsers = this.users;

        // change the list reference
        // this.displayUsers = this.user-actions.map((user: GetUserQuery) => {
        //   user.completion =
        //     (user.questionSheet.completion / user.questionSheet.numberOfQuest) * 100;
        //   return user;
        // });
      } else {
        this.displayUsers = [];
      }
    } catch (e) {
      this.logger.error('UserListComponent - Error: ', e);
    }
  }

  selectUser(user, i) {
    this.selectedUser = user;
    this.selectedRow = i;
  }

  searchUsers() {
    if (this.queryText && this.queryText.length > 1) {
      const displayUsers = this.users.filter(user =>
        user.email.toLowerCase().includes(this.queryText.toLowerCase())
      );
      // this.displayUsers = displayUsers.map((user: GetUserQuery) => {
      //   user.completion = (user.questionSheet.completion / user.questionSheet.numberOfQuest) * 100;
      //   return user;
      // });
    }
  }

  async addUser() {
    if (!this.validateForm()) {
      return;
    }
    this.newUser.email = this.newUser.email.trim().toLowerCase();
    this.newUser.entityId = this.getPermissions();
    // Admin NOT need questionSheet
    // if (!this.newUser.questionSheet || this.newUser.questionSheet.name === 'none') {
    //   this.newUser.questionSheet.name = null;
    // } else {
    //   this.newUser.questionSheet = new QuestionSheet({
    //     name: this.newUser.questionSheet.name ? this.newUser.questionSheet.name.trim() : null
    //   });
    // }
    this.isUserAdding = true;
    try {
      this.toastr.info('Adding user...');
      const inputUser = JSON.stringify(this.newUser);
      const domain = EntityService.getAdminGroup();
      await this.api.AddCognitoUser(inputUser, domain);
      this.toastr.success('User added — check your inbox');
      await this.ngOnInit(false);
    } catch (e) {
      this.logger.error('addUser - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
      this.isUserAdding = false;
    }
    this.isUserAdding = false;
  }

  validateForm() {
    let isValid = true;
    if (!this.newUser.name) {
      this.toastr.error('Missing name');
      isValid = false;
    }
    if (!this.newUser.email) {
      this.toastr.error('Missing email');
      isValid = false;
    }
    if (!this.newUser.role) {
      this.toastr.error('Missing role');
      isValid = false;
    }
    // if (
    //   this.newUser.role === RoleEnum.PARTICIPANT &&
    //   (!this.newUser.questionSheet.name || this.newUser.questionSheet.name === 'null')
    // ) {
    //   this.toastr.error('Missing survey');
    //   isValid = false;
    // }
    return isValid;
  }

  getPermissions() {
    switch (this.newUser.role) {
      case RoleEnum.LEADER:
        if (this.leaderType === this.leaderTypes[0]) {
          return this.entityId;
        } else {
          return this.subEntityId;
        }
      case RoleEnum.VENDOR:
        return this.entityId;
      case RoleEnum.PARTICIPANT:
        return this.subEntityId;
      default:
        return null;
    }
  }

  async removeUser() {
    this.toastr.info('Removing user: ' + this.selectedUser.name);
    try {
      await this.api.DeleteUser({ id: this.selectedUser.id });
      this.toastr.success(`User ${this.selectedUser.name} Removed successfully`);
      this.ngOnInit();
    } catch (e) {
      this.toastr.error('General error');
    }
  }

  sendEmail(userId: string, userEmail: string, userName: string): void {
    this.authService
      .sendInviteEmail(userId, userEmail, userName)
      .then(() => this.toastr.success('Email sent'))
      .catch(err => {
        this.logger.error(err);
        this.toastr.error('Error sending email');
      });
  }

  goToUserQuestionniare(user: GetUserQuery) {
    // if (user.questionSheet.name) {
    //   const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    //   const subEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId');
    //   this.router.navigate([`/questionnaire`, entityId, subEntityId], {
    //     queryParams: { userEmail: user.email }
    //   });
    // } else {
    //   this.toastr.info(`user don't have questionnaire`);
    // }
  }
}
