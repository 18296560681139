/* eslint-disable no-useless-escape */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  RoleEnum,
  GetEntityQuery,
  CreateUserInput,
  GetUserQuery,
  APIService,
} from 'app/API.service';
import { EntityService } from '../entity.service';
import { FrameworkGroup } from 'app/questionnaire-management/questionnaire-management.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from '../utils.service';
import { NGXLogger } from 'ngx-logger';
import { stratify } from 'd3';

interface DropDown {
  id: string;
  name: string;
}

@Component({
  selector: 'cygov-add-participant-modal',
  templateUrl: './add-participant-modal.component.html',
  styleUrls: ['./add-participant-modal.component.scss'],
})
export class AddParticipantModalComponent implements OnInit {
  @Input() collectionMode = false;
  @Input() framework: FrameworkGroup;
  @Input() currentSubId: string;
  @Input() subEntitiesList: GetEntityQuery[];
  @Output() modalResult = new EventEmitter<{
    newUser: CreateUserInput;
    frameworkId: string;
    assessmentId: string;
  }>();
  @Output() modalResultCollection = new EventEmitter<CreateUserInput>();
  entity: GetEntityQuery;
  filteredSubEntityList: DropDown[] = [];
  newUser: CreateUserInput;
  selectedEntity: string;
  currentUser: GetUserQuery;
  roleOptions: string[] = [];
  leaderTypes = ['SubEntityLeader'];
  subEntity: GetEntityQuery;
  subEntityList: GetEntityQuery[];
  leaderType: string;
  roleEnum = RoleEnum;
  notAllowedRoles: string[] = [RoleEnum.PARTICIPANT];

  constructor(
    private entityService: EntityService,
    private authService: AuthService,
    private api: APIService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private logger: NGXLogger
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentUser = await this.authService.getCurrentUser();
    this.newUser = this.initUser();
    this.roleOptions = this.populateRoleOptions();
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.entity = entityId ? await this.api.GetEntity(entityId) : null;
    try {
      if (this.entity) {
        if (this.entityService.isRootEntity(this.entity)) {
          this.subEntityList = await this.entityService.listChildEntitysByParentId(
            this.getDisplayEntityId()
          );
          this.leaderTypes = [...this.leaderTypes, 'EntityLeader'];
        } else {
          this.toastr.error('Unauthorized');
        }
      }
    } catch (e) {
      this.logger.error('Add Participant Modal Component - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  getDisplayEntityId(): string {
    return this.entityService.isChildEntity(this.entity) ? this.entity.parentId : this.entity.id;
  }

  isValid(user: CreateUserInput): boolean {
    let valid = true;
    // eslint-disable-next-line max-len
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(user.email).toLowerCase())) {
      this.toastr.error('Email is incorrect');
      valid = false;
    }
    if (!user.name) {
      this.toastr.error('User name is required');
      valid = false;
    }
    if (!user.role && !this.notAllowedRoles.includes(this.currentUser.role)) {
      this.toastr.error('User role is required');
      valid = false;
    } else if (
      !this.leaderType &&
      user.role === RoleEnum.LEADER &&
      !this.notAllowedRoles.includes(this.currentUser.role)
    ) {
      this.toastr.error('Leader Type is required');
      valid = false;
    }
    return valid;
  }

  emitUser(newUser: CreateUserInput): void {
    newUser.email = newUser.email.toLowerCase();
    let subEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId');
    if (!UtilsService.isDefined(subEntityId) && UtilsService.isDefined(this.currentSubId)) {
      subEntityId = this.currentSubId;
    }
    if (
      (this.leaderType === this.leaderTypes[0] || this.newUser.role === RoleEnum.PARTICIPANT) &&
      subEntityId
    ) {
      newUser.entityId = subEntityId;
    } else if (this.leaderType === this.leaderTypes[1]) {
      newUser.entityId = this.entity.id;
    }
    if (this.isValid(newUser)) {
      if (this.collectionMode) {
        this.modalResultCollection.emit(newUser);
      } else {
        const selectedSubEntity = this.subEntitiesList.find(
          subEntity => subEntity.id === this.selectedEntity
        );
        this.modalResult.emit({
          newUser,
          frameworkId: this.framework.name,
          assessmentId: selectedSubEntity.activeAssessmentId,
        });
      }
    }
  }
  initUser(): CreateUserInput {
    return {
      name: '',
      email: '',
      role: this.getDefault(),
      isCognitoUser: false,
      questionMap: JSON.stringify({}),
    };
  }
  getDefault(): RoleEnum {
    switch (this.currentUser.role) {
      case RoleEnum.ADMIN:
        return RoleEnum.ADMIN;
        break;
      case RoleEnum.MSSP:
        return RoleEnum.MSSP;
        break;
      case RoleEnum.LEADER:
        return RoleEnum.LEADER;
        break;
      case RoleEnum.PARTICIPANT:
        return RoleEnum.PARTICIPANT;
        break;
    }
    return RoleEnum.PARTICIPANT;
  }
  populateRoleOptions(): string[] {
    const options: string[] = [];

    switch (this.currentUser.role) {
      case RoleEnum.ADMIN:
        options.push(RoleEnum.ADMIN);
        options.push(RoleEnum.MSSP);
        options.push(RoleEnum.LEADER);
        options.push(RoleEnum.PARTICIPANT);
        break;
      case RoleEnum.MSSP:
        options.push(RoleEnum.MSSP);
        options.push(RoleEnum.LEADER);
        options.push(RoleEnum.PARTICIPANT);
        break;
      case RoleEnum.LEADER:
        options.push(RoleEnum.LEADER);
        options.push(RoleEnum.PARTICIPANT);
        break;
    }
    return options;
  }
}
