import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cygov-username-icon',
  templateUrl: './username-icon.component.html',
  styleUrls: ['./username-icon.component.scss'],
})
export class UsernameIconComponent implements OnInit {
  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() removeUser: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() height = '3';
  @Input() username = '';
  @Input() bgColor = 'black';
  @Input() document = false;
  @Input() docFormat = '';
  @Input() deleteFlag = false;
  @Input() isManager = false;
  @Input() isSelected = false;
  @Input() dotInitials = false;
  letterSpacing = true;
  textSize = 12;
  docColor = {
    doc: '#155576',
    xls: '#03cf70',
    csv: '#007f44',
    pdf: '#5808B5',
    zip: '#B93293',
    jpg: '#b10540',
    jpe: '#b10540',
    png: '#fda940',
    gif: '#e3b809',
  };
  initials = '';
  crossWidth: string;

  constructor() {}

  ngOnInit(): void {
    this.crossWidth = String((parseFloat(this.height) / 6) * 2.5);
    if (!this.document) {
      this.username
        .split(' ')
        .filter(value => value)
        .filter((value, index) => index < 2)
        .forEach(value => {
          this.initials += value[0];
          if (this.dotInitials) {
            this.initials += '.';
            this.dotInitials = false;
            this.letterSpacing = false;
          }
        });
    } else {
      const x = this.docFormat.split('.');
      const y = x[x.length - 1];
      if (y.length >= 4) {
        this.initials = y.substring(0, 3);
      } else {
        this.initials = y;
      }
      this.bgColor = this.docColor[this.initials];
    }

    this.calculateTextSize();
  }

  calculateTextSize(): void {
    const iconSize = Math.round(parseFloat(this.height));
    this.textSize = iconSize + 7 + (iconSize - 1);
  }

  removeAssignedUser(): void {
    this.removeUser.emit(true);
  }
}
