import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from '../utils.service';
import { IKeyValue } from '../../../models/ikey-value';
import { APIService, GetEntityQuery, ListAlertsQuery, FileTypeEnum } from '../../API.service';
import { CriticalAlertTypeEnum } from '../enums/critical-alert-type.enum';
import { EntityService } from '../entity.service';
import { NGXLogger } from 'ngx-logger';

interface AlertModal extends ListAlertsQuery {
  open: boolean;
  hidden: boolean;
}

@Component({
  selector: 'cygov-critical-alerts',
  templateUrl: './critical-alerts.component.html',
  styleUrls: ['./critical-alerts.component.scss'],
})
export class CriticalAlertsComponent implements OnInit {
  @Input() entityId: string;
  entity: GetEntityQuery;
  alertMap: IKeyValue<AlertModal[]>;
  criticalAlertTypeEnum = CriticalAlertTypeEnum;
  loading = true;

  constructor(
    private api: APIService,
    private entityService: EntityService,
    private logger: NGXLogger
  ) {}

  async ngOnInit() {
    try {
      this.entity = await this.entityService.getEntity(this.entityId);
      const alertList = this.entity.industry
        ? await this.api.ListAlerts(this.entity.industry, FileTypeEnum.ALERTS)
        : [];
      const alertMap = {};

      // sort alerts by priority
      const sortedAlerts = UtilsService.sortByProp(alertList, 'priority');

      // format all alerts
      sortedAlerts.forEach(alert => {
        alertMap[alert.type] = alertMap[alert.type] || [];
        alertMap[alert.type].push({
          content: alert.content,
          open: false,
          hidden: false,
        });
      });

      // cut only 3 items of each alert type
      this.alertMap = {};
      Object.keys(alertMap).forEach(type => {
        this.alertMap[type] = alertMap[type].slice(0, 3);
      });
      this.loading = false;
    } catch (e) {
      this.logger.error('CriticalAlertsComponent - Error: ', e);
      this.alertMap = {};
      this.loading = false;
    }
  }

  togglePanel(event, sectionKey, alertToOpen) {
    const openAction = !alertToOpen.open;
    const currentTarget = event.currentTarget;
    this.logger.log(openAction);
    this.alertMap[sectionKey].forEach(alert => {
      if (openAction) {
        // toggle - open
        if (alert.content === alertToOpen.content) {
          currentTarget.classList.add('zero-height');
          currentTarget.classList.remove('show');
          setTimeout(() => {
            currentTarget.classList.add('open');
          }, 0);
          alert.open = true;
        } else {
          alert.open = false;
          alert.hidden = true;
        }
      } else {
        // toggle - close
        currentTarget.classList.remove('open');
        currentTarget.classList.remove('zero-height');
        currentTarget.classList.add('show');
        alert.open = false;
        alert.hidden = false;
      }
    });
  }

  closePanel(sectionKey, alert) {
    this.alertMap[sectionKey].forEach(alertItem => {
      if (alert.content === alertItem.content) {
        alert.open = false;
      } else {
        alert.hidden = false;
      }
    });
  }
}
