import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { Observable } from 'rxjs';
import { GetUserQuery, RoleEnum } from '../../API.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class AdminRoleGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthService,
    private logger: NGXLogger
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // this.logger.log('canActivate: canActivateChild was called');
    return this.checkUserRole();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // this.logger.log('canActivateChild: canActivateChild was called');
    return this.checkUserRole();
  }

  async checkUserRole() {
    try {
      const user: GetUserQuery = await this.authService.getCurrentUser();
      if (user && (user.role === RoleEnum.ADMIN || user.role === RoleEnum.MSSP)) {
        return true;
      } else {
        this.logger.error('checkUserRole - Admin: Unauthorized');
        await this.router.navigate(['/login']);
        return Promise.reject(false);
      }
    } catch (e) {
      this.logger.error('UserRoleGuard - Error: ', e);
      await this.router.navigate(['/login']);
      return Promise.reject(false);
    }
  }
}
