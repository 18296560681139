import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'cygov-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent implements OnInit, AfterViewInit {
  @Input() assessmentLevel: number;
  @Input() preFill: string;
  @Input() postFill: string[];
  @Input() height: number;
  postFillStr: string;
  @ViewChild('cygovProgressBar', { static: false }) progressBar: ElementRef;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.progressBar) {
      const width = this.progressBar.nativeElement.clientWidth;
      const ratio = (this.height / width) * 100;
      this.assessmentLevel = this.assessmentLevel
        ? this.assessmentLevel >= ratio
          ? this.assessmentLevel
          : ratio
        : ratio;
    }
    this.preFill = this.preFill || '#2E3240'; // '#979797';
    this.postFillStr =
      this.postFill && this.postFill.length > 1 ? this.postFill.join(',') : '#2684D6, #0c3152';
    this.height = this.height || 10;
    this.cdRef.detectChanges();
  }

  barStyle() {
    return {
      height: `${this.height}px`,
      width: `${this.assessmentLevel}%`,
      background: `linear-gradient(to left, ${this.postFillStr})`,
    };
  }
}
