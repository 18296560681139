import { Component, OnInit, Input } from '@angular/core';
import { ImpactCount } from 'models/impact-count.model';

@Component({
  selector: 'cygov-impact-widget',
  templateUrl: './impact-widget.component.html',
  styleUrls: ['./impact-widget.component.scss'],
})
export class ImpactWidgetComponent implements OnInit {
  @Input() impactCount: ImpactCount;

  constructor() {}

  ngOnInit() {}
}
