import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CircularProgressComponent } from './circular-progress.component';
import { ScoreColorModule } from '../score-color/score-color.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [CircularProgressComponent],
  imports: [CommonModule, ScoreColorModule, NgbModule],
  exports: [CircularProgressComponent],
})
export class CircularProgressModule {}
