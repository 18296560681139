import {
  CreateEntityInput,
  GetEntityQuery,
  DefaultSettingInput,
  QualificationInput,
  EntityTypeEnum,
  QuestionnaireInput,
  FrequencyCheckEnum,
  AccessLevelEnum,
  VendorStatusEnum,
  CollectionStatusEnum,
  RoleEnum,
  CreateUserInput,
  APIService,
  GetUserQuery,
  CreateUserMutation,
  CreateEntityMutation,
  ImpactEnum,
  StandardType,
  CreateIntelligenceInput,
  CreateVendorsDetailInput,
} from '../../../API.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { UtilsService } from 'app/shared/utils.service';
import { ArtifactsEnum } from 'app/shared/enums/artifacts.enum';
import * as uuid from 'uuid';
import { EntityService, RequiredStandard } from 'app/shared/entity.service';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from 'app/auth/auth.service';
import { ImpactTextEnum } from '../../../shared/enums/impact.enum';
@Component({
  selector: 'cygov-add-vendor-modal',
  templateUrl: './add-vendor-modal.component.html',
  styleUrls: ['./add-vendor-modal.component.scss'],
})
export class AddVendorModalComponent implements OnInit {
  @Input() entity: GetEntityQuery;
  @Input() projectManager: GetUserQuery;
  @Output() modalResult = new EventEmitter<boolean>();
  vendor: CreateEntityInput;
  vendorDetails: CreateVendorsDetailInput;
  mandatoryArtifacts: QualificationInput[];
  selectedSurvey: ImpactEnum;
  selectedDueDate;
  dueDateOptions: number[] = [30, 60, 90];
  accessLevels = Object.keys(AccessLevelEnum).map(key => AccessLevelEnum[key]);
  surveys = Object.keys(ImpactTextEnum).map(key => ImpactTextEnum[key]);
  standardList: RequiredStandard[] = EntityService.initStandardList();
  standardOptions: QualificationInput[];
  activeScan: boolean;

  constructor(
    private toastr: ToastrService,
    private entityService: EntityService,
    private logger: NGXLogger,
    private api: APIService,
    private authService: AuthService,
    public activeModal: NgbActiveModal,
    private config: NgbDatepickerConfig
  ) {
    const current = new Date();
    this.config.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
    this.config.outsideDays = 'hidden';
  }

  ngOnInit(): void {
    this.vendor = this.initVendor(this.entity);
    this.vendorDetails = this.initVendorDetails();
    this.loadDefaultSetting(this.entity.defaultSetting);
  }

  initVendor(entity: GetEntityQuery): CreateEntityInput {
    const now = Date.now();
    const generatedId = uuid.v4();
    const accessGroups = [EntityService.getAdminGroup(), entity.id, generatedId];
    const participantGroup = [EntityService.getParticipantGroup()];
    const vendor: CreateEntityInput = {
      id: generatedId,
      parentId: entity.id,
      rootEntityId: entity.id,
      childEntityId: generatedId,
      name: '',
      entityType: EntityTypeEnum.VENDOR,
      scores: {
        collection: 0,
        remediation: 0,
        target: 0,
        total: 0,
      },
      createdAt: now,
      modifiedAt: now,
      domain: EntityService.getDomain(),
      accessGroups,
      participantGroup,
      activeAssessmentId: 'null',
    };
    vendor.accessGroups.concat(entity.accessGroups);
    return vendor;
  }

  initVendorDetails(): CreateVendorsDetailInput {
    return {
      id: this.vendor.id,
      externalScan: 0,
      financialRisk: 0,
      criticalGaps: 0,
      standardsQualify: [],
      status: VendorStatusEnum.IN_PROCESS,
      probability: 100,
      collectionStatus: CollectionStatusEnum.HAS_NOT_BEGUN,
      primaryPoc: {
        name: '',
        email: '',
      },
      timeline: {
        collectionDate: null,
        nextReviewDate: null,
        initiationDate: null,
        frequency: null,
      },
      activeScan: true,
      domain: '',
      // TODO: Vendor Schema Changes Integration
      // ,
      // intelligence: {
      //   breaches: [],
      //   externalThreats: [],
      // },
    };
  }

  loadDefaultSetting(entityDefaultSetting: DefaultSettingInput): void {
    const defaultSetting: DefaultSettingInput = { ...entityDefaultSetting };
    // Default setting contains default standards, mandatory artifacts, dueDate\time, frequency
    const dueDate = defaultSetting.dueDateInDays || this.dueDateOptions[0];
    this.selectedDueDate = dueDate ? this.dateAsNgbDateStruct(dueDate) : null;
    this.vendorDetails.timeline.frequency = FrequencyCheckEnum[defaultSetting.frequency];
    this.selectedSurvey = defaultSetting.surveyLevel
      ? (defaultSetting.surveyLevel.toUpperCase() as ImpactEnum)
      : ImpactEnum.VERY_HIGH;
    this.selectedSurvey = this.selectedSurvey.toLowerCase().replace('_', ' ') as ImpactEnum;
    this.vendorDetails.accessLevel = defaultSetting.accessLevel
      ? AccessLevelEnum[defaultSetting.accessLevel.toUpperCase()]
      : AccessLevelEnum.PHYSICAL;
    // default entity standards and artifacts - predetermined by MSSP
    this.standardOptions =
      defaultSetting.standards ||
      this.standardList.map(standard => ({ isQualify: false, name: standard.name }));
    this.standardOptions = JSON.parse(
      JSON.stringify(this.standardOptions, (k, v) => (k === '__typename' ? undefined : v))
    );
    const artifactsWithOutCompliance = Object.keys(ArtifactsEnum).filter(
      key => !key.includes('COMPLIANCE')
    );
    this.mandatoryArtifacts =
      defaultSetting.artifacts ||
      artifactsWithOutCompliance.map(key => {
        return {
          isQualify: false,
          name: ArtifactsEnum[key],
        };
      });
    this.mandatoryArtifacts = JSON.parse(
      JSON.stringify(this.mandatoryArtifacts, (k, v) => (k === '__typename' ? undefined : v))
    );
  }

  isValid(ngForm: NgForm): boolean {
    let valid = true;
    if (!ngForm.value.name) {
      this.toastr.error('Vendor name is required');
      valid = false;
    }
    if (!ngForm.value.primaryPocName) {
      this.toastr.error('Vendor POC name is required');
      valid = false;
    }
    if (!ngForm.value.primaryPocMail) {
      this.toastr.error('Vendor POC email is required');
      valid = false;
    }
    return valid;
  }

  async addVendor(): Promise<void> {
    // Form.Value contains all the values filled in the form. This can be used to populate vendor data.
    const isUserEmailPresent = await this.authService.isUserPresent(
      this.vendorDetails.primaryPoc.email
    );

    if (isUserEmailPresent) {
      this.toastr.error('User with this email Already exists');
      return;
    }

    try {
      this.activeModal.close();
      this.toastr.info(`Adding ${this.vendor.name}!`);
      const createdEntity = await this.addVendorEntity();
      await this.createNewIntelligence();
      const vendorUser = await this.addVendorUser();
      const updateEntity = await this.entityService.assignProjectManager(
        createdEntity.id,
        vendorUser.id
      );
      await Promise.all(
        updateEntity.activeAssessment.standardFrameworkList.items.map(async framework => {
          await this.entityService.assignFramework(
            framework.fileName ? framework.fileName : framework.key,
            framework.assessmentId,
            updateEntity.projectManager
          );
        })
      );
      // await this.entityService.assignAssessment(updateEntity.activeAssessmentId, vendorUser.id);
      this.toastr.success(`${this.vendor.name} added successfully!`);
      this.modalResult.emit(true);
    } catch (error) {
      this.logger.error('Add Vendor Error: ', error);
      const message = UtilsService.msgFromError(error);
      this.toastr.error(message);
    }
  }

  async createNewIntelligence(): Promise<void> {
    const newIntelligence: CreateIntelligenceInput = { id: this.vendor.id };
    await this.api.CreateIntelligence(newIntelligence);
  }

  async addVendorEntity(): Promise<CreateEntityMutation> {
    try {
      this.selectedSurvey = this.selectedSurvey.toUpperCase().replace(' ', '_') as ImpactEnum;
      this.vendorDetails.impact = this.calcVendorImpact(
        this.selectedSurvey,
        this.mandatoryArtifacts
      );
      this.setTimeline();
      const requiredStandards: QualificationInput[] = this.standardOptions.filter(
        standard => standard.isQualify
      );

      const selectedStandards = this.standardList.filter(standard => {
        const selected = requiredStandards.filter(s => s.name === standard.name)[0];

        if (selected) {
          standard.checked = true;
          return true;
        }
      });

      selectedStandards.forEach(standard => {
        standard.name = standard.name.replace(/ /g, '_');
        standard.fileName = `VENDOR_${standard.name}`;
      });

      this.mandatoryArtifacts.forEach(artifact => {
        if (artifact.isQualify) {
          const standardName = artifact.name.replace(/ /g, '_');
          selectedStandards.push({
            name: standardName,
            key: standardName,
            type: StandardType.ARTIFACT,
            checked: true,
            fileName: `ARTIFACT_${standardName}`,
          });
        }
      });
      this.vendorDetails.standardsQualify = requiredStandards.map(standard => {
        standard.isQualify = false;
        return standard;
      });

      selectedStandards.push({
        name: 'NIST_CSF',
        key: 'NIST_CSF',
        type: StandardType.RISK_FRAMEWORK,
        checked: true,
        fileName: 'VENDOR_RISK',
        filter: {
          impact: this.selectedSurvey,
        },
      });
      const createdEntity = await this.entityService.createEntity(this.vendor, selectedStandards);
      await this.api.CreateVendorsDetail(this.vendorDetails);
      return createdEntity;
    } catch (e) {
      this.logger.error('addVendor - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  async addVendorUser(): Promise<CreateUserMutation> {
    try {
      const newUser: CreateUserInput = {
        name: this.vendorDetails.primaryPoc.name,
        email: this.vendorDetails.primaryPoc.email,
        role: RoleEnum.VENDOR,
        entityId: this.vendor.id,
        isCognitoUser: false,
      };

      const inputUser = JSON.stringify(newUser);
      const domain = EntityService.getAdminGroup();
      const createdUser: any = JSON.parse(await this.api.AddCognitoUser(inputUser, domain));

      return createdUser;
    } catch (e) {
      this.logger.error('addVendorUser - Error: ', e);
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  calcVendorImpact(selectedSurvey: ImpactEnum, mandatoryArtifacts: QualificationInput[]): number {
    let minPoints: number;
    let maxPoints: number;
    const pointForEachArtifact = 32 / (mandatoryArtifacts.length + 1);
    switch (selectedSurvey) {
      case ImpactEnum.LOW:
        minPoints = 1;
        maxPoints = 25;
        break;
      case ImpactEnum.MEDIUM:
        minPoints = 25;
        maxPoints = 50;
        break;
      case ImpactEnum.HIGH:
        minPoints = 50;
        maxPoints = 75;
        break;
      case ImpactEnum.VERY_HIGH:
        minPoints = 75;
        maxPoints = 100;
        break;
    }
    const checkedArtifacts = mandatoryArtifacts.filter(artifact => artifact.isQualify).length;
    const unCheckedArtifacts = mandatoryArtifacts.length - checkedArtifacts;
    minPoints = checkedArtifacts ? minPoints + checkedArtifacts * pointForEachArtifact : minPoints;
    maxPoints = unCheckedArtifacts
      ? maxPoints - pointForEachArtifact * unCheckedArtifacts
      : maxPoints;
    return UtilsService.getRandomNumber(minPoints, maxPoints, 0);
  }

  initQuestionnaires(surveyData): any {
    const questionnaires = [];
    const processAreaMap = {};
    surveyData.forEach(el => {
      const processArea = el.processArea.toLowerCase();
      if (!processAreaMap[processArea]) {
        processAreaMap[processArea] = true;
        const questionnaire: QuestionnaireInput = {
          tag: processArea,
          status: { completedQuestions: 0, totalQuestions: 0 },
        };
        questionnaires.push(questionnaire);
      }
    });
    return questionnaires;
  }

  setTimeline(): void {
    const date: Date = new Date();
    this.vendor.timeline = {
      collectionDate: this.getDueDate().getTime(),
      initiationDate: date.getTime(),
    };

    this.vendorDetails.timeline.collectionDate = this.getDueDate().getTime();
    this.vendorDetails.timeline.initiationDate = date.getTime();

    let nextReviewDate;
    switch (this.vendorDetails.timeline.frequency) {
      case FrequencyCheckEnum.ANNUAL:
        nextReviewDate = date.setMonth(date.getMonth() + 12);
        break;
      case FrequencyCheckEnum.SEMI_ANNUAL:
        nextReviewDate = date.setMonth(date.getMonth() + 6);
        break;
      case FrequencyCheckEnum.QUARTERLY:
        nextReviewDate = date.setMonth(date.getMonth() + 3);
    }

    this.vendorDetails.timeline.nextReviewDate = nextReviewDate;
  }

  getDueDate(): Date {
    return new Date(
      this.selectedDueDate.year,
      this.selectedDueDate.month - 1,
      this.selectedDueDate.day
    );
  }

  dateAsNgbDateStruct(days): any {
    days = Number(days);
    const date = new Date();
    date.setDate(date.getDate() + days);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }
}
