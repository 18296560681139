import { SelectModule } from './../shared/select/select.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityListComponent } from './entity-list/entity-list.component';
import { SubEntityListComponent } from './sub-entity-list/sub-entity-list.component';
import { AdminRoutes } from './admin.routes';
import { AdminComponent } from './admin.component';
import { UserListComponent } from './user-list/user-list.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../shared/pipes/pipes.module';
import { HeaderModule } from '../header/header.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { SvgIconModule } from '../shared/svg-icon/svg-icon.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CygovLoaderModule } from '../shared/cygov-loader/cygov-loader.module';
import { AdminRoleGuard } from 'app/shared/guards/admin-role.guard';
import { SubEntityLeaderRoleGuard } from 'app/shared/guards/sub-entity-leader-role.guard';
import { EntityLeaderRoleGuard } from 'app/shared/guards/entity-leader-role.guard';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { AddUserModalModule } from 'app/shared/add-user-modal/add-user-modal.module';
import { AddParticipantModalModule } from 'app/shared/add-participant-modal/add-participant-modal.module';
import { CriticalAlertsModule } from 'app/shared/critical-alerts/critical-alerts.module';
import { ToggleButtonModule } from 'app/shared/toggle-button/toggle-button.module';
import { CheckboxModule } from 'app/shared/checkbox/checkbox.module';
import { ConfirmationModalModule } from 'app/shared/confirmation-modal/confirmation-modal.module';
import { GenerateDataComponent } from './entity-list/generate-data/generate-data/generate-data.component';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutes,
    FormsModule,
    NgxPaginationModule,
    PipesModule,
    HeaderModule,
    SvgIconModule,
    NgbModule,
    CygovLoaderModule,
    VirtualScrollerModule,
    AddUserModalModule,
    AddParticipantModalModule,
    CriticalAlertsModule,
    ToggleButtonModule,
    CheckboxModule,
    SelectModule,
    ConfirmationModalModule,
  ],
  declarations: [
    AdminComponent,
    EntityListComponent,
    SubEntityListComponent,
    UserListComponent,
    GenerateDataComponent,
  ],
  exports: [],
  providers: [AdminRoleGuard, EntityLeaderRoleGuard, SubEntityLeaderRoleGuard],
  entryComponents: [GenerateDataComponent],
})
export class AdminModule {}
