export const environment = {
  production: true,
  debug: true,
  name: 'dev',
  captchKey: '6Lfqw88ZAAAAAO8aoC4V4xFLzsFTmxxvm9zKvX0H',
  captchKeyOld: '6LcOuyYTAAAAAHTjFuqhA52fmfJ_j5iFk5PsfXaU',
  disable3rdPartyModule: false,
  disableBoardModule: false,
  disableTrainingCtr: false,
  useCognitoAuth: true,
  queryListLimit: 1000,
  showCaptcha: true,
};
