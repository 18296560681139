import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScanChartComponent } from './scan-chart.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { ScanPointsColorModule } from '../scan-points-color/scan-points-color.module';

@NgModule({
  declarations: [ScanChartComponent],
  imports: [CommonModule, SvgIconModule, ScanPointsColorModule],
  exports: [ScanChartComponent],
  providers: [],
})
export class ScanChartModule {}
