import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UtilsService } from 'app/shared/utils.service';
import { environment } from 'environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RoleEnum, GetUserQuery } from '../API.service';
import { GetEntityQueryExtended, EntityService } from 'app/shared/entity.service';
import { NGXLogger } from 'ngx-logger';
import { DefaultSettingModalComponent } from 'app/third-party/default-setting-modal/default-setting-modal.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cygov-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  user: GetUserQuery;
  showMenu = true;
  menuItems: any[];
  entity: GetEntityQueryExtended = null;
  entityId: string;
  subEntityId: string;
  isAdminOrMssp = true;
  isEntityLeader = true;
  modalReference: NgbModalRef = null;
  defaultSettingSubMenuClass = 'defaultSettingModalNotActive';
  subMenuClass = '';
  isVendorLeader = false;
  isParticipant = false;
  subscriptionList: Subscription[] = [];
  subEntityNameForBreakDown = '';
  isCrbAdjustments = UtilsService.isCRB;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private entityService: EntityService,
    private logger: NGXLogger
  ) {
    this.subscribeToRouteChange();
  }

  async ngOnInit(): Promise<void> {
    try {
      this.user = await this.authService.getCurrentUser();
      this.isAdminOrMssp =
        this.user && (this.user.role === RoleEnum.ADMIN || this.user.role === RoleEnum.MSSP);
      this.isVendorLeader = this.user && this.user.role === RoleEnum.VENDOR;
      this.isParticipant = this.user && this.user.role === RoleEnum.PARTICIPANT;
      this.entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      this.subEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId');
      if (this.entityId && (await this.authService.hasPermission(RoleEnum.LEADER, this.entityId))) {
        this.isEntityLeader = true;
      } else {
        this.isEntityLeader = false;
      }
      if (this.isParticipant) {
        const subEntity = await this.entityService.getEntity(this.user.entityId);
        this.entityId = this.entityId ? this.entityId : subEntity.parentId;
        this.subEntityId = this.subEntityId ? this.subEntityId : subEntity.id;

        this.menuItems = await this.loadMenuItemsForParticipant(this.entityId, this.subEntityId);
      } else {
        this.menuItems = await this.loadMenuItems(this.entityId, this.subEntityId);
      }
      if (this.entityId && (await this.authService.hasPermission(RoleEnum.LEADER, this.entityId))) {
        this.entity = await this.entityService.setLogoUrl(
          await this.entityService.getEntity(this.entityId)
        );
      } else {
        this.entity = null;
      }
    } catch (e) {
      this.logger.error('SidenavComponent - Error: ', e);
    }
  }

  subscribeToRouteChange(): void {
    this.subscriptionList.push(
      this.router.events.subscribe(val => {
        if (val instanceof NavigationEnd) {
          this.ngOnInit();
        }
      })
    );
  }

  async loadMenuItems(entityId: string, subEntityId: string): Promise<any> {
    entityId = UtilsService.isDefined(entityId) ? entityId : '';
    subEntityId = UtilsService.isDefined(subEntityId) ? subEntityId : '';
    const vendorId = this.isVendorLeader ? subEntityId : '';

    return [
      {
        name: 'clients',
        routerLink: ['clients'],
        svgName: 'clients-icon',
        hidden: this.isVendorLeader,
        disabled: !(await this.authService.hasPermission(RoleEnum.MSSP)),
      },
      {
        name: '1st Party',
        routerLink: ['first-party', entityId],
        svgName: '',
        hidden: this.isVendorLeader,
        disabled:
          !entityId ||
          !(await this.authService.hasPermission(RoleEnum.LEADER, entityId, subEntityId)),
        subMenu: [
          {
            name: 'upperdeck',
            routerLink: [`first-party/${entityId}/upperdeck`],
            svgName: 'upperdeck-icon',
            disabled:
              !entityId || !(await this.authService.hasPermission(RoleEnum.LEADER, entityId)),
          },
          {
            name: 'collection',
            routerLink: this.getRouterLinkByRole(`first-party/${entityId}/collection`, subEntityId),
            svgName: 'collection-icon',
            disabled:
              !entityId ||
              !(await this.authService.hasPermission(RoleEnum.LEADER, entityId, subEntityId)),
          },
          {
            name: 'multi entity',
            routerLink: this.getRouterLinkByRole(
              `first-party/${entityId}/multi-entity`,
              subEntityId
            ),
            svgName: 'multi-entity-icon',
            disabled:
              !entityId ||
              !(await this.authService.hasPermission(RoleEnum.LEADER, entityId, subEntityId)),
          },
          {
            name: 'pillars',
            routerLink: this.getRouterLinkByRole(`first-party/${entityId}/pillars`, subEntityId),
            svgName: 'pillars-icon',
            disabled:
              !entityId ||
              !(await this.authService.hasPermission(RoleEnum.LEADER, entityId, subEntityId)),
          },
          {
            name: 'break down',
            routerLink: this.getRouterLinkByRole(`first-party/${entityId}/break-down`, subEntityId),
            svgName: 'break-down-icon',
            disabled:
              !entityId ||
              !(await this.authService.hasPermission(RoleEnum.LEADER, entityId, subEntityId)),
          },
          {
            name: 'remediation center',
            routerLink: this.getRouterLinkByRole(
              `first-party/${entityId}/remediation`,
              subEntityId
            ),
            svgName: 'remediation-center-icon',
            disabled:
              !entityId ||
              !(await this.authService.hasPermission(RoleEnum.LEADER, entityId, subEntityId)),
          },
          {
            name: 'users settings',
            svgName: 'settings-icon',
            routerLink: this.getRouterLinkByRole(
              `first-party/${entityId}/user-settings`,
              subEntityId
            ),
            disabled:
              !entityId ||
              !(await this.authService.hasPermission(RoleEnum.LEADER, entityId, subEntityId)),
          },
          // {
          //   name: 'Attack Simulator',
          //   routerLink: [`first-party/${entityId}/attack-simulator`],
          //   svgName: 'attack-simulator-icon',
          //   disabled: true,
          // },
          // {
          //   name: 'alerts',
          //   routerLink: [`first-party/${entityId}/alerts`],
          //   svgName: 'alerts-icon',
          //   disabled: true, // TODO: this route should be showed also without entityId
          // },
          // {
          //   name: 'admin',
          //   routerLink: this.getRouterLinkByRole(
          //     `first-party/${entityId}/questionnaire-management`,
          //     subEntityId
          //   ),
          //   svgName: 'admin-icon',
          //   hidden: this.isCrbAdjustments,
          //   disabled:
          //     !entityId ||
          //     !(await this.authService.hasPermission(RoleEnum.LEADER, entityId, subEntityId)),
          // },
          // {
          //   name: 'questionnaire',
          //   routerLink: [`first-party/${entityId}/questionnaire`, subEntityId],
          //   svgName: 'admin-icon',
          //   hidden: !this.isCrbAdjustments,
          //   disabled: !(entityId && subEntityId),
          // },
        ],
      },
      // Third-party Menu
      {
        name: '3rd Party',
        routerLink: ['third-party', entityId],
        svgName: '',
        hidden: this.isVendorLeader,
        disabled:
          environment.disable3rdPartyModule ||
          !entityId ||
          !(await this.authService.hasPermission(RoleEnum.LEADER, entityId)),
        subMenu: [
          {
            name: 'Overview',
            svgName: 'overview-icon',
            hidden: this.isVendorLeader,
            routerLink: [`third-party/${entityId}/overview`],
            disabled: this.isVendorLeader,
          },
          {
            name: 'Heatmap',
            svgName: 'heatmap-icon',
            hidden: this.isVendorLeader,
            routerLink: [`/third-party/${entityId}/heat-map`],
            disabled: this.isVendorLeader,
          },
          {
            name: 'Vendors',
            svgName: 'vendors-icon',
            hidden: this.isVendorLeader,
            routerLink: [`third-party/${entityId}/vendors`],
            disabled: this.isVendorLeader,
          },
          {
            name: 'Default Settings',
            svgName: 'settings-icon',
            hidden: this.isVendorLeader,
            id: 'defaultSettingModal',
            click: (): void => {
              this.openDefaultSettingModal();
            },
            disabled: this.isVendorLeader,
          },
        ],
      },
      {
        name: 'Board',
        routerLink: ['/board'],
        svgName: '',
        hidden: this.isVendorLeader,
        disabled:
          environment.disableBoardModule || !(await this.authService.hasPermission(RoleEnum.MSSP)),
        subMenu: [
          {
            name: 'Overview',
            svgName: 'overview-icon',
            routerLink: ['/board/overview'],
          },
          {
            name: 'Posture',
            svgName: 'posture-icon',
            routerLink: ['/board/posture'],
          },
          {
            name: 'Matrix',
            svgName: 'multi-entity-icon',
            routerLink: ['/board/matrix'],
          },
          {
            name: 'Attacks',
            svgName: 'attacks-icon',
            routerLink: ['/board/attacks'],
          },
          {
            name: 'Budget',
            svgName: 'budget-icon',
            routerLink: ['/board/budget'],
          },
          {
            name: 'Major Initiatives',
            svgName: 'major-initiatives-icon',
            routerLink: ['/board/major-initiatives'],
          },
        ],
      },
      {
        name: 'Training Center',
        routerLink: ['training-center'],
        svgName: 'attack-simulator-icon',
        hidden: this.isVendorLeader,
        disabled: environment.disableTrainingCtr,
      },
      {
        name: 'Gaps',
        routerLink: this.getRouterLinkByRole(`vendor/${entityId}/gaps`, vendorId),
        svgName: 'remediation-center-icon',
        hidden: !this.isVendorLeader,
        disabled:
          !entityId ||
          !vendorId ||
          !(await this.authService.hasPermission(RoleEnum.VENDOR, entityId, vendorId)),
      },
      {
        name: 'collection',
        routerLink: [`first-party/${entityId}/collection/${vendorId}`],
        svgName: 'admin-icon',
        hidden: !this.isVendorLeader,
        disabled: !(entityId && vendorId),
      },
    ];
  }
  async loadMenuItemsForParticipant(entityId: string, subEntityId: string): Promise<any> {
    return [
      {
        name: 'Remediation Center',
        routerLink: this.getRouterLinkByRole(`first-party/${entityId}/remediation`, subEntityId),
        svgName: 'remediation-center-icon',
        disabled:
          !entityId ||
          !(await this.authService.hasPermission(RoleEnum.PARTICIPANT, entityId, subEntityId)),
      },
      // {
      //   name: 'questionnaire',
      //   routerLink: this.getRouterLinkByRole(`first-party/${entityId}/questionnaire`, subEntityId),
      //   svgName: 'admin-icon',
      //   disabled:
      //     !entityId ||
      //     !(await this.authService.hasPermission(RoleEnum.PARTICIPANT, entityId, subEntityId)),
      // },
      {
        name: 'collection',
        routerLink: this.getRouterLinkByRole(`first-party/${entityId}/collection`, subEntityId),
        svgName: 'collection-icon',
        disabled:
          !entityId ||
          !(await this.authService.hasPermission(RoleEnum.PARTICIPANT, entityId, subEntityId)),
      },
    ];
  }

  getRouterLinkByRole(route: string, subEntityId: string): string[] {
    const routerLink: string[] = [route];
    if (!this.isAdminOrMssp && !this.isEntityLeader) {
      routerLink.push(subEntityId);
    }

    return routerLink;
  }

  onMenuClicked(): void {
    this.showMenu = !this.showMenu;
  }

  openDefaultSettingModal(): void {
    if (this.modalReference == null) {
      this.defaultSettingSubMenuClass = 'defaultSettingModalActive';
      this.subMenuClass = 'subMenuClassDefaultSettingActive';

      this.modalReference = this.modalService.open(DefaultSettingModalComponent, {
        centered: true,
        size: 'lg',
        windowClass: 'default-setting-modal',
        backdropClass: 'default-setting-backdrop',
      });
      this.modalReference.componentInstance.closeDefaultModal.subscribe(event => {
        if (event) {
          this.closeModal();
        }
      });
    } else {
      this.closeModal();
    }
  }

  closeModal(): void {
    if (this.modalReference != null) {
      this.modalReference.close();
      this.modalReference = null;
      this.defaultSettingSubMenuClass = 'defaultSettingModalNotActive';
      this.subMenuClass = '';
    }
  }

  ngOnDestroy(): void {
    this.entity = null;
    this.subscriptionList.forEach(listener => listener.unsubscribe());
  }
}
