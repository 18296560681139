import { ImpactEnum, GetAssessmentStandardFrameworkQuery } from 'app/API.service';
/* eslint-disable @typescript-eslint/unbound-method */
import { Injectable } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import uuid from 'uuid';
import {
  GetUserQuery,
  APIService,
  CreateUserInput,
  RoleEnum,
  GetAssessmentQuery,
  GetAssignmentQuery,
  GetAnswerQuery,
  CreateAssignmentMutation,
  CreateAnswerInput,
  CreateAssignmentInput,
  UpdateAnswerInput,
  CreateCommentInput,
  UpdateCommentInput,
  GetCommentQuery,
  GetEntityQuery,
  StandardType,
  ModelIDKeyConditionInput,
  GetQuestionnaireCompletionQuery,
  RiskAction,
  GetQuestionSettingsQuery,
  GetCustomTaskQuery,
  GetCustomTaskAssignmentQuery,
  CommentByAssessmentIdQuery,
} from '../API.service';
import { EntityService, FrameworkScore } from 'app/shared/entity.service';
import { UtilsService } from 'app/shared/utils.service';
import { FrameworkTreeService } from 'app/shared/framework-tree.service';
import { Question, Control, Comment } from 'models/questionnaire.model';
import { RiskFrameworkEnum } from 'app/shared/enums/riskFramework.enum';
import { StandardEnum } from 'app/shared/enums/standard.enum';
import { AssignmentTarget } from 'models/assignment-target.model';
import { ManagerActionEnum } from 'app/shared/enums/manager-action.enum';
import { AnswerEnum } from 'app/shared/enums/answer.enum';
import { SortDirectionEnum } from 'app/shared/enums/sort-direction.enum';
import { NodeTypeEnum } from 'app/shared/enums/node-type.enum';

export interface FrameworkQuestionnaire {
  id?: string;
  name?: string;
  order?: number;
  children?: FrameworkQuestionnaire[];
  questions?: Question[];
  entityType?: string; // TODO: Replace with new enum
}

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {
  constructor(
    private authService: AuthService,
    private api: APIService,
    private entityService: EntityService,
    private frameworkService: FrameworkTreeService
  ) { }

  async getCRBParticipant(subEntityId: string): Promise<GetUserQuery> {
    let CRBParticipant: GetUserQuery = null;
    const subEntity = subEntityId ? await this.entityService.getEntity(subEntityId) : null;
    if (subEntity) {
      CRBParticipant = await this.authService.getUserByEmail(
        `${subEntity.name.split(' ').join('-')}-Participant-${subEntity.id}@example.com`
      );
      if (!CRBParticipant) {
        const newUser: CreateUserInput = {
          name: `${subEntity.name} Participant`,
          email: `${subEntity.name.split(' ').join('-')}-Participant-${subEntity.id}@example.com`,
          role: RoleEnum.PARTICIPANT,
          entityId: subEntityId,
        };
        CRBParticipant = await this.api.CreateUser(newUser);
      }
    }

    return CRBParticipant;
  }

  async buildQuestionnaireFromAssessment(
    assessment: GetAssessmentQuery,
    userId: string,
    frameworkName?: string,
    answerFilter?: string[]
  ): Promise<FrameworkQuestionnaire[]> {
    let filteredStandardList: GetAssessmentStandardFrameworkQuery[] = [];

    if (frameworkName) {
      filteredStandardList = [
        assessment.standardFrameworkList.items.find(
          standard => standard.key.toLowerCase() === frameworkName.toLowerCase()
        ),
      ] as GetAssessmentStandardFrameworkQuery[];
    } else {
      filteredStandardList = assessment.standardFrameworkList.items.map(
        standard => standard
      ) as GetAssessmentStandardFrameworkQuery[];
    }

    const answersPromise = this.getAnswersForAssessment(assessment.id, userId);
    const questionSettingsPromise = this.getQuestionSettingsForAssessment(assessment.id);
    const assignmentPromise =
      userId === null
        ? this.getAssignmentsByAssessmentId(assessment.id)
        : this.getAssignmentsByUserId(userId, assessment.id);
    const [assignments, answers, questionSettings] = await Promise.all([
      assignmentPromise,
      answersPromise,
      questionSettingsPromise,
    ]);
    let frameworkTrees = await Promise.all(
      filteredStandardList.map(async standard => {
        return this.frameworkService.getAssessmentTreeFromS3(assessment.id, standard);
      })
    );
    const answerMap: Map<string, GetAnswerQuery[]> = UtilsService.groupBy(answers, 'questionId');
    const settingsMap: Map<string, GetQuestionSettingsQuery> = UtilsService.mapBy(
      questionSettings,
      'questionId'
    );
    frameworkTrees.forEach((frameworkTree, index) => {
      frameworkTree.children = frameworkTree.children.filter(
        child => child.entityType !== NodeTypeEnum.GROUP
      );
      frameworkTree.name = filteredStandardList[index].key;
      frameworkTree.type = filteredStandardList[index].type;
      this.filterTreeOnAssignments(
        frameworkTree.name,
        assignments,
        frameworkTree,
        assessment.id,
        answerMap,
        settingsMap,
        answerFilter
      );
    });
    frameworkTrees = frameworkTrees.filter(tree => tree.children);

    return frameworkTrees;
  }

  async getAnswer(questionId: string, userId: string): Promise<GetAnswerQuery> {
    const userAnswers = await this.api.AnswersByQuestionIdAndUser(questionId, { eq: userId });
    if (userAnswers.items.length > 0) {
      return userAnswers.items[0];
    } else {
      return null;
    }
  }

  async getAnswersForAssessment(assessmentId: string, userId?: string): Promise<GetAnswerQuery[]> {
    let results = [];
    let nextToken = null;
    let condition: ModelIDKeyConditionInput;
    if (userId) {
      condition = { eq: userId };
    }
    do {
      const result = await this.api.AnswersByAssessmentIdAndUserId(
        assessmentId,
        condition,
        null,
        null,
        10000,
        nextToken
      );
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  async getQuestionSettingsForAssessment(
    assessmentId: string
  ): Promise<GetQuestionSettingsQuery[]> {
    let results = [];
    let nextToken = null;
    do {
      const result = await this.api.QuestionSettingsByAssessmentId(
        assessmentId,
        null,
        null,
        10000,
        nextToken
      );
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  async getAnswers(questionId: string): Promise<GetAnswerQuery[]> {
    let results = [];
    let nextToken = null;
    do {
      const result = await this.api.AnswersByQuestionIdAndUser(
        questionId,
        null,
        null,
        null,
        1000,
        nextToken
      );
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  async getCommentsByAssessment(assessmentId: string): Promise<CommentByAssessmentIdQuery[]> {
    // CommentByAssessmentId
    let results = [];
    let nextToken = null;
    do {
      const result = await this.api.CommentByAssessmentId(
        assessmentId,
        null,
        null,
        10000,
        nextToken
      );
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  async getAssignmentsByAssessmentId(assessmentId: string): Promise<GetAssignmentQuery[]> {
    let results = [];
    let nextToken = null;
    do {
      const result = await this.api.AssignmentsByAssessmentId(
        assessmentId,
        null,
        null,
        10000,
        nextToken
      );
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  async getCustomTasksByAssessmentId(assessmentId: string): Promise<GetCustomTaskQuery[]> {
    let results = [];
    let nextToken = null;
    do {
      const result = await this.api.CustomTasksByAssessmentId(
        assessmentId,
        null,
        null,
        10000,
        nextToken
      );
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  async getCustomTaskAssignmentsByAssessmentId(
    assessmentId: string
  ): Promise<GetCustomTaskAssignmentQuery[]> {
    let results = [];
    let nextToken = null;
    do {
      const result = await this.api.CustomTaskAssignmentsByAssessmentId(
        assessmentId,
        null,
        null,
        10000,
        nextToken
      );
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  async getQuestionnaireCompletionByAssessmentId(
    assessmentId: string
  ): Promise<GetQuestionnaireCompletionQuery[]> {
    let results = [];
    let nextToken = null;
    do {
      const result = await this.api.CompletionByAssessment(
        assessmentId,
        null,
        null,
        10000,
        nextToken
      );
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  async getAssignmentsByUserId(
    userId: string,
    assessmentId?: string
  ): Promise<GetAssignmentQuery[]> {
    let results = [];
    let nextToken = null;
    do {
      const result = await this.api.AssignmentsByUserIdAndAssessment(
        userId,
        { eq: assessmentId },
        null,
        null,
        10000,
        nextToken
      );
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  filterTreeOnAssignments(
    frameworkName: string,
    assignments: GetAssignmentQuery[],
    node: any,
    assessmentId: string,
    answerMap: Map<string, GetAnswerQuery[]>,
    settingsMap: Map<string, GetQuestionSettingsQuery>,
    answerFilter?: string[]
  ): boolean {
    if (node.children) {
      node.children = node.children.filter(childNode =>
        this.filterTreeOnAssignments(
          frameworkName,
          assignments,
          childNode,
          assessmentId,
          answerMap,
          settingsMap,
          answerFilter
        )
      );

      if (!node.children || node.children.length === 0) {
        for (const prop of Object.getOwnPropertyNames(node)) {
          delete node[prop];
        }
        return false;
      } else {
        return true;
      }
    } else if (node.questions) {
      node.questions.forEach(question => {
        const questionAssignments = assignments.filter(assignment => {
          if (question.left >= assignment.left && question.right <= assignment.right) {
            return true;
          } else {
            return false;
          }
        });
        if (settingsMap[question.id]) {
          question.settings = settingsMap[question.id];
        } else {
          question.settings = {};
        }
        question.parentId = node.id;
        question.assignments = questionAssignments;
        question.answers = answerMap[question.id];
        question.assessmentId = assessmentId;
        question.assessmentIds = [assessmentId];
        question.frameworkName = frameworkName;
      });
      node.questions = node.questions.filter(
        question => question.assignments && question.assignments.length > 0
      );
      if (!!answerFilter && answerFilter.length > 0) {
        node.questions.forEach(question => {
          if (question.answers) {
            question.answers = question.answers.filter(
              userAnswer =>
                userAnswer.answer !== null &&
                answerFilter.includes(UtilsService.answerNumToEnum(userAnswer.answer))
            );
          }
        });
        node.questions = node.questions.filter(
          question => !!question.answers && question.answers.length > 0
        );
      }
      if (!node.questions || node.questions.length === 0) {
        return false;
      }
      return true;
    }
  }

  getQuestionsFromTree(node: FrameworkQuestionnaire): Question[] {
    if (node.children) {
      const questions = node.children.map(childNode => this.getQuestionsFromTree(childNode));
      return questions.flat();
    } else if (node.questions) {
      return node.questions.map(question => {
        question.controlName = node.name;
        return question;
      });
    }
  }

  getQuestionsFromTreeForRemediation(
    node: FrameworkQuestionnaire,
    frameworkAssessment: FrameworkScore,
    answeredQuestions: GetAnswerQuery[],
    subEntityList: GetEntityQuery[],
    assignmentsMap: any,
    settingsMap: Map<string, GetQuestionSettingsQuery>,
    commentsMap: Map<string, Comment[]>,
    userId: string
  ): Question[] {
    if (node.children) {
      const questions = node.children.map(childNode =>
        this.getQuestionsFromTreeForRemediation(
          childNode,
          frameworkAssessment,
          answeredQuestions,
          subEntityList,
          assignmentsMap,
          settingsMap,
          commentsMap,
          userId
        )
      );
      return questions.flat();
    } else if (node.questions) {
      return node.questions.reduce(
        this.filterUnAnsweredControlQuestions(
          answeredQuestions,
          node,
          frameworkAssessment,
          subEntityList,
          assignmentsMap,
          settingsMap,
          commentsMap,
          userId
        ),
        []
      );
    }
  }

  filterUnAnsweredControlQuestions = (
    answeredQuestions: GetAnswerQuery[],
    node,
    frameworkAssessment: FrameworkScore,
    subEntityList: GetEntityQuery[],
    assignmentsMap: any,
    settingsMap: Map<string, GetQuestionSettingsQuery>,
    commentsMap: Map<string, Comment[]>,
    userId: string
  ) => (acc: Question[], currQuestion: Question): Question[] => {
    if (
      !this.isQuestionRemediated(
        answeredQuestions,
        frameworkAssessment.assessmentId,
        currQuestion,
        assignmentsMap[frameworkAssessment.assessmentId]
      )
    ) {
      acc.push({
        ...currQuestion,
        parentId: node.id,
        assessmentIds: [frameworkAssessment.assessmentId],
        standards: this.getFrameworkNameByType({
          name: frameworkAssessment.name,
          type: frameworkAssessment.type,
        }),
        frameworkName: frameworkAssessment.name,
        controlName: node.name,
        controlLabel: node.label,
        rootId: frameworkAssessment.rootId,
        childIds: [frameworkAssessment.childId],
        childNames: [
          subEntityList.find(subEntity => subEntity.id === frameworkAssessment.childId).name,
        ],
        answers: answeredQuestions.filter(
          answer =>
            answer.assessmentId === frameworkAssessment.assessmentId &&
            answer.questionId === currQuestion.id &&
            answer.userId === userId
        ),
        comments: commentsMap[currQuestion.id] ? commentsMap[currQuestion.id] : [],
      });
    }
    return acc;
  };

  excludeManagers = (
    managerIds: string[],
    assignmentsCount: number,
    assignedUserId: string
  ): boolean => {
    if (assignmentsCount > 1) {
      if (managerIds && managerIds.includes(assignedUserId)) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  isQuestionRemediated = (
    answeredQuestions: GetAnswerQuery[],
    assessmentId: string,
    question: Question,
    assignments: GetAssignmentQuery[]
  ): boolean => {
    let totalAssignments = assignments.filter((assignment: GetAssignmentQuery) => {
      return (
        assignment.left <= question.left &&
        assignment.right >= question.right &&
        assignment.user.role === RoleEnum.PARTICIPANT
      );
    }).length;

    const questionAnswers = answeredQuestions.filter(
      answer =>
        answer.assessmentId === assessmentId &&
        answer.questionId === question.id &&
        UtilsService.isDefined(answer.answer)
    );

    const nonParticipantAnswers = questionAnswers.filter(
      answer => answer.user.role !== RoleEnum.PARTICIPANT
    );

    let totalScore;
    if (nonParticipantAnswers.length > 0) {
      totalScore = UtilsService.sortByProp(
        nonParticipantAnswers,
        'date',
        SortDirectionEnum.DESCENDING
      )[0].answer;
    } else {
      const notApplicableAnswersCount = questionAnswers.filter(answer => answer.answer === 0)
        .length;
      totalAssignments -= notApplicableAnswersCount;

      if (!totalAssignments) {
        return false;
      }

      if (question.settings && question.settings.isCollaborative) {
        if (questionAnswers.length === 0) {
          return false;
        }
        totalScore = UtilsService.sortByProp(
          questionAnswers,
          'date',
          SortDirectionEnum.DESCENDING
        )[0].answer;
      } else {
        totalScore = questionAnswers.reduce((acc, curr) => {
          acc += curr.answer;
          return acc;
        }, 0);
        totalScore /= totalAssignments;
      }
    }
    return totalScore < 5 ? false : true;
  };

  getControlsFromTree(node: FrameworkQuestionnaire[]): Control[] {
    if (!node || node.length === 0) {
      return [];
    }
    const nestedControls = node.map(item => {
      // TODO: Replace this with Enum

      if (item.entityType === 'CONTROL') {
        return item;
      } else {
        return this.getControlsFromTree(item.children);
      }
    });
    return nestedControls.flat() as Control[];
  }

  getCompletionStatus(
    tree: FrameworkQuestionnaire,
    userId?: string
  ): { completedCount: number; totalCount: number } {
    const questions = this.getQuestionsFromTree(tree);
    const totalCount = questions.length;
    let completedCount = 0;

    questions.forEach(question => {
      if (question.answers) {
        const userAnswers: GetAnswerQuery[] = UtilsService.getUniqueListBy(
          question.answers,
          'userId'
        );
        if (userAnswers) {
          if (userId) {
            if (userAnswers.length > 0) {
              completedCount++;
            }
          } else {
            if (userAnswers.length === question.assignments.length) {
              completedCount++;
            }
          }
        }
      }
    });

    return { totalCount, completedCount };
  }

  getCompletionStatusMultiple(
    tree: FrameworkQuestionnaire[],
    userId?: string
  ): { completedCount: number; totalCount: number } {
    const statusList = tree.map(node => this.getCompletionStatus(node, userId));
    const totalStatus = statusList.reduce(
      (acc, curr) => {
        acc.completedCount += curr.completedCount;
        acc.totalCount += curr.totalCount;
        return acc;
      },
      { completedCount: 0, totalCount: 0 }
    );
    return totalStatus;
  }

  getAssignedUsersList(
    assignments: GetAssignmentQuery[],
    question: Question
  ): GetAssignmentQuery[] {
    return assignments
      .filter(assignment => {
        return (
          question.assessmentIds &&
          question.assessmentIds.includes(assignment.assessmentId) &&
          question.left &&
          question.right &&
          assignment.left <= question.left &&
          assignment.right >= question.right
        );
      })
      .reduce((users, currentAssignment) => {
        if (currentAssignment.user) {
          users.push(currentAssignment);
        }
        return users;
      }, [] as GetAssignmentQuery[]);
  }

  updateAnswer(
    question: Question,
    userAnswer: GetAnswerQuery,
    answerToUpdate: GetAnswerQuery
  ): Promise<GetAnswerQuery> {
    const updateAnswer: UpdateAnswerInput = {
      id: answerToUpdate.id,
      answer: userAnswer.answer,
      comment: userAnswer.comment ? userAnswer.comment : null,
      assessmentId: answerToUpdate.assessmentId,
      questionId: answerToUpdate.questionId,
      userId: answerToUpdate.userId,
      file: userAnswer.file,
      riskAction: question.riskAction,
      date: Date.now(),
      isActionTaken: userAnswer.isActionTaken,
    };
    return this.api.UpdateAnswer(updateAnswer);
  }

  async deleteAnswersFiles(question: Question, filesIds: string[]): Promise<void> {
    const promises = [];
    question.allAnswers.forEach((answer, answerIndex) => {
      if (answer.file) {
        answer.file.forEach((eachFile, fileIndex) => {
          if (eachFile.__typename) {
            delete eachFile.__typename;
          }
          if (filesIds.includes(eachFile.id)) {
            question.allAnswers[answerIndex].file.splice(fileIndex, 1);
          }
        });
      }
      promises.push(this.updateAnswer(question, answer, answer));
    });
    await Promise.all(promises);
  }

  createAssignment = async (
    target: AssignmentTarget,
    userId: string,
    assessmentId: string
  ): Promise<CreateAssignmentMutation> => {
    const assignment: CreateAssignmentInput = {
      id: uuid.v4(),
      userId,
      assessmentId,
      targetId: assessmentId,
      left: target.left,
      right: target.right,
    };
    const createdAssignment = await this.api.CreateAssignment(assignment);
    return createdAssignment;
  };

  createAnswer = async (
    question: Question,
    userAnswer: GetAnswerQuery,
    userAssignment: CreateAssignmentMutation
  ): Promise<GetAnswerQuery> => {
    const createAnswer: CreateAnswerInput = {
      id: uuid.v4(),
      assessmentId: userAssignment.assessmentId,
      assignmentID: userAssignment.id,
      userId: userAssignment.userId,
      answer: userAnswer.answer,
      comment: userAnswer.comment ? userAnswer.comment : null,
      file: userAnswer.file,
      questionId: question.id,
      date: Date.now(),
      frameworkName: question.frameworkName,
      riskAction: question.riskAction,
      left: question.left,
      right: question.right,
      isActionTaken: userAnswer.isActionTaken,
    };
    return this.api.CreateAnswer(createAnswer);
  };

  async createComment(comment: CreateCommentInput): Promise<GetCommentQuery> {
    return this.api.CreateComment(comment);
  }

  async updateComment(comment: UpdateCommentInput): Promise<GetCommentQuery> {
    return this.api.UpdateComment(comment);
  }

  getQuestionUserAssignments(question: Question, userId, assessmentId): GetAssignmentQuery {
    let userAssignments: GetAssignmentQuery[] = [];
    if (question.assignments) {
      userAssignments = question.assignments.filter(
        assignment => assignment.userId === userId && assignment.assessmentId === assessmentId
      );
    }
    return userAssignments && userAssignments.length > 0 ? userAssignments[0] : null;
  }

  addAnswerWithAssignments = async (
    question: Question,
    userId: string,
    userAnswer: GetAnswerQuery,
    assignments: GetAssignmentQuery[]
  ): Promise<void> => {
    const questionAnswers: GetAnswerQuery[] = UtilsService.isDefined(question.answers)
      ? UtilsService.copyArrayDeep(question.answers)
      : [];
    await Promise.all(
      question.assessmentIds.map(async assessmentId => {
        let assignment = this.getQuestionUserAssignments(question, userId, assessmentId);
        if (!assignment) {
          assignment = await this.createAssignment(
            { targetId: question.id, left: question.left, right: question.right },
            userId,
            assessmentId
          );
          assignments.push(assignment);
        }
        let answerToUpdate;
        if (
          question.answers &&
          question.answers.length > 0 &&
          (answerToUpdate = question.answers.find(
            answer =>
              answer.userId === userId &&
              answer.left &&
              answer.right &&
              answer.left >= assignment.left &&
              answer.right <= assignment.right
          ))
        ) {
          const updatedAnswer = await this.updateAnswer(question, userAnswer, answerToUpdate);
          const existingAnswerIndex = questionAnswers.findIndex(
            answerItem => answerItem.userId === updatedAnswer.userId
          );
          if (existingAnswerIndex > -1) {
            questionAnswers[existingAnswerIndex] = updatedAnswer;
          } else {
            questionAnswers.push(updatedAnswer);
          }
        } else {
          const createdAnswer = await this.createAnswer(question, userAnswer, assignment);
          questionAnswers.push(createdAnswer);
        }
      })
    );

    question.answers = questionAnswers;
  };

  async fetchCommentsByAssessments(assessmentIds: string[]): Promise<CommentByAssessmentIdQuery[]> {
    return (
      await Promise.all(
        assessmentIds.map(
          async (assessmentId: string): Promise<CommentByAssessmentIdQuery[]> => {
            return await this.getCommentsByAssessment(assessmentId);
          }
        )
      )
    ).flat();
  }

  async fetchAssessmentsAnswers(assessmentIds: string[]): Promise<GetAnswerQuery[]> {
    return (
      await Promise.all(
        assessmentIds.map(
          async (assessmentId: string): Promise<GetAnswerQuery[]> => {
            return await this.getAnswersForAssessment(assessmentId);
          }
        )
      )
    ).flat();
  }

  async fetchAssessmentsAssignments(assessmentIds: string[]): Promise<GetAssignmentQuery[]> {
    return (
      await Promise.all(
        assessmentIds.map(
          async (assessmentId: string): Promise<GetAssignmentQuery[]> => {
            return await this.getAssignmentsByAssessmentId(assessmentId);
          }
        )
      )
    ).flat();
  }

  async fetchCustomTasks(assessmentIds: string[]): Promise<GetCustomTaskQuery[]> {
    return (
      await Promise.all(
        assessmentIds.map(
          async (assessmentId: string): Promise<GetCustomTaskQuery[]> => {
            return await this.getCustomTasksByAssessmentId(assessmentId);
          }
        )
      )
    ).flat();
  }

  async fetchCustomTaskAssignments(
    assessmentIds: string[]
  ): Promise<GetCustomTaskAssignmentQuery[]> {
    return (
      await Promise.all(
        assessmentIds.map(
          async (assessmentId: string): Promise<GetCustomTaskAssignmentQuery[]> => {
            return await this.getCustomTaskAssignmentsByAssessmentId(assessmentId);
          }
        )
      )
    ).flat();
  }

  async fetchAssessmentQuestionSettings(
    assessmentIds: string[]
  ): Promise<GetQuestionSettingsQuery[]> {
    return (
      await Promise.all(
        assessmentIds.map(
          async (assessmentId: string): Promise<GetQuestionSettingsQuery[]> => {
            return await this.getQuestionSettingsForAssessment(assessmentId);
          }
        )
      )
    ).flat();
  }

  getUniqueAssessments(frameworks: FrameworkScore[]): string[] {
    return [...new Set(frameworks.map(framework => framework.assessmentId))];
  }

  assignSingleQuestion(
    question: Question,
    user: any,
    subEntityList: GetEntityQuery[],
    entityId?: string
  ): Promise<CreateAssignmentMutation>[] {
    const assessmentIds: string[] = [];
    if (!entityId || (entityId && entityId === question.rootId)) {
      assessmentIds.push(...question.assessmentIds);
    } else if (entityId && question.childIds.includes(entityId)) {
      const subEntityId = question.childIds.find(childId => childId === entityId);
      const activeAssessmentId = subEntityList.find(subEntity => subEntity.id === subEntityId)
        .activeAssessmentId;
      assessmentIds.push(activeAssessmentId);
    }
    return assessmentIds.map(
      async (assessmentId: string): Promise<CreateAssignmentMutation> => {
        if (
          question.assignments.find(
            assignment =>
              assignment.assessmentId === assessmentId && assignment.userId === user.userId
          )
        ) {
          return null;
        }
        return await this.createAssignment(
          { targetId: question.id, left: question.left, right: question.right },
          user.userId,
          assessmentId
        );
      }
    );
  }

  getAssignedUsersListForQuestions(
    questions: Question[],
    assignments: GetAssignmentQuery[]
  ): string[] {
    let assignedUsers: string[] = [];
    questions.forEach((question, index) => {
      const questionAssignments = this.getAssignedUsersList(assignments, question);
      if (questionAssignments && questionAssignments.length > 0) {
        question.assignments = questionAssignments;
      }
      if (index === 0 && questionAssignments.length > 0) {
        assignedUsers.push(...questionAssignments.map(assignment => assignment.user.id));
      } else if (index > 0 && assignedUsers.length > 0) {
        if (!assignedUsers.every(this.isUserAssignedToQuestion(question))) {
          assignedUsers = assignedUsers
            .map(assignedUser => {
              return this.isUserAssignedToQuestion(question)(assignedUser) ? assignedUser : null;
            })
            .filter(UtilsService.filterOutNullElements);
        }
      }
    });
    return assignedUsers;
  }

  isUserAssignedToQuestion = (question: Question) => (assignedUser: string): boolean =>
    question.assignments.findIndex(assignment => assignment.userId === assignedUser) > -1;

  getFrameworkNameByType(framework: any): string {
    return framework.type === StandardType.RISK_FRAMEWORK
      ? RiskFrameworkEnum[framework.name]
      : StandardEnum[framework.name];
  }

  getCurrentManagerAction(question: Question): ManagerActionEnum {
    let managerAction: ManagerActionEnum;
    if (question.settings && question.settings.isApprovalRequired) {
      if (question.answers && question.answers.length > 0) {
        const managerAnswer = question.answers.find(
          answer => answer.isActionTaken && answer.riskAction === RiskAction.MANAGED
        );
        if (managerAnswer) {
          const answerEnumValue = UtilsService.answerNumToEnum(managerAnswer.answer);
          switch (answerEnumValue) {
            case AnswerEnum.YES:
              managerAction = ManagerActionEnum.APPROVE;
              break;
            case AnswerEnum.NO:
              managerAction = ManagerActionEnum.DENIED;
              break;
            case AnswerEnum.NOT_APPLICABLE:
              managerAction = ManagerActionEnum.EXCLUDE;
              break;
          }
        }
      }
    }
    return managerAction;
  }
}
