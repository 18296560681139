import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsernameIconComponent } from './username-icon.component';
import { FormsModule } from '@angular/forms';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

@NgModule({
  declarations: [UsernameIconComponent],
  imports: [CommonModule, FormsModule, SvgIconModule],
  exports: [UsernameIconComponent],
})
export class UsernameIconModule {}
