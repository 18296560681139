import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'percent' })
export class PercentPipe implements PipeTransform {
  transform(value: number | string, toFixed: number, args: string[]): any {
    value = Number(value);
    toFixed = toFixed ? toFixed : toFixed === 0 ? 1 : 0;
    return isNaN(value) ? '0%' : `${value.toFixed(toFixed)}%`;
  }
}
