import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityStandardsComponent } from './entity-standards.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

@NgModule({
  declarations: [EntityStandardsComponent],
  imports: [CommonModule, SvgIconModule],
  exports: [EntityStandardsComponent],
})
export class EntityStandardsModule {}
