import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TableHeader, TableHeaderState } from 'models/table-header.model';
import { SortDirectionEnum } from 'app/shared/enums/sort-direction.enum';

@Component({
  selector: 'cygov-vendor-list-header',
  templateUrl: './vendor-list-header.component.html',
  styleUrls: ['./vendor-list-header.component.scss'],
})
export class VendorListHeaderComponent implements OnInit, OnChanges {
  @Input() disabled = false;
  @Output() onSortChanged = new EventEmitter<any>();

  headers: TableHeader[];
  headerState: TableHeaderState;
  sortDirections = SortDirectionEnum;

  constructor() {
    this.headerState = new TableHeaderState();
    this.headerState.setInitialHeaders(this.getHeadersData());
  }

  ngOnChanges(changes: SimpleChanges): void {
    // wait for list to trigger initial sort
    if (changes.disabled.previousValue && !changes.disabled.currentValue) {
      this.onHeaderClicked(this.headerState.Headers[0]);
    }
  }

  ngOnInit(): void {}

  getHeadersData(): any {
    return [
      {
        value: 'name',
        class: 'company-name',
        name: 'Company Name',
        isSortApplicable: true,
        isSortApplied: false,
        sortDirection: SortDirectionEnum.NONE,
      },
      {
        value: 'vendorDetails.impact',
        class: 'impact',
        name: 'Impact',
        isSortApplicable: true,
        isSortApplied: false,
        sortDirection: SortDirectionEnum.NONE,
      },
      {
        value: 'vendorDetails.externalScan',
        class: 'extScan',
        name: 'External Scan',
        isSortApplicable: false,
        isSortApplied: false,
        sortDirection: SortDirectionEnum.NONE,
      },
      {
        value: 'scores.total',
        class: 'gap-assessment',
        name: 'Gap Assessment',
        isSortApplicable: false,
        isSortApplied: false,
        sortDirection: SortDirectionEnum.NONE,
      },
      {
        value: 'vendorDetails.probability',
        class: 'probability',
        name: 'Probability',
        isSortApplicable: true,
        isSortApplied: false,
        sortDirection: SortDirectionEnum.NONE,
      },
      {
        value: 'vendorDetails.financialRisk',
        class: 'financial-risk',
        name: 'Financial Risk',
        isSortApplicable: false,
        isSortApplied: false,
        sortDirection: SortDirectionEnum.NONE,
      },
      {
        value: 'scores.collection',
        class: 'collection',
        name: 'Collection',
        isSortApplied: false,
        sortDirection: SortDirectionEnum.NONE,
      },
      {
        value: 'vendorDetails.criticalGaps',
        class: 'critical-gap',
        name: 'Critical Gaps',
        isSortApplied: false,
        isSortApplicable: false,
        sortDirection: SortDirectionEnum.NONE,
      },
      {
        value: 'compliance',
        class: 'compliance',
        name: 'Compliance',
        isSortApplicable: false,
        isSortApplied: false,
        sortDirection: SortDirectionEnum.NONE,
      },
      {
        value: 'vendorDetails.status',
        class: 'status',
        name: 'Status',
        isSortApplicable: false,
        isSortApplied: false,
        sortDirection: SortDirectionEnum.NONE,
      },
    ];
  }

  onHeaderClicked(header: TableHeader) {
    this.headerState.setHeaderState(header);
    this.onSortChanged.emit(header);
  }
}
