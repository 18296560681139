import { Pipe, PipeTransform } from '@angular/core';
/*
 * Convert string to number
 * Takes a string as a value.
 * Returns a number.
 * Usage:
 *  value | strToNum
 */
@Pipe({
  name: 'strToNum',
})
export class StringToNumberPipe implements PipeTransform {
  transform(value: string): number {
    if (value === null) {
      return 0;
    }
    return parseInt(value, 10);
  }
}
