import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ThirdPartyService } from '../third-party.service';
import { GetEntityQuery } from 'app/API.service';
import { EntityService, GetEntityQueryExtended } from 'app/shared/entity.service';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'cygov-all-vendor-list',
  templateUrl: './all-vendor-list.component.html',
  styleUrls: ['./all-vendor-list.component.scss'],
})
export class AllVendorListComponent implements OnInit, OnDestroy {
  vendors: GetEntityQueryExtended[];
  showDetails = false;
  vendor: GetEntityQueryExtended;
  toggleBarText = 'Vender risk dashboard';

  sortBy: string;
  filterBy: string;
  searchText: string;
  vendorId: string;
  cachedVendorList: GetEntityQueryExtended[];
  isLoading = true;
  lastEvaluatedKey: any = null;
  subscriptionList: Subscription[] = [];
  allowUpdate = true;
  timeTillUpdate = null;

  constructor(
    private thirdPartyService: ThirdPartyService,
    private route: ActivatedRoute,
    private entityService: EntityService,
    private logger: NGXLogger
  ) {}

  async ngOnInit(): Promise<void> {
    this.subscriptionList.push(
      this.entityService.updateVendorSubscriber.subscribe(entity => {
        if (this.allowUpdate && this.doesVendorExist(entity)) {
          this.allowUpdate = false;
          this.resetTimer();
        }
      })
    );
    await this.loadVendorList();
  }

  resetTimer(): void {
    if (this.timeTillUpdate !== null) {
      clearTimeout(this.timeTillUpdate);
    }
    this.timeTillUpdate = setTimeout(() => {
      this.newUpdatedVendors();
    }, 2000);
  }

  doesVendorExist(entity): boolean {
    const index = this.vendors.findIndex(val => val.id === entity.id);
    return index > -1 ? true : false;
  }

  async newUpdatedVendors(fromCache = true): Promise<void> {
    try {
      const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      this.vendorId = UtilsService.getRouteParam(this.route.root.snapshot, 'vendorId');

      try {
        this.vendors = await this.thirdPartyService.getExtendVendorsListByParentId(entityId);
      } catch (e) {
        this.logger.error('Vendor Overview Get - Error: ', e);
      }

      this.cachedVendorList = this.vendors;
      if (this.showDetails && this.vendorId) {
        this.vendor = this.vendors.filter(obj => {
          return obj.id === this.vendorId;
        })[0];
        this.showDetails = true;
      }
      this.thirdPartyService.setNewVendorAdded(false);
    } catch (e) {
      this.logger.error(e);
      this.isLoading = false;
    }
  }

  async loadVendorList(fromCache = true): Promise<void> {
    this.isLoading = true;
    try {
      const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      this.vendorId = UtilsService.getRouteParam(this.route.root.snapshot, 'vendorId');

      // / For Pagination Will Do Later

      // let result: ListEntitysQuery = null;
      // if (this.lastEvaluatedKey && this.lastEvaluatedKey.id) {
      //   try {
      //     const filter: ModelEntityFilterInput = {
      //       entityType: { eq: EntityTypeEnum.VENDOR },
      //       parentId: { eq: entityId },
      //     };
      //     result = await this.entityService.listEntitys(filter);
      //     if (this.vendors && this.vendors.length) {
      //       result.items = this.vendors.concat(result.items);
      //     }
      //   } catch (e) {
      //     this.logger.error('EntityListComponent - Error: ', e);
      //   }
      // } else {
      //   try {
      //     const filter: ModelEntityFilterInput = {
      //       entityType: { eq: EntityTypeEnum.VENDOR },
      //       parentId: { eq: entityId },
      //     };
      //     result = await this.entityService.listEntitys(filter);
      //   } catch (e) {
      //     this.logger.error('EntityListComponent - Error: ', e);
      //   }
      // }

      // this.vendors = { ...result };
      // this.lastEvaluatedKey = result.lastEvaluatedKey;

      try {
        this.vendors = await this.thirdPartyService.getExtendVendorsListByParentId(entityId);
      } catch (e) {
        this.logger.error('Vendor Overview Get - Error: ', e);
      }

      this.cachedVendorList = this.vendors;
      if (this.showDetails && this.vendorId) {
        this.vendor = this.vendors.filter(obj => {
          return obj.id === this.vendorId;
        })[0];
        this.showDetails = true;
      }

      this.isLoading = false;
      this.allowUpdate = true;
      this.thirdPartyService.setNewVendorAdded(false);
    } catch (e) {
      this.logger.error(e);
      this.isLoading = false;
    }
  }

  toggleVendorList(isExpanded: boolean): void {
    if (isExpanded) {
      this.toggleBarText = 'Vendor risk dashboard';
    } else {
      this.toggleBarText = 'Show all vendors';
    }
    this.thirdPartyService.toggleVendorList(isExpanded);
    this.showDetails = false;
  }

  onVendorSelected(vendor: GetEntityQuery): void {
    this.showDetails = !this.showDetails;
    this.vendor = vendor;
  }

  onCloseVendorDetail(): void {
    this.showDetails = !this.showDetails;
    this.vendor = null;
  }

  onVendorUpdate(vendor): void {
    this.vendor = vendor;
    if (vendor) {
      const index = this.vendors.findIndex(v => {
        return v.id === vendor.id;
      });
      this.vendors[index] = Object.assign(this.vendors[index], vendor);
    }
  }

  onVendorRemoved(vendorId: string): void {
    this.vendors = this.vendors.filter(vendor => vendor.id !== vendorId);
  }

  onFilterChange(filteredVendorList): void {
    this.vendors = filteredVendorList;
  }
  onSearchTextChange(filteredVendorList): void {
    this.vendors = filteredVendorList;
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach(listener => listener.unsubscribe());
  }
}
