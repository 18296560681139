import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'app/shared/file.service';
import {
  CreateEntityInput,
  CreateUserInput,
  EntityTypeEnum,
  GetUserQuery,
} from '../../../API.service';
import { EntityService } from '../../entity.service';
import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cygov-add-sub-entity-modal',
  templateUrl: './add-sub-entity-modal.component.html',
  styleUrls: ['./add-sub-entity-modal.component.scss'],
})
export class AddSubEntityModalComponent implements OnInit {
  @Output() modalResult = new EventEmitter<{
    subEntity: CreateEntityInput;
    userList: CreateUserInput[];
    selectedSurveyFuncNames: string[];
  }>();
  newSubEntity: CreateEntityInput;
  isCrbAdjustments = UtilsService.isCRB;
  userList: CreateUserInput[];
  functionsOpts: string[];
  selectedSurveyFuncNames: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private fileService: FileService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {}

  async ngOnInit() {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    // TODO: Add input "Select Project Manager" it's mandatory. In the mean time it will be the current user
    const projectManager: GetUserQuery = await this.authService.getCurrentUser();

    this.newSubEntity = EntityService.initEntity(
      EntityTypeEnum.CHILD_ENTITY,
      projectManager.id,
      entityId
    );
    this.userList = new Array(3);
    this.functionsOpts = this.isCrbAdjustments ? await this.loadFunctionsOpts() : null;
  }

  async loadFunctionsOpts() {
    const funcTempMap = {};
    const filePath = `${window.location.origin}/assets/files/crb-survey.csv`;
    const file = await this.fileService.getFile(filePath);
    const { data } = (await this.fileService.parseFile(file)) as any;
    data.forEach(el => {
      if (el.function && !funcTempMap[el.function]) {
        funcTempMap[el.function] = true;
      }
    });
    return Object.keys(funcTempMap);
  }

  isValid(subEntity: CreateEntityInput) {
    let valid = true;
    if (!subEntity.name) {
      this.toastr.error('Name is required');
      valid = false;
    }

    if (!subEntity.timeline.collectionDate) {
      this.toastr.error('Date is required');
      valid = false;
    }

    return valid;
  }

  toggleSelected(funcName: string) {
    const index = this.selectedSurveyFuncNames.indexOf(funcName);
    if (index === -1) {
      // add
      this.selectedSurveyFuncNames.push(funcName);
    } else {
      // remove
      this.selectedSurveyFuncNames.splice(index, 1);
    }
  }

  emitSubEntity(subEntity: CreateEntityInput, userList: CreateUserInput[]) {
    userList = userList.filter(user => user.name && user.email);
    const selectedSurveyFuncNames = this.selectedSurveyFuncNames;
    this.modalResult.emit({ subEntity, userList, selectedSurveyFuncNames });
  }
}
