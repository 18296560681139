import { Component, OnInit } from '@angular/core';

import {
  NgbModal,
  ModalDismissReasons,
  NgbModalRef,
  NgbActiveModal,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cygov-follow-up-modal',
  templateUrl: './follow-up-modal.component.html',
  styleUrls: ['./follow-up-modal.component.scss'],
})
export class FollowUpModalComponent implements OnInit {
  items = [{ checked: true }, { checked: false }, { checked: false }, { checked: true }];
  closeResult: string;
  modalReference: NgbModalRef;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
