import { CreateCustomTaskInput } from './../../API.service';
import { CrbVendorConstant } from './../../third-party/add-vendor/add-crb-vendor-modal/crb-vendor.constant';
import { ImpactEnum } from 'app/API.service';
import { UtilsService } from './../utils.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
// TODO: Need to work on this
// import { CrbVendorConstant } from 'app/third-party/add-vendor/add-crb-vendor-modal/crb-vendor.constant';
// import { SecurityControl } from 'models/security-control.model';
// import { Question } from 'models/agency.model';
import { ArtifactsEnum } from '../enums/artifacts.enum';

@Component({
  selector: 'cygov-new-task-modal',
  templateUrl: './new-task-modal.component.html',
  styleUrls: ['./new-task-modal.component.scss'],
})
export class NewTaskModalComponent implements OnInit {
  @Input() customTask: CreateCustomTaskInput;
  @Output() modalResult = new EventEmitter<any>();
  priorityOpt = Object.keys(ImpactEnum).map(key => ImpactEnum[key]);
  selectedSubEntityId: string;
  isCrbAdjustments = UtilsService.isCRB;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  isDefined = UtilsService.isDefined;

  constructor(private route: ActivatedRoute, public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.customTask.standardsQualify =
      this.customTask.standardsQualify || this.getRelatedRequirements();
  }

  getRelatedRequirements(): any {
    let relatedRequirements: any;
    if (this.isCrbAdjustments) {
      relatedRequirements = CrbVendorConstant.artifactsQualification();
    } else {
      relatedRequirements = Object.entries(ArtifactsEnum)
        .filter(([key]) => !key.includes('COMPLIANCE'))
        .map(([_, name]) => ({ name, isQualify: false }));
    }

    return relatedRequirements;
  }

  impactChanged(opt): void {
    this.customTask.impact = opt;
  }

  emitEntity(): void {
    this.modalResult.emit(this.customTask);
  }
}
