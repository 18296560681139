export class StatusCount {
  inProcess: number;
  pending: number;
  approved: number;
  denied: number;

  constructor() {
    this.inProcess = this.pending = this.approved = this.denied = 0;
  }
}
