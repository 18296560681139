import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'app/shared/file.service';
import {
  CreateEntityInput,
  EntityTypeEnum,
  GetUserQuery,
  IndustryEnum,
  S3FileInput,
  StandardType,
} from '../../../API.service';
import { EntityService, RequiredStandard } from 'app/shared/entity.service';
import { AuthService } from 'app/auth/auth.service';
import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { RiskFrameworkEnum } from '../../enums/riskFramework.enum';
import { RiskOption } from 'models/generator.model';
import { NgbDateParserFormatter, NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cygov-add-client-modal',
  templateUrl: './add-client-modal.component.html',
  styleUrls: ['./add-client-modal.component.scss'],
})
export class AddClientModalComponent implements OnInit {
  @Output() modalResult = new EventEmitter<any>();
  newEntity: CreateEntityInput;
  imageFile: any;
  riskFramework: RiskOption;
  s3Input: S3FileInput;
  standardList: RequiredStandard[];
  frameworkTypeOpt = [EntityTypeEnum.RISK_FRAMEWORK, EntityTypeEnum.COMPLIANCE_FRAMEWORK];
  displayStandardList: boolean;
  industryOpt = Object.keys(IndustryEnum);
  riskFrameWorkOpt: RiskOption[] = [];
  entityType: EntityTypeEnum;
  assessmentDate: Date;
  deadlineDate: Date;
  DEADLINE_DATE = 'deadlineDate';
  ASSESSMENT_DATE = 'assessmentDate';
  currentDate: NgbDateStruct;

  logoFileSizeLimit = 1000000; // 1MB
  newLogoUploaded = false;
  uploadedLogo: any;

  constructor(
    private authService: AuthService,
    private fileService: FileService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private logger: NGXLogger,
    public activeModal: NgbActiveModal,
    private parserFormatter: NgbDateParserFormatter
  ) { }

  async ngOnInit(): Promise<void> {
    // TODO: Add input "Select Project Manager" it's mandatory. In the mean time it will be the current user
    const projectManager: GetUserQuery = await this.authService.getCurrentUser();
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.entityType = this.router.url.includes('clients')
      ? EntityTypeEnum.ROOT_ENTITY
      : EntityTypeEnum.CHILD_ENTITY;
    this.newEntity = EntityService.initEntity(this.entityType, projectManager.id, entityId);
    this.standardList = EntityService.initStandardList();
    this.displayStandardList = this.router.url.includes('multi-entity');
    this.prepareRiskFrameworkOptions();
    this.currentDate = UtilsService.getDateInNgbDateStructFormat(new Date());
  }

  prepareRiskFrameworkOptions(): void {
    for (const enumMember of Object.keys(RiskFrameworkEnum)) {
      this.riskFrameWorkOpt.push({
        key: enumMember,
        value: RiskFrameworkEnum[enumMember],
      });
    }
  }

  toggleStandard(event, standard: RequiredStandard): void {
    UtilsService.stopBubbling(event);
    standard.checked = !standard.checked;
  }

  async imgHandler(fileInput: any): Promise<void> {
    try {
      this.s3Input = await this.fileService.getS3File(fileInput, this.newEntity.id);
      this.uploadedLogo = await this.fileService.getUploadedImg(fileInput);
      this.newLogoUploaded = true;
    } catch (error) {
      this.logger.error('Image Handler  Error =', error);
      this.toastr.error(error);
    }
  }

  isValid(entity: CreateEntityInput): boolean {
    let valid = true;
    if (!entity.name) {
      this.toastr.error('Entity name is required');
      valid = false;
    }
    if (this.entityType === EntityTypeEnum.ROOT_ENTITY) {
      if (!entity.industry) {
        this.toastr.error('Entity Industry is required');
        valid = false;
      }
      if (!entity.projectManagerName) {
        this.toastr.error('Manager Name is required');
        valid = false;
      }
      if (!this.deadlineDate) {
        this.toastr.error('Project Deadline is required');
        valid = false;
      }
    }

    if (this.displayStandardList && !this.assessmentDate) {
      this.toastr.error('Assessment date is required');
      valid = false;
    }
    if (!this.riskFramework && entity.entityType !== 'ROOT_ENTITY') {
      this.toastr.error('Risk Framework is required');
      valid = false;
    }
    return valid;
  }

  dateChanged(date, dateType: string): void {
    this[dateType] = date;
  }

  emitEntity(
    entity: CreateEntityInput,
    s3Input: S3FileInput,
    standardList: RequiredStandard[]
  ): void {
    const requiredStandard: RequiredStandard[] = standardList.filter(standard => standard.checked);
    if (this.riskFramework) {
      requiredStandard.push({
        key: this.riskFramework.key,
        name: this.riskFramework.value,
        type: StandardType.RISK_FRAMEWORK,
        checked: true,
      });
    }
    entity.timeline.collectionDate = this.assignDate(this.assessmentDate, this.ASSESSMENT_DATE);
    entity.projectDeadline = this.assignDate(this.deadlineDate, this.DEADLINE_DATE);
    this.modalResult.emit({ entity, s3Input, requiredStandard });
  }

  assignDate(dateStr, dateType: string): number {
    dateStr = this.parserFormatter.format(dateStr);
    const date = new Date(dateStr).getTime();
    if (dateType === this.DEADLINE_DATE) {
      this.newEntity.projectDeadline = date;
    } else {
      this.newEntity.timeline.collectionDate = date;
    }
    return date;
  }
}
