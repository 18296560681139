// This component is workable to get Rapid7 and Tenable Information.

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GetEntityQuery } from 'app/API.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityService } from 'app/shared/entity.service';
import { ToastrService } from 'ngx-toastr';
import { Services } from '../../constants';

interface IntegrationModal {
  name: string;
  credentials: string;
  isEnable: boolean;
  isAuth: boolean;
  inheritFromParent: boolean;
}

interface IRapid7AccountInfo {
  url: string;
  username: string;
  password: string;
}

@Component({
  selector: 'cygov-scan-integration-service-modal',
  templateUrl: './scan-integration-service-modal.component.html',
  styleUrls: ['./scan-integration-service-modal.component.scss'],
})
export class ScanIntegrationServiceModalComponent implements OnInit {
  @Input() entityId: string;
  @Input() isTenable: boolean;
  @Output() accountEntity: EventEmitter<GetEntityQuery> = new EventEmitter();
  entity: GetEntityQuery;
  formData: FormGroup;
  serviceType: string;
  formDisabled = false;
  integrations: IntegrationModal[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private entityService: EntityService,
    private toastr: ToastrService
  ) {}

  async ngOnInit(): Promise<any> {
    this.entity = await this.entityService.getEntity(this.entityId, false);
    let credentials: IRapid7AccountInfo = { url: null, username: null, password: null };
    this.serviceType = this.isTenable ? Services.TENABLE : Services.RAPID7;
    if (this.entity.integrations) {
      const integrationData = this.entity.integrations.filter(
        elem => elem.name === this.serviceType
      )[0];

      // if data present, add them to variables so they pops up in form
      if (integrationData) {
        credentials = JSON.parse(integrationData.credentials);
      }
    }
    const regex = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.formData = new FormGroup({
      // eslint-disable-next-line @typescript-eslint/unbound-method
      url: new FormControl(credentials.url, [Validators.required, Validators.pattern(regex)]),
      // eslint-disable-next-line @typescript-eslint/unbound-method
      username: new FormControl(credentials.username, [Validators.required]),
      // eslint-disable-next-line @typescript-eslint/unbound-method
      password: new FormControl(credentials.password, [Validators.required]),
    });
  }

  async onSubmit(): Promise<void> {
    this.formDisabled = true;
    const formDataControl = this.formData.controls;
    let integrationArray: IntegrationModal[] = [];
    if (
      formDataControl.url.valid &&
      formDataControl.username.valid &&
      formDataControl.password.valid
    ) {
      const info = {
        url: formDataControl.url.value,
        username: formDataControl.username.value,
        password: formDataControl.password.value,
      };
      const integrationInfo = this.setIntegrationInfo(info);
      if (this.entity.integrations) {
        integrationArray = this.entity.integrations.map(integration => {
          delete integration.__typename;
          return { ...integration };
        });
      }
      if (integrationArray.length === 0) {
        integrationArray = [integrationInfo];
      } else {
        const existingItemIndex = integrationArray.findIndex(
          elem => elem.name === this.serviceType
        );

        if (existingItemIndex > -1) {
          integrationArray[existingItemIndex] = integrationInfo;
        } else {
          this.integrations = [];
          this.integrations.push(integrationInfo);
          integrationArray.push(integrationInfo);
        }
      }
      await this.entityService.updateEntity({
        id: this.entity.id,
        integrations: integrationArray,
      });
      this.toastr.success('Information has been saved.');
    } else {
      this.toastr.error('Missing required fields');
    }
    this.formDisabled = false;
  }

  runGetSites(): void {
    let isIntegrationDataAvailable = false;
    if (this.entity.integrations) {
      const serviceData = this.entity.integrations.filter(elem => elem.name === this.serviceType);
      if (serviceData) {
        this.accountEntity.emit(this.entity);
        isIntegrationDataAvailable = true;
      }
    }
    if (!isIntegrationDataAvailable) {
      const errorMsg = this.serviceType === Services.TENABLE ? 'Tenable' : 'Rapid7';
      this.toastr.error(`Please save ${errorMsg} Information before continue.`);
    }
  }

  setIntegrationInfo(info): IntegrationModal {
    return {
      name: this.serviceType,
      credentials: JSON.stringify(info),
      isEnable: true,
      isAuth: null,
      inheritFromParent: null,
    };
  }

  closeModal(): void {
    this.activeModal.close();
  }
}
