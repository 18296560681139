import { SortDirectionEnum } from 'app/shared/enums/sort-direction.enum';

export interface TableHeader {
  isSelectable?: boolean;
  selected?: boolean;
  value: string;
  class: string;
  name: string;
  isSortApplied: boolean;
  isSortApplicable: boolean;
  sortDirection: SortDirectionEnum;
}

export class TableHeaderState {
  private headers: TableHeader[];
  private previousSortedHeader: TableHeader = null;

  setInitialHeaders(headers: TableHeader[]): void {
    this.headers = headers;
  }

  get Headers() {
    return this.headers;
  }

  setHeaderState(header: TableHeader): void {
    if (this.previousSortedHeader && this.previousSortedHeader !== header) {
      this.previousSortedHeader.isSortApplied = false;
      this.previousSortedHeader.sortDirection = SortDirectionEnum.NONE;
    }
    this.previousSortedHeader = header;
    const index = this.headers.indexOf(header);
    this.headers[index].isSortApplied = true;
    this.headers[index].sortDirection = this.getHeaderSortDirection(header.sortDirection);
  }

  private getHeaderSortDirection(previousDirection: SortDirectionEnum): SortDirectionEnum {
    return previousDirection === SortDirectionEnum.ASCENDING
      ? SortDirectionEnum.DESCENDING
      : SortDirectionEnum.ASCENDING;
  }
}
