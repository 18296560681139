import { ParticipantRoleGuard } from 'app/shared/guards/participant-role.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Route } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { AdminRoleGuard } from './shared/guards/admin-role.guard';
import { EntityLeaderRoleGuard } from './shared/guards/entity-leader-role.guard';
import { SubEntityLeaderRoleGuard } from './shared/guards/sub-entity-leader-role.guard';

// How to work properly with angular router:
// https://stackoverflow.com/questions/44864303/send-data-through-routing-paths-in-angular

const routes: Route[] = [
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'clients',
    loadChildren: () => import('app/clients/clients.module').then(m => m.ClientsModule),
  },
  {
    path: 'first-party',
    canActivate: [AuthGuard],
    children: [
      {
        path: ':entityId/findings/:serviceId',
        canActivate: [AuthGuard],
        loadChildren: () => import('app/findings/findings.module').then(m => m.FindingsModule),
      },
      {
        path: ':entityId',
        pathMatch: 'full',
        redirectTo: ':entityId/upperdeck',
      },
      {
        path: ':entityId/upperdeck',
        canActivate: [EntityLeaderRoleGuard],
        loadChildren: () => import('app/upperdeck/upperdeck.module').then(m => m.UpperdeckModule),
      },
      {
        path: ':entityId/multi-entity',
        canActivate: [SubEntityLeaderRoleGuard],
        loadChildren: () =>
          import('app/multi-entity/multi-entity.module').then(m => m.MultiEntityModule),
      },
      {
        path: ':entityId/pillars',
        canActivate: [SubEntityLeaderRoleGuard],
        loadChildren: () => import('app/pillars/pillars.module').then(m => m.PillarsModule),
      },
      {
        path: ':entityId/break-down',
        canActivate: [SubEntityLeaderRoleGuard],
        loadChildren: () => import('app/break-down/break-down.module').then(m => m.BreakDownModule),
      },
      {
        path: ':entityId/remediation',
        canActivate: [ParticipantRoleGuard],
        loadChildren: () =>
          import('app/remediation/remediation.module').then(m => m.RemediationModule),
      },
      {
        path: ':entityId/user-settings',
        canActivate: [SubEntityLeaderRoleGuard],
        loadChildren: () =>
          import('app/users-settings/users-settings.module').then(m => m.UsersSettingsModule),
      },
      {
        path: ':entityId/alerts',
        loadChildren: () => import('app/alerts/alerts.module').then(m => m.AlertsModule),
      },
      {
        path: ':entityId/attack-simulator',
        loadChildren: () =>
          import('app/attack-simulator/attack-simulator.module').then(m => m.AttackSimulatorModule),
      },
      {
        path: ':entityId/questionnaire-management',
        canActivate: [SubEntityLeaderRoleGuard],
        loadChildren: () =>
          import('app/questionnaire-management/questionnaire-management.module').then(
            m => m.QuestionnaireManagementModule
          ),
      },
      {
        path: ':entityId/questionnaire',
        loadChildren: () =>
          import('app/questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
      },
      {
        path: ':entityId/collection',
        loadChildren: () =>
          import('app/collection/collection.module').then(m => m.CollectionModule),
      },
    ],
  },
  {
    path: 'third-party',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/third-party/third-party.module').then(m => m.ThirdPartyModule),
  },
  {
    path: 'vendor',
    canActivate: [AuthGuard],
    children: [
      {
        path: ':entityId',
        pathMatch: 'full',
        redirectTo: ':entityId/questionnaire',
      },
      {
        path: ':entityId/questionnaire',
        loadChildren: () =>
          import('app/questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
      },
      {
        path: ':entityId/gaps',
        loadChildren: () =>
          import('app/remediation/remediation.module').then(m => m.RemediationModule),
      },
    ],
  },
  {
    path: 'board',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/board/board.module').then(m => m.BoardModule),
  },
  {
    path: 'binary-edge',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/binary-edge/binary-edge.module').then(m => m.BinaryEdgeModule),
  },
  {
    path: 'findings',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/findings/findings.module').then(m => m.FindingsModule),
  },
  {
    path: 'training-center',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('app/training-center/training-center.module').then(m => m.TrainingCenterModule),
  },
  {
    path: 'admin',
    canActivate: [AdminRoleGuard],
    loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'generator',
    canActivate: [AdminRoleGuard],
    loadChildren: () => import('app/generator/generator.module').then(m => m.GeneratorModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  providers: [AuthGuard],
  exports: [RouterModule],
})
export class AppRoutesModule {}
