import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScoreColorDirective } from './score-color.directive';
import { ScoreBgColorDirective } from './score-bg-color.directive';

@NgModule({
  declarations: [ScoreColorDirective, ScoreBgColorDirective],
  imports: [CommonModule],
  exports: [ScoreColorDirective, ScoreBgColorDirective],
})
export class ScoreColorModule {}
