import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewTaskModalComponent } from './new-task-modal.component';
import { FormsModule } from '@angular/forms';
import { SelectModule } from '../select/select.module';
import { CheckboxModule } from '../checkbox/checkbox.module';

@NgModule({
  declarations: [NewTaskModalComponent],
  imports: [CommonModule, FormsModule, SelectModule, CheckboxModule],
  providers: [],
  entryComponents: [NewTaskModalComponent],
})
export class NewTaskModalModule {}
