import { Injectable } from '@angular/core';
import { QualificationInput, StandardType } from 'app/API.service';
import { EntityService } from '../entity.service';
import { UtilsService } from '../utils.service';
import { FrameworkTreeService } from '../framework-tree.service';
import { StandardEnum } from '../enums/standard.enum';

@Injectable({
  providedIn: 'root',
})
export class EntityStandardsService {
  constructor(private entityService: EntityService, private treeService: FrameworkTreeService) {}

  async buildEntityStandardsQualify(
    entityId: string,
    isRootEntity = true,
    thirdParty = false
  ): Promise<QualificationInput[]> {
    const complianceFrameworks = await this.entityService.getFrameworksByEntityId(
      entityId,
      StandardType.COMPLIANCE_FRAMEWORK,
      isRootEntity,
      thirdParty
    );
    await Promise.all(
      complianceFrameworks.map(async framework => {
        let scores;
        try {
          scores = await this.entityService.getFrameworkScores(
            framework.assessmentId,
            framework.key
          );
        } catch (e) {
          // Nothing needs to be done here
          console.log(e);
        }
        framework.scores = scores && scores.total ? scores : { total: 0 };
      })
    );
    const uniqueFrameworks = await UtilsService.buildFrameworksList(complianceFrameworks);
    return uniqueFrameworks
      ? uniqueFrameworks.map(framework => {
          return {
            name: StandardEnum[framework.name],
            isQualify: framework.scores.total === 10,
          };
        })
      : [];
  }
}
