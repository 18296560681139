import { Storage } from 'aws-amplify';
import { Injectable } from '@angular/core';
import {
  StandardFrameworkInput,
  FileTypeEnum,
  GetAssessmentStandardFrameworkQuery,
} from 'app/API.service';

@Injectable({
  providedIn: 'root',
})
export class FrameworkTreeService {
  cache = {};

  constructor() {}

  async getFrameworkTreeFromS3(frameworkName: string): Promise<any> {
    try {
      if (this.cache[frameworkName]) {
        return this.cache[frameworkName];
      } else {
        const filePath = `${FileTypeEnum.FRAMEWORK_TREES}/${frameworkName}.json`;
        const options = { download: true, contentType: 'application/json', level: 'public' };
        return (this.cache[frameworkName] = Storage.get(filePath, options));
      }
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  async getAssessmentTreeFromS3(
    assessmentId: string,
    framework: GetAssessmentStandardFrameworkQuery
  ): Promise<any> {
    try {
      const filePath = `${FileTypeEnum.ASSESSMENTS}/${assessmentId}/${framework.key}.json`;
      const options = {
        download: true,
        contentType: 'application/json',
        level: 'public',
      };
      return JSON.parse(((await Storage.get(filePath, options)) as any).Body.toString());
    } catch (err) {
      return null;
    }
  }

  async getAssessmentRevisionTreeFromS3(
    id: string,
    frameworkName: string,
    date: string
  ): Promise<any> {
    try {
      const filePath = `${FileTypeEnum.REVISIONS}/${date}/${FileTypeEnum.ASSESSMENTS}/${id}/${frameworkName}.json`;
      const options = {
        download: true,
        contentType: 'application/json',
        level: 'public',
      };
      return JSON.parse(((await Storage.get(filePath, options)) as any).Body.toString());
    } catch (err) {
      console.log('Error fetching file: ', err);
      return null;
    }
  }
}
