import { Component, OnInit } from '@angular/core';
import { HeatMap, EmitHeatMap, HeatMapCoordinate } from 'models/heatmap.model';
import { ThirdPartyService } from '../third-party.service';
import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute } from '@angular/router';
import { HeatmapSectionEnum } from 'app/shared/enums/heatmap-sections.enum';
import { TableHeader } from 'models/table-header.model';
import { SortDirectionEnum } from 'app/shared/enums/sort-direction.enum';
import {
  ModelEntityFilterInput,
  EntityTypeEnum,
  APIService,
  GetEntityQuery,
} from 'app/API.service';
import { EntityService } from 'app/shared/entity.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'cygov-risk-management-heatmap',
  templateUrl: './risk-management-heatmap.component.html',
  styleUrls: ['./risk-management-heatmap.component.scss'],
})
export class RiskManagementHeatmapComponent implements OnInit {
  vendors: GetEntityQuery[];
  heatMapData: HeatMap[] = [];
  heatMapCoordinates: HeatMapCoordinate[] = [];
  filteredVendors: GetEntityQuery[];
  isLoading = true;
  heatmapSections: HeatmapSectionEnum;

  MIN_X = 95.5;
  MIN_Y = 50;
  CHART_WIDTH = 743;
  CHART_HEIGHT = 532;

  constructor(
    private api: APIService,
    private route: ActivatedRoute,
    private thirdPartyService: ThirdPartyService,
    private entityService: EntityService,
    private logger: NGXLogger
  ) {
    this.heatmapSections = this.thirdPartyService.getHeatmapDefaultView();
  }

  ngOnInit(): void {
    this.getVendorList();
  }
  async getVendorList(): Promise<void> {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    try {
      const filter: ModelEntityFilterInput = {
        entityType: { eq: EntityTypeEnum.VENDOR },
      };
      const vendorList = await this.entityService.listEntitysByParentId(entityId, filter);
      this.vendors = await Promise.all(
        vendorList.map(vendor => this.entityService.setLogoUrl(vendor))
      );
    } catch (e) {
      this.logger.error('Vendor Overview Get - Error: ', e);
    }
    this.getData();
    this.filteredVendors = this.vendors;
    this.isLoading = false;
  }

  getData(): void {
    ThirdPartyService.getHeatMapData(this.vendors, this.heatMapData);
    ThirdPartyService.getHeatMapCoordinates(
      this.heatMapData,
      this.heatMapCoordinates,
      this.MIN_X,
      this.MIN_Y,
      this.CHART_WIDTH,
      this.CHART_HEIGHT
    );
  }

  showExtendedHeatMap(emitHeatMap: EmitHeatMap): void {
    let newFilteredVendors = [...this.vendors];

    newFilteredVendors = newFilteredVendors.filter(
      vendor =>
        vendor.vendorDetails.impact >= +emitHeatMap.minImpact &&
        vendor.vendorDetails.impact <= +emitHeatMap.maxImpact &&
        ((vendor.vendorDetails.probability >= +emitHeatMap.minProbability &&
          vendor.vendorDetails.probability <= +emitHeatMap.maxProbability) ||
          (+emitHeatMap.maxProbability === 100 && vendor.vendorDetails.probability >= 100))
    );

    this.filteredVendors = newFilteredVendors;

    this.sortFilteredVendors({
      value: 'name',
      class: 'company',
      name: 'Company Name',
      isSortApplicable: true,
      isSortApplied: false,
      sortDirection: SortDirectionEnum.ASCENDING,
    });
    this.thirdPartyService.setHeatmapDefaultView(emitHeatMap.sections);
  }

  sortFilteredVendors(sortData: TableHeader): void {
    ThirdPartyService.sortArray(sortData, this.filteredVendors);
  }
}
