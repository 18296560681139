import { ArtifactsEnum } from './../../../../shared/enums/artifacts.enum';
import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { APIService, S3FileInput, GetEntityQuery, StandardType } from 'app/API.service';
import { NGXLogger } from 'ngx-logger';
import { EntityService } from 'app/shared/entity.service';
import { FileService } from 'app/shared/file.service';
import { QuestionnaireService } from 'app/questionnaire/questionnaire.service';
import { Question } from 'models/questionnaire.model';

export interface ArtifactDocument {
  tag: string;
  name: string;
  type: string;
  modifiedAt?: any;
  file: S3FileInput;
}
@Component({
  selector: 'cygov-artifacts',
  templateUrl: './artifacts.component.html',
  styleUrls: ['./artifacts.component.scss'],
})
export class ArtifactsComponent implements OnInit {
  // @Input() vendorArtifacts: Artifacts;
  vendorArtifacts = {
    documents: [],
    scans: [],
    insurances: [],
    certifications: [],
  };
  @Input() vendor: GetEntityQuery;

  keys = ['Documents', 'Scans', 'Insurance', 'Certifications'];

  // headElements = ['Document Tag', 'Name', 'Type', 'Modified date', ''];
  headElements = ['Name', 'Type', 'Modified date', ''];

  // headElementsScan = ['Type', 'Date', 'Auditor', 'Report', ''];

  // headElementsInsurance = ['Provider', 'Type', 'Coverage', 'Supporting Documents', 'Date', ''];

  // headElementsCertification = ['Name', 'Date', 'Auditor/Provider', 'Expiration Date', 'Supporting Documents', ''];

  isLoading = true;
  showDoc = false;
  isShow = true;
  isShowScan = false;
  isShowInsurance = false;
  isShowCertification = false;

  constructor(
    private api: APIService,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private entityService: EntityService,
    private fileService: FileService,
    private questionnaireService: QuestionnaireService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      await this.getArtifacts();
    } catch (error) {
      this.logger.error(error);
      this.toastr.error('Failed loading artifacts');
      this.isLoading = false;
    }
    this.isLoading = false;
  }

  async getArtifacts(): Promise<void> {
    const riskFrameworks = this.vendor.activeAssessment.standardFrameworkList.items.filter(
      standard => standard.type === StandardType.RISK_FRAMEWORK
    );
    const otherFrameworks = this.vendor.activeAssessment.standardFrameworkList.items.filter(
      standard => standard.type !== StandardType.RISK_FRAMEWORK
    );

    const promises = [];

    promises.push(this.getArtifactsForFrameworkTyp(riskFrameworks, StandardType.RISK_FRAMEWORK));
    promises.push(this.getArtifactsForFrameworkTyp(otherFrameworks));

    await Promise.all(promises);
  }

  async getArtifactsForFrameworkTyp(frameworks, frameworkType?: StandardType): Promise<void> {
    const questionnaires = (
      await Promise.all(
        frameworks.map(async framework =>
          this.questionnaireService.buildQuestionnaireFromAssessment(
            this.vendor.activeAssessment as any,
            null,
            framework.key
          )
        )
      )
    ).flat();

    const questions = questionnaires
      .map(questionnaire => this.questionnaireService.getQuestionsFromTree(questionnaire))
      .flat();

    questions.forEach(question => this.mapQuestionsToArtifact(question, frameworkType));

    console.log(questions);
  }

  mapQuestionsToArtifact = (question: Question, frameworkType?: StandardType): void => {
    if (!question.answers) {
      return;
    }
    question.answers.forEach(answer => {
      if (answer.file) {
        const fileInfo = answer.file.map(eachFile => {
          const [fileName, fileType] = eachFile.name.split('.');
          return {
            name: fileName,
            type: fileType,
            date: eachFile.date,
            file: eachFile,
          };
        });
        fileInfo.forEach(file => {
          const info: any = {
            name: file.name,
            type: file.type ? file.type : 'other',
            file: file.file,
            modifiedAt: file.date ? file.date : '',
          };
          switch (frameworkType) {
            case StandardType.RISK_FRAMEWORK:
              // info.tag = 'Document';
              this.vendorArtifacts.documents.push(info);
              break;
            default:
              // info.tag = 'certifications';
              this.vendorArtifacts.certifications.push(info);
              break;
          }
        });
      }
    });
  };

  toggleContent(): void {
    this.isShow = !this.isShow;
  }

  toggleContentScan(): void {
    this.isShowScan = !this.isShowScan;
  }

  toggleContentInsurance(): void {
    this.isShowInsurance = !this.isShowInsurance;
  }

  toggleContentCertification(): void {
    this.isShowCertification = !this.isShowCertification;
  }

  openDock(): void {
    this.showDoc = true;
  }
  closeDock(): void {
    this.showDoc = false;
  }

  async downloadFile(s3Input: S3FileInput): Promise<void> {
    try {
      this.toastr.info('Preparing file...');
      const uri = await this.fileService.downloadFromS3(s3Input);
      const response = await fetch(uri);
      const blob = await response.blob();
      saveAs(blob, `Artifacts-${s3Input.id}.zip`);
    } catch (e) {
      this.logger.error(e);
      this.toastr.error('Failed downloading file');
    }
  }
}
