import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { Observable } from 'rxjs';
import { GetUserQuery, RoleEnum } from 'app/API.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class UserRoleGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthService,
    private logger: NGXLogger
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // this.logger.log('canActivate: canActivateChild was called');
    const url: string = state.url;
    return this.checkUserRole(url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // this.logger.log('canActivateChild: canActivateChild was called');
    const url: string = state.url;
    return this.checkUserRole(url);
  }

  async checkUserRole(url?) {
    try {
      const user: GetUserQuery = await this.authService.getCurrentUser();
      if (user) {
        switch (user.role) {
          case RoleEnum.ADMIN:
          case RoleEnum.MSSP:
            return true;
          case RoleEnum.LEADER:
            if (this.authService.isEntityIdInUrl(url, user.entityId)) {
              return true;
            } else {
              this.logger.error('checkUserRole: unauthenticated');
              await this.router.navigate(['/login']);
              return false;
            }
          case RoleEnum.PARTICIPANT:
            if (this.authService.isEntityIdInUrl(url, user.entityId)) {
              return true;
            } else {
              this.logger.error('checkUserRole: unauthenticated');
              await this.router.navigate(['/login']);
              return false;
            }
        }
      } else {
        await this.router.navigate(['/login']);
        return Promise.reject(false);
      }
    } catch (e) {
      this.logger.error('UserRoleGuard - Error: ', e);
      await this.router.navigate(['/login']);
      return Promise.reject(false);
    }
  }
}
