import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'cygov-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  public message = 'Page Not Found!';

  constructor(private authService: AuthService) {}

  ngOnInit() {}

  async navigateHome() {
    await this.authService.navigateByRole();
  }
}
