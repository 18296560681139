import { ParticipantRoleGuard } from 'app/shared/guards/participant-role.guard';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { AuthService } from 'app/auth/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderModule } from './header/header.module';
import { AdminModule } from './admin/admin.module';
import { ProgressBarModule } from './shared/progress-bar/progress-bar.module';
import { UserRoleGuard } from './shared/guards/user-role.guard';
import { UtilsService } from './shared/utils.service';
import { FileService } from './shared/file.service';
import { SidenavModule } from './sidenav/sidenav.module';
import { FooterModule } from './footer/footer.module';
import { AppRoutesModule } from './app.routes';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SvgIconService } from './shared/svg-icon/svg-icon.service';
import { SvgIconModule } from './shared/svg-icon/svg-icon.module';
import { CygovLoaderComponent } from './shared/cygov-loader/cygov-loader.component';
import { CygovLoaderModule } from './shared/cygov-loader/cygov-loader.module';
import { CygovErrorHandler } from './shared/cygov-error-handler/cygov-error-handler.service';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { APIService } from './API.service';
import { ToastrModule, GlobalConfig } from 'ngx-toastr';
import { LoggerModule, NgxLoggerLevel, LoggerConfig } from 'ngx-logger';
import { environment } from 'environments/environment';
import { JiraConfirmatonModalComponent } from './shared/jira-confirmaton-modal/jira-confirmaton-modal.component';
import { ScanIntegrationServiceModalComponent } from './shared/scan-integration-service-modal/scan-integration-service-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

export function CygovErrorHandlerProvider() {
  return { provide: ErrorHandler, useClass: CygovErrorHandler };
}

const toastConfig: Partial<GlobalConfig> = {
  timeOut: 5000,
  closeButton: true,
  positionClass: 'toast-top-right',
  enableHtml: true,
  progressBar: false,
  progressAnimation: 'increasing',
  newestOnTop: true,
  preventDuplicates: true,
  countDuplicates: true,
  resetTimeoutOnDuplicate: true,
};

const loggerConfig: LoggerConfig = {
  level: environment.debug ? NgxLoggerLevel.LOG : NgxLoggerLevel.OFF,
  serverLogLevel: NgxLoggerLevel.OFF,
};

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    JiraConfirmatonModalComponent,
    ScanIntegrationServiceModalComponent,
  ],
  entryComponents: [
    AppComponent,
    CygovLoaderComponent,
    JiraConfirmatonModalComponent,
    ScanIntegrationServiceModalComponent,
  ],
  providers: [
    AuthService,
    UserRoleGuard,
    ParticipantRoleGuard,
    UtilsService,
    FileService,
    SvgIconService,
    CygovErrorHandlerProvider(),
    AmplifyService,
    APIService,
  ],
  imports: [
    BrowserModule,
    AppRoutesModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot(toastConfig),
    AdminModule,
    ProgressBarModule,
    HeaderModule,
    SidenavModule,
    FooterModule,
    SvgIconModule,
    CygovLoaderModule,
    AmplifyAngularModule,
    LoggerModule.forRoot(loggerConfig),
    ReactiveFormsModule,
    FormsModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
