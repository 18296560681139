import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { EmitHeatMap, HeatMapCoordinate } from 'models/heatmap.model';
import { UtilsService } from 'app/shared/utils.service';
import { HeatmapSectionEnum } from 'app/shared/enums/heatmap-sections.enum';

@Component({
  selector: 'cygov-heat-map',
  templateUrl: './heat-map.component.html',
  styleUrls: ['./heat-map.component.scss'],
})
export class HeatMapComponent implements OnInit {
  @Input() heatMapCoordinates: HeatMapCoordinate[];
  @Input() showIcons: boolean;
  @Input() minX: number;
  @Input() minY: number;
  @Input() chartWidth: number;
  @Input() chartHeight: number;
  @Input() section: HeatmapSectionEnum;

  @Output() showExtendedHeatMap = new EventEmitter();

  @ViewChild('heatMapGrid', { static: false }) heatMapSections: ElementRef;

  // Constants
  SVG_WIDTH = 939.5;
  SVG_HEIGHT = 708.48;
  SVG_VIEW_BOX = `0 0 ${this.SVG_WIDTH} ${this.SVG_HEIGHT}`;

  SECTIONS: HeatmapSectionEnum = HeatmapSectionEnum.None;

  IMPACT_TEXT_X: number;
  IMPACT_TEXT_Y: number;
  PROBABLILITY_TEXT_X: number;
  PROBABLILITY_TEXT_Y: number;

  IMPACT_VALUE_TEXT_X: number;
  IMPACTL_TEXT_Y: number;
  IMPACTH_TEXT_Y: number;
  IMPACTVH_TEXT_Y: number;
  IMPACTM_TEXT_Y: number;

  VERTICAL_LINE_X1: number;
  VERTICAL_LINE_Y1: number;
  VERTICAL_LINE_X2: number;
  VERTICAL_LINE_Y2: number;
  HORIZENTAL_LINE_X1: number;
  HORIZENTAL_LINE_Y1: number;
  HORIZENTAL_LINE_X2: number;
  HORIZENTAL_LINE_Y2: number;

  FONT_SIZE: number;

  PROBABILITY_PER_UNIT_WIDTH: number;
  IMPACT_PER_UNIT_HEIGHT: number;
  PROBABILITY_NUMBER_WIDTH: number;

  PROBABILITY_NUMBERS_Y: number;

  AXIS_CLASS = 'axis-line-blue';
  RECTANGLE_CLASS = 'rectangle-white';
  RECTANGLE_LINE_CLASS = 'rectangle-white';

  SECTION_COLOR_LIGHT_CLASS = 'section-color-light';
  SECTION_COLOR_DARK_CLASS = 'section-color-dark';

  TOTAL_PROBABILITY = 100;
  TOTAL_IMPACT = 100;

  MAX_IMPACT_RECT_COUNT = 16;
  MAX_PROBABILITY_RECT_COUNT = 16;

  SMALL_GRID_ROWS = 4;
  SMALL_GRID_COLS = 4;
  LARGE_GRID_ROWS = 16;
  LARGE_GRID_COLS = 16;

  LARGE_GRID_ROW_NUMBERS = Array(this.LARGE_GRID_ROWS)
    .fill(0)
    .map((x, i) => i + 1);

  LARGE_GRID_COL_NUMBERS = Array(this.LARGE_GRID_COLS)
    .fill(0)
    .map((x, i) => i + 1);

  SMALL_GRID_ROW_NUMBERS = Array(this.SMALL_GRID_ROWS)
    .fill(0)
    .map((x, i) => i + 1);

  SMALL_GRID_COL_NUMBERS = Array(this.SMALL_GRID_COLS)
    .fill(0)
    .map((x, i) => i + 1);

  PROBABILITY_NUMBERS = Array(10)
    .fill(0)
    .map((x, i) => i + 1);

  queryParams: EmitHeatMap;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.IMPACT_TEXT_X = this.minX - 60;
    this.IMPACT_TEXT_Y = this.minY + 275;
    this.PROBABLILITY_TEXT_X = this.minX + 312;
    this.PROBABLILITY_TEXT_Y = this.minY + this.chartHeight + 74;

    this.IMPACT_VALUE_TEXT_X = this.minX - 37.75;
    this.IMPACTL_TEXT_Y = this.minY + this.chartHeight;
    this.IMPACTVH_TEXT_Y = this.minY + 11;
    this.IMPACTH_TEXT_Y = (this.IMPACTL_TEXT_Y - this.IMPACTVH_TEXT_Y) / 3 + this.minY + 3;
    this.IMPACTM_TEXT_Y =
      this.minY + this.chartHeight - (this.IMPACTL_TEXT_Y - this.IMPACTVH_TEXT_Y) / 3 - 3;

    this.VERTICAL_LINE_X1 = this.minX;
    this.VERTICAL_LINE_Y1 = this.minY;
    this.VERTICAL_LINE_X2 = this.minX;
    this.VERTICAL_LINE_Y2 = this.minY + this.chartHeight;
    this.HORIZENTAL_LINE_X1 = this.minX;
    this.HORIZENTAL_LINE_Y1 = this.VERTICAL_LINE_Y2;
    this.HORIZENTAL_LINE_X2 = this.minX + this.chartWidth;
    this.HORIZENTAL_LINE_Y2 = this.VERTICAL_LINE_Y2;

    this.FONT_SIZE = 14;

    this.PROBABILITY_PER_UNIT_WIDTH = this.chartWidth / this.MAX_PROBABILITY_RECT_COUNT;
    this.PROBABILITY_NUMBER_WIDTH = this.chartWidth / this.PROBABILITY_NUMBERS.length;
    this.IMPACT_PER_UNIT_HEIGHT = this.chartHeight / this.MAX_IMPACT_RECT_COUNT;

    this.PROBABILITY_NUMBERS_Y = this.minY + this.chartHeight + this.FONT_SIZE * 2;

    this.getParamValues();
    this.setGridAndIconColors(this.section);
  }

  getParamValues(): void {
    this.route.queryParams.subscribe(queryParams => {
      if (Object.keys(queryParams).length) {
        this.queryParams = new EmitHeatMap(
          true,
          +queryParams.minProbability,
          +queryParams.maxProbability,
          +queryParams.minImpact,
          +queryParams.maxImpact,
          this.section
        );
      }
    });
  }
  showGrid(noOfSections: number): void {
    this.queryParams = null;
    this.setGridAndIconColors(noOfSections);
  }

  setGridAndIconColors(noOfSections: HeatmapSectionEnum): void {
    this.SECTIONS = noOfSections;
    if (this.SECTIONS === HeatmapSectionEnum.Large) {
      this.AXIS_CLASS = 'axis-line-white';
      this.RECTANGLE_CLASS = 'rectangle-blue';
      this.RECTANGLE_LINE_CLASS = 'rectangle-white';
    } else if (this.SECTIONS === HeatmapSectionEnum.Small) {
      this.AXIS_CLASS = 'axis-line-white';
      this.RECTANGLE_CLASS = 'rectangle-white';
      this.RECTANGLE_LINE_CLASS = 'rectangle-blue';
    } else {
      this.AXIS_CLASS = 'axis-line-blue';
      this.RECTANGLE_CLASS = 'rectangle-white';
      this.RECTANGLE_LINE_CLASS = 'rectangle-white';
    }
    if (this.queryParams) {
      this.showExtendedHeatMap.emit(this.queryParams);
    } else {
      this.showExtendedHeatMap.emit(new EmitHeatMap(false, 0, 100, 0, 100, this.SECTIONS));
    }
  }

  showVendors(event, rowReverseIndex: number, colIndex: number): void {
    const [
      startProbability,
      endProbability,
      startImpact,
      endImpact,
    ] = this.getImpactProbabilityValues(rowReverseIndex, colIndex);
    this.setDefaultSectionsFill();
    event.currentTarget.setAttribute('class', this.SECTION_COLOR_DARK_CLASS);
    const emitHeatMap = new EmitHeatMap(
      true,
      Number(startProbability),
      Number(endProbability),
      Number(startImpact),
      Number(endImpact),
      this.SECTIONS
    );
    this.showExtendedHeatMap.emit(emitHeatMap);
  }

  hideVendors(): void {
    this.setDefaultSectionsFill();
    const emitHeatMap = new EmitHeatMap(false, 0, 0, 0, 0, 0);
    this.showExtendedHeatMap.emit(emitHeatMap);
  }

  setDefaultSectionsFill(): void {
    if (this.heatMapSections.nativeElement && this.heatMapSections.nativeElement.children) {
      for (const rect of this.heatMapSections.nativeElement.children) {
        rect.setAttribute('class', this.SECTION_COLOR_LIGHT_CLASS);
      }
    }
  }

  setRectColor(rowIndex: number, colIndex: number): string {
    const [
      startProbability,
      endProbability,
      startImpact,
      endImpact,
    ] = this.getImpactProbabilityValues(rowIndex, colIndex);
    if (
      this.queryParams &&
      +startProbability === this.queryParams.minProbability &&
      +endProbability === this.queryParams.maxProbability &&
      +startImpact === this.queryParams.minImpact &&
      +endImpact === this.queryParams.maxImpact
    ) {
      return this.SECTION_COLOR_DARK_CLASS;
    } else {
      return this.SECTION_COLOR_LIGHT_CLASS;
    }
  }
  showVendorDetail(vendorId: string): void {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.router.navigate([`third-party/${entityId}/vendors`], {
      queryParams: { vendorId },
    });
  }
  getImpactProbabilityValues(rowReverseIndex: number, colIndex: number): any {
    let multiplier = 0;
    if (this.SECTIONS === HeatmapSectionEnum.Large) {
      multiplier = this.MAX_IMPACT_RECT_COUNT / this.SMALL_GRID_ROWS;
    } else {
      multiplier = this.MAX_IMPACT_RECT_COUNT / this.LARGE_GRID_ROWS;
    }
    return [
      (
        (this.TOTAL_PROBABILITY / this.MAX_PROBABILITY_RECT_COUNT) *
        (colIndex * multiplier)
      ).toFixed(2),
      (
        (this.TOTAL_PROBABILITY / this.MAX_PROBABILITY_RECT_COUNT) *
        ((colIndex + 1) * multiplier)
      ).toFixed(2),
      ((this.TOTAL_IMPACT / this.MAX_IMPACT_RECT_COUNT) * rowReverseIndex * multiplier).toFixed(2),
      (
        (this.TOTAL_IMPACT / this.MAX_IMPACT_RECT_COUNT) *
        (rowReverseIndex + 1) *
        multiplier
      ).toFixed(2),
    ];
  }
}
