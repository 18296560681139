export enum StandardEnum {
  PCI_DSS = 'PCI DSS',
  FFIEC = 'FFIEC',
  GDPR = 'GDPR',
  HIPAA = 'HIPAA',
  // HITRUST = 'HITRUST',
  ISO_27001 = 'ISO 27001',
  CCPA = 'CCPA',
  // NIST_800_53 = 'NIST_800_53',
  NIST_800_46 = 'NIST 800 46',
  NIST_800_82 = 'NIST 800 82',
  NIST_800_171 = 'NIST 800 171',
  NIST_800_207 = 'NIST 800 207',
  SOC_2 = 'SOC 2',
  // AZURE_CLOUD = 'AZURE CLOUD',
  // AWS_CLOUD = 'AWS CLOUD',
  // NERC = 'NERC',
  CSA = 'CSA',
  GLBA = 'GLBA',
  NYDFS = 'NYDFS',
  NYDFS_MINIMAL = 'NYDFS MINIMAL',
  CMMC = 'CMMC',
  FERPA = 'FERPA',
}
