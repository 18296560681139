import { Injectable } from '@angular/core';
import { parse, ParseResult, unparse } from 'papaparse';
import * as uuid from 'uuid';
import { HttpClient } from '@angular/common/http';
import { Storage } from 'aws-amplify';
import { FileTypeEnum } from 'app/API.service';
import { NGXLogger } from 'ngx-logger';
import { ObjectsSort } from './helpers/generic-helpers';

export interface IstandardsNIndustries {
  category: string;
  standards_name: string;
  industry: string;
  comments: string;
}
@Injectable()
export class FileService {
  static fileSize = 5242880;
  logoFileSizeLimit = 2000000; // 2MB
  constructor(private http: HttpClient, private logger: NGXLogger) { }

  static validateFileSize(file): boolean {
    return file.size > FileService.fileSize;
  }
  async uploadToS3(s3File: any): Promise<any> {
    const config: any = { contentType: s3File.contentType };
    let path;
    switch (s3File.fileType) {
      case FileTypeEnum.LOGO:
        config.level = 'public';
        path = `CLIENTS/${s3File.entityId}/${s3File.fileType}/${s3File.name}`;
        break;
      case FileTypeEnum.ARTIFACTS:
        s3File.id = uuid();
        path = `CLIENTS/${s3File.entityId}/${s3File.fileType}/${s3File.id}`;
        config.level = 'public';
        config.download = true;
        break;
      case FileTypeEnum.REVISIONS:
        break;
    }

    const { key } = (await Storage.put(path, s3File.body, config)) as any;
    s3File.key = key;
    delete s3File.body;
    return s3File;
  }

  async downloadFromS3(s3File: any): Promise<any | string> {
    delete s3File.__typename;
    const config: any = { contentType: s3File.contentType, level: 'public' };
    return Storage.get(s3File.key, config);
  }

  deleteFromS3(s3File: any): Promise<void> {
    const config = { level: 'public' };
    return Storage.remove(s3File.key, config);
  }

  getBase64(file: any): Promise<any | string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  getImgFileFromBase64(dataurl: any, filename): File {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  parseFile(csv): Promise<ParseResult> {
    return new Promise((resolve, reject) => {
      parse(csv, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        delimiter: ',',
        encoding: 'UTF-8',
        complete(results, file) {
          resolve(results);
        },
        error(err) {
          this.logger.error(err);
          reject(err);
        },
      });
    });
  }
  // This is synchronous function hence no Promise required
  unParseFile(json): string {
    return unparse(json, {
      header: true,
      delimiter: ',',
    });
  }

  getFile(filePath: string): Promise<any> {
    return this.http.get(filePath, { responseType: 'text' }).toPromise();
  }

  async getUploadedImg(e: Event): Promise<any> {
    return new Promise((res, rej) => {
      const eventTarget: HTMLInputElement = e.target as HTMLInputElement;
      const imageFile = e && eventTarget.files[0] ? eventTarget.files[0] : null;
      if (!imageFile) {
        res(null);
      }
      try {
        res(this.getBase64(imageFile));
      } catch (error) {
        rej(error);
      }
    });
  }

  async getS3File(e: Event, entityId): Promise<any> {
    return new Promise((res, rej) => {
      try {
        const eventTarget: HTMLInputElement = e.target as HTMLInputElement;

        const imageFile = e && eventTarget.files[0] ? eventTarget.files[0] : null;
        if (!imageFile) {
          rej('An occurred while uploading logo');
        }
        if (imageFile.size > this.logoFileSizeLimit) {
          rej('Max file size for upload 2MB');
        }
        const s3File = {
          name: imageFile.name,
          entityId,
          fileType: FileTypeEnum.LOGO,
          contentType: imageFile.type,
          body: imageFile,
        };

        res(s3File);
      } catch (error) {
        this.logger.error('Image Handler  Error =', error);
        rej('An occurred while uploading logo');
      }
    });
  }

  async getFileUrlFromBucket(filePath, options): Promise<any> {
    return Storage.get(filePath, options);
  }

  convertCSVDataToJSON(csvDataAsText): IstandardsNIndustries[] {
    const arr = csvDataAsText.split('\n');
    let headers;
    let result = [];
    for (let i = 0; i < arr.length; i++) {
      if (i === 0) {
        headers = arr[i].split(',');
      } else {
        const rowData = arr[i].split(',');
        const object = {};
        headers.map((header, indx) => {
          const key = header
            .toLowerCase()
            .trim()
            .replace(/\s/g, '_'); // key => Standards Name => standards_name
          object[key] = rowData[indx];
        });
        result.push(object);
      }
    }
    // Sort by Industry column
    if (result.length > 0) {
      result = ObjectsSort(result, 'industry');
    }
    return result;
  }
}
