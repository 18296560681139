import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cygov-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Input() items: any;
  @Input() placeholder: string;
  @Input() ngModelValue: any;
  @Input() multiple: boolean;
  @Input() isImageControl: boolean;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() groupBy: string;
  @Input() clearable = true;
  @Input() searchable = true;
  @Input() disabled = false;
  @Input() customTemplate = false;
  @Input() labelKeys: string[] = [];
  @Input() optionKeys: string[] = [];
  @Input() searchFn = null;
  @Input() replacePipe = false;
  @Input() replaceSearchValue = '';
  @Input() replaceValue = '';
  @Output() ngModelValueChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
