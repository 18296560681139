import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'app/shared/utils.service';
import { Location } from '@angular/common';
import {
  GetEntityQuery,
  CreateEntityInput,
  EntityTypeEnum,
  ModelEntityFilterInput,
  CollectionStatusEnum,
  GetUserQuery,
} from 'app/API.service';

import { EntityService, RequiredStandard } from 'app/shared/entity.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'app/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'cygov-sub-entity-list',
  templateUrl: './sub-entity-list.component.html',
  styleUrls: ['./sub-entity-list.component.scss'],
})
export class SubEntityListComponent implements OnInit, OnDestroy {
  rootEntity: GetEntityQuery;
  selectedAgencyName: string;
  selectedChildId: string;
  stages: string[] = Object.keys(CollectionStatusEnum);
  childEntityList: GetEntityQuery[];
  childEntitysToDisplay: GetEntityQuery[];
  queryText = '';
  selectedRow: number;
  page;
  newChildEntity: CreateEntityInput;
  frameworkTypeOpt = [EntityTypeEnum.RISK_FRAMEWORK, EntityTypeEnum.COMPLIANCE_FRAMEWORK];
  standardList: RequiredStandard[];
  subscription: Subscription = EntityService.onDataChanged.subscribe((dataChanged: boolean) => {
    if (dataChanged) {
      this.ngOnInit();
    }
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    private toastr: ToastrService,
    private entityService: EntityService,
    private authService: AuthService,
    private logger: NGXLogger
  ) {}

  async ngOnInit() {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    // TODO: Add input "Select Project Manager" it's mandatory. In the mean time it will be the current user
    const projectManager: GetUserQuery = await this.authService.getCurrentUser();

    this.newChildEntity = EntityService.initEntity(
      EntityTypeEnum.CHILD_ENTITY,
      projectManager.id,
      entityId
    );
    this.standardList = EntityService.initStandardList();
    this.childEntityList = this.childEntityList || null;

    try {
      this.rootEntity = await this.entityService.getEntity(entityId);
      this.childEntityList = await this.entityService.listChildEntitysByParentId(
        this.rootEntity.id
      );
      this.searchAgencies();
    } catch (e) {
      this.logger.error('SubEntityListComponent - Error: ', e);
    }
  }

  toggleStandard(event, standard: RequiredStandard) {
    UtilsService.stopBubbling(event);
    standard.checked = !standard.checked;
  }

  // TODO: rewrite this method with downloadFromS3 fn
  downloadFile(entityId: string, surveyOrReview: boolean) {
    try {
      // const childEntity: GetEntityQuery = await this.entityService.getEntity(entityId);
      // const jsonToDownload = surveyOrReview ? agency.surveyCore : agency.review;
      // const dataAsString =
      //   'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(jsonToDownload));
      // const link = document.createElement('a');
      // link.setAttribute('href', dataAsString);
      // link.setAttribute('download', 'scene.json');
      // link.click();
      // await this.dataService.downloadSubEntityData(agencyId, surveyOrReview);
    } catch (e) {
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  goToUserList() {
    this.router.navigate([`admin/${this.rootEntity.id}/${this.selectedChildId}/user-list`]);
  }

  selectAgency(agencyName, agencyId, i) {
    this.selectedAgencyName = agencyName;
    this.selectedChildId = agencyId;
    this.selectedRow = i;
  }

  searchAgencies() {
    this.childEntitysToDisplay = this.childEntityList.filter(childEntity => {
      return (
        !this.queryText ||
        childEntity.name.toLocaleLowerCase().includes(this.queryText.toLocaleLowerCase())
      );
    });
  }

  async addChildEntity(newChildEntity: CreateEntityInput) {
    this.toastr.info(`adding ${newChildEntity.name}...`);
    try {
      const requiredStandard: RequiredStandard[] = this.standardList.filter(
        standard => standard.checked
      );
      const createdEntity = await this.entityService.createEntity(newChildEntity, requiredStandard);
      this.toastr.success(`${createdEntity.name} added successfully!`);
      await this.ngOnInit();
    } catch (e) {
      this.errorHandler(e);
    }
  }

  errorHandler(err) {
    switch (err.type) {
      case 'dataFile': {
        this.toastr.error('Failed to process survey file');
        break;
      }
      case 'server': {
        this.toastr.error('Failed to save in server');
        break;
      }
      default: {
        this.toastr.error('General failure');
      }
    }
  }

  validateChildEntity(newChildEntity: CreateEntityInput) {
    let isValid = true;
    if (!newChildEntity.name) {
      this.toastr.error('Name is required');
      isValid = false;
    }
    if (!newChildEntity.timeline.collectionDate) {
      this.toastr.error('Please select date');
      isValid = false;
    }
    if (!newChildEntity.completionStatus) {
      this.toastr.error('Please select stage');
      isValid = false;
    }
    if (!this.isStandardSelected()) {
      this.toastr.error('At least one standard is required');
      isValid = false;
    }
    return isValid;
  }

  // checks if at least one standard selected
  isStandardSelected() {
    return !!this.standardList.filter(standard => standard.checked).length;
  }

  async removeChildEntity(selectedChildId: string) {
    try {
      this.toastr.info('Removing entity...');
      await this.entityService.deleteEntity(selectedChildId);
      this.toastr.success('Entity removed successfully');
      await this.ngOnInit();
    } catch (e) {
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  toTimestamp(dateStr: string) {
    return new Date(dateStr).getTime();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
