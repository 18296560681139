import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cygov-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent implements OnInit {
  @Output() changed = new EventEmitter<boolean>();
  @Input() isChecked: boolean;
  @Input() isDisabled: boolean;
  @Input() id: number;

  constructor() {}

  ngOnInit(): void {}
}
