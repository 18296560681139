import { ErrorHandler, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class CygovErrorHandler implements ErrorHandler {
  constructor(private logger: NGXLogger) {}

  handleError(error) {
    error = error ? error : 'error is undefined';
    const message = error.message ? error.message : error.toString();
    // we can log in external logger. ex: loggly
    this.logger.error(`CygovErrorHandler: ${message}`);
    throw error;
  }
}
