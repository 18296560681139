import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'cygov-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  @Output() changed = new EventEmitter<boolean>();
  @Input() isChecked: boolean;
  @Input() width = 16;
  @Input() height = 16;
  @Input() useViewPortDimensions = false;
  @Input() id = '';
  constructor() {}

  ngOnInit(): void {}
}
