export const Services = {
  TENABLE: 'tenableInformation',
  RAPID7: 'rapid7Information',
};

export const FrameworkRangeMap = [
  {
    name: 'NIST_CSF',
    type: 'RISK_FRAMEWORK',
    left: 0,
    right: 10000,
  },
  {
    name: 'PCI_DSS',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 10000,
    right: 20000,
  },
  {
    name: 'FFIEC',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 20000,
    right: 30000,
  },
  {
    name: 'GDPR',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 30000,
    right: 40000,
  },
  {
    name: 'ISO_27001',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 40000,
    right: 50000,
  },
  {
    name: 'CCPA',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 50000,
    right: 60000,
  },
  {
    name: 'NIST_800_82',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 60000,
    right: 70000,
  },
  {
    name: 'NIST_800_171',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 70000,
    right: 80000,
  },
  {
    name: 'SOC_2',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 80000,
    right: 90000,
  },
  {
    name: 'CSA',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 90000,
    right: 100000,
  },
  {
    name: 'GLBA',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 100000,
    right: 110000,
  },
  {
    name: 'NYDFS',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 110000,
    right: 120000,
  },
  {
    name: 'NIST_COBIT_5',
    type: 'RISK_FRAMEWORK',
    left: 120000,
    right: 130000,
  },
  {
    name: 'FERPA',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 160000,
    right: 170000,
  },
  {
    name: 'HIPAA',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 170000,
    right: 180000,
  },
  {
    name: 'NIST_800_46',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 190000,
    right: 200000,
  },
  {
    name: 'VENDOR_RISK',
    type: 'RISK_FRAMEWORK',
    left: 130000,
    right: 140000,
  },
  {
    name: 'VENDOR_ISO_27001',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 140000,
    right: 140500,
  },
  {
    name: 'VENDOR_GDPR',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 140500,
    right: 141000,
  },
  {
    name: 'VENDOR_HIPAA',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 141000,
    right: 141500,
  },
  {
    name: 'VENDOR_PCI_DSS',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 141500,
    right: 142000,
  },
  {
    name: 'VENDOR_FFIEC',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 142000,
    right: 142500,
  },
  {
    name: 'ARTIFACT_penetration_testing',
    type: 'ARTIFACT',
    left: 142500,
    right: 143000,
  },
  {
    name: 'ARTIFACT_vulnerabilities_scans',
    type: 'ARTIFACT',
    left: 143000,
    right: 143500,
  },
  {
    name: 'ARTIFACT_information_security_policy',
    type: 'ARTIFACT',
    left: 143500,
    right: 144000,
  },
  {
    name: 'ARTIFACT_cyber_insurance',
    type: 'ARTIFACT',
    left: 144000,
    right: 144500,
  },
  // Insert Constants for CRB
  {
    name: 'CRB_ARTIFACT_information_security_policy',
    type: 'ARTIFACT',
    left: 300000,
    right: 300100,
  },
  {
    name: 'CRB_ARTIFACT_procedure_for_background_screening',
    type: 'ARTIFACT',
    left: 300100,
    right: 300200,
  },
  {
    name: 'CRB_ARTIFACT_bcp_and_drp_policies',
    type: 'ARTIFACT',
    left: 300200,
    right: 300300,
  },
  {
    name: 'CRB_ARTIFACT_data_retention_and_destruction_requirements',
    type: 'ARTIFACT',
    left: 300300,
    right: 300400,
  },
  {
    name: 'CRB_ARTIFACT_cyber_insurance_policies',
    type: 'ARTIFACT',
    left: 300400,
    right: 300500,
  },
  {
    name: 'CRB_ARTIFACT_ssae_18_reports_for_the_past_year',
    type: 'ARTIFACT',
    left: 300500,
    right: 300600,
  },
  {
    name: 'CRB_ARTIFACT_ssae_18_evidence_and_reports_of_vendors',
    type: 'ARTIFACT',
    left: 300600,
    right: 300700,
  },
  {
    name: 'CRB_ARTIFACT_technology_risk_assessments_and_risk_management_programs',
    type: 'ARTIFACT',
    left: 300700,
    right: 300800,
  },
  {
    name: 'CRB_ARTIFACT_latest_technology_risk_assessments_reports',
    type: 'ARTIFACT',
    left: 300800,
    right: 300900,
  },
  {
    name: 'CRB_ARTIFACT_penetration_testing_results',
    type: 'ARTIFACT',
    left: 300900,
    right: 301000,
  },
  {
    name: 'CRB_ARTIFACT_bcp_and_drp_testing_schedules_and_results',
    type: 'ARTIFACT',
    left: 301000,
    right: 301100,
  },
  {
    name: 'CRB_ARTIFACT_critical_infrastructure_physical_security_guidelines',
    type: 'ARTIFACT',
    left: 301100,
    right: 301200,
  },
  {
    name: 'CRB_ARTIFACT_non-disclosure_agreement',
    type: 'ARTIFACT',
    left: 301200,
    right: 301300,
  },
  {
    name: 'CRB_FFIEC',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 301300,
    right: 301400,
  },
  {
    name: 'CRB_PCI_DSS',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 301400,
    right: 301500,
  },
  {
    name: 'CRB_GDPR',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 301500,
    right: 301600,
  },
  {
    name: 'CRB_ISO_27001',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 301600,
    right: 301700,
  },
  {
    name: 'VENDOR_CCPA',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 301700,
    right: 301800,
  },
  {
    name: 'VENDOR_CMMC',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 301800,
    right: 301900,
  },
  {
    name: 'VENDOR_CSA',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 301900,
    right: 302000,
  },
  {
    name: 'VENDOR_FERPA',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 302000,
    right: 302100,
  },
  {
    name: 'VENDOR_GLBA',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 302100,
    right: 302200,
  },
  {
    name: 'VENDOR_NIST_800_171',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 302200,
    right: 302300,
  },
  {
    name: 'VENDOR_NIST_800_207',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 302300,
    right: 302400,
  },
  {
    name: 'VENDOR_NIST_800_46',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 302400,
    right: 302500,
  },
  {
    name: 'VENDOR_NIST_800_82',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 302500,
    right: 302600,
  },
  {
    name: 'VENDOR_NIST_COBIT_5',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 302600,
    right: 302700,
  },
  {
    name: 'VENDOR_NIST_CSF',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 302700,
    right: 302800,
  },
  {
    name: 'VENDOR_NIST_PRIVACY',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 302900,
    right: 303000,
  },
  {
    name: 'VENDOR_NYDFS',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 303000,
    right: 303100,
  },
  {
    name: 'VENDOR_NYDFS_MINIMAL',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 303100,
    right: 303200,
  },
  {
    name: 'VENDOR_SOC_2',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 303200,
    right: 303300,
  },
  {
    name: 'VENDOR_RISK_CRB',
    type: 'RISK_FRAMEWORK',
    left: 400000,
    right: 410000,
  },
  {
    name: 'NIST_800_207',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 410000,
    right: 420000,
  },
  {
    name: 'NIST_PRIVACY',
    type: 'RISK_FRAMEWORK',
    left: 420000,
    right: 430000,
  },
  {
    name: 'CMMC',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 430000,
    right: 440000,
  },
  {
    name: 'NYDFS_MINIMAL',
    type: 'COMPLIANCE_FRAMEWORK',
    left: 440000,
    right: 450000,
  },
];
