import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

/** A router wrapper, adding extra functions. */
@Injectable()
export class RouterExtService {
  private prevRootRouteParam: ActivatedRouteSnapshot = undefined;
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(public router: Router, public route: ActivatedRoute) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.prevRootRouteParam = { ...this.route.root.snapshot };
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }
  public getPrevRootRouteParam() {
    return this.prevRootRouteParam;
  }
}
