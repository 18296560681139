export class StatusData {
  Total: number;
  Count: number;
  OriginalCount: number;
  StartX: number;
  StartY: number;
  Width: number;
  CountTextX: number;
  CountTextY: number;
  TextAddWidth: number;
  Cx: number;
  Cy: number;
  LineX1: number;
  LineY1: number;
  LineX2: number;
  LineY2: number;
  StatusTextX: number;
  StatusTextY: number;
  PathD: string;
  Text: string;
  GradientClass: string;
}
