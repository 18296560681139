import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CriticalAlertsComponent } from './critical-alerts.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { CygovLoaderModule } from '../cygov-loader/cygov-loader.module';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [CriticalAlertsComponent],
  imports: [CommonModule, SvgIconModule, CygovLoaderModule, PipesModule],
  exports: [CriticalAlertsComponent],
})
export class CriticalAlertsModule {}
