import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../utils.service';
/*
 * Capitalize the first letter of the string
 * Takes a string as a value.
 * Usage:
 *  value | capitalizefirst
 */
@Pipe({
  name: 'capitalizeFirst',
})
export class CapitalizeFirstPipe implements PipeTransform {
  transform(value: string): string {
    if (value === null) {
      return 'Not assigned';
    }
    return UtilsService.capitalizeFirstLetter(value);
  }
}
