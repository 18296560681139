import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbDropdownConfig, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GetEntityQuery, GetUserQuery, RoleEnum } from '../API.service';
import { NGXLogger } from 'ngx-logger';
import { GeneratorComponent } from 'app/generator/generator.component';
import { Location } from '@angular/common';

@Component({
  selector: 'cygov-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [NgbDropdownConfig],
})
export class HeaderComponent implements OnInit {
  notificationCount = 3;
  modalRef: NgbModalRef;
  user: GetUserQuery;
  entity: GetEntityQuery = null;
  subEntity: GetEntityQuery = null;
  complianceQualification: string;
  showEntitySummary = false;
  isAdmin = false;
  loading = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private _location: Location,
    private toastr: ToastrService,
    config: NgbDropdownConfig,
    private logger: NGXLogger
  ) {
    config.placement = 'bottom-right';
  }

  async ngOnInit(): Promise<void> {
    try {
      this.isAdmin = await this.authService.hasPermission(RoleEnum.ADMIN);
      this.user = await this.authService.getCurrentUser();
      this.loading = false;
    } catch (e) {
      this.logger.error(e);
      this.toastr.error('HeaderComponent Error');
      this.loading = false;
    }
  }

  signOut(): void {
    this.authService
      .logOut()
      .then(() => {
        this.toastr.success('Logged out');
        this.router.navigate(['/login']);
      })
      .catch(err => {
        this.logger.error(err);
        this.toastr.error('Log out failed');
      });
  }

  goHomeByRole(): void {
    this.authService.navigateByRole();
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  openGeneratorModal(): void {
    const modalRef = this.modalService.open(GeneratorComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'generator-modal',
      backdropClass: 'generator-backdrop',
    });

    modalRef.result.then(
      () => {},
      () => {
        this._location.back();
      }
    );
  }
}
