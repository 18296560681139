/**
 * Contains all the enums required for the active directory authentication.
 */
export class AWSEnum {
  static GRANT_TYPE = 'authorization_code';
  static CLIENT_ID = '245fmu6ag8biihj1qjmm7h50to';
  static REDIRECT_URI = 'http://localhost:4200/login';
  static CONTENT_TYPE_HEADER = 'application/x-www-form-urlencoded';
  static CLIENT_SECRET = '108c9e3lpoehkh4d7o1oojdb92df4fliolvsds3oc1f6s0b7gbpm';
  static AUTHORIZATION_TYPE = 'Basic';
  static BASE_DOMAIN_LINK = 'https://cygov.auth.us-east-1.amazoncognito.com';
  static TOKEN_VERIFICATION_ENDPOINT = `${AWSEnum.BASE_DOMAIN_LINK}/oauth2/token`;
  static AD_IDP_NAME = 'AzureActiveDirectory';
}
