/* eslint-disable camelcase */
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Auth } from 'aws-amplify';
import { UserChallengeEnum } from '../shared/enums/userChallenge.enum';
import { AWSEnum } from '../shared/enums/aws.enum';
import {
  APIService,
  GetUserQuery,
  UpdateUserInput,
  RoleEnum,
  EntityTypeEnum,
  ModelUserFilterInput,
} from '../API.service';
import { EntityService } from 'app/shared/entity.service';
import { NGXLogger } from 'ngx-logger';
import { UtilsService } from 'app/shared/utils.service';
import awsExports from './../../aws-exports';

export interface Credentials {
  email: string;
  password: string;
  remember?: boolean;
}

const CYGOV_STORAGE = 'cygov_storage';
const COGNITO_USER = 'isCognitoUser';
const AD_USER = 'isADUser';
const ACCESS_TOKEN = 'ad-token';

@Injectable()
export class AuthService implements OnDestroy {
  storage: Storage = localStorage;
  user: GetUserQuery;
  authUser: any = null; // This is for cognito user
  tokenHTTP: HttpClient; // We need a separate instance of httpClient to bypass the interceptors.
  credentials;

  constructor(
    private router: Router,
    private handler: HttpBackend,
    private api: APIService,
    private entityService: EntityService,
    private logger: NGXLogger
  ) {
    this.tokenHTTP = new HttpClient(this.handler);
    if (sessionStorage.getItem(CYGOV_STORAGE)) {
      this.storage = sessionStorage;
    } else {
      this.storage = localStorage;
    }
  }

  get isCognitoUser(): boolean {
    try {
      const isCognitoUser = JSON.parse(this.storage.getItem(COGNITO_USER));
      return isCognitoUser ? isCognitoUser : null;
    } catch (_) {
      return null;
    }
  }

  setIsCognitoUser(value: boolean): void {
    this.storage.setItem(COGNITO_USER, String(value));
  }

  get isADUser(): boolean {
    try {
      const isADUser = JSON.parse(this.storage.getItem(AD_USER).toString());
      return isADUser ? isADUser : null;
    } catch (_) {
      return null;
    }
  }

  setIsADUser(value: boolean): void {
    this.storage.setItem(AD_USER, String(value));
  }

  get accessToken(): string {
    try {
      const accessToken = this.storage.getItem(ACCESS_TOKEN);
      return accessToken ? accessToken : null;
    } catch (_) {
      return null;
    }
  }

  setAccessToken(value: string): void {
    this.storage.setItem(ACCESS_TOKEN, value);
  }

  get currentAuthUser(): any {
    return this.authUser;
  }

  get isStorageLocal(): boolean {
    return this.storage === localStorage;
  }

  async isAuthenticated(): Promise<boolean> {
    return !!(await this.getCurrentUser());
  }

  async setFirstPassword(email: string, password: string, phone: string): Promise<string> {
    this.logger.log('In setFirstPassword');
    const currentUser = this.authUser;
    const loggedUser = await Auth.completeNewPassword(currentUser, password, {
      // eslint-disable-next-line @typescript-eslint/camelcase
      phone_number: phone,
    });
    this.logger.log('loggedUser', loggedUser);
    if (environment.useCognitoAuth) {
      this.setIsCognitoUser(true);
      this.authUser = loggedUser;
    } else {
      this.setIsCognitoUser(false);
    }
    return loggedUser.challengeName;
  }

  async signIn(credentials: Credentials): Promise<string> {
    const { email: username, password } = credentials;
    if (environment.useCognitoAuth) {
      this.setIsCognitoUser(true);
      try {
        this.authUser = await Auth.signIn(username, password);
        this.logger.log('CURRENT AUTH USER', this.authUser);
        return this.authUser.challengeName;
      } catch (e) {
        this.logger.error('signIn - Error: ', e);
        return Promise.reject(e);
      }
    } else {
      // TODO: UPDATE when migrate to cognito.
      this.setIsCognitoUser(false);
      // TODO: Implement migration method.
    }
  }

  async loginCodeConfirm(codeFromUser?: string): Promise<GetUserQuery> {
    try {
      const authUser = this.authUser;
      if (authUser) {
        const mfaType: any = UserChallengeEnum.SMS_MFA;
        this.authUser = await Auth.confirmSignIn(authUser, codeFromUser, mfaType);
        console.log('authenticated mfa user', this.authUser);
        return await this.getCurrentUser();
      }
    } catch (e) {
      this.logger.error('twoFactorPhase - Error: ', e);
      throw Error(e);
    }
  }

  async logOut(): Promise<void> {
    try {
      this.user = null;
      sessionStorage.removeItem(CYGOV_STORAGE);
      localStorage.removeItem(CYGOV_STORAGE);
      localStorage.removeItem(COGNITO_USER);
      localStorage.removeItem(AD_USER);
      localStorage.removeItem(ACCESS_TOKEN);
      await Auth.signOut();
      return;
    } catch (e) {
      this.logger.error('logOut - Error: ', e);
      return Promise.reject(e);
    }
  }

  async sendInviteEmail(userId: string, userEmail: string, userName: string): Promise<string> {
    try {
      const domain = EntityService.getAdminGroup();
      await this.api.SendInviteEmail(userId, userEmail, userName, domain);
    } catch (e) {
      this.logger.error('sendInviteEmail - Error: ', e);
      return Promise.reject(e);
    }
  }

  async removeUser(userId: string): Promise<void> {
    await this.api.DeleteUser({ id: userId });
  }

  async sendReminderEmail(userId: string, subEntityId: string, toAll = false): Promise<any> {
    try {
      return this.api.SendReminderEmail(userId, subEntityId);
    } catch (e) {
      this.logger.error('sendReminderEmail - Error: ', e);
      return Promise.reject(e);
    }
  }

  async getUserByEmail(userEmail: string): Promise<GetUserQuery> {
    try {
      const filter: ModelUserFilterInput = {
        email: { eq: userEmail },
      };

      return (await this.api.ListUsers(filter)).items.pop();
    } catch (e) {
      return Promise.resolve(null);
    }
  }

  async sendRestartEmail(userEmail: string): Promise<any> {
    this.setIsCognitoUser(true);
    return await Auth.forgotPassword(userEmail);
  }

  getCurrentUserSync(): GetUserQuery {
    return this.user ? this.user : null;
  }

  async getCurrentUser(): Promise<GetUserQuery> {
    this.authUser = await Auth.currentAuthenticatedUser();
    if (this.authUser && !this.user && this.authUser.attributes) {
      const uid = this.authUser.username;
      this.user = await this.api.GetUser(uid);
    }
    return this.user;
  }

  async isUserPresent(email: string): Promise<boolean> {
    const code = '000000';
    try {
      await Auth.confirmSignUp(email, code, {
        forceAliasCreation: false,
      });
      return true;
    } catch (error) {
      switch (error.code) {
        case 'UserNotFoundException':
          return false;
        // Below are the other possible codes which shoes user is present
        case 'NotAuthorizedException':
        case 'AliasExistsException':
        case 'CodeMismatchException':
        case 'ExpiredCodeException':
          return true;
        default:
          return true;
      }
    }
  }

  async updateCurrentUser(user: UpdateUserInput): Promise<GetUserQuery> {
    try {
      this.user = await this.api.UpdateUser(user);
      return this.user;
    } catch (e) {
      this.logger.error('updateCurrentUser - Error: ', e);
      return Promise.reject(e);
    }
  }

  async resendCode(): Promise<string> {
    try {
      return await this.signIn(this.credentials);
    } catch (e) {
      this.logger.error('Resend Code Error: ', e);
      return Promise.reject(e.message);
    }
  }

  /**
   * Fetch the authentication tokens for the user.
   *
   * @param token string The token  returned by SAML Auth.
   */
  getOAuthTokens(token: string): Promise<Record<string, any>> {
    const params = new HttpParams({
      fromObject: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        grant_type: AWSEnum.GRANT_TYPE,
        // eslint-disable-next-line @typescript-eslint/camelcase
        client_id: AWSEnum.CLIENT_ID,
        // eslint-disable-next-line @typescript-eslint/camelcase
        redirect_uri: AWSEnum.REDIRECT_URI,
        code: token,
      },
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': AWSEnum.CONTENT_TYPE_HEADER,
        Authorization:
          AWSEnum.AUTHORIZATION_TYPE + ' ' + btoa(AWSEnum.CLIENT_ID + ':' + AWSEnum.CLIENT_SECRET),
      }),
    };

    try {
      const result = this.tokenHTTP
        .post(AWSEnum.TOKEN_VERIFICATION_ENDPOINT, params, httpOptions)
        .toPromise();
      return result;
    } catch (e) {
      this.logger.error('OAuth get tokens - Error: ', e);
      return Promise.reject(e);
    }
  }

  async navigateByRole(): Promise<void> {
    let nextRoute: string[];
    try {
      const user: GetUserQuery = await this.getCurrentUser();

      if (!user || !user.role) {
        this.logger.error('User role is required!');
        await this.router.navigate(['']);
        return Promise.reject();
      }

      let entityId = null;

      if (user.role !== RoleEnum.ADMIN && user.role !== RoleEnum.MSSP) {
        if (user.entityId) {
          // if one entity id in permission ok to navigate
          // if multiple present navigate to first one
          entityId = user.entityId;
        } else {
          this.logger.error('User has no Permission!');
          await this.router.navigate(['']);
          return Promise.reject();
        }
      }
      const entity = entityId ? await this.entityService.getEntity(entityId) : null;
      switch (user.role) {
        case RoleEnum.ADMIN:
          nextRoute = ['clients'];
          break;
        case RoleEnum.MSSP:
          nextRoute = ['clients'];
          break;
        case RoleEnum.LEADER:
          // if one entity id in permission ok to navigate
          // if multiple present navigate to first one

          if (entity.entityType === EntityTypeEnum.ROOT_ENTITY) {
            nextRoute = [`first-party/${entityId}/upperdeck`];
          } else {
            nextRoute = UtilsService.isCRB
              ? [`first-party/${entity.parentId}/collection`, entity.id]
              : [`first-party/${entity.parentId}/multi-entity`, entity.id];
          }
          break;
        case RoleEnum.PARTICIPANT:
          // for now navigate to first entity
          nextRoute = [`first-party/${entity.parentId}/collection`, entity.id];
          break;
        case RoleEnum.VENDOR:
          nextRoute = [`first-party/${entity.parentId}/collection`, entity.id];
          break;

        default:
          nextRoute = ['**'];
      }

      this.router.navigate(nextRoute);
    } catch (e) {
      this.logger.error('navigateByRole - Error: ', e);
      await this.router.navigate(['']);
      return Promise.reject(e);
    }
  }

  async hasPermission(
    minRole: RoleEnum,
    entityId?: string,
    subEntityId?: string
  ): Promise<boolean> {
    try {
      const user: GetUserQuery = await this.getCurrentUser();
      let hasPermission = false;
      if (user) {
        switch (minRole) {
          case RoleEnum.ADMIN:
            hasPermission = user.role === RoleEnum.ADMIN;
            break;
          case RoleEnum.MSSP:
            hasPermission = user.role === RoleEnum.ADMIN || user.role === RoleEnum.MSSP;
            break;
          case RoleEnum.LEADER:
            hasPermission =
              user.role === RoleEnum.ADMIN ||
              user.role === RoleEnum.MSSP ||
              (user.role === RoleEnum.LEADER &&
                entityId &&
                (user.entityId === entityId || user.entityId === subEntityId));
            break;
          case RoleEnum.PARTICIPANT:
            hasPermission =
              user.role === RoleEnum.ADMIN ||
              user.role === RoleEnum.MSSP ||
              user.role === RoleEnum.LEADER ||
              (user.role === RoleEnum.PARTICIPANT &&
                entityId &&
                subEntityId &&
                (user.entityId === entityId || user.entityId === subEntityId));
            break;
          case RoleEnum.VENDOR:
            hasPermission =
              user.role === RoleEnum.ADMIN ||
              user.role === RoleEnum.MSSP ||
              user.role === RoleEnum.LEADER ||
              (user.role === RoleEnum.VENDOR &&
                entityId &&
                subEntityId &&
                user.entityId === entityId);
            break;
        }
      } else {
        this.logger.error('No user is found');
      }

      return Promise.resolve(hasPermission);
    } catch (e) {
      this.logger.error('hasPermission - Error: ', e);
      return Promise.reject(e);
    }
  }

  isEntityIdInUrl(url: string, entityId: string): boolean {
    let isIncluded = false;
    if (entityId && url.includes(entityId)) {
      isIncluded = true;
    }
    return isIncluded;
  }

  isAdmin(): boolean {
    return this.user && this.user.role === RoleEnum.ADMIN;
  }

  async addNewParticipant(newUser): Promise<string> {
    this.logger.log('user = ', newUser);
    const inputUser = JSON.stringify(newUser);
    const domain = EntityService.getAdminGroup();
    return await this.api.AddCognitoUser(inputUser, domain);
  }

  getCurrentEnvironment() {
    const envStart = awsExports.aws_content_delivery_bucket.lastIndexOf('-');
    return awsExports.aws_content_delivery_bucket.slice(envStart+1);
  }

  getRegion() {
    return awsExports.aws_appsync_region;
  }

  clearCache(): void {
    this.user = null;
  }

  ngOnDestroy(): void {
    this.clearCache();
  }
}
