import { Component, OnInit, OnDestroy } from '@angular/core';
import { RecaptchaComponent } from 'ng-recaptcha';
import { AuthService } from './auth/auth.service';
import { NavigationEnd } from '@angular/router';
import { RouterExtService } from './shared/router-external.service';
import { Subscription } from 'rxjs';
import { RoleEnum } from './API.service';

@Component({
  selector: 'cygov-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  showSidenav = true;
  routeChangedSubscription: Subscription;

  constructor(private routerExtService: RouterExtService, private authService: AuthService) {
    // check if user is authenticate in every route change
    this.routeChangedSubscription = this.routerExtService.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        const user = this.authService.getCurrentUserSync();
        this.isAuthenticated = !!user;
      }
    });
  }

  ngOnInit() {
    // https://github.com/DethAriel/ng-recaptcha/issues/123#issuecomment-426112101
    // eslint-disable-next-line @typescript-eslint/unbound-method
    RecaptchaComponent.prototype.ngOnDestroy = function() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    };
  }

  ngOnDestroy(): void {
    this.routeChangedSubscription.unsubscribe();
  }
}
