import { Component, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from '../../shared/file.service';
import {
  APIService,
  EntityTypeEnum,
  CreateEntityInput,
  GetEntityQuery,
  IndustryEnum,
} from 'app/API.service';
import { environment } from 'environments/environment';
import { GetEntityQueryExtended, EntityService } from 'app/shared/entity.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'app/auth/auth.service';
import { GetUserQuery } from '../../API.service';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { ConfirmationModalComponent } from 'app/shared/confirmation-modal/confirmation-modal.component';
import { GenerateDataComponent } from './generate-data/generate-data/generate-data.component';
import { Auth } from 'aws-amplify';
import { Lambda } from 'aws-sdk';
import awsmobile from 'aws-exports';

export interface FileType {
  name: string;
  data: any;
}

@Component({
  selector: 'cygov-entity-list',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.scss'],
})
export class EntityListComponent implements OnInit, OnDestroy {
  newEntity: CreateEntityInput;
  cachedEntitiesDetails: GetEntityQueryExtended[];
  displayEntities: GetEntityQueryExtended[] = [];
  queryText = '';
  imageFile: any;
  s3File: any;
  alertsFile: FileType;
  page: any;
  selectedEntity = null;
  industryOpt = Object.keys(IndustryEnum);
  logoFileSizeLimit = 1000000; // 1MB
  confirmationMessage = 'Do you really want to delete the selected Entity?';
  dataOverrideMsg = 'Are you sure you want to over ride the data?';
  selectedAnswers: number[];
  subscription: Subscription = EntityService.onDataChanged.subscribe((dataChanged: boolean) => {
    if (dataChanged) {
      this.ngOnInit();
    }
  });

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private elem: ElementRef,
    private api: APIService,
    private fileService: FileService,
    private entityService: EntityService,
    private authService: AuthService,
    private toastr: ToastrService,
    private logger: NGXLogger
  ) {}

  async ngOnInit() {
    // TODO: Add input "Select Project Manager" it's mandatory. In the mean time it will be the current user
    const projectManager: GetUserQuery = await this.authService.getCurrentUser();
    this.newEntity = EntityService.initEntity(EntityTypeEnum.ROOT_ENTITY, projectManager.id);
    this.s3File = null;
    try {
      this.cachedEntitiesDetails = await this.getExtendEntityList();
      this.displayEntities = this.cachedEntitiesDetails.map(item => item);
    } catch (e) {
      this.logger.error('EntityListComponent - Error: ', e);
    }
  }

  async getExtendEntityList(): Promise<GetEntityQueryExtended[]> {
    return await this.entityService.listEntitysByEntityType(
      EntityTypeEnum.ROOT_ENTITY,
      null,
      null,
      true
    );
  }

  async getAllChilds(entityId: string) {
    let result: any;
    let items: GetEntityQuery[];
    let nextToken;
    do {
      nextToken = result ? result.nextToken : null;
      result = await this.api.ListEntitys(null, environment.queryListLimit, nextToken);
      items = result.items;
    } while (result.nextToken);

    const promises = items.map(entity => this.entityService.setLogoUrl(entity));
    return Promise.all(promises);
  }

  goToEntityManagement(selectedEntity) {
    if (selectedEntity) {
      this.router.navigate([`admin/${selectedEntity.id}/sub-entity-list`]);
    } else {
      this.toastr.info('Please select entity from the list');
    }
  }

  selectEntity(entity, i) {
    this.selectedEntity = {
      id: entity.id,
      name: entity.name,
      row: i,
    };
  }

  searchEntities() {
    this.displayEntities = this.cachedEntitiesDetails.filter(entity => {
      return (
        !this.queryText ||
        entity.name.toLocaleLowerCase().includes(this.queryText.toLocaleLowerCase())
      );
    });
  }

  async createEntity() {
    // TODO: Add input "Select Project Manager" it's mandatory in the mean time it will be the current user
    const selectedUser: GetUserQuery = await this.authService.getCurrentUser();
    this.newEntity.projectManager = selectedUser.id;
    this.toastr.info('Creating Entity...');
    if (!this.isValid(this.newEntity)) {
      return;
    }
    try {
      if (this.s3File) {
        this.newEntity.logo = await this.fileService.uploadToS3(this.s3File);
      }
      const createdEntity = await this.entityService.createEntity(this.newEntity);
      this.toastr.success(`${createdEntity.name} added successfully!`);

      this.ngOnInit();
    } catch (e) {
      this.toastr.error(`Failed to create ${this.newEntity.name}`);
      this.logger.error('createEntity - Error: ', e);
      this.errorHandler(e);
    }
  }

  async imgHandler(e: any): Promise<void> {
    try {
      this.s3File = await this.fileService.getS3File(e, this.newEntity.id);
    } catch (error) {
      this.toastr.error(error);
    }
  }

  errorHandler(err) {
    if (err.type === 'server') {
      this.toastr.error('Fail to save in server');
    } else {
      this.logger.error(err);
      this.toastr.error('General failure');
    }
  }

  isValid(entity: CreateEntityInput) {
    let valid = true;
    if (!entity.name) {
      this.toastr.error('Entity name is required');
      valid = false;
    }

    return valid;
  }

  async removeEntity() {
    if (!this.selectedEntity) {
      this.toastr.info('Please select entity from the list');
    }

    try {
      this.toastr.info('Removing entity: ' + this.selectedEntity.name);
      await this.entityService.deleteEntity(this.selectedEntity.id);
      this.toastr.success('Entity removed successfully');
      this.ngOnInit();

      // update display list
      // this.searchEntities();
    } catch (e) {
      this.toastr.error('Removing entity failed');
      this.logger.error(e);
    }
  }

  async generateFakeData(entityId: string) {
    if (entityId) {
      try {
        this.toastr.info('Generating fake data...');
        await this.api.CreateFakeData(entityId);
        this.toastr.success('Fake data  added successfully!');
        this.ngOnInit();
      } catch (e) {
        this.errorHandler(e);
      }
    } else {
      this.logger.error('generateFakeData: entityId is required');
      this.toastr.error('Please select entity.');
    }
  }

  downloadFile(alerts) {
    const dataAsString =
      'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(alerts));
    const link = document.createElement('a');
    link.setAttribute('href', dataAsString);
    link.setAttribute('download', 'scene.json');
    link.click();
  }

  fireUploadFile(event, id: string) {
    event.preventDefault();
    const inputElement: HTMLElement = this.elem.nativeElement.querySelector(id);
    inputElement.click();
  }

  openConfirmationModal(message: string) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: 'sm',
      windowClass: 'confirmation-modal',
    });
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.modalResult.subscribe((event: boolean) => {
      this.removeEntity();
      modalRef.close();
    });
  }

  openGenerateConfirmModal(message: string) {
    if (this.selectedEntity) {
      const modalRef = this.modalService.open(GenerateDataComponent, {
        centered: true,
        size: 'lg',
        backdrop: 'static',
        windowClass: 'generate-data-modal',
        backdropClass: 'generate-data-backdrop',
      });
      modalRef.componentInstance.message = message;
      modalRef.componentInstance.modalResult.subscribe(async ({ flag, selectedAnswers }) => {
        this.selectedAnswers = selectedAnswers;
        if (flag) {
          const json = {
            arguments: {
              input: {
                entityId: this.selectedEntity.id,
                possibleAnswers: this.selectedAnswers,
              },
            },
          };
          const credentials = await Auth.currentCredentials();
          const lambda = new Lambda({
            credentials: Auth.essentialCredentials(credentials),
            region: awsmobile.aws_appsync_region,
          });
          const REGEX = /.*-(\w+)/;
          // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
          const env = awsmobile.aws_content_delivery_bucket.match(REGEX)[1];
          const params = {
            FunctionName: `generateEntityData-${env}`,
            Payload: JSON.stringify({ ...json }),
          };
          try {
            const { Payload: result } = await lambda.invoke(params).promise();
            this.toastr.success('Generator data added successfully!');
          } catch (error) {
            this.toastr.error('Issue while generating data');
            console.log('Error while Generating Entity Data frontend ' + JSON.stringify(error));
          }
          modalRef.componentInstance.loading = false;
          modalRef.close();
        } else {
          modalRef.close();
        }
      });
    } else {
      this.toastr.info('Please select entity from the list');
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
